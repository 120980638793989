import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

export const useEventCodeParams = () => {
    const { errorCodeId } = useParams<{ errorCodeId: string }>();

    const eventCodeParams = useMemo(() => errorCodeId?.split(','), [errorCodeId]);

    return {
        eventCodeParams
    };
};
