var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { faExclamation, faCheck, faInfo } from '@fortawesome/pro-solid-svg-icons';
import { capitalize } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { Icon } from '../Icon/Icon';
var Content = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: flex;\n    align-items: center;\n    margin: 8px;\n    padding: 12px 60px 12px 20px;\n    background-color: ", ";\n    color: #fff;\n    border-radius: 3px;\n    white-space: pre-line;\n"], ["\n    display: flex;\n    align-items: center;\n    margin: 8px;\n    padding: 12px 60px 12px 20px;\n    background-color: ", ";\n    color: #fff;\n    border-radius: 3px;\n    white-space: pre-line;\n"])), function (_a) {
    var theme = _a.theme, type = _a.type;
    return theme.palette.backgrounds.notifications[type].background;
});
var IconContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    min-width: 25px;\n    width: 25px;\n    height: 25px;\n    margin-right: 20px;\n    border-radius: 50%;\n    background-color: ", ";\n"], ["\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    min-width: 25px;\n    width: 25px;\n    height: 25px;\n    margin-right: 20px;\n    border-radius: 50%;\n    background-color: ", ";\n"])), function (_a) {
    var theme = _a.theme, type = _a.type;
    return theme.palette.backgrounds.notifications[type].iconBackground;
});
var TextContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    display: flex;\n    flex-direction: column;\n"], ["\n    display: flex;\n    flex-direction: column;\n"])));
var Title = styled.span(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    margin-bottom: 4px;\n    font-family: ", ";\n    font-size: 16px;\n    font-weight: 500;\n    line-height: 17px;\n    letter-spacing: 1.5px;\n"], ["\n    margin-bottom: 4px;\n    font-family: ", ";\n    font-size: 16px;\n    font-weight: 500;\n    line-height: 17px;\n    letter-spacing: 1.5px;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.fonts.families.title;
});
var Subtitle = styled.span(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    font-size: 14px;\n    line-height: 21px;\n    letter-spacing: 1px;\n"], ["\n    font-size: 14px;\n    line-height: 21px;\n    letter-spacing: 1px;\n"])));
export var ToastContent = function (_a) {
    var style = _a.style, className = _a.className, type = _a.type, description = _a.description, additionalInfo = _a.additionalInfo;
    var renderIconBasedOnType = function () {
        if (type === 'success') {
            return React.createElement(Icon, { style: { height: 12, width: 12, marginTop: 1 }, icon: faCheck });
        }
        if (type === 'info') {
            return React.createElement(Icon, { style: { height: 12, width: 5 }, icon: faInfo });
        }
        return React.createElement(Icon, { style: { height: 12, width: 5 }, icon: faExclamation });
    };
    return (React.createElement(Content, { style: style, className: className, type: type, "data-testid": "type-".concat(type) },
        React.createElement(IconContainer, { type: type }, renderIconBasedOnType()),
        React.createElement(TextContainer, null,
            React.createElement(Title, null, capitalize(type)),
            React.createElement(Subtitle, null, description),
            additionalInfo &&
                React.createElement(Subtitle, null,
                    React.createElement("br", null),
                    additionalInfo))));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
