import { setMode } from '@daisy/middleware-redux';
import { SegmentedControl, Suspense, Tabs } from '@daisy/react-components';
import { camelCase } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useLocation, useNavigate, useParams } from 'react-router-dom';

import { TTPViewSkeleton } from '@/components/Skeletons/TTPViewSkeleton';
import { OneColumn } from '@/components/styled/shared';
import { useSerialNumber } from '@/hooks/useSerialNumber';
import { useTtpFiles } from '@/hooks/useTtpFiles';
import { OneColStackedLayout } from '@/layouts/OneColStackedLayout';
import { RootState, useAppDispatch } from '@/store';
import { SerialNumberViewTabOptions, TruckUsageTabSubTabOptions } from '@/types';
import { TruckUsageChart } from './Tabs/TruckUsageChart';
import { TruckUsageDevicesTabView } from './Tabs/TruckUsageDevicesTabView';
import { TruckUsageEventsTabView } from './Tabs/TruckUsageEventsTabView';
import { TruckUsageObjectsTabView } from './Tabs/TruckUsageObjectsTabView';

const toggleOptions = [
    {
        label: 'Single',
        value: 'single'
    },
    {
        label: 'Compare',
        value: 'compare'
    }
];

export const SerialNumberTruckUsageTabView = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { serialNumber } = useSerialNumber();
    const { setExtFileIds, isLoading: isTtpLoading } = useTtpFiles(serialNumber);

    const { activeSubTab } = useParams<{
        activeSubTab: string;
    }>();

    const { uiLoading } = useSelector((state: RootState) => state.ui);
    const { mode } = useSelector((state: RootState) => state.usageGraph);

    const dispatch = useAppDispatch();

    const isLoading = isTtpLoading || uiLoading;

    const modeOnChangeHandler = (value: typeof mode) => {
        if (value === 'single') {
            setExtFileIds([]);
        }

        dispatch(setMode(value));
    };

    const navigateToSubTab = useCallback(
        (subTab: TruckUsageTabSubTabOptions) => {
            navigate(
                {
                    pathname: generatePath(
                        '/serial-number/:serialNumberId/:activeTab/:activeSubTab',
                        {
                            serialNumberId: serialNumber,
                            activeTab: SerialNumberViewTabOptions.TRUCK_USAGE,
                            activeSubTab: subTab
                        }
                    ),
                    search: location.search
                },
                {
                    replace: true
                }
            );
        },
        [location.search, navigate, serialNumber]
    );

    const tabList = useMemo(
        () => [
            {
                label: 'Events',
                name: 'events',
                onClick: () => navigateToSubTab(TruckUsageTabSubTabOptions.EVENTS)
            },
            {
                label: 'Objects',
                name: 'objects',
                onClick: () => navigateToSubTab(TruckUsageTabSubTabOptions.OBJECTS)
            },
            {
                label: 'Devices',
                name: 'devices',
                onClick: () => navigateToSubTab(TruckUsageTabSubTabOptions.DEVICES)
            }
        ],
        [navigateToSubTab]
    );

    return (
        <Suspense condition={!isLoading} fallback={<TTPViewSkeleton />}>
            <Tabs initialValue={activeSubTab ?? 'events'}>
                <OneColStackedLayout
                    dataTestId="truck-usage-content"
                    title="Truck operating hours"
                    isHeaderOutside
                    titleSuffix={
                        <SegmentedControl
                            name="toggleGroup"
                            value={mode}
                            options={toggleOptions}
                            onChange={modeOnChangeHandler}
                        />
                    }
                >
                    <div>
                        <TruckUsageChart />
                    </div>
                    <OneColumn>
                        <Tabs.Header>
                            {tabList.map((tab) => (
                                <Tabs.Button
                                    key={tab.name}
                                    name={tab.name}
                                    text={tab.label}
                                    onClick={tab.onClick}
                                    aria-label={tab.name}
                                    role="button"
                                    id={`tabButton-${camelCase(tab.name)}`}
                                />
                            ))}
                        </Tabs.Header>
                        <Tabs.Content>
                            {tabList.map((tab) => (
                                <Tabs.Panel key={tab.name} name={tab.name}>
                                    {tab.name === TruckUsageTabSubTabOptions.EVENTS && (
                                        <TruckUsageEventsTabView />
                                    )}
                                    {tab.name === TruckUsageTabSubTabOptions.OBJECTS && (
                                        <TruckUsageObjectsTabView />
                                    )}
                                    {tab.name === TruckUsageTabSubTabOptions.DEVICES && (
                                        <TruckUsageDevicesTabView />
                                    )}
                                </Tabs.Panel>
                            ))}
                        </Tabs.Content>
                    </OneColumn>
                </OneColStackedLayout>
            </Tabs>
        </Suspense>
    );
};
