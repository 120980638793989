"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CREATE_APPLICATION_EVENT = void 0;
const client_1 = require("@apollo/client");
class Spellbook {
    constructor(applicationId, applicationName, uuidGenerator) {
        this.setUserId = (userId) => {
            this.userId = userId;
        };
        this.setMutator = (mutator) => {
            this.mutator = mutator;
        };
        this.createRequestID = () => this.uuidGenerator();
        this.createEventChain = (eventChainName) => {
            const eventChainId = this.uuidGenerator();
            return (eventData) => this.sendApplicationEvent(eventChainId, eventChainName, eventData);
        };
        this.sendApplicationEvent = (eventChainId, eventChainName, eventData) => {
            if (!this.mutator) {
                throw new Error('No mutator found.');
            }
            const eventCreated = new Date();
            const data = {
                variables: {
                    input: Object.assign({ userId: this.userId || '', applicationId: this.applicationId, applicationName: this.applicationName, eventChainId,
                        eventChainName,
                        eventCreated }, eventData)
                }
            };
            return this.mutator(data);
        };
        this.userId = '';
        this.applicationId = applicationId;
        this.applicationName = applicationName;
        this.uuidGenerator = uuidGenerator;
    }
}
exports.default = Spellbook;
exports.CREATE_APPLICATION_EVENT = (0, client_1.gql) `
    mutation sendApplicationEvent($input: CreateApplicationEvent) {
        createApplicationEvent(input: $input) {
            externalId
            userId
            applicationId
            applicationName
            eventCreated
            eventChainId
            eventChainName
            eventName
            eventMeta
        }
    }
`;
