import { DatasetInfo } from '@/components/Infos/DatasetInfo';
import { useTruckModel } from '@/hooks/useTruckModel';
import { calculatePercentage } from '@/utils/helpers';

export const ObjectValueInspectorChartDatasetInfo: React.FC<{
    dataPointsCount: number;
    uniqueSerialNumbersCount: number;
    allSerialNumbersInTruckModelCount: number;
}> = ({ dataPointsCount, uniqueSerialNumbersCount, allSerialNumbersInTruckModelCount }) => {
    const { truckModel } = useTruckModel();

    const items = [
        {
            label: 'Data points',
            value: dataPointsCount
        },
        ...(truckModel
            ? [
                  {
                      label: 'Representation of serial numbers',
                      value: uniqueSerialNumbersCount
                  },
                  {
                      label: `Total serial numbers for ${truckModel?.softwareModelName}`,
                      value: allSerialNumbersInTruckModelCount
                  },
                  {
                      label: 'Percentage',
                      value: `${calculatePercentage(
                          uniqueSerialNumbersCount,
                          allSerialNumbersInTruckModelCount
                      )
                          .toFixed(2)
                          .toLocaleString()} %`
                  }
              ]
            : [])
    ];

    return <DatasetInfo items={items} />;
};
