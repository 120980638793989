import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import styled, { useTheme } from 'styled-components';

const TableContent = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1em;
    flex: 1;
`;

const TableContainer = styled.div`
    padding: 1em;
`;

export const TTPViewSkeleton = () => {
    const theme = useTheme();

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                padding: '0 1em'
            }}
        >
            <SkeletonTheme
                baseColor={theme.palette.skeletons.chart.color}
                highlightColor={theme.palette.skeletons.chart.highlightColor}
            >
                <Skeleton height={50} width={150} style={{ display: 'flex', margin: '2em auto' }} />
                <Skeleton height={300} style={{ marginBottom: '2em' }} />

                <TableContent>
                    <TableContainer>
                        <Skeleton height={300} />
                    </TableContainer>
                </TableContent>
            </SkeletonTheme>
        </div>
    );
};
