import { useGetSerialNumberTtpFilesQuery } from '@daisy/data-access';
import { last, uniqBy } from 'lodash';
import { useCallback, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

export const useTtpFiles = (serialNumberId?: string) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const extFileIds = searchParams.getAll('extFileId');

    const setExtFileIds = useCallback(
        (ids: string[]) => {
            setSearchParams(
                { extFileId: ids },
                {
                    replace: true
                }
            );
        },
        [setSearchParams]
    );

    const { data, error, loading } = useGetSerialNumberTtpFilesQuery({
        variables: { serialNumber: serialNumberId || '' },
        skip: !serialNumberId
    });

    useEffect(() => {
        if (!extFileIds?.length && data?.serialNumberTtpFiles?.length) {
            const latestFile = last(data.serialNumberTtpFiles);
            if (latestFile?.ttpFileExternalId) {
                setExtFileIds([latestFile.ttpFileExternalId]);
            }
        }
    }, [data?.serialNumberTtpFiles, extFileIds?.length, setExtFileIds]);

    const ttpFiles = data?.serialNumberTtpFiles
        ? uniqBy(data.serialNumberTtpFiles, ({ ttpFileTime }) => ttpFileTime)
        : undefined;

    const selectedTtpFiles = ttpFiles?.filter((file) =>
        file.ttpFileExternalId ? extFileIds.includes(file.ttpFileExternalId) : false
    );

    return {
        ttpFiles,
        selectedTtpFiles,
        extFileIds,
        setExtFileIds,
        isError: error,
        isLoading: loading
    };
};
