import { TruckToolOperationFragment } from '@daisy/data-access';
import { H3 } from '@daisy/react-components';
import { useEffect, useState } from 'react';
import { DateObject } from 'react-multi-date-picker';

import { StyledMiniChart } from '@/components/styled/shared';

export const TruckToolOperationsHistogramChart: React.FC<{
    eventsData: TruckToolOperationFragment[];
    selectedDate: DateObject;
}> = ({ eventsData, selectedDate }) => {
    const [histogramChartOptions, setHistogramChartOptions] = useState<Highcharts.Options>({
        plotOptions: {
            series: {
                dataGrouping: {
                    units: [['hour', [1]]],
                    forced: true,
                    enabled: true
                }
                // pointInterval: 3600 * 1000
            }
        },
        yAxis: {
            title: undefined,
            tickInterval: 1
        },
        xAxis: {
            type: 'datetime',
            tickInterval: 3600 * 1000,
            labels: {
                format: '{value:%H:%M}'
            },
            endOnTick: false
        },
        legend: {
            enabled: false
        },
        rangeSelector: {
            selected: 1,
            enabled: false
        },
        navigator: {
            enabled: false
        },
        scrollbar: {
            enabled: false
        },

        series: []
    });

    useEffect(() => {
        const minDate = new DateObject(selectedDate).setHour(0).setMinute(0).setSecond(0);
        const maxDate = new DateObject(minDate).setHour(23).setMinute(59).setSecond(59);

        setHistogramChartOptions({
            xAxis: {
                min: minDate.valueOf(),
                max: maxDate.valueOf()
            },
            series: [
                {
                    type: 'column',
                    id: 'eventsHistogram',
                    name: 'Number of events',
                    data: eventsData.map((event) => [new Date(event.time).getTime(), 1])
                }
            ]
        });
    }, [eventsData, selectedDate]);

    return (
        <>
            <H3>Events histogram</H3>
            <StyledMiniChart options={histogramChartOptions} />
        </>
    );
};
