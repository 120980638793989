import { CommonErrorHistoryEventFragment } from '@daisy/data-access';
import { H1, IconButton, Link, P1, SegmentedControl } from '@daisy/react-components';
import { find } from 'lodash';
import { useRef } from 'react';
import { useTheme } from 'styled-components';

import { DummyProgressBar } from '@/components/DummyProgressBar';
import { ErrorHandler } from '@/components/ErrorHandler';
import { DatasetInfo } from '@/components/Infos/DatasetInfo';
import { ErrorInfo } from '@/components/Infos/ErrorInfo';
import { TTPInfo } from '@/components/Infos/TTPInfo';
import { PageTitle } from '@/components/PageTitle';
import {
    PageContent,
    ProgressBarContainer,
    StyledPointChart,
    ViewContainer,
    ViewContent
} from '@/components/styled/shared';
import { useErrorCodeView } from '@/hooks/useErrorCodeView';
import { useEventCodeParams } from '@/hooks/useEventCodeParams';
import { TwoColStackedLayout } from '@/layouts/TwoColStackedLayout';
import { calculatePercentage } from '@/utils/helpers';
import { ChartToggle, getOptions } from '@/views/Charts/errorCodeChartConfig';
import { TitleArea } from './styles';

const timeframeSelectOptions = [
    { label: '30', value: 30 },
    { label: '60', value: 60 },
    { label: '90', value: 90 },
    { label: '180', value: 180 },
    { label: '365', value: 365 }
];

export const ErrorCodeView = () => {
    const selectedEventRef = useRef<HTMLDivElement>(null);
    const theme = useTheme();

    const {
        serialNumber,
        subtitle,
        truckModel,
        uniqueSerialNumbers,
        allSerialNumbersInTruckModel,
        eventData,
        chartToggleValue,
        setChartToggleValue,
        selectedEvent,
        setSelectedEvent,
        headerImage,
        isLoading,
        hasErrored,
        timeframeValue,
        setTimeframeValue
    } = useErrorCodeView();
    const { eventCodeParams } = useEventCodeParams();

    const handlePointClick = (
        clickedTtpFilename: string,
        time: string,
        errorMetaData: CommonErrorHistoryEventFragment
    ) => {
        setSelectedEvent({
            filename: clickedTtpFilename,
            time,
            serialNumber: errorMetaData?.serialNumber?.externalId ?? '',
            errorMetaData
        });

        selectedEventRef?.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'end',
            inline: 'nearest'
        });
    };

    const infoItems: { label: string; value: string | number }[] = [
        {
            label: 'Data points',
            value: eventData?.length
        }
    ];

    if (!serialNumber) {
        infoItems.push(
            ...[
                {
                    label: 'Representation of serial numbers',
                    value: uniqueSerialNumbers.length
                },
                {
                    label: `Total serial numbers for ${truckModel?.softwareModelName}`,
                    value: allSerialNumbersInTruckModel
                },
                {
                    label: 'Percentage',
                    value: `${calculatePercentage(
                        uniqueSerialNumbers.length,
                        allSerialNumbersInTruckModel
                    )
                        .toFixed(2)
                        .toLocaleString()} %`
                }
            ]
        );
    }

    return (
        <ViewContainer>
            <PageContent>
                <ViewContent>
                    <PageTitle
                        title={
                            serialNumber
                                ? `Serial Number: ${serialNumber}`
                                : `Truck Model: ${truckModel?.softwareModelName}`
                        }
                        subtitle={subtitle}
                        image={headerImage}
                    />
                    <TitleArea>
                        <Link
                            href={
                                serialNumber
                                    ? `/serial-number/${serialNumber}/error-history-events`
                                    : `/truck-model/${truckModel?.uuid}/error-history-events`
                            }
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '0.5em',
                                color: theme.palette.texts.black
                            }}
                        >
                            <IconButton icon="back" isTransparent />
                            <H1>{serialNumber ?? truckModel?.softwareModelName}</H1>
                        </Link>
                    </TitleArea>
                    <ErrorHandler hasErrored={hasErrored} />

                    {isLoading && (
                        <ProgressBarContainer>
                            <DummyProgressBar initialProgress={0} />
                        </ProgressBarContainer>
                    )}

                    <TwoColStackedLayout
                        dataTestId="errorCodeView-content"
                        title={`Event '${eventCodeParams?.join(', ')}' Counts for ${
                            serialNumber || truckModel?.softwareModelName
                        }`}
                        titleSuffix={
                            <div style={{ display: 'flex', gap: '1rem' }}>
                                <SegmentedControl
                                    isHorizontal
                                    isSmall
                                    name="timeframe"
                                    label="Time frame"
                                    value={timeframeValue ? parseInt(timeframeValue, 10) : null}
                                    options={timeframeSelectOptions}
                                    onChange={setTimeframeValue}
                                />
                                <SegmentedControl
                                    isSmall
                                    toggleKey="chartToggle"
                                    name="chartToggle"
                                    leftLabel={ChartToggle.SCATTER}
                                    rightLabel={ChartToggle.COLUMN}
                                    value={chartToggleValue}
                                    options={[
                                        {
                                            label: 'Scatter',
                                            value: ChartToggle.SCATTER
                                        },
                                        {
                                            label: 'Column',
                                            value: ChartToggle.COLUMN
                                        }
                                    ]}
                                    onChange={setChartToggleValue}
                                />
                            </div>
                        }
                    >
                        <>
                            <P1>Click and drag to zoom in. Hold down shift key to pan.</P1>
                            <StyledPointChart
                                options={
                                    eventData
                                        ? getOptions(eventData, chartToggleValue, handlePointClick)
                                        : []
                                }
                            />
                        </>
                        <DatasetInfo items={infoItems} />
                        {selectedEvent ? (
                            <>
                                <div>
                                    <TTPInfo
                                        serialNumber={
                                            serialNumber || selectedEvent?.serialNumber || ''
                                        }
                                        time={selectedEvent?.time}
                                        ttpFile={
                                            find(
                                                eventData,
                                                ({ ttpFile }) =>
                                                    ttpFile?.externalId === selectedEvent?.filename
                                            )?.ttpFile
                                        }
                                    />
                                </div>
                                <hr />
                                <div ref={selectedEventRef}>
                                    <ErrorInfo errorMetaData={selectedEvent?.errorMetaData} />
                                </div>
                            </>
                        ) : (
                            <P1>Click on a point to see parameter info.</P1>
                        )}
                    </TwoColStackedLayout>
                </ViewContent>
            </PageContent>
        </ViewContainer>
    );
};
