import { LoadingIndicator, Tabs } from '@daisy/react-components';
import { includes } from 'lodash';
import { useSelector } from 'react-redux';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

import { FeatureFlagGuard } from '@/components/FeatureFlagGuard';
import { useFeatureFlags } from '@/hooks/useFeatureFlags';
import { useSerialNumber } from '@/hooks/useSerialNumber';
import { PermissionGuard } from '@/providers/PermissionGuard';
import { RootState } from '@/store';
import { SerialNumberViewTabOptions } from '@/types';
import { ObjectValueInspectorView } from '@/views/ObjectValueInspector/ObjectValueInspectorView';
import { SerialNumberErrorHistoryEventsTabView } from '@/views/SerialNumber/SerialNumberErrorHistoryEventsTabView';
import { SerialNumberLiveDataTabView } from '@/views/SerialNumber/SerialNumberLiveDataTabView';
import { SerialNumberTruckToolOperationsView } from '@/views/SerialNumber/SerialNumberTruckToolOperationsView';
import { SerialNumberTruckUsageTabView } from '@/views/SerialNumber/SerialNumberTruckUsageTabView';

type RouteParams = {
    activeTab: string;
};

export const SerialNumberTabsView = () => {
    const { activeTab } = useParams<RouteParams>();
    const { serialNumber, isLoading } = useSerialNumber();

    const { permissions } = useSelector((store: RootState) => store.user);
    const featureFlags = useFeatureFlags();
    const navigate = useNavigate();

    if (isLoading) return <LoadingIndicator />;

    const tabList = [
        {
            name: 'error-history-events',
            label: 'Error History Events',
            onClick: () =>
                navigate({
                    pathname: generatePath('/serial-number/:serialNumber/:activeTab', {
                        serialNumber,
                        activeTab: SerialNumberViewTabOptions.ERROR_HISTORY_EVENTS
                    })
                })
        },
        {
            name: 'truck-usage',
            label: 'Truck Usage',
            onClick: () =>
                navigate({
                    pathname: generatePath('/serial-number/:serialNumber/:activeTab', {
                        serialNumber,
                        activeTab: SerialNumberViewTabOptions.TRUCK_USAGE
                    })
                })
        },
        {
            name: 'object-value-inspector',
            label: 'Object Value Inspector',
            onClick: () =>
                navigate({
                    pathname: generatePath('/serial-number/:serialNumber/:activeTab', {
                        serialNumber,
                        activeTab: SerialNumberViewTabOptions.OBJECT_VALUE_INSPECTOR
                    })
                })
        }
    ];

    if (featureFlags?.truckToolOperations && includes(permissions, 'access:trucktool-operations')) {
        tabList.push({
            name: 'trucktool-operations',
            label: 'TruckTool Operations',
            onClick: () =>
                navigate({
                    pathname: generatePath('/serial-number/:serialNumber/:activeTab', {
                        serialNumber,
                        activeTab: SerialNumberViewTabOptions.TRUCKTOOL_OPERATIONS
                    })
                })
        });
    }

    if (featureFlags?.iotLiveData && includes(permissions, 'access:live-data')) {
        tabList.push({
            name: 'iot-live-data',
            label: 'Live Data',
            onClick: () =>
                navigate({
                    pathname: generatePath('/serial-number/:serialNumber/:activeTab', {
                        serialNumber,
                        activeTab: SerialNumberViewTabOptions.IOT_LIVE_DATA
                    })
                })
        });
    }

    return (
        <Tabs initialValue={activeTab}>
            <Tabs.Header>
                {tabList.map((tab) => (
                    <Tabs.Button
                        key={tab.name}
                        name={tab.name}
                        text={tab.label}
                        onClick={tab.onClick}
                    />
                ))}
            </Tabs.Header>
            <Tabs.Content>
                {tabList.map((tab) => (
                    <Tabs.Panel key={tab.name} name={tab.name}>
                        {tab.name === 'error-history-events' && (
                            <SerialNumberErrorHistoryEventsTabView />
                        )}
                        {tab.name === 'truck-usage' && <SerialNumberTruckUsageTabView />}
                        {tab.name === 'object-value-inspector' && <ObjectValueInspectorView />}
                        {tab.name === 'trucktool-operations' && (
                            <FeatureFlagGuard feature="truckToolOperations">
                                <PermissionGuard permission="access:trucktool-operations">
                                    <SerialNumberTruckToolOperationsView />
                                </PermissionGuard>
                            </FeatureFlagGuard>
                        )}
                        {tab.name === 'iot-live-data' && (
                            <FeatureFlagGuard feature="iotLiveData">
                                <SerialNumberLiveDataTabView />
                            </FeatureFlagGuard>
                        )}
                    </Tabs.Panel>
                ))}
            </Tabs.Content>
        </Tabs>
    );
};
