import { map, uniqBy } from 'lodash';
import { CommonErrorHistoryEventFragment } from '@daisy/data-access';

import { CustomPointOptionsObject } from '@/types';

export enum ChartToggle {
    SCATTER = 'scatter',
    COLUMN = 'column'
}

const SERIES_KEYS = ['x', 'y', 'time', 'eventName', 'ttpFilename', 'errorMetaData'];

export const getOptions = (
    data: CommonErrorHistoryEventFragment[],
    chartToggleValue: `${ChartToggle}`,
    handlePointClick: (
        ttpFilename: string,
        time: string,
        errorMetaData: CommonErrorHistoryEventFragment
    ) => void
): Highcharts.Options => ({
    chart: {
        zooming: { type: 'xy' },
        resetZoomButton: {
            position: { x: 0, y: -50 }
        },
        panning: {
            enabled: true,
            type: 'xy'
        },
        spacingLeft: 0,
        spacingTop: 0,
        panKey: 'shift'
    },
    subtitle: { align: 'left', style: { fontSize: undefined, color: undefined } },
    legend: {
        maxHeight: 100
    },
    xAxis: {
        title: {
            text: ''
        },
        categories: createXAxisCategories(data),
        tickInterval: Math.floor(data.length / 10)
    },
    yAxis: {
        title: {
            text: ''
        },
        maxPadding: 0.3
    },
    series: createSeries(data, chartToggleValue),
    plotOptions: {
        column: { states: { select: { color: '#F39649', borderColor: 'none' } } },
        series: {
            cursor: 'pointer',
            cropThreshold: 5000,
            point: {
                events: {
                    click(e) {
                        const { ttpFilename, time, errorMetaData } = e.point
                            .options as CustomPointOptionsObject;
                        handlePointClick(ttpFilename, time, errorMetaData);
                    },
                    mouseOver() {
                        if ((this.series as any).halo) {
                            (this.series as any).halo
                                .attr({
                                    class: 'highcharts-tracker',
                                    style: 'cursor: pointer'
                                })
                                .toFront();
                        }
                    }
                }
            },
            allowPointSelect: true,
            marker: {
                states: {
                    select: { fillColor: '#F39649', lineWidth: 4, lineColor: '#FFE9DD', radius: 7 }
                }
            }
        }
    },
    tooltip: {
        enabled: true,
        useHTML: true,
        hideDelay: 100,
        headerFormat: '<small>{point.key}</small><table>',
        pointFormatter() {
            if (this) {
                const firstRow = `<tr><td><b style="font-size: 16px;">${this.y}</b></td></tr>`;
                const secondRow = `<tr><td>${
                    (this.options as CustomPointOptionsObject).eventName
                }</td></tr>`;
                const thirdRow = `<tr><td>${
                    (this.options as CustomPointOptionsObject).errorMetaData.serialNumber
                        ?.externalId ?? ''
                }</td></tr>`;
                return firstRow + secondRow + thirdRow;
            }
            return '';
        },
        footerFormat: '</table>',
        style: {
            pointerEvents: 'none'
        }
    }
});

export const createXAxisCategories = (eventsData: CommonErrorHistoryEventFragment[]) => {
    const formatter = new Intl.DateTimeFormat();
    return map(eventsData, (entry) => formatter.format(new Date(entry.time)));
}

export function createSeries(
    eventsData: NonNullable<CommonErrorHistoryEventFragment[]>,
    chartToggleValue: `${ChartToggle}`
): Highcharts.SeriesOptionsType[] {
    const uniqueCodes = map(uniqBy(eventsData, 'eventCode'), 'eventCode');

    return uniqueCodes.map((code) => ({
        name: code || '',
        type: chartToggleValue,
        data: eventsData.map((value, index) =>
            createSeriesData(value.eventCode === code, value, index)
        ),
        keys: SERIES_KEYS
    }));
}

function createSeriesData(
    condition: boolean,
    value: CommonErrorHistoryEventFragment,
    index: number
) {
    const eventCount = value.eventCount !== null ? value.eventCount : 1;

    return [
        index,
        condition ? eventCount : null,
        value.time,
        value.event?.name ?? 'Event',
        value.ttpFile.externalId,
        { ...value }
    ];
}
