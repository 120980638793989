"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.clearQueryState = exports.setSerialNumberFilterActive = exports.setSelectedData = exports.setSelectedClustering = exports.setSelectedXAxis = exports.setReferenceObjects = exports.setReferenceObjectQueryFilters = exports.setReferenceObjectName = exports.setMainObjectQueryFilters = exports.setMainObjectName = exports.setQueryStringIsCollapsed = exports.setQueryState = exports.queryEditorSlice = exports.initialState = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const redux_persist_1 = require("redux-persist");
const queryEditorTypes_1 = require("../../types/queryEditorTypes");
exports.initialState = {
    queryStringIsCollapsed: true,
    mainObject: {
        object: { label: null, name: null },
        where: { AND: [] }
    },
    referenceObjects: [],
    selectedXAxis: queryEditorTypes_1.XAxisDefaultSelections.TIME,
    selectedClustering: queryEditorTypes_1.ClusteringDefaultSelections.NO_SELECTION,
    selectedData: undefined,
    serialNumberFilterActive: false,
    showOnlyLatestTTP: false,
    showOnlyFactoryDefaultTTP: false
};
exports.queryEditorSlice = (0, toolkit_1.createSlice)({
    name: 'queryEditor',
    initialState: exports.initialState,
    reducers: {
        setQueryState: (_state, action) => (Object.assign({}, action.payload)),
        setQueryStringIsCollapsed: (state, action) => {
            state.queryStringIsCollapsed = action.payload;
        },
        setMainObjectName: (state, action) => {
            state.mainObject.object = action.payload;
        },
        setMainObjectQueryFilters: (state, action) => {
            state.mainObject.where = action.payload;
        },
        setReferenceObjectName: (state, action) => {
            state.referenceObjects[action.payload.index].object = action.payload.object;
        },
        setReferenceObjectQueryFilters: (state, action) => {
            state.referenceObjects[action.payload.index].where = action.payload.where;
        },
        setReferenceObjects: (state, action) => {
            state.referenceObjects = action.payload;
        },
        setSelectedXAxis: (state, action) => {
            state.selectedXAxis = action.payload;
        },
        setSelectedClustering: (state, action) => {
            state.selectedClustering = action.payload;
        },
        setSelectedData: (state, action) => {
            state.selectedData = action.payload;
        },
        setSerialNumberFilterActive: (state, action) => {
            state.serialNumberFilterActive = action.payload;
        },
        clearQueryState: (state) => {
            state.mainObject = exports.initialState.mainObject;
            state.referenceObjects = exports.initialState.referenceObjects;
            state.selectedXAxis = exports.initialState.selectedXAxis;
            state.selectedClustering = exports.initialState.selectedClustering;
            state.selectedData = exports.initialState.selectedData;
            state.serialNumberFilterActive = exports.initialState.serialNumberFilterActive;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(redux_persist_1.PURGE, (state) => {
            state.mainObject = exports.initialState.mainObject;
            state.referenceObjects = exports.initialState.referenceObjects;
            state.selectedXAxis = exports.initialState.selectedXAxis;
            state.selectedClustering = exports.initialState.selectedClustering;
            state.selectedData = exports.initialState.selectedData;
            state.serialNumberFilterActive = exports.initialState.serialNumberFilterActive;
        });
    }
});
_a = exports.queryEditorSlice.actions, exports.setQueryState = _a.setQueryState, exports.setQueryStringIsCollapsed = _a.setQueryStringIsCollapsed, exports.setMainObjectName = _a.setMainObjectName, exports.setMainObjectQueryFilters = _a.setMainObjectQueryFilters, exports.setReferenceObjectName = _a.setReferenceObjectName, exports.setReferenceObjectQueryFilters = _a.setReferenceObjectQueryFilters, exports.setReferenceObjects = _a.setReferenceObjects, exports.setSelectedXAxis = _a.setSelectedXAxis, exports.setSelectedClustering = _a.setSelectedClustering, exports.setSelectedData = _a.setSelectedData, exports.setSerialNumberFilterActive = _a.setSerialNumberFilterActive, exports.clearQueryState = _a.clearQueryState;
exports.default = exports.queryEditorSlice.reducer;
