import { useAuth0 } from '@auth0/auth0-react';
import { Button, Icon } from '@daisy/react-components';
import { faSignIn } from '@fortawesome/pro-solid-svg-icons';
import styled from 'styled-components';

const StyledButton = styled(Button)`
    margin-right: 1em;
`;

export const LoginButton = () => {
    const { loginWithRedirect } = useAuth0();

    return (
        <StyledButton onClick={() => loginWithRedirect()} isPrimary>
            <Icon icon={faSignIn} />
            Log in
        </StyledButton>
    );
};
