var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { faLongArrowLeft } from '@fortawesome/pro-solid-svg-icons';
import React from 'react';
import styled from 'styled-components';
import { Icon } from '../Icon/Icon';
import { H2 } from '../Typography/H2';
import { H3 } from '../Typography/H3';
import { H4 } from '../Typography/H4';
import { P1 } from '../Typography/P1';
var paddings = {
    none: '0',
    small: '1.5em',
    default: '2rem'
};
var CardContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    background-color: ", ";\n    box-shadow: ", ";\n    ", "\n    border-radius: ", ";\n    width: 100%;\n"], ["\n    background-color: ", ";\n    box-shadow: ", ";\n    ", "\n    border-radius: ", ";\n    width: 100%;\n"])), function (_a) {
    var isTransparent = _a.isTransparent, isInner = _a.isInner, theme = _a.theme;
    return isTransparent ? 'transparent' : isInner ? theme.palette.silver : '#fff';
}, function (_a) {
    var theme = _a.theme, isTransparent = _a.isTransparent, isInner = _a.isInner, isSmall = _a.isSmall;
    if (isTransparent || isInner)
        return 'none';
    if (isSmall)
        return theme.palette.card.smallShadow;
    return theme.palette.card.shadow;
}, function (_a) {
    var isInner = _a.isInner, theme = _a.theme;
    return isInner && "border: 1px solid ".concat(theme.palette.platinum, ";");
}, function (_a) {
    var isTransparent = _a.isTransparent;
    return (isTransparent ? '0' : '8px');
});
var CardImage = styled.img(function (_a) {
    var imageStyle = _a.imageStyle, isTransparent = _a.isTransparent;
    return (__assign({ width: '100%', objectFit: 'contain', height: '100%', maxHeight: '120px', padding: '1em', backgroundColor: '#f9fbfd', verticalAlign: 'bottom', borderRadius: isTransparent ? '0' : '8px 8px 0 0' }, imageStyle));
});
var CardHeader = styled.header(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    display: flex;\n    flex-direction: column;\n    gap: 0.5rem;\n    padding: ", ";\n\n    ", "\n\n    @media (max-width: 768px) {\n        align-items: flex-start;\n        flex-direction: column;\n        padding: ", ";\n    }\n\n    ", "\n\n    ", "\n"], ["\n    display: flex;\n    flex-direction: column;\n    gap: 0.5rem;\n    padding: ", ";\n\n    ", "\n\n    @media (max-width: 768px) {\n        align-items: flex-start;\n        flex-direction: column;\n        padding: ", ";\n    }\n\n    ", "\n\n    ", "\n"])), function (_a) {
    var isTransparent = _a.isTransparent, isHeaderOutside = _a.isHeaderOutside, _b = _a.padding, padding = _b === void 0 ? 'default' : _b;
    return isTransparent || isHeaderOutside ? "0 0 ".concat(paddings[padding], " 0") : paddings[padding];
}, function (_a) {
    var isSegmented = _a.isSegmented, isTransparent = _a.isTransparent, isHeaderOutside = _a.isHeaderOutside;
    return !isSegmented && !isTransparent && !isHeaderOutside && 'padding-bottom: 0;';
}, function (_a) {
    var isTransparent = _a.isTransparent;
    return (isTransparent ? '0' : '1.5rem');
}, function (_a) {
    var isSegmented = _a.isSegmented, theme = _a.theme;
    return isSegmented &&
        "\n        border-bottom: 1px solid ".concat(theme.palette.platinum, ";\n    ");
}, function (_a) {
    var isSmall = _a.isSmall;
    return isSmall &&
        "\n        padding: 1rem 1.5rem;\n    ";
});
var CardHeaderRow = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    gap: 1em;\n\n    @media (max-width: 768px) {\n        align-items: flex-start;\n        flex-direction: column;\n    }\n"], ["\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    gap: 1em;\n\n    @media (max-width: 768px) {\n        align-items: flex-start;\n        flex-direction: column;\n    }\n"])));
var CardContent = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    border-radius: 0 0 8px 8px;\n    padding: ", ";\n\n    @media (max-width: 768px) {\n        padding: ", ";\n    }\n\n    ", "\n"], ["\n    border-radius: 0 0 8px 8px;\n    padding: ", ";\n\n    @media (max-width: 768px) {\n        padding: ", ";\n    }\n\n    ", "\n"])), function (_a) {
    var isTransparent = _a.isTransparent, _b = _a.padding, padding = _b === void 0 ? 'default' : _b;
    return isTransparent ? '0' : paddings[padding];
}, function (_a) {
    var isTransparent = _a.isTransparent;
    return (isTransparent ? '0' : '1.5rem');
}, function (_a) {
    var isSmall = _a.isSmall;
    return isSmall &&
        "\n        padding: 2rem 1.5rem;\n    ";
});
var CardH2 = styled(H2)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    padding-bottom: 0;\n"], ["\n    padding-bottom: 0;\n"])));
var titleComponent = {
    h2: H2,
    h3: H3,
    h4: H4
};
export var Card = function (_a) {
    var style = _a.style, className = _a.className, handleClick = _a.handleClick, imageSource = _a.imageSource, imageStyle = _a.imageStyle, title = _a.title, titleSuffix = _a.titleSuffix, subtitle = _a.subtitle, hasBackButton = _a.hasBackButton, isTransparent = _a.isTransparent, isSegmented = _a.isSegmented, isHeaderOutside = _a.isHeaderOutside, isSmall = _a.isSmall, _b = _a.headingLevel, headingLevel = _b === void 0 ? 'h2' : _b, padding = _a.padding, contentPadding = _a.contentPadding, contentBackground = _a.contentBackground, children = _a.children, dataTestId = _a.dataTestId, rest = __rest(_a, ["style", "className", "handleClick", "imageSource", "imageStyle", "title", "titleSuffix", "subtitle", "hasBackButton", "isTransparent", "isSegmented", "isHeaderOutside", "isSmall", "headingLevel", "padding", "contentPadding", "contentBackground", "children", "dataTestId"]);
    var header = (React.createElement(CardHeader, { isSegmented: isSegmented, isTransparent: isTransparent, isHeaderOutside: isHeaderOutside, padding: padding, isSmall: isSmall },
        React.createElement(CardHeaderRow, null,
            hasBackButton && React.createElement(Icon, { icon: faLongArrowLeft, style: { marginRight: 15 } }),
            React.createElement(CardH2, { as: titleComponent[headingLevel] }, title),
            titleSuffix),
        subtitle && React.createElement(P1, null, subtitle)));
    return (React.createElement("div", { "data-testid": dataTestId },
        isHeaderOutside && !!title && header,
        React.createElement(CardContainer, __assign({ style: style, className: className, onClick: handleClick, isTransparent: isTransparent, isSmall: isSmall }, rest),
            imageSource && (React.createElement(CardImage, { isTransparent: isTransparent, src: imageSource, imageStyle: imageStyle })),
            !isHeaderOutside && !!title && header,
            React.createElement(CardContent, { isSegmented: isSegmented, isTransparent: isTransparent, padding: contentPadding || padding, isSmall: isSmall, style: { backgroundColor: contentBackground } }, children))));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
