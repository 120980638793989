import { setMode } from '@daisy/middleware-redux';
import { Knob } from '@daisy/react-components';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { TTPDownloadButton } from '@/components/Buttons/TTPDownloadButton';
import { StyledChart } from '@/components/styled/shared';
import { useSerialNumber } from '@/hooks/useSerialNumber';
import { useTtpFiles } from '@/hooks/useTtpFiles';
import { RootState, useAppDispatch } from '@/store';
import { getChartColor } from '@/utils/helpers';
import { getOptions } from '@/views/Charts/TTPChartConfig';

export const TruckUsageChart = () => {
    const { serialNumber } = useSerialNumber();
    const { ttpFiles, selectedTtpFiles, extFileIds, setExtFileIds } = useTtpFiles(serialNumber);
    const [hideNulls, setHideNulls] = useState(false);

    const { mode } = useSelector((state: RootState) => state.usageGraph);

    const modeRef = useRef<typeof mode>();
    const extFileIdsRef = useRef<string[]>();
    const setExtFileIdsRef = useRef<typeof setExtFileIds>();

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (extFileIds.length > 1 && mode !== 'compare') dispatch(setMode('compare'));
        // We need to use the mode inside a closure so we store it in a ref
        modeRef.current = mode;
        // We need to use the extFileIds and setExtFileIds inside a closure so we store it in a ref
        extFileIdsRef.current = extFileIds;
        setExtFileIdsRef.current = setExtFileIds;
    }, [dispatch, extFileIds, mode, setExtFileIds]);

    const chartData = useMemo(
        () =>
            ttpFiles
                ?.filter((file) => (hideNulls ? !!file.operatingHours : true))
                .map((file) => ({
                    y: file.operatingHours || 0,
                    id: file.ttpFileExternalId || '',
                    date: file.ttpFileTime as string,
                    serialNumber: file.serialNumber || '',
                    color: getChartColor(extFileIds.indexOf(file.ttpFileExternalId || ''))
                })),
        [extFileIds, hideNulls, ttpFiles]
    );

    const handlePointSelect = useCallback((point: any) => {
        if (!setExtFileIdsRef.current) return;
        if (modeRef.current === 'single') {
            setExtFileIdsRef.current([point.id]);
        }

        if (modeRef.current === 'compare') {
            const currentFileIds = extFileIdsRef.current || [];
            if (!extFileIdsRef.current?.includes(point.id)) {
                setExtFileIdsRef.current([...currentFileIds, point.id]);
            } else {
                setExtFileIdsRef.current(currentFileIds.filter((id) => id !== point.id));
            }
        }
    }, []);

    const chartOptions = getOptions(
        chartData || [],
        handlePointSelect,
        () => {},
        () => {}
    );

    return (
        <>
            <StyledChart options={chartOptions} />
            {serialNumber &&
                selectedTtpFiles?.length === 1 &&
                selectedTtpFiles?.[0]?.ttpFileExternalId && (
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Knob
                            value={0}
                            name="filterNulls"
                            label="Hide TTP files with no operating hours"
                            onChange={() => setHideNulls(!hideNulls)}
                            isChecked={hideNulls}
                        />
                        <TTPDownloadButton
                            serialNumber={serialNumber}
                            ttpFile={{
                                time: selectedTtpFiles[0].ttpFileTime,
                                externalId: selectedTtpFiles[0].ttpFileExternalId
                            }}
                        />
                    </div>
                )}
        </>
    );
};
