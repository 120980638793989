import { SerialNumberTelematicsUsageTimesFragment } from '@daisy/data-access';
import { Table, TableColumn, TableMain, TablePager } from '@daisy/react-components';
import { groupBy, map, merge } from 'lodash';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useSerialNumber } from '@/hooks/useSerialNumber';
import { useTtpFiles } from '@/hooks/useTtpFiles';
import { RootState } from '@/store';
import { getSign, tableSortWithNulls } from '@/utils/helpers';
import { transformData } from '@/utils/truckUsageHelpers';

export const TruckUsageDevicesTable = ({
    data,
    type
}: {
    data: SerialNumberTelematicsUsageTimesFragment['data'];
    type: SerialNumberTelematicsUsageTimesFragment['type'];
}) => {
    const { mode } = useSelector((state: RootState) => state.usageGraph);
    const { serialNumber } = useSerialNumber();
    const { selectedTtpFiles, extFileIds } = useTtpFiles(serialNumber);

    const tableData = useMemo(() => {
        let result = [];
        if (selectedTtpFiles) {
            result = transformData(data, mode, type);

            if (mode === 'compare') {
                // Group TTP file values into same rows
                result = map(groupBy(result, 'name'), (obj) => merge({}, ...obj));
            }
        }
        return result;
    }, [data, mode, selectedTtpFiles, type]);

    const columns: TableColumn<any>[] = useMemo(() => {
        const formatter = new Intl.DateTimeFormat();
        if (selectedTtpFiles) {
            if (mode === 'single')
                return [
                    ...headerBaseStructure,
                    {
                        Header: 'Value',
                        accessor: 'value',
                        sortType: tableSortWithNulls
                    }
                ];

            if (mode === 'compare') {
                const headerStructure = [
                    ...headerBaseStructure,
                    ...selectedTtpFiles.flatMap((item, index) => {
                        const result: any = [
                            {
                                Header: formatter.format(new Date(item.ttpFileTime)),
                                accessor: `${item.ttpFileExternalId}-value`,
                                sortType: tableSortWithNulls
                            }
                        ];

                        if (index > 0) {
                            result.push({
                                Header: 'Delta',
                                accessor: `${item.ttpFileExternalId}-delta`,
                                sortType: tableSortWithNulls,
                                Cell: DeltaCell
                            });
                        }

                        return result;
                    })
                ];

                return headerStructure;
            }
        }

        return [];
    }, [mode, selectedTtpFiles]);

    const doRender = !!selectedTtpFiles && !!data?.length;

    return (
        <>
            {doRender && (
                <Table columns={columns} data={tableData}>
                    <TableMain highlightedCell={`${extFileIds[0]}-value`} isSortable />
                    <TablePager />
                </Table>
            )}
        </>
    );
};

const DeltaCell = ({ value }: { value: number }) => (value !== undefined ? getSign(value) : null);

const headerBaseStructure = [
    {
        Header: 'Name',
        accessor: 'name'
    }
];
