var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { faChevronLeft, faChevronRight, faEllipsisH } from '@fortawesome/pro-solid-svg-icons';
import { range } from 'lodash';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useTableContext } from './Table';
import { Icon } from '../Icon/Icon';
import { Button } from '../Button/Button';
import { LoadingIndicator } from '../LoadingIndicator/LoadingIndicator';
export function TablePager() {
    var _a = useTableContext(), table = _a.table, isLoading = _a.isLoading;
    var _b = table !== null && table !== void 0 ? table : { state: { pageIndex: undefined } }, nextPage = _b.nextPage, previousPage = _b.previousPage, canNextPage = _b.canNextPage, canPreviousPage = _b.canPreviousPage, pageOptions = _b.pageOptions, gotoPage = _b.gotoPage, pageIndex = _b.state.pageIndex;
    var pagerRange = useMemo(function () {
        if (typeof pageIndex !== 'undefined' && !!(pageOptions === null || pageOptions === void 0 ? void 0 : pageOptions.length))
            return getRange(pageIndex, pageOptions.length);
        return undefined;
    }, [pageIndex, pageOptions === null || pageOptions === void 0 ? void 0 : pageOptions.length]);
    if (!table ||
        typeof pageIndex === 'undefined' ||
        !previousPage ||
        !gotoPage ||
        !pageOptions ||
        !nextPage)
        return React.createElement(React.Fragment, null);
    return (React.createElement(Pager, null,
        React.createElement(PagerButton, { type: "button", onClick: function () { return previousPage(); }, disabled: !canPreviousPage, isPrimary: false },
            React.createElement(Icon, { icon: faChevronLeft, className: "fa-xs" })),
        pageIndex >= 4 && !(pagerRange === null || pagerRange === void 0 ? void 0 : pagerRange.includes(0)) && (React.createElement(React.Fragment, null,
            React.createElement(PagerButton, { type: "button", onClick: function () { return gotoPage(0); }, isPrimary: false }, 1),
            React.createElement(PagerDots, null))), pagerRange === null || pagerRange === void 0 ? void 0 :
        pagerRange.map(function (pageNumber) { return (React.createElement(PagerButton, { key: pageNumber, type: "button", onClick: function () { return gotoPage(pageNumber); }, isPrimary: pageNumber === pageIndex }, pageNumber + 1)); }),
        pageIndex + 1 <= pageOptions.length - 4 &&
            !(pagerRange === null || pagerRange === void 0 ? void 0 : pagerRange.includes(pageOptions.length - 1)) && (React.createElement(React.Fragment, null,
            React.createElement(PagerDots, null),
            React.createElement(PagerButton, { type: "button", onClick: function () { return gotoPage(pageOptions.length - 1); }, disabled: !canNextPage, isPrimary: false }, pageOptions.length))),
        React.createElement(PagerButton, { type: "button", onClick: function () { return nextPage(); }, disabled: !canNextPage, isPrimary: false },
            React.createElement(Icon, { icon: faChevronRight, className: "fa-xs" })),
        React.createElement("div", { style: { display: 'flex', alignItems: 'center' } }, isLoading && React.createElement(LoadingIndicator, { className: "fa-lg" }))));
}
var Pager = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: flex;\n    gap: 0.5rem;\n"], ["\n    display: flex;\n    gap: 0.5rem;\n"])));
var PagerButton = styled(Button)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    padding: 0;\n    height: 2.1rem;\n    width: 2.25rem;\n"], ["\n    padding: 0;\n    height: 2.1rem;\n    width: 2.25rem;\n"])));
var StyledPagerDots = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    align-items: center;\n    display: inline-flex;\n    justify-content: center;\n    width: 2rem;\n"], ["\n    align-items: center;\n    display: inline-flex;\n    justify-content: center;\n    width: 2rem;\n"])));
var PagerDots = function () { return (React.createElement(StyledPagerDots, null,
    React.createElement(Icon, { icon: faEllipsisH, className: "fa-lg" }))); };
function getRange(index, total) {
    if (total < 8) {
        return range(0, total);
    }
    if (index < 4) {
        return range(0, 5);
    }
    if (total - index < 5) {
        return range(total - 5, total);
    }
    return range(index - 1, index + 2);
}
var templateObject_1, templateObject_2, templateObject_3;
