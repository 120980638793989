import styled from 'styled-components';

import { PageContent, ViewContent } from '@/components/styled/shared';
import { ErrorCodeSearchCardView } from '@/views/ErrorCode/ErrorCodeSearchCardView';
import { SerialNumberSearchCardView } from '@/views/SerialNumber/SerialNumberSearchCardView';
import { TruckModelSearchCardView } from '@/views/TruckModel/TruckModelSearchCardView';

const CardsWrapper = styled.div`
    gap: 2rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
`;

export const HomeView = () => (
    <PageContent>
        <ViewContent>
            <CardsWrapper>
                <TruckModelSearchCardView />
                <SerialNumberSearchCardView />
                <ErrorCodeSearchCardView />
            </CardsWrapper>
        </ViewContent>
    </PageContent>
);
