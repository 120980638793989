import { get } from 'lodash';
export var addChartRotation = function (H, chart) {
    function dragStart(eStart) {
        var normalizedEStart = chart.pointer.normalize(eStart);
        var posX = normalizedEStart === null || normalizedEStart === void 0 ? void 0 : normalizedEStart.chartX;
        var posY = normalizedEStart === null || normalizedEStart === void 0 ? void 0 : normalizedEStart.chartY;
        var _a = get(chart, 'options.chart.options3d', { alpha: 0, beta: 0 }), alpha = _a.alpha, beta = _a.beta;
        var sensitivity = 5;
        // There is a conflict between Highcharts type definitions and eslint.
        // Highcharts uses Function as a type, but eslint doesn't accept it.
        // eslint-disable-next-line @typescript-eslint/ban-types
        var handlers = [];
        function drag(e) {
            var normalizedEvent = chart.pointer.normalize(e);
            var deltaY = (normalizedEvent === null || normalizedEvent === void 0 ? void 0 : normalizedEvent.chartY) - posY || 0;
            var deltaX = posX - (normalizedEvent === null || normalizedEvent === void 0 ? void 0 : normalizedEvent.chartX) || 0;
            chart.update({
                chart: {
                    options3d: {
                        alpha: alpha !== undefined ? alpha + deltaY / sensitivity : 0,
                        beta: beta !== undefined ? beta + deltaX / sensitivity : 0
                    }
                }
            }, undefined, undefined, false);
        }
        function unbindAll() {
            handlers.forEach(function (unbind) {
                if (unbind) {
                    unbind();
                }
            });
            handlers.length = 0;
        }
        handlers.push(H.addEvent(document, 'mousemove', drag));
        handlers.push(H.addEvent(document, 'touchmove', drag));
        handlers.push(H.addEvent(document, 'mouseup', unbindAll));
        handlers.push(H.addEvent(document, 'touchend', unbindAll));
    }
    H.addEvent(chart.container, 'mousedown', dragStart);
    H.addEvent(chart.container, 'touchstart', dragStart);
};
