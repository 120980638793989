import React, { useRef } from 'react';
import { SearchInput } from '../../SearchInput/SearchInput';
import { useTableContext } from '../Table';
export function GlobalSearchFilter(_a) {
    var searchLabel = _a.searchLabel;
    var table = useTableContext().table;
    var inputRef = useRef(null);
    if (!table)
        return React.createElement(React.Fragment, null);
    var setGlobalFilter = table.setGlobalFilter, globalFilter = table.state.globalFilter;
    var onChange = function (value) { return setGlobalFilter(value); };
    var onReset = function () {
        if (inputRef.current) {
            inputRef.current.value = '';
        }
        setGlobalFilter('');
    };
    return (React.createElement(SearchInput, { placeholder: searchLabel !== null && searchLabel !== void 0 ? searchLabel : 'Search', name: "search", id: "search", value: globalFilter, inputRef: inputRef, onChange: onChange, onReset: onReset }));
}
