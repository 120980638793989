import { Button, Card } from '@daisy/react-components';

import { Collapsible } from '@/components/Collapsible';
import { SelectTruckModal } from '@/modals/SelectTruckModal';

export const TruckModelSearchCardView = () => (
    <Card title="Truck Model">
        <Collapsible>
            {({ isOpen, toggleOpen }) => (
                <>
                    <Button
                        id="card_truck_model_search_button"
                        data-testid="select-truck-modal-button"
                        onClick={toggleOpen}
                    >
                        Select truck model
                    </Button>
                    {isOpen && <SelectTruckModal handleModalClose={toggleOpen} />}
                </>
            )}
        </Collapsible>
    </Card>
);
