var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    height: 25px;\n    background-color: ", ";\n    width: 100%;\n    border-radius: 3px;\n"], ["\n    height: 25px;\n    background-color: ", ";\n    width: 100%;\n    border-radius: 3px;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.palette.defaultBorder;
});
var Filler = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    width: ", "%;\n    background-color: ", ";\n    height: 100%;\n    border-radius: 3px;\n    transition: all 0.2s ease-in-out;\n"], ["\n    width: ", "%;\n    background-color: ", ";\n    height: 100%;\n    border-radius: 3px;\n    transition: all 0.2s ease-in-out;\n"])), function (_a) {
    var progress = _a.progress;
    return progress;
}, function (_a) {
    var theme = _a.theme;
    return theme.palette.backgrounds.notifications.info.background;
});
export var ProgressBar = function (_a) {
    var progress = _a.progress;
    return (React.createElement(Container, null,
        React.createElement(Filler, { progress: progress })));
};
var templateObject_1, templateObject_2;
