import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { useTheme } from 'styled-components';

export const PieChartSkeleton = () => {
    const theme = useTheme();

    return (
        <SkeletonTheme
            baseColor={theme.palette.skeletons.chart.color}
            highlightColor={theme.palette.skeletons.chart.highlightColor}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    height: '30em',
                    padding: '2rem 0'
                }}
            >
                <Skeleton width={350} height={350} circle />
            </div>
        </SkeletonTheme>
    );
};
