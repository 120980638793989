import { LoadingIndicator } from '@daisy/react-components';

import { PageTitle } from '@/components/PageTitle';
import { PageContent, ViewContainer, ViewContent } from '@/components/styled/shared';
import { useTruckModel } from '@/hooks/useTruckModel';
import { TruckModelViewTabs } from './TruckModelViewTabs';

export const TruckModelView = () => {
    const { truckModel, image, isLoading } = useTruckModel();

    if (isLoading) return <LoadingIndicator />;

    if (!truckModel) return null;

    return (
        <ViewContainer>
            <PageContent>
                <ViewContent>
                    <PageTitle
                        title={`Truck Model: ${truckModel?.softwareModelName}`}
                        image={image}
                    />
                    <TruckModelViewTabs />
                </ViewContent>
            </PageContent>
        </ViewContainer>
    );
};
