import { Icon, Input, Select, SelectOption, UnstyledButton } from '@daisy/react-components';
import { faCircleMinus } from '@fortawesome/pro-regular-svg-icons';
import styled from 'styled-components';

type QueryFilterProps = {
    operatorOptions: SelectOption[];
    operatorValue: SelectOption;
    inputValue: string;
    operatorOnChange: (value: SelectOption | null) => void;
    inputOnChange: (value: string) => void;
    onRemoveClick: () => void;
};

const StyledQueryFilter = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 1rem;
`;

const SelectContainer = styled.div`
    width: 5em;
`;

export const QueryFilter = ({
    operatorOptions,
    operatorValue,
    inputValue,
    operatorOnChange,
    inputOnChange,
    onRemoveClick
}: QueryFilterProps) => (
    <StyledQueryFilter>
        <SelectContainer>
            <Select
                name="Operator"
                options={operatorOptions}
                defaultOptions={operatorOptions}
                value={operatorValue}
                handleChange={operatorOnChange}
                className="operator-input"
                showIcon={false}
            />
        </SelectContainer>
        <Input
            style={{ flex: 1 }}
            id="filterValue"
            name="FilterValue"
            value={inputValue}
            onChange={inputOnChange}
        />
        <UnstyledButton
            style={{ alignSelf: 'center' }}
            onClick={onRemoveClick}
            aria-label="remove-condition"
        >
            <Icon icon={faCircleMinus} color="#444" />
        </UnstyledButton>
    </StyledQueryFilter>
);
