"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.signOut = exports.setSelectedRegion = exports.setTruckToolPermissions = exports.setPermissions = exports.setPreferences = exports.setUserInfo = exports.setAccessToken = exports.initialState = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
exports.initialState = {
    accessToken: null,
    userInfo: {},
    preferences: null,
    permissions: null,
    truckToolPermissions: null,
    selectedRegion: null,
    signedOut: false
};
const userSlice = (0, toolkit_1.createSlice)({
    name: 'user',
    initialState: exports.initialState,
    reducers: {
        setAccessToken(state, action) {
            state.accessToken = action.payload;
            state.signedOut = false;
        },
        setUserInfo(state, action) {
            state.userInfo = action.payload;
        },
        setPreferences(state, action) {
            state.preferences = action.payload;
        },
        setTruckToolPermissions(state, action) {
            state.truckToolPermissions = action.payload;
        },
        setPermissions(state, action) {
            state.permissions = action.payload;
        },
        setSelectedRegion(state, action) {
            state.selectedRegion = action.payload;
        },
        signOut(state) {
            state.signedOut = true;
            state.accessToken = null;
            state.permissions = null;
            state.truckToolPermissions = null;
            state.selectedRegion = null;
            state.userInfo = {};
        }
    }
});
_a = userSlice.actions, exports.setAccessToken = _a.setAccessToken, exports.setUserInfo = _a.setUserInfo, exports.setPreferences = _a.setPreferences, exports.setPermissions = _a.setPermissions, exports.setTruckToolPermissions = _a.setTruckToolPermissions, exports.setSelectedRegion = _a.setSelectedRegion, exports.signOut = _a.signOut;
exports.default = userSlice.reducer;
