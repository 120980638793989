import { Chart, Multiselect, Select } from '@daisy/react-components';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const ViewContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const ViewContent = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding: 2rem;
    max-width: 90rem;
    margin: 0 auto;
    gap: 1.5rem;
    width: 100%;

    @media (max-width: 768px) {
        padding: 1rem;
    }
`;

export const PageContent = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background-color: ${({ theme }) => theme.palette.main.contentBackground};
    // box-shadow: -2px -7px 10px rgb(210 210 210 / 10%);
    width: 100%;
`;

export const HeaderContent = styled(ViewContent)`
    align-items: center;
    flex-direction: row;
    padding-top: 0;
    padding-bottom: 0;
`;

export const RouteContent = styled.main`
    background-color: ${({ theme }) => theme.palette.main.background};
    display: flex;
    flex: 1 1 auto;
    justify-content: center;
    min-height: 100vh;
    padding: 7em 0 0;
    position: relative;
`;

export const OneColumn = styled.div<{
    gapSize?: GapSizes;
    invertedMobile?: boolean;
}>`
    display: flex;
    flex-direction: column;
    gap: ${({ gapSize = 'default' }) => gapSizes[gapSize]}rem;

    @media (max-width: 768px) {
        order: ${({ invertedMobile }) => (invertedMobile ? '-1' : undefined)};
    }
`;

type GapSizes = 'small' | 'default' | 'large' | 'none';

const gapSizes: Record<GapSizes, number> = {
    none: 0,
    small: 0.75,
    default: 2,
    large: 4
};

export const TwoColumn = styled.div<{
    gapSize?: GapSizes;
    leftWidth?: number;
    rightWidth?: number;
    withBorder?: boolean;
}>`
    display: grid;
    gap: ${({ gapSize = 'default' }) => gapSizes[gapSize]}rem;
    grid-template-columns: ${({ leftWidth = 5, rightWidth = 2 }) =>
        `${leftWidth}fr minmax(15em, ${rightWidth}fr)`};

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
    }

    ${({ withBorder, gapSize = 'default', theme }) =>
        withBorder &&
        `
        > * {
            position: relative;
            overflow: visible;
        }
    
        > :nth-child(2)::before {
            content: '';
            position: absolute;
            top: 0;
            left: -${gapSizes[gapSize] / 2}rem;
            height: 100%;
            border-left: 1px solid ${theme.palette.platinum};
        }
    `}
`;

export const LeftContent = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 1em;
    row-gap: 1em;
    width: 600px;
`;

export const RightContent = styled.div`
    padding: 1em 0;
    text-align: left;
    max-width: 22em;
    width: 100%;
`;

export const JumboHeaderDropdown = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 35em;
`;

export const DropdownLabel = styled.div`
    font-size: 12px;
    text-transform: uppercase;
    color: ${({ theme }) => theme.palette.jumboHeader.title};
    white-space: nowrap;
`;

export const DropDownMenu = styled(Select)`
    margin: 0 1em;
`;

export const MultiselectDropDownMenu = styled(Multiselect)`
    margin: 0 1em;
`;

export const StyledChart = styled(Chart)`
    height: 30em;
    padding: 2rem 0;

    .highcharts-pie-series {
    }

    .highcharts-background {
        fill: transparent;
    }

    .highcharts-title {
        font-size: 22px;
    }

    .highcharts-subtitle {
        fill: ${({ theme }) => theme.palette.texts.grey};
        font-size: 14px;
    }

    .highcharts-point.non-selected {
        fill: ${({ theme }) => theme.palette.charts.nonSelected};
    }

    .highcharts-point.base-point {
        fill: ${({ theme }) => theme.palette.charts.baseColor};
    }

    .highcharts-point.compare-point-0 {
        fill: ${({ theme }) => theme.palette.charts.compareColors.first};
    }

    .highcharts-point.compare-point-1 {
        fill: ${({ theme }) => theme.palette.charts.compareColors.second};
    }

    .highcharts-point.compare-point-2 {
        fill: ${({ theme }) => theme.palette.charts.compareColors.third};
    }

    .highcharts-point.compare-point-3 {
        fill: ${({ theme }) => theme.palette.charts.compareColors.fourth};
    }

    .highcharts-point.compare-point-4 {
        fill: ${({ theme }) => theme.palette.charts.compareColors.fifth};
    }
`;

export const StyledPointChart = styled(StyledChart)`
    height: 37.5em;
    padding: 0;
`;

export const StyledStockChart = styled(StyledChart)`
    height: 37.5em;
    padding: 0;
`;

export const StyledMediumChart = styled(StyledChart)`
    height: 20em;
    padding: 0;
`;

export const StyledMiniChart = styled(StyledChart)`
    height: 10em;
    padding: 0;
`;

export const StyledTimelineChart = styled(StyledChart)`
    height: 20em;
    padding: 0;
`;

export const StyledUsageTimesChart = styled(Chart)`
    border: 1px solid ${({ theme }) => theme.palette.defaultBorder};
    border-radius: 8px;
    height: 50em;
`;

export const Styled3DChart = styled(Chart)`
    border: 1px solid ${({ theme }) => theme.palette.defaultBorder};
    border-radius: 8px;
    height: 50em;
`;

export const TableContainer = styled.section`
    text-align: left;
`;

export const ActionTitle = styled.div`
    font-family: ${({ theme }) => theme.fonts.families.title};
    font-size: 1em;
    line-height: 1em;
    font-weight: 500;
    letter-spacing: 1.5px;
`;

export const ProgressBarContainer = styled.div`
    position: fixed;
    z-index: 100;
    top: 50%;
    left: 50%;
    width: 25%;
    transform: translateX(-50%) translateY(-50%);
`;

export const JumboHeaderActionContainer = styled.div`
    display: flex;
    align-items: center;
    height: 1em;
`;

export const ChartContainer = styled.div`
    flex: 5;
`;

export const DataSetInfoContainer = styled.div`
    flex: 2;
    min-width: 15em;
`;

export const StyledLink = styled(Link)`
    color: ${({ theme }) => theme.palette.link};
    :hover {
        text-decoration: underline;
    }
`;

export const Parameter = styled.td`
    min-width: 7em;
    padding-right: 1em;
    font-weight: 300;
    padding: 0 0 0.35em 0;
    vertical-align: top;
`;

export const Value = styled.td`
    text-align: right;
    padding: 0 0 0.35em 0;
    vertical-align: top;
`;
