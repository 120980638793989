import styled from 'styled-components';

const EmptyChartContainer = styled.div`
    margin: 0 auto;
`;

const EmptyPieChart = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20em;
    width: 20em;
    border-radius: 50%;
    border: 1px solid ${({ theme }) => theme.palette.defaultBorder};
`;

const NoDataText = styled.span`
    color: ${({ theme }) => theme.palette.texts.lightgrey};
`;

export const EmptyPieChartInfo = () => (
    <EmptyChartContainer>
        <EmptyPieChart>
            <NoDataText>No event data</NoDataText>
        </EmptyPieChart>
    </EmptyChartContainer>
);
