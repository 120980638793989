import { TruckToolOperationFragment } from '@daisy/data-access';
import {
    H3,
    Icon,
    LinkButton,
    Table,
    TableColumn,
    TableHeader,
    TableMain,
    TablePager
} from '@daisy/react-components';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import { Row } from '@/components/Row';
import { OneColumn } from '@/components/styled/shared';
import {
    TruckToolOperationsActions,
    useTruckToolOperationsDispatchContext,
    useTruckToolOperationsStateContext
} from './TruckToolOperationsContext';

dayjs.extend(utc);

export const TruckToolOperationsTable: React.FC<{ eventsData: TruckToolOperationFragment[] }> = ({
    eventsData
}) => {
    const { expandedRows } = useTruckToolOperationsStateContext();
    const truckToolDispatch = useTruckToolOperationsDispatchContext();

    const handleExpandedChange = useCallback(
        (value: number) => {
            if (expandedRows?.indexOf(value) === -1) {
                truckToolDispatch({
                    type: TruckToolOperationsActions.SET_EXPANDED,
                    payload: { expandedRows: expandedRows.concat(value) }
                });
            } else {
                truckToolDispatch({
                    type: TruckToolOperationsActions.SET_EXPANDED,
                    payload: { expandedRows: expandedRows?.filter((row) => row !== value) }
                });
            }
        },
        [expandedRows, truckToolDispatch]
    );

    const expandedRowsObjects = useMemo(
        () =>
            typeof expandedRows !== 'undefined'
                ? expandedRows.reduce(
                      (acc, curr) => ({
                          ...acc,
                          ...(typeof curr !== 'undefined' && { [curr]: true })
                      }),
                      {}
                  )
                : {},
        [expandedRows]
    );

    const columns: TableColumn<TruckToolOperationFragment>[] = useMemo(
        () => [
            {
                Header: 'Time',
                accessor: 'time',
                Cell: TimeCell
            },
            {
                Header: 'Name',
                accessor: 'eventName'
            },
            {
                id: 'operations',
                Header: () => null,
                width: 1,
                Cell: OperationsCell(handleExpandedChange)
            }
        ],
        [handleExpandedChange]
    );

    return (
        <Table<TruckToolOperationFragment>
            columns={columns}
            data={eventsData}
            expandedRows={expandedRowsObjects}
            pageSize={-1}
        >
            <TableHeader searchLabel="Filter rows" title={<H3>Events</H3>} />
            <TableMain isSortable rowSubComponent={RowSubComponent} />
            <TablePager />
        </Table>
    );
};

const TimeCell = ({ value }: { value: TruckToolOperationFragment['time'] }) => (
    <>{dayjs.utc(value).format('hh:mm:ss.SSS')}</>
);

const OperationsCell =
    (handleExpandedChange: (value: number) => void) =>
    ({ row }: { row: any }) =>
        (
            <LinkButton
                {...row.getToggleRowExpandedProps()}
                type="button"
                onClick={() => {
                    handleExpandedChange(row.index);
                }}
                style={{ alignItems: 'center' }}
            >
                Expand
                <Icon
                    className="fa-sm"
                    icon={faChevronDown}
                    style={{
                        transform: `rotate(${!row.isExpanded ? '-90deg' : '0deg'})`,
                        transition: 'transform 0.2s'
                    }}
                />
            </LinkButton>
        );

const subRows: { label: string; value: keyof TruckToolOperationFragment }[] = [
    {
        label: 'Time',
        value: 'time'
    },
    { label: 'Device OS version', value: 'deviceOsVersion' },
    { label: 'Location', value: 'location' }
];

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    column-gap: 2rem;
    row-gap: 2rem;

    @media (min-width: 768px) {
        flex-direction: row;
        justify-content: space-between;
    }
`;

const RowSubComponent: React.FC<{ original: TruckToolOperationFragment }> = ({ original }) => (
    <div>
        <OneColumn>
            <OneColumn gapSize="small">
                <Wrapper>
                    {subRows.map(({ label, value }) => (
                        <Row key={value} label={label} value={original[value]} isVertical />
                    ))}
                </Wrapper>
            </OneColumn>
            <OneColumn gapSize="small">
                <CustomInfo dimensions={original.custom?.dimensions} />
            </OneColumn>
        </OneColumn>
    </div>
);

const CustomInfo: React.FC<{ dimensions: any }> = ({ dimensions }) => (
    <div>
        {dimensions?.map((dimension: any) =>
            Object.entries(dimension)?.map(([key, val]) =>
                val ? <Row key={key} label={key} value={val as string} hasBackground /> : null
            )
        )}
    </div>
);
