import { ErrorHistoryEventForTtpFragment, TtpTelematicsFragment } from '@daisy/data-access';
import { SelectOption } from '@daisy/react-components';
import { flow, pickBy } from 'lodash';

import { notEmpty } from './helpers';

type ObjectFiltersOption = {
    removeStrings?: boolean;
    filterRows?: string;
    behaviorTags?: SelectOption[];
};

type EventFiltersOption = {
    filterRows?: string;
};

const defaultValue = (rows: TtpTelematicsFragment[]) => rows || [];

const removeStringFilter = (rows: TtpTelematicsFragment[]) =>
    rows.filter((row: any) => {
        const valueKeys = pickBy(row, (_, key) => key === 'value');
        return Object.keys(valueKeys).some((key: string) => !Number.isNaN(+row[key]));
    });

const freeTextRowFilter = (rows: any[], needle: string) =>
    rows.filter((row: any) => {
        const haystack = JSON.stringify(row).toLowerCase();
        return haystack?.includes(needle.toLowerCase());
    });

const behaviorTagFilter = (rows: TtpTelematicsFragment[], filterTags: SelectOption[]) =>
    rows.filter((row: TtpTelematicsFragment) => {
        const tagsToArray = row.object_behaviour_tags?.filter(notEmpty).join(', ');
        return filterTags.some((tag) => tagsToArray?.includes(tag.value.toString()));
    });

export const filterTtpTelematics = (
    rows: TtpTelematicsFragment[],
    filters?: ObjectFiltersOption
) => {
    const filterArray = [defaultValue];

    if (filters?.filterRows && filters.filterRows.length) {
        filterArray.push((_rows) => freeTextRowFilter(_rows, filters.filterRows || ''));
    }

    if (filters?.behaviorTags?.length) {
        filterArray.push((_rows: TtpTelematicsFragment[]) =>
            behaviorTagFilter(_rows, filters.behaviorTags || [])
        );
    }

    if (filters?.removeStrings) {
        filterArray.push(removeStringFilter);
    }

    return flow(filterArray)(rows);
};

export const filterEventRows = (
    rows: ErrorHistoryEventForTtpFragment[] | undefined,
    filters?: EventFiltersOption
) => {
    const filterArray = [defaultValue];

    if (filters?.filterRows && filters?.filterRows.length) {
        filterArray.push((_rows) => freeTextRowFilter(_rows, filters.filterRows || ''));
    }

    return flow(filterArray)(rows);
};
