import { useMemo } from 'react';

import { StyledChart } from '@/components/styled/shared';
import { createSeries, getOptions } from '@/views/Charts/errorEventsChartConfig';

export const ErrorHistoryEventsChart: React.FC<any> = ({ errorHistoryEvents, onPointClick }) => {
    const chartOptions = useMemo(
        () =>
            errorHistoryEvents ? getOptions(createSeries(errorHistoryEvents), onPointClick) : [],
        [onPointClick, errorHistoryEvents]
    );

    return (
        <StyledChart
            title={`<span>Top ${errorHistoryEvents?.length}<br/>events</span>`}
            options={chartOptions}
        />
    );
};
