import { includes } from 'lodash';
import { PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, To } from 'react-router-dom';

import { RootState } from '@/store';
import { NotFoundView } from '@/views/NotFoundView';

export const RouteGuard: React.FC<PropsWithChildren<{ permission: string; redirectPath?: To }>> = ({
    permission,
    redirectPath,
    children
}) => {
    const { permissions } = useSelector((store: RootState) => store.user);

    if (!includes(permissions, permission)) {
        if (redirectPath) {
            return <Navigate to={redirectPath} replace />;
        }
        return <NotFoundView />;
    }

    return <>{children || <Outlet />}</>;
};
