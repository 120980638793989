import { TtpTelematicsFragment, useTtpTelematicsQuery } from '@daisy/data-access';
import {
    LoadingIndicator,
    Table,
    TableColumn,
    TableMain,
    TablePager
} from '@daisy/react-components';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useSerialNumber } from '@/hooks/useSerialNumber';
import { useTtpFiles } from '@/hooks/useTtpFiles';
import { RootState } from '@/store';
import { getSign, notEmpty, tableSortWithNulls } from '@/utils/helpers';
import { filterTtpTelematics } from '@/utils/ttpNavigationHelpers';

const BehaviorTagsCell = ({ row: { original } }: { row: { original: TtpTelematicsFragment } }) => (
    <>
        {original.object_behaviour_tags
            ? original.object_behaviour_tags.filter(notEmpty).join(', ')
            : ''}
    </>
);

const DeltaCell = ({ value }: { value: TtpTelematicsFragment['delta'] }) =>
    value !== undefined ? getSign(value) : null;

export const TruckUsageObjectsTable = () => {
    const { serialNumber } = useSerialNumber();
    const { isLoading: ttpFilesLoading, selectedTtpFiles, extFileIds } = useTtpFiles(serialNumber);

    const ttpTelematicsQuery = useTtpTelematicsQuery({
        variables: { externalIds: extFileIds }
    });

    const telematicsData = useMemo(
        () => ttpTelematicsQuery.data?.ttpTelematics?.filter(notEmpty),
        [ttpTelematicsQuery.data?.ttpTelematics]
    );

    const { mode } = useSelector((state: RootState) => state.usageGraph);
    const { freeSearchFilter, behaviorTagFilter, showStringFilter } = useSelector(
        (state: RootState) => state.usageGraph.objectTable
    );

    const isLoading = ttpFilesLoading || ttpTelematicsQuery.loading;

    const columns: TableColumn<TtpTelematicsFragment>[] = useMemo(() => {
        const headerBaseStructure: TableColumn<TtpTelematicsFragment>[] = [
            {
                Header: 'Name',
                accessor: 'object_name'
            }
        ];
        const formatter = new Intl.DateTimeFormat();

        if (mode === 'single')
            return [
                ...headerBaseStructure,
                {
                    Header: 'Tags',
                    accessor: 'object_behaviour_tags',
                    Cell: BehaviorTagsCell
                },
                {
                    Header: 'Value',
                    accessor: 'value',
                    sortType: tableSortWithNulls
                }
            ];

        if (mode === 'compare' && selectedTtpFiles) {
            return [
                ...headerBaseStructure,
                ...selectedTtpFiles.flatMap((item, index) => {
                    const headerName = formatter.format(new Date(item.ttpFileTime));
                    const valueAccessorName = `${item.ttpFileExternalId}.value`;
                    const deltaAccessorName = `${item.ttpFileExternalId}.delta`;

                    if (index > 0) {
                        return [
                            {
                                Header: 'Delta',
                                accessor: deltaAccessorName,
                                sortType: tableSortWithNulls,
                                Cell: DeltaCell
                            },
                            {
                                Header: headerName,
                                accessor: valueAccessorName,
                                sortType: tableSortWithNulls
                            }
                        ];
                    }

                    return [
                        {
                            Header: headerName,
                            accessor: valueAccessorName,
                            sortType: tableSortWithNulls
                        }
                    ];
                })
            ] as TableColumn<TtpTelematicsFragment>[];
        }

        return [];
    }, [mode, selectedTtpFiles]);

    const data: TtpTelematicsFragment[] = useMemo(() => {
        const objectRows = telematicsData?.map((group) =>
            group.filter(notEmpty).reduce(
                (acc, curr) => ({
                    ...acc,
                    ...(curr.ttp_external_id && {
                        [curr.ttp_external_id]: {
                            ...curr
                        }
                    }),
                    ...curr
                }),
                {} as TtpTelematicsFragment
            )
        );

        if (!objectRows) return [];

        return filterTtpTelematics(objectRows, {
            removeStrings: !showStringFilter,
            filterRows: freeSearchFilter,
            ...(mode === 'single' ? { behaviorTags: behaviorTagFilter } : undefined)
        });
    }, [behaviorTagFilter, freeSearchFilter, mode, showStringFilter, telematicsData]);

    return (
        <>
            {isLoading && (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <LoadingIndicator />
                </div>
            )}
            {!isLoading && (
                <Table columns={columns} data={data} pageSize={100}>
                    <TableMain highlightedCell={`${extFileIds[0]}-value`} isSortable />
                    <TablePager />
                </Table>
            )}
        </>
    );
};
