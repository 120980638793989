import { useGetTruckQuery, useTelematicsQuery } from '@daisy/data-access';
import { setQueryState, setSerialNumberFilterActive } from '@daisy/middleware-redux';
import { P1, Suspense } from '@daisy/react-components';
import { find, get, uniqBy } from 'lodash';
import { useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { DummyProgressBar } from '@/components/DummyProgressBar';
import { TTPInfo } from '@/components/Infos/TTPInfo';
import { ChartSkeleton } from '@/components/Skeletons/ChartSkeleton';
import { OneColumn, ProgressBarContainer } from '@/components/styled/shared';
import { useQueryEditorSearchParam } from '@/hooks/useQueryEditorSearchParam';
import { useSerialNumber } from '@/hooks/useSerialNumber';
import { useTruckModel } from '@/hooks/useTruckModel';
import { TwoColStackedLayout } from '@/layouts/TwoColStackedLayout';
import { RootState, useAppDispatch } from '@/store';
import { prepareQueryFilters } from '@/utils/objectValueInspectorHelpers';
import { ObjectValueInspectorChart } from './ObjectValueInspectorChart';
import { ObjectValueInspectorChartDatasetInfo } from './ObjectValueInspectorChartDatasetInfo';
import { ObjectValueInspectorChartFilterMenu } from './ObjectValueInspectorChartFilterMenu';
import { ObjectValueInspectorQueryInfo } from './ObjectValueInspectorQueryInfo';
import { FiltersAndContent } from './styles';
import { mockTelematics } from '@/tests/mocks';

export const ObjectValueInspectorChartView = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { serialNumber, relatedSerialNumbers } = useSerialNumber();
    const { truckModel } = useTruckModel();
    const { queryEditorSearchParam } = useQueryEditorSearchParam();

    const selectedEventRef = useRef<HTMLDivElement>(null);

    const queryEditorState = useSelector((state: RootState) => state.queryEditor);
    const { mainObject, showOnlyLatestTTP, selectedData, serialNumberFilterActive, showOnlyFactoryDefaultTTP } =
        queryEditorState;

    const telematicsDataQuery = useTelematicsQuery({
        skip: !queryEditorSearchParam && (!serialNumber || !truckModel?.softwareModelName),
        variables: {
            softwareModel: serialNumber
                ? relatedSerialNumbers?.[0]?.truck?.softwareModelName || ''
                : truckModel?.softwareModelName || '',
            serialNumber:
                serialNumber ?? (serialNumberFilterActive ? selectedData?.serialNumber : '') ?? '',
            query: queryEditorSearchParam
                ? JSON.stringify(
                      prepareQueryFilters(
                          queryEditorSearchParam.mainObject,
                          queryEditorSearchParam.referenceObjects,
                          queryEditorSearchParam.selectedXAxis
                      )
                  )
                : '',
            onlyLatestTTP: showOnlyLatestTTP,
            onlyFactoryDefaultTTP: showOnlyFactoryDefaultTTP,
        },
        fetchPolicy: 'no-cache'
    });

    const truckQuery = useGetTruckQuery({
        skip: !truckModel?.uuid,
        variables: { uuid: truckModel?.uuid as string }
    });

    const allSerialNumbersInTruckModelCount = truckQuery.data?.truck?._count?.serialNumbers || 0;

    const filteredTelematicsData = useMemo(
        () => uniqBy(telematicsDataQuery?.data?.customQuery, ({ time }) => time),
        [telematicsDataQuery?.data?.customQuery]
    );

    const uniqueSerialNumbers = useMemo(
        () => uniqBy(filteredTelematicsData, 'serialNumber'),
        [filteredTelematicsData]
    );

    const isLoading = truckQuery.loading || telematicsDataQuery.loading;

    useEffect(() => {
        if (queryEditorSearchParam) {
            dispatch(setQueryState(queryEditorSearchParam));
        } else {
            navigate('/not-found');
        }
    }, [dispatch, navigate, queryEditorSearchParam]);

    const handleTTPInfoOnChange = () => {
        dispatch(setSerialNumberFilterActive(!serialNumberFilterActive));
    };

    return (
        <>
            <FiltersAndContent>
                <Suspense
                    condition={!isLoading}
                    fallback={
                        <div>
                            <ProgressBarContainer>
                                <DummyProgressBar initialProgress={0} />
                            </ProgressBarContainer>
                            <ChartSkeleton />
                        </div>
                    }
                >
                    <TwoColStackedLayout
                        title={`Object: ${get(mainObject, 'object.label', 'Object')?.toString()}`}
                        titleSuffix={<ObjectValueInspectorChartFilterMenu />}
                    >
                        <OneColumn>
                            <ObjectValueInspectorChart
                                filteredTelematicsData={filteredTelematicsData as any}
                                serialNumberFilterActive={serialNumberFilterActive}
                            />
                        </OneColumn>

                        <OneColumn>
                            <ObjectValueInspectorChartDatasetInfo
                                dataPointsCount={filteredTelematicsData.length}
                                uniqueSerialNumbersCount={uniqueSerialNumbers.length}
                                allSerialNumbersInTruckModelCount={
                                    allSerialNumbersInTruckModelCount
                                }
                            />
                            <ObjectValueInspectorQueryInfo />
                        </OneColumn>
                        {selectedData ? (
                            <div ref={selectedEventRef}>
                                <TTPInfo
                                    serialNumber={selectedData.serialNumber}
                                    time={selectedData.ttp?.time}
                                    onChange={handleTTPInfoOnChange}
                                    isChecked={serialNumberFilterActive}
                                    ttpFile={find(
                                        filteredTelematicsData,
                                        ({ ttpFilename }) =>
                                            ttpFilename === selectedData.ttp?.filename
                                    )}
                                />
                            </div>
                        ) : (
                            <P1>Click on a point to see parameter info.</P1>
                        )}
                    </TwoColStackedLayout>
                </Suspense>
            </FiltersAndContent>
        </>
    );
};
