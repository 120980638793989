export const behaviorTagOptions = [
    'ActiveTest',
    'Info',
    'Parameter',
    'Input',
    'Output',
    'Analog',
    'Numeric',
    'ObjectDictionaryChange',
    'HeartBeat',
    'Battery',
    'OperatingHours',
    'ServiceHours',
    'SerialNumber',
    'MastSerialNumber',
    'ServiceHoursReset',
    'Option',
    'Calibratable',
    'Truck-Scope',
    'Digital',
    'ExcludeFromExport',
    'ExcludeFromImport',
    'IgnoreImportFail',
    'IgnoreExportFail',
    'UseForOfflineCheck',
    'ModifyOnlyUIValue',
    'LinkedOnlyObject',
    'DeviceConfiguration',
    'DoNotUseForOfflinityCheck',
    'ExportLimit',
    'DirectPartNumber',
    'ShowOnlyInFlashMode',
    'SlowCommunication',
    'DoNotOverwriteByTdfGenerator',
    'DynamicallyCreated',
    'IncorrectConfiguration',
    'DoNotUseForCommunication',
    'IgnoreStatusError',
    'DoNotUseForChangedObjectsCheck',
    'SellableOption'
] as string[];
