import { CustomQueryFragment } from '@daisy/data-access';
import { ClusteringDefaultSelections, ObjectQuery } from '@daisy/middleware-redux';
import { filter, findIndex, get, includes, isNil, map, uniq } from 'lodash';

import { AxisObject, CustomPointOptionsObject } from '@/types';

const SERIES_KEYS = ['x', 'y', 'serialNumber', 'ttpFilename', 'time', 'marker'];

export const getOVIChartOptions = ({
    series,
    xAxis,
    handlePointClick,
    serialNumberFilterActive
}: {
    series: Highcharts.SeriesOptionsType[];
    xAxis: AxisObject;
    handlePointClick: (serialNumber: string, ttpFilename: string, time: string) => void;
    serialNumberFilterActive: boolean;
}): Highcharts.Options => ({
    chart: {
        zooming: { type: 'xy' },
        resetZoomButton: {
            position: { x: 0, y: -50 }
        },
        panning: {
            enabled: true,
            type: 'xy'
        },
        spacingLeft: 0,
        spacingTop: 0,
        panKey: 'shift'
    },
    legend: {
        maxHeight: 100
    },
    title: { align: 'left', style: { fontSize: undefined, color: undefined } },
    subtitle: { align: 'left', style: { fontSize: undefined, color: undefined } },
    xAxis: {
        title: {
            text: xAxis.title
        },
        categories: xAxis.categories,
        tickInterval: xAxis.tickInterval
    },
    yAxis: {
        title: {
            text: xAxis.title
        },
        maxPadding: 0.3
    },
    series,
    plotOptions: {
        series: {
            cursor: 'pointer',
            point: {
                events: {
                    click(e) {
                        const { serialNumber, ttpFilename, time, marker } = e.point
                            .options as CustomPointOptionsObject;
                        if (marker?.enabled) handlePointClick(serialNumber, ttpFilename, time);
                    },
                    mouseOver() {
                        if ((this.series as any).halo) {
                            (this.series as any).halo
                                .attr({
                                    class: 'highcharts-tracker',
                                    style: 'cursor: pointer'
                                })
                                .toFront();
                        }
                    }
                }
            },
            allowPointSelect: true,
            marker: {
                states: {
                    select: { fillColor: '#F39649', lineWidth: 4, lineColor: '#FFE9DD', radius: 7 }
                }
            }
        }
    },
    tooltip: {
        enabled: !serialNumberFilterActive,
        useHTML: true,
        hideDelay: 100,
        headerFormat: '<small>{point.key}</small><table>',
        pointFormatter() {
            if (this) {
                const firstRow = `<tr><td><b style="font-size: 16px;">${this.y}</b></td></tr>`;
                const secondRow = `<tr><td>${
                    (this.options as CustomPointOptionsObject).serialNumber
                }</td></tr>`;
                return firstRow + secondRow;
            }
            return '';
        },
        footerFormat: '</table>',
        style: {
            pointerEvents: 'none'
        }
    }
});

export const createSeries = (
    telematicsData: CustomQueryFragment[],
    serialNumber: string | undefined,
    serialNumberFilterActive: boolean,
    selectedClustering: string,
    mainObjectName: string,
    referenceObjects: ObjectQuery[]
): Highcharts.SeriesOptionsType[] => {
    // NO CLUSTERING
    if (selectedClustering === ClusteringDefaultSelections.NO_SELECTION) {
        return [
            {
                name: get(telematicsData, '[0].objectValue.name', 'Value'),
                type: 'scatter',
                data: map(telematicsData, (value, index) => [
                    ...createSeriesData(true, value, index),
                    createSeriesMarker(
                        value.serialNumber,
                        serialNumber || '',
                        serialNumberFilterActive
                    )
                ]),
                keys: SERIES_KEYS
            }
        ];
    }

    // CLUSTER BY UNIQUE MAIN OBJECT VALUES
    if (selectedClustering === mainObjectName) {
        const allMainObjectValues = map(telematicsData, ({ objectValue }) => objectValue.value);
        const uniqueMainObjectValues = filter(uniq(allMainObjectValues), (x) => !isNil(x));

        return map(uniqueMainObjectValues, (unique) => ({
            name: `${get(telematicsData, '[0].objectValue.name', 'Value')}: ${unique}`,
            type: 'scatter',
            data: map(telematicsData, (value, index) => [
                ...createSeriesData(unique === value.objectValue.value, value, index),
                createSeriesMarker(value.serialNumber, serialNumber || '', serialNumberFilterActive)
            ]),
            keys: SERIES_KEYS
        }));
    }

    // CLUSTER BY UNIQUE REFERENCE OBJECT VALUES
    const referenceObjectIndex = findIndex(
        referenceObjects,
        ({ object }) => object.name === selectedClustering
    );

    const allReferenceObjectValues = map(
        telematicsData,
        ({ referenceObjectValues }) => referenceObjectValues?.[referenceObjectIndex]
    );
    const uniqueReferenceObjectValues = filter(uniq(allReferenceObjectValues), (x) => !isNil(x));

    return map(uniqueReferenceObjectValues, (unique) => {
        const referenceObjectName = get(
            referenceObjects,
            `[${referenceObjectIndex}].name`,
            'Value'
        );
        const name = `${referenceObjectName}: ${unique}`;

        return {
            name,
            type: 'scatter',

            data: map(telematicsData, (value, index) => [
                ...createSeriesData(
                    unique === value.referenceObjectValues?.[referenceObjectIndex],
                    value,
                    index
                ),
                createSeriesMarker(value.serialNumber, serialNumber || '', serialNumberFilterActive)
            ]),

            keys: SERIES_KEYS
        };
    });
};

const createSeriesData = (
    condition: boolean,
    value: CustomQueryFragment | undefined,
    index: number
) => [
    index,
    condition ? value?.objectValue.value : null,
    value?.serialNumber,
    value?.ttpFilename,
    value?.time
];

const createSeriesMarker = (
    dataPointSerialNumber: string,
    selectedSerialNumber: string | null,
    serialNumberFilterActive: boolean
) => ({
    enabled: serialNumberFilterActive ? dataPointSerialNumber === selectedSerialNumber : true,
    states: {
        hover: {
            enabled: serialNumberFilterActive
                ? dataPointSerialNumber === selectedSerialNumber
                : true
        }
    }
});

export const createXAxisCategories = (
    telematicsData: CustomQueryFragment[],
    referenceObjects: ObjectQuery[],
    selectedXAxis: string
) => {
    const formatter = new Intl.DateTimeFormat();
    return map(telematicsData, (entry) => {
        if (selectedXAxis === 'time') {
            return formatter.format(new Date(entry.time));
        }
        const referenceObjectIndex = findIndex(
            referenceObjects,
            ({ object }) => object.name === selectedXAxis
        );
        return !includes(entry.referenceObjectValues, null)
            ? entry.referenceObjectValues?.[referenceObjectIndex]?.toString() || ''
            : '';
    });
}
