import {
    ClusteringDefaultSelections,
    FilterGroup,
    ObjectQuery,
    QueryFilters,
    SelectedObject,
    setMainObjectName,
    setMainObjectQueryFilters,
    setReferenceObjectName,
    setReferenceObjectQueryFilters,
    setReferenceObjects,
    setSelectedClustering,
    setSelectedXAxis,
    XAxisDefaultSelections
} from '@daisy/middleware-redux';
import {
    ACollapse,
    Button,
    Card,
    GroupedSelectOption,
    Icon,
    UnstyledButton
} from '@daisy/react-components';
import { faCirclePlus, faKey, faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { AnimatePresence } from 'framer-motion';
import { filter, includes, map, some } from 'lodash';
import { useSelector } from 'react-redux';
import styled, { useTheme } from 'styled-components';

import { ClusteringSelection } from '@/components/QueryEditor/ClusteringSelection';
import { XAxisSelection } from '@/components/QueryEditor/XAxisSelection';
import { TwoColStackedLayout } from '@/layouts/TwoColStackedLayout';
import { RootState, useAppDispatch } from '@/store';
import { OneColumn } from '../styled/shared';
import { ObjectEditor } from './ObjectEditor';
import { OtherSelection } from './OtherSelection';

type QueryEditorProps = {
    objectSelectOptions: GroupedSelectOption[];
    handleLoadOptions: (inputValue: string, callback: any) => void;
    onExecuteButtonClick: (query: Record<string, any>) => void;
};

const ButtonArea = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const QueryEditor = ({
    objectSelectOptions,
    handleLoadOptions,
    onExecuteButtonClick
}: QueryEditorProps) => (
    <OneColumn>
        <div
            style={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr 1fr',
                columnGap: '1rem',
                rowGap: '2rem'
            }}
        >
            <QueryEditorFilters />
        </div>
        <QueryEditorContent
            objectSelectOptions={objectSelectOptions}
            handleLoadOptions={handleLoadOptions}
            onExecuteButtonClick={onExecuteButtonClick}
        />
    </OneColumn>
);

export const QueryEditorPage = ({
    objectSelectOptions,
    handleLoadOptions,
    onExecuteButtonClick
}: QueryEditorProps) => (
    <TwoColStackedLayout
        title="Query builder"
        isSegmented
        contentBackground="#f9fbfd"
        leftWidth={4}
        isHeaderOutside
        dataTestId="object-value-inspector-query-builder"
    >
        <QueryEditorContent
            objectSelectOptions={objectSelectOptions}
            handleLoadOptions={handleLoadOptions}
            onExecuteButtonClick={onExecuteButtonClick}
        />
        <div
            style={{ display: 'flex', flexDirection: 'column', columnGap: '4rem', rowGap: '2rem' }}
        >
            <QueryEditorFilters />
        </div>
    </TwoColStackedLayout>
);

const QueryEditorContent: React.FC<QueryEditorProps> = ({
    objectSelectOptions,
    handleLoadOptions,
    onExecuteButtonClick
}) => {
    const theme = useTheme();
    const dispatch = useAppDispatch();

    const { mainObject, referenceObjects } = useSelector((state: RootState) => state.queryEditor);

    return (
        <OneColumn>
            <OneColumn>
                <PrimaryObject
                    handleLoadOptions={handleLoadOptions}
                    objectSelectOptions={objectSelectOptions}
                />
                <AnimatePresence initial={false}>
                    {map(referenceObjects, ({ object, where }, index) => (
                        <ACollapse key={index}>
                            <ReferenceObject
                                handleLoadOptions={handleLoadOptions}
                                objectSelectOptions={objectSelectOptions}
                                object={object}
                                where={where}
                                index={index}
                            />
                        </ACollapse>
                    ))}
                </AnimatePresence>
            </OneColumn>

            <ButtonArea>
                <Button
                    isPrimary={false}
                    onClick={() =>
                        dispatch(
                            setReferenceObjects([
                                ...referenceObjects,
                                {
                                    object: { label: null, name: null },
                                    where: { AND: [] as FilterGroup[] } as QueryFilters
                                }
                            ])
                        )
                    }
                    icon={<Icon icon={faCirclePlus} color={theme.palette.primary} />}
                >
                    Add reference object
                </Button>

                <Button
                    isPrimary
                    onClick={onExecuteButtonClick}
                    disabled={
                        !mainObject.object.name ||
                        some(referenceObjects, ({ object }) => !object.name)
                    }
                >
                    Run Query
                </Button>
            </ButtonArea>
        </OneColumn>
    );
};

const QueryEditorFilters = () => (
    <>
        <ClusteringSelection />
        <XAxisSelection />
        <OtherSelection />
    </>
);

type PrimaryObjectProps = Pick<QueryEditorProps, 'handleLoadOptions'> &
    Pick<QueryEditorProps, 'objectSelectOptions'>;

const PrimaryObject: React.FC<PrimaryObjectProps> = ({
    handleLoadOptions,
    objectSelectOptions
}) => {
    const dispatch = useAppDispatch();

    const { mainObject } = useSelector((state: RootState) => state.queryEditor);

    return (
        <Card
            headingLevel="h3"
            title={
                <div style={{ display: 'flex', gap: '1rem' }}>
                    <Icon icon={faKey} color="#61a9d0" />
                    Primary object
                </div>
            }
            isSegmented
            isSmall
            style={{ borderLeft: '3px solid #61a9d0' }}
        >
            <ObjectEditor
                editorKey="main-object"
                objectName={mainObject.object?.label}
                queryFilters={mainObject.where}
                objectSelectOptions={objectSelectOptions}
                handleLoadOptions={handleLoadOptions}
                handleObjectSelection={(option) =>
                    dispatch(
                        setMainObjectName({
                            label: (option?.label as string | undefined) ?? null,
                            name: (option?.value as string | undefined) ?? null
                        })
                    )
                }
                handleReduxUpdate={(queryFilters) =>
                    dispatch(setMainObjectQueryFilters(queryFilters))
                }
            />
        </Card>
    );
};

type ReferenceObjectProps = PrimaryObjectProps & {
    index: number;
    object: SelectedObject;
    where: QueryFilters;
};

const ReferenceObject: React.FC<ReferenceObjectProps> = ({
    handleLoadOptions,
    objectSelectOptions,
    index,
    object,
    where
}) => {
    const dispatch = useAppDispatch();

    const { referenceObjects, selectedXAxis, selectedClustering } = useSelector(
        (state: RootState) => state.queryEditor
    );

    const resetSelectionsBackToDefaults = (objects: ObjectQuery[]) => {
        if (!includes(Object.values(XAxisDefaultSelections), selectedXAxis)) {
            if (!includes(map(objects, 'name'), selectedXAxis)) {
                dispatch(setSelectedXAxis(XAxisDefaultSelections.TIME));
            }
        }

        if (!includes(map(objects, 'name'), selectedClustering)) {
            dispatch(setSelectedClustering(ClusteringDefaultSelections.NO_SELECTION));
        }
    };

    const handleRemoveReferenceObject = (id: number) => {
        const newReferenceObjects = filter(referenceObjects, (_, i) => id !== i);
        dispatch(setReferenceObjects(newReferenceObjects));

        resetSelectionsBackToDefaults(newReferenceObjects);
    };

    return (
        <Card
            headingLevel="h3"
            isSegmented
            isSmall
            key={`${object.name}-${index}`}
            title="Reference object"
            titleSuffix={
                <UnstyledButton
                    onClick={() => handleRemoveReferenceObject(index)}
                    aria-label="remove-reference-object"
                >
                    <Icon icon={faTrashCan} color="#444" />
                </UnstyledButton>
            }
        >
            <ObjectEditor
                editorKey={`reference-object-${index}`}
                objectName={object.label}
                queryFilters={where}
                objectSelectOptions={objectSelectOptions}
                handleLoadOptions={handleLoadOptions}
                handleObjectSelection={(option) =>
                    dispatch(
                        setReferenceObjectName({
                            index,
                            object: {
                                label: (option?.label as string | undefined) ?? null,
                                name: (option?.value as string | undefined) ?? null
                            }
                        })
                    )
                }
                handleReduxUpdate={(queryFilters) =>
                    dispatch(
                        setReferenceObjectQueryFilters({
                            index,
                            where: queryFilters
                        })
                    )
                }
            />
        </Card>
    );
};
