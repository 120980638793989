import { Button, H1 } from '@daisy/react-components';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { ViewContainer, ViewContent } from '@/components/styled/shared';

const Buttons = styled.div`
    margin-top: 1em;
`;

export const NotFoundView = () => {
    const navigate = useNavigate();

    return (
        <ViewContainer>
            <ViewContent>
                <H1>It seems that something went wrong. There is nothing here.</H1>
                <Buttons>
                    <Button onClick={() => navigate(-1)}>Go back</Button>
                    <Button onClick={() => navigate('/')} style={{ marginLeft: '1em' }}>
                        Go home
                    </Button>
                </Buttons>
            </ViewContent>
        </ViewContainer>
    );
};
