import {
    useGetTruckToolOperationTimesQuery,
    useGetTruckToolOperationsQuery
} from '@daisy/data-access';
import { Calendar, LoadingIndicator, P1, SearchInput } from '@daisy/react-components';
import { debounce } from 'lodash';
import { useCallback, useMemo, useRef, useState } from 'react';
import { DateObject } from 'react-multi-date-picker';
import { useSearchParams } from 'react-router-dom';

import { OneColumn } from '@/components/styled/shared';
import { useSerialNumber } from '@/hooks/useSerialNumber';
import { OneColStackedLayout } from '@/layouts/OneColStackedLayout';
import { notEmpty } from '@/utils/helpers';
import { TruckToolOperationsProvider } from './Tabs/TruckToolOperations/TruckToolOperationsContext';
import { TruckToolOperationsEventChart } from './Tabs/TruckToolOperations/TruckToolOperationsEventChart';
import { TruckToolOperationsHistogramChart } from './Tabs/TruckToolOperations/TruckToolOperationsHistogramChart';
import { TruckToolOperationsTable } from './Tabs/TruckToolOperations/TruckToolOperationsTable';

export const SerialNumberTruckToolOperationsView: React.FC = () => {
    const { serialNumber } = useSerialNumber();
    const [daySearchParams, setDaySearchParams] = useSearchParams();
    const [filterValue, setFilterValue] = useState<string>();

    const inputRef = useRef<HTMLInputElement>(null);

    const dateParam = daySearchParams.get('date');

    const selectedDate = useMemo(
        () => (dateParam ? new DateObject(dateParam) : undefined),
        [dateParam]
    );
    const searchOnChange = (value: string) => setFilterValue(value);
    const searchOnReset = () => {
        if (inputRef.current) {
            inputRef.current.value = '';
        }
        setFilterValue('');
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const eventFilterDebouncedChangeHandler = useCallback(debounce(searchOnChange, 300), [
        searchOnChange
    ]);

    const getTruckToolOperationTimes = useGetTruckToolOperationTimesQuery({
        variables: {
            externalId: serialNumber as string
        },
        skip: !serialNumber
    });

    const getTruckToolOperations = useGetTruckToolOperationsQuery({
        variables: {
            externalId: serialNumber as string,
            dateString: selectedDate?.format('YYYY-MM-DD') ?? ''
        },
        skip: !selectedDate || !serialNumber
    });

    const dateOptions = useMemo(
        () =>
            getTruckToolOperationTimes.data?.truckToolOperationTimes?.map((item) =>
                item ? new DateObject(item) : undefined
            ),
        [getTruckToolOperationTimes.data?.truckToolOperationTimes]
    );

    const calendarOnChange = (date: DateObject) => {
        setDaySearchParams(
            {
                date: `${date}`
            },
            { replace: true }
        );
    };

    const getCurrentDate = () => selectedDate ?? new DateObject().subtract(2, 'month');

    const eventsData = useMemo(
        () =>
            getTruckToolOperations.data?.truckToolOperations?.operations
                ?.filter(notEmpty)
                .filter((o) =>
                    filterValue
                        ? JSON.stringify(o).toLowerCase().includes(filterValue.toLowerCase())
                        : true
                ),
        [getTruckToolOperations.data?.truckToolOperations, filterValue]
    );

    const errorEvents = useMemo(
        () => getTruckToolOperations.data?.truckToolOperations?.errorEvents?.filter(notEmpty),
        [getTruckToolOperations.data?.truckToolOperations?.errorEvents]
    );

    return (
        <OneColStackedLayout
            title="TruckTool Operations"
            isHeaderOutside
            dataTestId="trucktool-operations-content"
        >
            <OneColumn>
                <Calendar
                    onChange={calendarOnChange}
                    numberOfMonths={3}
                    value={selectedDate}
                    currentDate={getCurrentDate()}
                    maxDate={new DateObject()}
                    datesWithData={dateOptions}
                    disableDaysWithoutData
                />
            </OneColumn>
            <>
                {getTruckToolOperations.loading ? <LoadingIndicator /> : null}
                <TruckToolOperationsProvider>
                    <OneColumn gapSize="large">
                        {eventsData && (
                            <>
                                <SearchInput
                                    style={{ maxWidth: '20rem' }}
                                    placeholder="Filter rows"
                                    name="search"
                                    id="search"
                                    inputRef={inputRef}
                                    onChange={eventFilterDebouncedChangeHandler}
                                    searchOnReset={searchOnReset}
                                />
                                {selectedDate && (
                                    <OneColumn>
                                        <TruckToolOperationsHistogramChart
                                            eventsData={eventsData}
                                            selectedDate={selectedDate}
                                        />
                                    </OneColumn>
                                )}
                                {errorEvents?.length ? (
                                    <OneColumn>
                                        <TruckToolOperationsEventChart errorEvents={errorEvents} />
                                    </OneColumn>
                                ) : null}
                                {/* Commented out for performance reasons, highcharts doesn't seem to handle timeline charts pretty well
                                    <OneColumn>
                                        <DelayedRender
                                            delay={500}
                                            key={selectedDate.toString()}
                                            fallback={<LoadingIndicator />}
                                        >
                                            <TruckToolOperationsTimelineChart
                                                eventsData={eventsData}
                                            />
                                        </DelayedRender>
                                    </OneColumn> */}
                            </>
                        )}
                        <OneColumn>
                            {!!eventsData?.length && eventsData.length > 0 ? (
                                <TruckToolOperationsTable eventsData={eventsData} />
                            ) : (
                                <P1>Click on a day that has data to show operations.</P1>
                            )}
                        </OneColumn>
                    </OneColumn>
                </TruckToolOperationsProvider>
            </>
        </OneColStackedLayout>
    );
};
