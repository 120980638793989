import { PropsWithChildren } from 'react';
import styled from 'styled-components';

import { useScrollPosition } from '@/hooks/useScrollPosition';

const StyledHeader = styled.header<{ isScrollTop?: boolean }>`
    box-shadow: ${({ theme, isScrollTop }) => (!isScrollTop ? theme.palette.header.shadow : null)};
    position: fixed;
    z-index: 5;
    width: 100%;
    transition: box-shadow 0.5s ease-in-out;
`;

export const Header: React.FC<PropsWithChildren> = ({ children }) => {
    const scrollY = useScrollPosition();

    return <StyledHeader isScrollTop={scrollY === 0}>{children}</StyledHeader>;
};
