import { useGetDevicesByTrucksQuery } from '@daisy/data-access';
import {
    QueryToggle,
    setFilterMenuIsCollapsed,
    setSidebarIsCollapsed
} from '@daisy/middleware-redux';
import { Button, Icon, Modal } from '@daisy/react-components';
import { faSlidersH } from '@fortawesome/pro-solid-svg-icons';
import { isEmpty } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled, { useTheme } from 'styled-components';

import { QueryEditor } from '@/components/QueryEditor/QueryEditor';
import { useQueryEditorSearchParam } from '@/hooks/useQueryEditorSearchParam';
import { useSerialNumber } from '@/hooks/useSerialNumber';
import { useTruckModel } from '@/hooks/useTruckModel';
import { RootState, useAppDispatch } from '@/store';
import { formatObjectSelectOptions, getToggleKey } from '@/utils/objectValueInspectorHelpers';
import { generateTruckModelUUIDs, handleGroupedLoadOptions } from '@/utils/selectHelpers';

const Badge = styled.span`
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.palette.filterMenu.badge};
    position: absolute;
    top: -3px;
    right: -3px;
`;

export const ObjectValueInspectorChartFilterMenu = () => {
    const theme = useTheme();

    const dispatch = useAppDispatch();
    const { relatedSerialNumbers } = useSerialNumber();
    const { truckModel } = useTruckModel();
    const { setQueryEditorSearchParam } = useQueryEditorSearchParam();

    const queryEditorState = useSelector((state: RootState) => state.queryEditor);
    const { mainObject, referenceObjects, selectedData } = queryEditorState;

    const { filterMenuIsCollapsed, sidebarIsCollapsed } = useSelector((state: RootState) => ({
        filterMenuIsCollapsed: state.filterMenu.isCollapsed,
        sidebarIsCollapsed: state.sidebar.isCollapsed
    }));

    const truckModelUUIDs = useMemo(
        () => generateTruckModelUUIDs(truckModel?.uuid, relatedSerialNumbers),
        [relatedSerialNumbers, truckModel?.uuid]
    );

    const devicesByTrucksQuery = useGetDevicesByTrucksQuery({
        skip: !truckModelUUIDs || filterMenuIsCollapsed,
        variables: { uuids: truckModelUUIDs || [] }
    });

    const { data: { devicesByTrucks } = {} } = devicesByTrucksQuery;

    const isDataFiltered =
        mainObject.where[getToggleKey(mainObject.where) as QueryToggle].length > 0 ||
        referenceObjects?.length > 0 ||
        !isEmpty(selectedData?.serialNumber);

    const objectSelectOptions = useMemo(
        () =>
            devicesByTrucks?.length
                ? formatObjectSelectOptions(devicesByTrucks, truckModel?.softwareModelName || '')
                : [],
        [devicesByTrucks, truckModel]
    );

    const handleLoadOptions = useMemo(
        () => handleGroupedLoadOptions(objectSelectOptions),
        [objectSelectOptions]
    );

    const handleFilterCollapse = () => {
        if (!sidebarIsCollapsed && filterMenuIsCollapsed) {
            dispatch(setSidebarIsCollapsed(true));
        }
        dispatch(setFilterMenuIsCollapsed(!filterMenuIsCollapsed));
    };

    const handleExecuteButtonClick = useCallback(() => {
        dispatch(setFilterMenuIsCollapsed(true));
        setQueryEditorSearchParam(queryEditorState);
    }, [dispatch, queryEditorState, setQueryEditorSearchParam]);

    return (
        <div style={{ flexShrink: 0 }}>
            <Button
                onClick={handleFilterCollapse}
                style={{ display: 'flex', gap: '0.6rem', position: 'relative' }}
                isPrimary={false}
            >
                {isDataFiltered && <Badge />}
                <Icon icon={faSlidersH} color={theme.palette.primary} />
                Edit query
            </Button>
            <Modal
                title="Edit query"
                isSegmented
                contentBackground="#f9fbfd"
                isOpen={!filterMenuIsCollapsed}
                onClose={handleFilterCollapse}
            >
                <QueryEditor
                    objectSelectOptions={objectSelectOptions}
                    handleLoadOptions={handleLoadOptions}
                    onExecuteButtonClick={handleExecuteButtonClick}
                />
            </Modal>
        </div>
    );
};
