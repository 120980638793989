var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { motion } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';
var KnobLabel = styled.label(function (_a) {
    var theme = _a.theme;
    return (__assign(__assign({}, theme.fonts.fonts.p1), { wordBreak: 'break-all', cursor: 'inherit' }));
});
var KnobWrapper = styled.label(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: inline-flex;\n    gap: 0.6rem;\n    cursor: ", ";\n\n    &:hover {\n        ", " {\n            opacity: 0.8;\n        }\n    }\n\n    ", "\n"], ["\n    display: inline-flex;\n    gap: 0.6rem;\n    cursor: ", ";\n\n    &:hover {\n        ", " {\n            opacity: 0.8;\n        }\n    }\n\n    ", "\n"])), function (_a) {
    var disabled = _a.disabled;
    return (disabled ? 'not-allowed' : 'pointer');
}, KnobLabel, function (_a) {
    var disabled = _a.disabled;
    return disabled && 'opacity: 0.5;';
});
var HiddenKnob = styled.input(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    border: 0;\n    clip: rect(0 0 0 0);\n    clip-path: inset(50%);\n    height: 1px;\n    margin: -1px;\n    overflow: hidden;\n    padding: 0;\n    position: absolute;\n    white-space: nowrap;\n    width: 1px;\n"], ["\n    border: 0;\n    clip: rect(0 0 0 0);\n    clip-path: inset(50%);\n    height: 1px;\n    margin: -1px;\n    overflow: hidden;\n    padding: 0;\n    position: absolute;\n    white-space: nowrap;\n    width: 1px;\n"])));
var StyledKnob = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    display: inline-flex;\n    width: 2.5em;\n    height: 1.25em;\n    background-color: ", ";\n    box-shadow: ", ";\n    border-radius: 0.625em;\n    text-align: center;\n    position: relative;\n"], ["\n    display: inline-flex;\n    width: 2.5em;\n    height: 1.25em;\n    background-color: ", ";\n    box-shadow: ", ";\n    border-radius: 0.625em;\n    text-align: center;\n    position: relative;\n"])), function (_a) {
    var theme = _a.theme, isChecked = _a.isChecked;
    if (isChecked) {
        return theme.palette.knob.checked;
    }
    return theme.palette.knob.background;
}, function (_a) {
    var theme = _a.theme;
    return theme.palette.knob.shadow;
});
var Background = styled(motion.div)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    position: absolute;\n    width: 1em;\n    height: 1em;\n    top: 0.125em;\n    ", ": 0.125em;\n    background-color: ", ";\n    box-shadow: ", ";\n    border-radius: 50%;\n}"], ["\n    position: absolute;\n    width: 1em;\n    height: 1em;\n    top: 0.125em;\n    ", ": 0.125em;\n    background-color: ", ";\n    box-shadow: ", ";\n    border-radius: 50%;\n}"])), function (_a) {
    var isChecked = _a.isChecked;
    return (isChecked ? 'right' : 'left');
}, function (_a) {
    var theme = _a.theme;
    return theme.palette.white;
}, function (_a) {
    var theme = _a.theme;
    return theme.palette.segmentedControl.item.shadow;
});
export var Knob = function (_a) {
    var style = _a.style, className = _a.className, _b = _a.label, label = _b === void 0 ? '' : _b, name = _a.name, value = _a.value, _c = _a.disabled, disabled = _c === void 0 ? false : _c, _d = _a.isChecked, isChecked = _d === void 0 ? false : _d, onChange = _a.onChange;
    return (React.createElement(KnobWrapper, { style: style, className: className, disabled: disabled },
        React.createElement(HiddenKnob, { type: "checkbox", defaultChecked: isChecked, value: value, onChange: !disabled ? onChange : function () { return undefined; }, name: name, disabled: disabled, id: name }),
        React.createElement(StyledKnob, { disabled: disabled, isChecked: isChecked },
            React.createElement(motion.div, { className: "knob-container", layout: true, transition: { duration: 0 } },
                React.createElement(Background, { layoutId: "knob-".concat(name), transition: { duration: 0.15 }, isChecked: isChecked, disabled: disabled }))),
        React.createElement(KnobLabel, { htmlFor: name }, label)));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
