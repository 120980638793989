import {
    ErrorHistoryEventForTtpFragment,
    useGetErrorHistoryEventsForTtpQuery
} from '@daisy/data-access';
import {
    H3,
    LoadingIndicator,
    Table,
    TableColumn,
    TableMain,
    TablePager
} from '@daisy/react-components';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useSerialNumber } from '@/hooks/useSerialNumber';
import { useTtpFiles } from '@/hooks/useTtpFiles';
import { RootState } from '@/store';
import { getSign, notEmpty, tableSortWithNulls } from '@/utils/helpers';
import { filterEventRows } from '@/utils/ttpNavigationHelpers';

const eventTableHeaders: TableColumn<ErrorHistoryEventForTtpFragment>[] = [
    {
        Header: 'Name',
        accessor: (d) => d['event']?.['name']
    },
    {
        Header: 'Code',
        accessor: 'eventCode'
    },
    {
        Header: 'Class',
        accessor: (d) => d['event']?.['class']
    }
];

const DeltaCell = ({ value }: { value: ErrorHistoryEventForTtpFragment['eventCountDelta'] }) =>
    value !== undefined ? getSign(value) : null;

export const TruckUsageEvents: React.FC<{
    title: string;
    eventType?: string;
}> = ({ title, eventType }) => {
    const { serialNumber } = useSerialNumber();
    const { selectedTtpFiles, extFileIds, isLoading: isTtpLoading } = useTtpFiles(serialNumber);

    const errorHistoryEventsForTtpQuery = useGetErrorHistoryEventsForTtpQuery({
        variables: { externalIds: extFileIds },
        skip: !extFileIds
    });

    const errorHistoryEvents =
        errorHistoryEventsForTtpQuery.data?.errorHistoryEventsForTtp?.filter(notEmpty);

    const { mode } = useSelector((state: RootState) => state.usageGraph);
    const { eventTableSearchFilter } = useSelector(
        (state: RootState) => state.usageGraph.eventTable
    );

    const columns: any = useMemo(() => {
        const formatter = new Intl.DateTimeFormat();
        if (selectedTtpFiles) {
            if (mode === 'single')
                return [
                    ...eventTableHeaders,
                    {
                        Header: 'Count',
                        accessor: 'eventCount'
                    }
                ];

            if (mode === 'compare') {
                const headerStructure = [
                    ...eventTableHeaders,
                    ...selectedTtpFiles.flatMap((item, index) => {
                        const headerName = formatter.format(new Date(item.ttpFileTime));
                        const valueAccessorName = `${item.ttpFileExternalId}.eventCount`;
                        const deltaAccessorName = `${item.ttpFileExternalId}.eventCountDelta`;

                        if (index > 0) {
                            return [
                                {
                                    Header: 'Delta',
                                    accessor: deltaAccessorName,
                                    sortType: tableSortWithNulls,
                                    Cell: DeltaCell
                                },
                                { Header: headerName, accessor: valueAccessorName }
                            ];
                        }

                        return [{ Header: headerName, accessor: valueAccessorName }];
                    })
                ];

                return headerStructure;
            }
        }

        return [];
    }, [mode, selectedTtpFiles]);

    const tableData: ErrorHistoryEventForTtpFragment[] = useMemo(() => {
        const objectRows = errorHistoryEvents
            ?.flatMap((group) =>
                group
                    .filter(notEmpty)
                    .filter((item) =>
                        item.event?.userId !== undefined && eventType
                            ? item.eventType === eventType
                            : true
                    )
                    .reduce(
                        (acc, curr) => ({
                            ...acc,
                            ...(curr.ttpFile.externalId && {
                                [curr.ttpFile.externalId]: {
                                    ...curr
                                }
                            }),
                            ...curr
                        }),
                        {} as ErrorHistoryEventForTtpFragment
                    )
            )
            .filter((value) => Object.keys(value).length !== 0);

        if (!objectRows || objectRows.length === 0) return [];

        return filterEventRows(objectRows, {
            filterRows: eventTableSearchFilter
        });
    }, [errorHistoryEvents, eventTableSearchFilter, eventType]);

    const isLoading = errorHistoryEventsForTtpQuery.loading || isTtpLoading;

    const doRender = errorHistoryEventsForTtpQuery.data && selectedTtpFiles;

    return (
        <>
            {isLoading && (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <LoadingIndicator />
                </div>
            )}
            {doRender && !!tableData.length && (
                <>
                    <H3>{title}</H3>
                    <Table<ErrorHistoryEventForTtpFragment> columns={columns} data={tableData}>
                        <TableMain highlightedCell={`${extFileIds[0]}-value`} isSortable />
                        <TablePager />
                    </Table>
                </>
            )}
        </>
    );
};
