import { get } from 'lodash';
import { useSelector } from 'react-redux';

import { DatasetInfo } from '@/components/Infos/DatasetInfo';
import { RootState } from '@/store';

export const ObjectValueInspectorQueryInfo = () => {
    const { referenceObjects, selectedXAxis, selectedClustering } = useSelector(
        (state: RootState) => state.queryEditor
    );

    const items = [
        {
            label: 'Reference object(s)',
            value:
                referenceObjects
                    ?.map((refObject) => get(refObject, 'object.label', 'Object'))
                    .join(', ') || 'No selection'
        },
        { label: 'Selected clustering', value: selectedClustering },
        {
            label: 'Selected Axis',
            value: selectedXAxis
        }
    ];

    return (
        <DatasetInfo
            items={items}
            // title="Query info"
        />
    );
};
