import styled from 'styled-components';

const ActionBarContainer = styled.nav`
    display: flex;
    align-items: center;
    height: 3.5em;
    color: ${({ theme }) => theme.palette.texts.white};
    background-color: ${({ theme }) => theme.palette.actionbar.background};
    padding-left: 1em;
    padding-right: 1em;
    position: fixed;
    top: 3.5em;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 4;
`;

export const DummyActionBar = () => (
    <ActionBarContainer/>
);
