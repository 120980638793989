import {
    SelectOption,
    setObjectTableBehaviorTagFilter,
    setObjectTableFreeSearchFilter,
    setObjectTableShowStringFilter
} from '@daisy/middleware-redux';
import { Knob, Multiselect, OnMultiselectChange, SearchInput } from '@daisy/react-components';
import { debounce } from 'lodash';
import { useCallback, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { OneColumn } from '@/components/styled/shared';
import { RootState, useAppDispatch } from '@/store';
import { behaviorTagOptions } from '@/utils/behaviorTagOptions';
import { TruckUsageObjectsTable } from './TruckUsageObjectsTable';

const TableToolbar = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const TruckUsageObjectsTabView = () => {
    const objectFilterRef = useRef<HTMLInputElement>(null);
    const dispatch = useAppDispatch();

    const { mode } = useSelector((state: RootState) => state.usageGraph);

    const { behaviorTagFilter, showStringFilter } = useSelector(
        (state: RootState) => state.usageGraph.objectTable
    );

    const handleFilterStringsChange = () => {
        dispatch(setObjectTableShowStringFilter(!showStringFilter));
    };

    const objectFilterOnChangeHandler = useCallback(
        (value: string) => {
            dispatch(setObjectTableFreeSearchFilter(value));
        },
        [dispatch]
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const objectFilterDebouncedChangeHandler = useCallback(
        debounce(objectFilterOnChangeHandler, 300),
        [objectFilterOnChangeHandler]
    );

    const objectFilterResetHandler = () => {
        if (objectFilterRef.current) {
            objectFilterRef.current.value = '';
        }

        dispatch(setObjectTableFreeSearchFilter(''));
    };

    const multiSelectMockOptions = useMemo(
        () =>
            behaviorTagOptions.map((item) => ({
                value: item,
                label: item
            })),
        []
    );

    const behaviorTagChangeHandler: OnMultiselectChange = (newOptions) => {
        dispatch(setObjectTableBehaviorTagFilter(newOptions as SelectOption[]));
    };

    return (
        <OneColumn data-testid="truck-usage-objects-content">
            <TableToolbar>
                <div style={{ display: 'flex', gap: '1rem', flexGrow: 1 }}>
                    <SearchInput
                        style={{ maxWidth: '20rem' }}
                        key="objectFilterInput"
                        inputRef={objectFilterRef}
                        name="objectFilter"
                        placeholder="Filter rows"
                        onChange={objectFilterDebouncedChangeHandler}
                        onReset={objectFilterResetHandler}
                    />
                    {mode === 'single' && (
                        <div style={{ maxWidth: '20rem', width: '100%' }}>
                            <Multiselect
                                name="tag"
                                value={behaviorTagFilter}
                                onChange={behaviorTagChangeHandler}
                                defaultOptions={multiSelectMockOptions}
                                options={multiSelectMockOptions}
                                placeholder="Filter with behavior tag"
                            />
                        </div>
                    )}
                </div>

                <Knob
                    label="Show string values"
                    name="filter_strings"
                    value="filter_strings"
                    isChecked={showStringFilter}
                    onChange={handleFilterStringsChange}
                />
            </TableToolbar>
            <TruckUsageObjectsTable />
        </OneColumn>
    );
};
