var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import 'focus-visible';
import { faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { faChevronLeft, faCommentAlt, faEllipsisH, faPen, faPlus, faTimes } from '@fortawesome/pro-solid-svg-icons';
import React from 'react';
import styled, { useTheme } from 'styled-components';
import { Icon } from '../Icon/Icon';
var StyledButton = styled.button(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    border-radius: 100%;\n    width: ", ";\n    height: ", ";\n    box-shadow: ", ";\n    cursor: pointer;\n    border: none;\n    background: ", ";\n\n    :hover:not(:disabled) {\n        opacity: 0.8;\n    }\n\n    :disabled {\n        background: ", ";\n        pointer-events: none;\n    }\n\n    :focus:not(.focus-visible) {\n        outline: none;\n    }\n"], ["\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    border-radius: 100%;\n    width: ", ";\n    height: ", ";\n    box-shadow: ", ";\n    cursor: pointer;\n    border: none;\n    background: ", ";\n\n    :hover:not(:disabled) {\n        opacity: 0.8;\n    }\n\n    :disabled {\n        background: ", ";\n        pointer-events: none;\n    }\n\n    :focus:not(.focus-visible) {\n        outline: none;\n    }\n"])), function (_a) {
    var size = _a.size;
    return size;
}, function (_a) {
    var size = _a.size;
    return size;
}, function (_a) {
    var theme = _a.theme, isTransparent = _a.isTransparent;
    return !isTransparent ? theme.palette.backgrounds.buttons.shadow : 'none';
}, function (_a) {
    var theme = _a.theme, isPrimary = _a.isPrimary, isTransparent = _a.isTransparent;
    return !isTransparent
        ? isPrimary
            ? theme.palette.primary
            : theme.palette.backgrounds.buttons.iconButtons.light
        : 'none';
}, function (_a) {
    var theme = _a.theme, isTransparent = _a.isTransparent;
    return !isTransparent ? theme.palette.backgrounds.buttons.iconButtons.disabled : 'none';
});
var iconDefinitions = {
    close: faTimes,
    edit: faPen,
    details: faEllipsisH,
    remove: faTrashCan,
    add: faPlus,
    back: faChevronLeft,
    feedback: faCommentAlt
};
export var IconButton = function (_a) {
    var className = _a.className, style = _a.style, icon = _a.icon, onClick = _a.onClick, _b = _a.disabled, disabled = _b === void 0 ? false : _b, _c = _a.isPrimary, isPrimary = _c === void 0 ? false : _c, _d = _a.isTransparent, isTransparent = _d === void 0 ? false : _d, _e = _a.isWhiteIcon, isWhiteIcon = _e === void 0 ? false : _e, _f = _a.size, size = _f === void 0 ? '2em' : _f, _g = _a.ariaLabel, ariaLabel = _g === void 0 ? '' : _g;
    var iconLabel = ariaLabel || icon;
    var icons = useTheme().palette.icons;
    return (React.createElement(StyledButton, { className: className, style: style, onClick: onClick, disabled: disabled, isPrimary: isPrimary, isTransparent: isTransparent, size: size, "aria-label": iconLabel },
        React.createElement(Icon, { icon: iconDefinitions[icon], color: !isTransparent
                ? disabled
                    ? icons.disabled
                    : icons.white
                : isWhiteIcon
                    ? icons.white
                    : icons.black, style: { width: 18, height: 16 }, "data-testid": "icon-".concat(icon) })));
};
var templateObject_1;
