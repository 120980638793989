"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FeedbackType = exports.FeedbackStatus = void 0;
var FeedbackStatus;
(function (FeedbackStatus) {
    FeedbackStatus["created"] = "created";
    FeedbackStatus["acknowledged"] = "acknowledged";
    FeedbackStatus["replied"] = "replied";
    FeedbackStatus["processed"] = "processed";
    FeedbackStatus["deleted"] = "deleted";
})(FeedbackStatus = exports.FeedbackStatus || (exports.FeedbackStatus = {}));
var FeedbackType;
(function (FeedbackType) {
    FeedbackType["feedback"] = "feedback";
    FeedbackType["feature"] = "feature";
    FeedbackType["issue"] = "issue";
})(FeedbackType = exports.FeedbackType || (exports.FeedbackType = {}));
