var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from 'react';
import styled from 'styled-components';
import { faFile } from '@fortawesome/pro-light-svg-icons';
import { Button } from '../Button/Button';
import { H3 } from '../Typography/H3';
import { Icon } from '../Icon/Icon';
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: flex;\n    flex-direction: column;\n    gap: 2rem;\n"], ["\n    display: flex;\n    flex-direction: column;\n    gap: 2rem;\n"])));
var StyledFile = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    font-family: 'Lato', sans-serif;\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 20px;\n    display: flex;\n    gap: 0.75em;\n    align-items: center;\n"], ["\n    font-family: 'Lato', sans-serif;\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 20px;\n    display: flex;\n    gap: 0.75em;\n    align-items: center;\n"])));
var StyledDiv = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    display: flex;\n    flex-direction: column;\n    gap: 0.5rem;\n"], ["\n    display: flex;\n    flex-direction: column;\n    gap: 0.5rem;\n"])));
var Actions = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    display: flex;\n    gap: 0.5rem;\n"], ["\n    display: flex;\n    gap: 0.5rem;\n"])));
export var FileInput = function (_a) {
    var label = _a.label, onFileSelectSuccess = _a.onFileSelectSuccess, onFileSelectError = _a.onFileSelectError, uploadOnClick = _a.uploadOnClick;
    var inputRef = React.useRef(null);
    var _b = React.useState([]), files = _b[0], setFiles = _b[1];
    var clickFileInput = function () {
        if (inputRef.current !== null) {
            inputRef.current.click();
        }
    };
    var onFileInputChange = function (event) {
        if (event && event.target) {
            var inputFiles = event.target.files;
            if (!inputFiles || !(inputFiles === null || inputFiles === void 0 ? void 0 : inputFiles.length)) {
                onFileSelectError({ error: 'No files selected.' });
                return;
            }
            var inputFilesArray = Array.from(inputFiles);
            if (onFileSelectSuccess)
                onFileSelectSuccess(inputFilesArray);
            setFiles(inputFilesArray);
        }
    };
    return (React.createElement(Wrapper, null,
        files.length > 0 && (React.createElement(StyledDiv, null,
            React.createElement(H3, { style: { marginBottom: '0.5rem' } }, "Selected file(s)"),
            files.map(function (file) { return (React.createElement(StyledFile, { key: "".concat(file.name, ":").concat(file.size) },
                React.createElement(Icon, { icon: faFile }),
                file.name)); }))),
        React.createElement(Actions, null,
            React.createElement(Button, { isPrimary: false, onClick: clickFileInput }, label),
            React.createElement(Button, { onClick: uploadOnClick, disabled: !files.length }, "Upload")),
        React.createElement("input", { ref: inputRef, id: "importFile", "aria-label": "importFile", type: "file", style: { display: 'none' }, onChange: onFileInputChange, multiple: true })));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
