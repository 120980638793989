import { CommonErrorHistoryEventFragment } from '@daisy/data-access';
import { H2 } from '@daisy/react-components';
import { get, includes, isNil } from 'lodash';
import styled from 'styled-components';

import { convertFromCamelCase } from '@/utils/helpers';
import { Row } from '../Row';
import { OneColumn } from '../styled/shared';

type ErrorInfoProps = {
    errorMetaData: CommonErrorHistoryEventFragment;
};

const omittedKeys = ['ttpFile', 'serialNumber', '__typename'];

const Wrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 2rem;
    row-gap: 2rem;

    @media (min-width: 768px) {
        grid-template-columns: repeat(4, 1fr);
    }
`;

export const ErrorInfo = ({ errorMetaData }: ErrorInfoProps) => {
    const filteredKeys = (Object.keys(errorMetaData) as Array<keyof typeof errorMetaData>).filter(
        (key) =>
            !isNil(errorMetaData[key]) && errorMetaData[key] !== '' && !includes(omittedKeys, key)
    );

    const filteredValues = filteredKeys.map((key) => ({
        key,
        value: errorMetaData[key]
    }));

    return (
        <OneColumn gapSize="small">
            <H2>Error Info</H2>
            <Wrapper>
                {filteredValues.map(({ key, value }) => (
                    <Row
                        key={key}
                        isVertical
                        label={convertFromCamelCase(key)}
                        value={get(value, 'name', value)}
                    />
                ))}
            </Wrapper>
        </OneColumn>
    );
};
