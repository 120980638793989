import {
    ChartValue,
    SerialNumberTelematicsChartData,
    SerialNumberTelematicsUsageTimesFragment
} from '@daisy/data-access';
import { UsageGraphState } from '@daisy/middleware-redux';

function getName({
    type,
    firstItem,
    secondItem
}: {
    type: SerialNumberTelematicsUsageTimesFragment['type'];
    firstItem: SerialNumberTelematicsChartData;
    secondItem: ChartValue | null;
}) {
    return type === '3d'
        ? `${firstItem.type} ${firstItem.key}, ${secondItem?.type} ${secondItem?.key}`
        : secondItem?.key;
}

export function transformData(
    data: SerialNumberTelematicsUsageTimesFragment['data'],
    mode: UsageGraphState['mode'],
    type: SerialNumberTelematicsUsageTimesFragment['type']
) {
    return (
        data?.flatMap((firstItem) =>
            firstItem?.chartValues?.map((secondItem) => {
                const resultItem: any = {
                    name: getName({ type, firstItem, secondItem })
                };

                if (mode === 'single') {
                    resultItem.value = secondItem?.value;
                } else {
                    resultItem[`${secondItem?.ttpexternalid}-value`] = secondItem?.value;
                    resultItem[`${secondItem?.ttpexternalid}-delta`] = secondItem?.delta;
                }

                return resultItem;
            })
        ) ?? []
    );
}
