import styled from 'styled-components';

type RowProps = {
    label: React.ReactNode;
    value?: React.ReactNode;
    isVertical?: boolean;
    hasBackground?: boolean;
};

const RowContainer = styled.div<{ isVertical?: boolean; hasBackground?: boolean }>`
    display: flex;
    justify-content: space-between;
    gap: 0.3rem;
    flex-direction: ${({ isVertical }) => (isVertical ? `column` : `row`)};

    ${({ hasBackground, theme }) =>
        hasBackground &&
        `
        border-bottom: 1px solid ${theme.palette.table.row.border};
        padding: 0.5rem 0.7rem;
        background-color: #fff;

        :nth-child(odd) {
            background-color: #f9fbfd;
        }

        :last-child {
            border: none;
        }
    `}
`;

const RowLabel = styled.div`
    font-weight: 300;
    font-size: 13px;
`;

const RowValue = styled.div<{ isVertical?: boolean }>`
    font-weight: 500;
    font-size: 17px;

    ${({ isVertical }) =>
        !isVertical &&
        `
        text-align: right; 
        font-size: 15px;
    `}
`;

export const Row = ({ label, value, isVertical, hasBackground }: RowProps) => (
    <RowContainer isVertical={isVertical} hasBackground={hasBackground}>
        <RowLabel>{label}</RowLabel>
        <RowValue isVertical={isVertical}>{value}</RowValue>
    </RowContainer>
);
