"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.isBlob = void 0;
function dispatchThunk(thunk, f) {
    var _a;
    return __awaiter(this, void 0, void 0, function* () {
        const response = yield thunk.dispatch(f);
        if (response.error) {
            console.log(response);
            return thunk.rejectWithValue({ body: null, error: (_a = response.payload) === null || _a === void 0 ? void 0 : _a.error });
        }
        const { payload: { body } } = response;
        return { body, error: null };
    });
}
exports.default = dispatchThunk;
const isBlob = (input) => 'Blob' in window && input instanceof Blob;
exports.isBlob = isBlob;
