import middleware, {
    Auth0SessionProvider,
    filterMenuReducer,
    modalReducer,
    queryEditorReducer,
    sidebarReducer,
    truckModelReducer,
    uiReducer,
    usageGraphReducer,
    userReducer,
    featureFlagsReducer,
    serialNumberReducer
} from '@daisy/middleware-redux';
import { PreloadedState, combineReducers, configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

const sessionProvider = new Auth0SessionProvider();

export const setToken = (token: string) => {
    sessionProvider.setToken(token);
};

const rootReducer = combineReducers({
    filterMenu: filterMenuReducer,
    modal: modalReducer,
    queryEditor: queryEditorReducer,
    sidebar: sidebarReducer,
    truckModel: truckModelReducer,
    user: userReducer,
    ui: uiReducer,
    usageGraph: usageGraphReducer,
    featureFlags: featureFlagsReducer,
    serialNumber: serialNumberReducer
});

const settingsProvider = {
    API_URL: process.env.REACT_APP_API_BASE_URL || '',
    createRequestID: () => undefined
};

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware(middleware(sessionProvider, settingsProvider)),
    devTools: process.env.NODE_ENV !== 'production'
});

export const setupStore = (preloadedState?: PreloadedState<RootState>) =>
    configureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware(middleware(sessionProvider, settingsProvider)),
        devTools: process.env.NODE_ENV !== 'production',
        preloadedState
    });

export type RootState = ReturnType<typeof store.getState>;
export type AppStore = ReturnType<typeof setupStore>;

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
