var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import 'focus-visible';
import { faChevronDoubleLeft, faChevronDoubleRight } from '@fortawesome/pro-light-svg-icons';
import React from 'react';
import styled from 'styled-components';
import { Icon } from '../Icon/Icon';
var SidebarContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    width: ", ";\n    height: calc(100vh - 56px);\n    color: ", ";\n    background-color: ", ";\n    transition: width 0.2s;\n"], ["\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    width: ", ";\n    height: calc(100vh - 56px);\n    color: ", ";\n    background-color: ", ";\n    transition: width 0.2s;\n"])), function (props) { return (props.isCollapsed ? '48px' : '250px'); }, function (_a) {
    var theme = _a.theme;
    return theme.palette.texts.white;
}, function (_a) {
    var theme = _a.theme;
    return theme.palette.sidebar.background;
});
var SidebarContent = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    display: flex;\n    flex-direction: column;\n    width: 100%;\n"], ["\n    display: flex;\n    flex-direction: column;\n    width: 100%;\n"])));
export var SidebarSegment = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    display: flex;\n    flex-direction: column;\n    margin-bottom: ", ";\n"], ["\n    display: flex;\n    flex-direction: column;\n    margin-bottom: ", ";\n"])), function (props) { return (props.isCollapsed ? '0' : '16px'); });
var SidebarSegmentHeader = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    display: flex;\n    width: ", ";\n    padding: ", ";\n    justify-content: ", ";\n    cursor: pointer;\n    transition: background-color 0.2s;\n\n    text-decoration: none;\n    color: ", ";\n\n    :hover {\n        background-color: ", ";\n    }\n"], ["\n    display: flex;\n    width: ", ";\n    padding: ", ";\n    justify-content: ", ";\n    cursor: pointer;\n    transition: background-color 0.2s;\n\n    text-decoration: none;\n    color: ", ";\n\n    :hover {\n        background-color: ", ";\n    }\n"])), function (props) { return (props.isCollapsed ? '100%' : 'unset'); }, function (props) { return (props.isCollapsed ? '16px 0' : '12px 0 12px 12px'); }, function (props) { return (props.isCollapsed ? 'center' : 'flex-start'); }, function (_a) {
    var theme = _a.theme;
    return theme.palette.texts.white;
}, function (_a) {
    var theme = _a.theme;
    return theme.palette.primary;
});
var SidebarSegmentTitle = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    margin-left: 12px;\n"], ["\n    margin-left: 12px;\n"])));
var SidebarSegmentActions = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    display: flex;\n    flex-direction: column;\n"], ["\n    display: flex;\n    flex-direction: column;\n"])));
export var SidebarAction = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    display: flex;\n    flex-direction: column;\n    padding: 12px 0 12px 42px;\n    cursor: pointer;\n    transition: background-color 0.1s;\n\n    text-decoration: none;\n    color: ", ";\n    white-space: nowrap;\n\n    :hover {\n        background-color: ", ";\n    }\n"], ["\n    display: flex;\n    flex-direction: column;\n    padding: 12px 0 12px 42px;\n    cursor: pointer;\n    transition: background-color 0.1s;\n\n    text-decoration: none;\n    color: ", ";\n    white-space: nowrap;\n\n    :hover {\n        background-color: ", ";\n    }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.palette.texts.white;
}, function (_a) {
    var theme = _a.theme;
    return theme.palette.primary;
});
var CollapseButton = styled.button(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n    width: 16px;\n    height: 16px;\n    margin-bottom: 8px;\n    padding: 0;\n    color: #fff;\n    background: none;\n    border: none;\n    cursor: pointer;\n\n    :focus:not(.focus-visible) {\n        outline: none;\n    }\n"], ["\n    width: 16px;\n    height: 16px;\n    margin-bottom: 8px;\n    padding: 0;\n    color: #fff;\n    background: none;\n    border: none;\n    cursor: pointer;\n\n    :focus:not(.focus-visible) {\n        outline: none;\n    }\n"])));
var CollapseButtonLeft = styled(CollapseButton)(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n    align-self: flex-end;\n    margin-right: 8px;\n"], ["\n    align-self: flex-end;\n    margin-right: 8px;\n"])));
var CollapseButtonRight = styled(CollapseButton)(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n    align-self: center;\n"], ["\n    align-self: center;\n"])));
export var Sidebar = function (_a) {
    var style = _a.style, className = _a.className, segments = _a.segments, onCollapse = _a.onCollapse, isCollapsed = _a.isCollapsed;
    return (React.createElement(SidebarContainer, { style: style, className: className, isCollapsed: isCollapsed },
        React.createElement(SidebarContent, null, segments.map(function (segment) { return (React.createElement(SidebarSegment, { key: segment.title, isCollapsed: isCollapsed, "data-testid": "sidebar-segment" },
            React.createElement(segment.linkComponent, null,
                React.createElement(SidebarSegmentHeader, { isCollapsed: isCollapsed },
                    React.createElement(Icon, { icon: segment.icon }),
                    !isCollapsed && (React.createElement(SidebarSegmentTitle, null, segment.title)))),
            !isCollapsed && (React.createElement(SidebarSegmentActions, null, segment.actions.map(function (action) { return (React.createElement(action.linkComponent, { key: action.title },
                React.createElement(SidebarAction, null, action.title))); }))))); })),
        isCollapsed ? (React.createElement(CollapseButtonRight, { onClick: onCollapse, "data-testid": "sidebar-collapse-button" },
            React.createElement(Icon, { icon: faChevronDoubleRight }))) : (React.createElement(CollapseButtonLeft, { onClick: onCollapse, "data-testid": "sidebar-collapse-button" },
            React.createElement(Icon, { icon: faChevronDoubleLeft })))));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;
