var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import React from 'react';
import ReactModal from 'react-modal';
import styled from 'styled-components';
import { Button, UnstyledButton } from '../Button/Button';
import { Card } from '../Card/Card';
import { Icon } from '../Icon/Icon';
var ModalActionRow = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: flex;\n    justify-content: flex-end;\n    margin-top: 2rem;\n"], ["\n    display: flex;\n    justify-content: flex-end;\n    margin-top: 2rem;\n"])));
export var Modal = function (_a) {
    var isOpen = _a.isOpen, buttonsAreDisabled = _a.buttonsAreDisabled, title = _a.title, onClose = _a.onClose, onCancelButtonClick = _a.onCancelButtonClick, onActionButtonClick = _a.onActionButtonClick, actionButtonText = _a.actionButtonText, children = _a.children, contentPadding = _a.contentPadding, contentBackground = _a.contentBackground, isSegmented = _a.isSegmented, isSmall = _a.isSmall, isOverflow = _a.isOverflow, _b = _a.dataTestId, dataTestId = _b === void 0 ? 'modal' : _b, rest = __rest(_a, ["isOpen", "buttonsAreDisabled", "title", "onClose", "onCancelButtonClick", "onActionButtonClick", "actionButtonText", "children", "contentPadding", "contentBackground", "isSegmented", "isSmall", "isOverflow", "dataTestId"]);
    var shouldCloseOnOverlayClick = rest.shouldCloseOnOverlayClick, shouldCloseOnEsc = rest.shouldCloseOnEsc;
    return (React.createElement(ReactModal, __assign({ isOpen: isOpen, onRequestClose: onClose, shouldCloseOnEsc: shouldCloseOnEsc !== null && shouldCloseOnEsc !== void 0 ? shouldCloseOnEsc : false, shouldCloseOnOverlayClick: shouldCloseOnOverlayClick !== null && shouldCloseOnOverlayClick !== void 0 ? shouldCloseOnOverlayClick : true, style: {
            overlay: {
                animation: 'fadeIn 0.15s ease-in-out',
                backgroundColor: 'rgba(0, 0, 0, 0.2)',
                position: 'fixed',
                zIndex: 10,
                transition: 'all 0.1s ease',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            },
            content: {
                animation: 'fadeInY 0.15s ease-in-out',
                boxShadow: 'none',
                border: '0',
                bottom: 'auto',
                // minHeight: '10rem',
                padding: '0',
                minWidth: '20rem',
                maxHeight: '95vh',
                position: 'static',
                width: '80%',
                maxWidth: '55rem',
                overflow: isOverflow ? 'visible' : 'auto',
                zIndex: 2
            }
        } }, rest),
        React.createElement(Card, { "data-testid": dataTestId, title: title, titleSuffix: React.createElement(UnstyledButton, { onClick: onClose, "aria-label": "Close" },
                React.createElement(Icon, { icon: faTimes, className: "fa-lg" })), isSegmented: isSegmented, isSmall: isSmall, contentPadding: contentPadding, contentBackground: contentBackground },
            children,
            (onCancelButtonClick || onActionButtonClick) && (React.createElement(ModalActionRow, { "data-testid": "modal-action-row" },
                onCancelButtonClick && (React.createElement(Button, { isPrimary: false, onClick: onCancelButtonClick, style: { marginRight: '20px' }, disabled: buttonsAreDisabled, id: "cancel-modal" }, "Cancel")),
                onActionButtonClick && (React.createElement(Button, { isPrimary: true, onClick: onActionButtonClick, disabled: buttonsAreDisabled, id: "submit-modal" }, actionButtonText)))))));
};
var templateObject_1;
