var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import { sortBy } from 'lodash';
import React, { useMemo } from 'react';
import ReactSelect, { components } from 'react-select';
import ReactSelectAsync from 'react-select/async';
import styled, { useTheme } from 'styled-components';
import { Icon } from '../Icon/Icon';
import { StyledLabel } from '../Label/Label';
import { LoadingIndicator } from '../LoadingIndicator/LoadingIndicator';
import { InputContainer } from '../SearchInput/SearchInput';
import { selectStyles } from '../Select/styles';
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: inline-flex;\n    width: 100%;\n    ", ": center;\n    flex-direction: ", ";\n"], ["\n    display: inline-flex;\n    width: 100%;\n    ", ": center;\n    flex-direction: ", ";\n"])), function (_a) {
    var isHorizontal = _a.isHorizontal;
    return (isHorizontal ? 'align-items' : 'justify-content');
}, function (_a) {
    var isHorizontal = _a.isHorizontal;
    return (isHorizontal ? 'row' : 'column');
});
var Option = function (_a) {
    var label = _a.label, rest = __rest(_a, ["label"]);
    return (React.createElement("div", { title: label },
        React.createElement(components.Option, __assign({ label: label }, rest))));
};
export function Multiselect(_a) {
    var className = _a.className, name = _a.name, label = _a.label, placeholder = _a.placeholder, value = _a.value, onChange = _a.onChange, options = _a.options, defaultOptions = _a.defaultOptions, isClearable = _a.isClearable, handleLoadOptions = _a.handleLoadOptions, isAsync = _a.isAsync, isHorizontal = _a.isHorizontal, _b = _a.size, size = _b === void 0 ? 'm' : _b, _c = _a.isGrouped, isGrouped = _c === void 0 ? false : _c, _d = _a.showIcon, showIcon = _d === void 0 ? true : _d, inputId = _a.inputId, noOptionsMessage = _a.noOptionsMessage;
    var theme = useTheme();
    var sortedOptions = useMemo(function () { return sortBy(options, function (option) { return option.label; }); }, [options]);
    var Component = isAsync && !!handleLoadOptions ? ReactSelectAsync : ReactSelect;
    return (React.createElement(Container, { isHorizontal: isHorizontal },
        label && (React.createElement(StyledLabel, { as: "label", htmlFor: inputId, isHorizontal: isHorizontal }, label)),
        React.createElement(InputContainer, { "data-testid": "multiSelect-".concat(name) },
            showIcon && (React.createElement(Icon, { icon: faSearch, color: theme.palette.texts.grey, style: {
                    left: '0.75rem',
                    position: 'absolute',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    zIndex: 1
                } })),
            React.createElement(Component, { components: __assign(__assign({ Option: Option, LoadingIndicator: function () { return (React.createElement(LoadingIndicator, { className: "fa-xl", style: { marginRight: '0.5em' } })); } }, (isAsync && { DropdownIndicator: function () { return null; } })), (isAsync && { IndicatorSeparator: function () { return null; } })), className: className, classNamePrefix: "multiselect", defaultOptions: defaultOptions, grouped: isGrouped, inputId: inputId, isClearable: isClearable, isMulti: true, isSearchable: true, loadOptions: handleLoadOptions, name: name, noOptionsMessage: noOptionsMessage, onChange: function (selectedOption) {
                    return onChange(selectedOption);
                }, options: sortedOptions, placeholder: placeholder, styles: selectStyles({ theme: theme, showIcon: showIcon, size: size }), value: value }))));
}
var templateObject_1;
