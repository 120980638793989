import { Button, Icon, UnstyledButton } from '@daisy/react-components';
import {
    IconDefinition,
    faArrowUpFromBracket,
    faCircleExclamation,
    faEye,
    faForklift,
    faTag
} from '@fortawesome/pro-solid-svg-icons';
import { camelCase, includes } from 'lodash';
import { useSelector } from 'react-redux';
import { NavLink, To, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { Collapsible } from '@/components/Collapsible';
import { FeatureFlagGuard } from '@/components/FeatureFlagGuard';
import { HeaderContent } from '@/components/styled/shared';
import { ErrorCodesModal } from '@/modals/ErrorCodesModal';
import { SelectTruckModal } from '@/modals/SelectTruckModal';
import { SerialNumberModal } from '@/modals/SerialNumberModal';
import { UploadTTPModal } from '@/modals/UploadTTPModal';
import { PermissionGuard } from '@/providers/PermissionGuard';
import { RootState } from '@/store';
import { StrictUnion } from '@/types/helpers';

const MenuBarWrapper = styled.nav`
    display: flex;
    align-items: center;
    height: 3.5em;
    color: ${({ theme }) => theme.palette.texts.white};
    background-color: ${({ theme }) => theme.palette.actionbar.background};
    left: 0;
    right: 0;
    width: 100%;
`;

const MenuItemsWrapper = styled.ul`
    display: flex;
    align-items: center;
    column-gap: 2.75rem;
    padding: 0;
`;

const StyledLi = styled.li`
    list-style-type: none;
`;

type MenuCommonItemType = {
    name: string;
    icon: IconDefinition;
    permission?: string;
    isActive?: boolean;
};

type MenuLinkItemType = MenuCommonItemType & {
    path: To;
};

type MenuButtonItemType = MenuCommonItemType & {
    modal: React.FC<any>;
};
export const MenuBar = () => {
    const location = useLocation();
    const { permissions } = useSelector((store: RootState) => store.user);

    const menuItems: StrictUnion<MenuLinkItemType | MenuButtonItemType>[] = [
        // { path: '/', name: 'Home', icon: faHome },
        {
            name: 'Truck model',
            icon: faForklift,
            modal: SelectTruckModal,
            isActive: location.pathname.startsWith('/truck-model')
        },
        {
            name: 'Serial number',
            icon: faTag,
            modal: SerialNumberModal,
            isActive: location.pathname.startsWith('/serial-number')
        },
        {
            name: 'Error codes',
            icon: faCircleExclamation,
            modal: ErrorCodesModal
        },
        {
            name: 'Business intelligence',
            icon: faEye,
            path: '/business-intelligence',
            permission: 'access:bi'
        }
    ];

    return (
        <MenuBarWrapper>
            <HeaderContent>
                <MenuItemsWrapper>
                    {menuItems
                        .filter(({ permission }) =>
                            permission ? includes(permissions, permission) : true
                        )
                        .map((item) => (
                            <StyledLi key={item.name}>
                                {item.path && <MenuLinkItem {...item} />}
                                {item.modal && <MenuButtonItem {...item} />}
                            </StyledLi>
                        ))}
                </MenuItemsWrapper>
                <FeatureFlagGuard feature="ttpUpload">
                    <PermissionGuard permission="access:ttp-upload">
                        <div style={{ marginLeft: 'auto' }}>
                            <Collapsible>
                                {({ isOpen, toggleOpen }) => (
                                    <>
                                        <Button onClick={toggleOpen} permission="access:bi">
                                            <Icon icon={faArrowUpFromBracket} />
                                            Upload TTP
                                        </Button>
                                        {isOpen && <UploadTTPModal handleModalClose={toggleOpen} />}
                                    </>
                                )}
                            </Collapsible>
                        </div>
                    </PermissionGuard>
                </FeatureFlagGuard>
            </HeaderContent>
        </MenuBarWrapper>
    );
};

const MenuItemWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    column-gap: 0.75rem;
    line-height: 1;
    transition: opacity 0.1s ease;

    &:hover {
        opacity: 0.8;
    }
`;

const Name = styled.span`
    position: relative;
`;

const Underline = styled.span`
    animation: fadeIn 0.075s ease-in-out;
    position: absolute;
    z-index: 1;
    height: 1px;
    background: white;
    width: 100%;
    left: 0;
    bottom: -0.3em;
`;

const MenuLinkItem: React.FC<MenuLinkItemType> = ({ path, ...rest }) => (
    <NavLink to={path} id={`menuLink-${camelCase(rest.name)}`}>
        {({ isActive }) => (
            <MenuItemWrapper>
                <MenuItem isActive={isActive} {...rest} />
            </MenuItemWrapper>
        )}
    </NavLink>
);

const MenuButtonItem: React.FC<MenuButtonItemType> = ({ modal, ...rest }) => {
    const ModalComponent = modal;

    return (
        <Collapsible>
            {({ isOpen, toggleOpen }) => (
                <>
                    <UnstyledButton
                        onClick={toggleOpen}
                        aria-label={rest.name}
                        aria-haspopup="true"
                        aria-expanded={isOpen}
                        role="button"
                        id={`menuButton-${camelCase(rest.name)}`}
                    >
                        <MenuItem {...rest} />
                    </UnstyledButton>
                    {isOpen && <ModalComponent handleModalClose={toggleOpen} />}
                </>
            )}
        </Collapsible>
    );
};

const MenuItem: React.FC<MenuCommonItemType> = ({ icon, name, isActive }) => (
    <MenuItemWrapper>
        <Icon icon={icon} className="fa-sm" />
        <Name>
            {name}
            {isActive && <Underline />}
        </Name>
    </MenuItemWrapper>
);
