import { useGetDevicesByTrucksQuery } from '@daisy/data-access';
import { Suspense } from '@daisy/react-components';
import { encode } from 'js-base64';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useNavigate } from 'react-router-dom';

import { ErrorHandler } from '@/components/ErrorHandler';
import { LoadingContainer } from '@/components/LoadingContainer';
import { QueryEditorPage } from '@/components/QueryEditor/QueryEditor';
import { useSerialNumber } from '@/hooks/useSerialNumber';
import { useSpellbook } from '@/hooks/useSpellbook';
import { useTruckModel } from '@/hooks/useTruckModel';
import { RootState } from '@/store';
import { formatObjectSelectOptions } from '@/utils/objectValueInspectorHelpers';
import { generateTruckModelUUIDs, handleGroupedLoadOptions } from '@/utils/selectHelpers';

export const ObjectValueInspectorQueryView = () => {
    const navigate = useNavigate();
    const { sendApplicationEvent } = useSpellbook();
    const { truckModel } = useTruckModel();
    const { relatedSerialNumbers } = useSerialNumber();

    const { uiLoading } = useSelector((state: RootState) => state.ui);

    const truckModelUUIDs = generateTruckModelUUIDs(truckModel?.uuid, relatedSerialNumbers);

    const queryState = useSelector((state: RootState) => state.queryEditor);
    const { mainObject, referenceObjects, selectedXAxis, selectedClustering } = queryState;

    const devicesByTrucksQuery = useGetDevicesByTrucksQuery({
        skip: !truckModelUUIDs,
        variables: { uuids: truckModelUUIDs || [] }
    });

    useEffect(() => {
        sendApplicationEvent('Query', {
            eventName: 'Object value inspector',
            eventMeta: {
                type: 'start',
                truckModel: truckModel?.softwareModelName
            }
        }).catch((e) => console.error(e));
    }, [sendApplicationEvent, truckModel?.softwareModelName]);

    const objectSelectOptions = useMemo(
        () =>
            devicesByTrucksQuery.data?.devicesByTrucks?.length
                ? formatObjectSelectOptions(
                      devicesByTrucksQuery.data.devicesByTrucks,
                      truckModel?.softwareModelName || ''
                  )
                : [],
        [devicesByTrucksQuery.data?.devicesByTrucks, truckModel]
    );

    const handleLoadOptions = useMemo(() => {
        const asd = handleGroupedLoadOptions(objectSelectOptions);
        return asd;
    }, [objectSelectOptions]);

    const handleOnExecuteButtonClick = () => {
        navigate({
            pathname: generatePath('graph?query=:query', {
                query: encode(JSON.stringify(queryState))
            })
        });
        sendApplicationEvent('Query', {
            eventName: 'Object value inspector',
            eventMeta: {
                type: 'finish',
                truckModel: truckModel?.softwareModelName,
                mainObject,
                referenceObjects,
                selectedXAxis,
                selectedClustering
            }
        }).catch((e) => console.error(e));
    };

    const isLoading = uiLoading || devicesByTrucksQuery.loading;

    return (
        <>
            <ErrorHandler hasErrored={!!devicesByTrucksQuery.error} />
            <Suspense condition={!isLoading} fallback={<LoadingContainer />}>
                <QueryEditorPage
                    objectSelectOptions={objectSelectOptions}
                    handleLoadOptions={handleLoadOptions}
                    onExecuteButtonClick={handleOnExecuteButtonClick}
                />
            </Suspense>
        </>
    );
};
