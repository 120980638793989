import {
    Filter,
    FilterGroup,
    FilterValueObject,
    QueryFilters,
    QueryOperator,
    QueryToggle
} from '@daisy/middleware-redux';
import {
    ACollapse,
    Button,
    GroupedSelectOption,
    Icon,
    SegmentedControl,
    Select,
    SelectOption
} from '@daisy/react-components';
import { faFolderPlus } from '@fortawesome/pro-regular-svg-icons';
import { AnimatePresence } from 'framer-motion';
import { filter, map, mapKeys } from 'lodash';
import { nanoid } from 'nanoid';
import { ReactElement } from 'react';
import styled, { useTheme } from 'styled-components';

import { createFilterObject, getToggleKey } from '../../utils/objectValueInspectorHelpers';
import { OneColumn } from '../styled/shared';
import { Indent, QueryFilterGroup } from './QueryFilterGroup';

type QueryEditorProps = {
    editorKey: string;
    objectName: string | null;
    queryFilters: QueryFilters;
    objectSelectOptions: GroupedSelectOption[];
    handleLoadOptions: (inputValue: string, callback: any) => void;
    handleObjectSelection: (option: SelectOption | null) => void;
    handleReduxUpdate: (payload: QueryFilters) => void;
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

export const ObjectEditor = ({
    editorKey,
    objectName,
    queryFilters,
    objectSelectOptions,
    handleLoadOptions,
    handleObjectSelection,
    handleReduxUpdate
}: QueryEditorProps) => {
    const theme = useTheme();

    const handleParentToggleUpdate = (newToggle: QueryToggle) => {
        const newQueryFilters = mapKeys(queryFilters, () => newToggle) as QueryFilters;
        handleReduxUpdate(newQueryFilters);
    };

    const handleFilterGroupToggleUpdate = (
        updatedToggle: FilterGroup,
        filterGroupIndex: number
    ) => {
        const newQueryFilters = { ...queryFilters };
        const toggle = getToggleKey(newQueryFilters);
        const filterValues = [...newQueryFilters[toggle as QueryToggle]];
        filterValues[filterGroupIndex] = updatedToggle;
        newQueryFilters[toggle as QueryToggle] = filterValues;
        handleReduxUpdate(newQueryFilters);
    };

    const handleFilterOperatorUpdate = (
        updatedFilter: FilterValueObject,
        filterGroupIndex: number,
        filterIndex: number
    ) => {
        const newQueryFilters = { ...queryFilters };
        const toggle = getToggleKey(newQueryFilters);
        const filterValues = [...newQueryFilters[toggle as QueryToggle]];
        const filtersInGroupIndex = { ...filterValues[filterGroupIndex] };
        const valuesInGroupIndex = [
            ...filtersInGroupIndex[getToggleKey(filtersInGroupIndex) as QueryToggle]
        ];
        const filterToBeUpdated = { ...valuesInGroupIndex[filterIndex] };

        filterToBeUpdated.valueNumber = updatedFilter;
        valuesInGroupIndex[filterIndex] = filterToBeUpdated;
        filtersInGroupIndex[getToggleKey(filtersInGroupIndex) as QueryToggle] = valuesInGroupIndex;
        filterValues[filterGroupIndex] = filtersInGroupIndex;
        newQueryFilters[toggle as QueryToggle] = filterValues;

        handleReduxUpdate(newQueryFilters);
    };

    const addNewFilterToGroup = (filterGroupIndex: number) => {
        const newQueryFilters = { ...queryFilters };
        const toggle = getToggleKey(newQueryFilters);
        const filterValues = [...newQueryFilters[toggle as QueryToggle]];
        const filtersInGroupIndex = { ...filterValues[filterGroupIndex] };
        const valuesInGroupIndex = [
            ...filtersInGroupIndex[getToggleKey(filtersInGroupIndex) as QueryToggle],
            createFilterObject(QueryOperator.GT, '')
        ];

        filtersInGroupIndex[getToggleKey(filtersInGroupIndex) as QueryToggle] = valuesInGroupIndex;
        filterValues[filterGroupIndex] = filtersInGroupIndex;
        newQueryFilters[toggle as QueryToggle] = filterValues;

        handleReduxUpdate(newQueryFilters);
    };

    const removeFilterFromGroup = (filterGroupIndex: number, newFilters: Filter[]) => {
        const newQueryFilters = { ...queryFilters };
        const toggle = getToggleKey(newQueryFilters);
        const filterValues = [...newQueryFilters[toggle as QueryToggle]];
        const filtersInGroupIndex = { ...filterValues[filterGroupIndex] };

        filtersInGroupIndex[getToggleKey(filtersInGroupIndex) as QueryToggle] = newFilters;
        filterValues[filterGroupIndex] = filtersInGroupIndex;
        newQueryFilters[toggle as QueryToggle] = filterValues;

        handleReduxUpdate(newQueryFilters);
    };

    const removeFilterGroup = (filterGroupIndex: number) => {
        const newQueryFilters = { ...queryFilters };
        const toggle = getToggleKey(newQueryFilters);
        const filterValues = [...newQueryFilters[toggle as QueryToggle]];

        newQueryFilters[toggle as QueryToggle] = filter(
            filterValues,
            (_, i) => filterGroupIndex !== i
        );

        handleReduxUpdate(newQueryFilters);
    };

    const renderQueryContainer = (): ReactElement => (
        <OneColumn>
            <Select
                name="Objects"
                isAsync
                isClearable
                placeholder="Type object name to inspect"
                options={objectSelectOptions}
                value={objectName ? { label: objectName, value: objectName } : null}
                handleChange={handleObjectSelection}
                handleLoadOptions={handleLoadOptions}
                showIcon={false}
                isGrouped
                inputId={`${editorKey}_input`}
            />
            <AnimatePresence initial={false}>
                {!!objectName &&
                    !!queryFilters[getToggleKey(queryFilters) as QueryToggle].length && (
                        <ACollapse style={{ display: 'flex', gap: '1rem' }}>
                            {queryFilters[getToggleKey(queryFilters) as QueryToggle].length > 1 && (
                                <Indent>
                                    <SegmentedControl
                                        name={`toggle-${editorKey}`}
                                        value={getToggleKey(queryFilters)}
                                        isSmall
                                        onChange={handleParentToggleUpdate}
                                        options={[
                                            { value: QueryToggle.AND, label: QueryToggle.AND },
                                            { value: QueryToggle.OR, label: QueryToggle.OR }
                                        ]}
                                    />
                                </Indent>
                            )}
                            <div style={{ flex: 4 }}>
                                <OneColumn gapSize="small">
                                    <AnimatePresence initial={false}>
                                        {map(
                                            queryFilters[getToggleKey(queryFilters) as QueryToggle],
                                            (filterGroup, index) => (
                                                <ACollapse key={filterGroup.id}>
                                                    <QueryFilterGroup
                                                        toggleKey={filterGroup.id}
                                                        filterGroupIndex={index}
                                                        filterGroup={filterGroup}
                                                        handleFilterGroupToggleUpdate={
                                                            handleFilterGroupToggleUpdate
                                                        }
                                                        handleFilterUpdate={
                                                            handleFilterOperatorUpdate
                                                        }
                                                        addNewFilterToGroup={addNewFilterToGroup}
                                                        removeFilterFromGroup={
                                                            removeFilterFromGroup
                                                        }
                                                        removeFilterGroup={removeFilterGroup}
                                                    />
                                                </ACollapse>
                                            )
                                        )}
                                    </AnimatePresence>
                                </OneColumn>
                            </div>
                        </ACollapse>
                    )}
            </AnimatePresence>
            <AnimatePresence initial={false}>
                {objectName && (
                    <ACollapse>
                        <Button
                            isPrimary={false}
                            onClick={() => {
                                const toggle = getToggleKey(queryFilters) as QueryToggle;
                                handleReduxUpdate({
                                    ...queryFilters,
                                    [toggle]: [
                                        ...queryFilters[toggle],
                                        {
                                            id: nanoid(),
                                            [QueryToggle.AND]: [
                                                createFilterObject(QueryOperator.GT, '')
                                            ]
                                        }
                                    ]
                                });
                            }}
                            icon={
                                <Icon
                                    icon={faFolderPlus}
                                    color={theme.palette.primary}
                                    className="fa-regular"
                                />
                            }
                        >
                            Add condition group
                        </Button>
                    </ACollapse>
                )}
            </AnimatePresence>
        </OneColumn>
    );

    return <Container>{renderQueryContainer()}</Container>;
};
