export enum SerialNumberViewTabOptions {
    ERROR_HISTORY_EVENTS = 'error-history-events',
    TRUCK_USAGE = 'truck-usage',
    OBJECT_VALUE_INSPECTOR = 'object-value-inspector',
    IOT_LIVE_DATA = 'iot-live-data',
    TRUCKTOOL_OPERATIONS = 'trucktool-operations'
}

export enum TruckUsageTabSubTabOptions {
    EVENTS = 'events',
    OBJECTS = 'objects',
    DEVICES = 'devices'
}
