var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { motion } from 'framer-motion';
import { uniqueId } from 'lodash';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { UnstyledButton } from '../Button/Button';
import { LoadingIndicator } from '../LoadingIndicator/LoadingIndicator';
export var TabContext = createContext(null);
export function useTabContext() {
    var context = useContext(TabContext);
    if (!context) {
        console.error('Error deploying Tabs Context');
    }
    return context;
}
export var Tabs = function (_a) {
    var _b = _a.initialValue, initialValue = _b === void 0 ? '' : _b, isVertical = _a.isVertical, id = _a.id, children = _a.children;
    var _c = useState(''), activeTab = _c[0], setActiveTab = _c[1];
    useEffect(function () {
        if (initialValue)
            setActiveTab(initialValue);
    }, [initialValue]);
    var uniqId = useMemo(function () { return uniqueId(); }, []);
    var tabProviderValue = useMemo(function () { return ({
        activeTab: activeTab,
        setActiveTab: setActiveTab,
        isVertical: isVertical,
        id: id !== null && id !== void 0 ? id : uniqId
    }); }, [activeTab, setActiveTab, isVertical, id, uniqId]);
    return React.createElement(TabContext.Provider, { value: tabProviderValue }, children);
};
var StyledTabHeader = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: flex;\n    flex-direction: ", ";\n    margin-bottom: 1rem;\n\n    ", "\n\n    ", "\n"], ["\n    display: flex;\n    flex-direction: ", ";\n    margin-bottom: 1rem;\n\n    ", "\n\n    ", "\n"])), function (_a) {
    var isVertical = _a.isVertical;
    return (isVertical ? 'column' : 'row');
}, function (_a) {
    var isVertical = _a.isVertical, theme = _a.theme;
    return isVertical &&
        "\n        border-right: 1px solid ".concat(theme.palette.border, ";\n        ");
}, function (_a) {
    var isVertical = _a.isVertical, theme = _a.theme;
    return !isVertical &&
        "\n        border-bottom: 1px solid ".concat(theme.palette.border, ";\n\n        @media (min-width: 768px) {\n            gap: 2rem;\n        }");
});
var TabHeader = function (_a) {
    var children = _a.children;
    var tabContext = useTabContext();
    return React.createElement(StyledTabHeader, { isVertical: tabContext === null || tabContext === void 0 ? void 0 : tabContext.isVertical }, children);
};
var VerticalButton = styled(UnstyledButton)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    position: relative;\n\n    ", "\n"], ["\n    position: relative;\n\n    ", "\n"])), function (_a) {
    var isActive = _a.isActive, theme = _a.theme;
    return "\n        color: ".concat(isActive ? theme.palette.primary : theme.palette.texts.grey, ";\n        font-weight: ").concat(isActive ? '600' : '400', ";\n        padding: 0.5rem 0;\n    ");
});
var HorizontalButton = styled(UnstyledButton)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    align-items: center;\n    border-radius: 8px 8px 0 0;\n    color: ", ";\n    display: flex;\n    font-family: 'Roboto', sans-serif;\n    font-size: 16px;\n    justify-content: center;\n    padding: 0.75rem 1.25rem;\n    position: relative;\n    transition: 0.1s all;\n\n    ", "\n\n    ", "\n"], ["\n    align-items: center;\n    border-radius: 8px 8px 0 0;\n    color: ", ";\n    display: flex;\n    font-family: 'Roboto', sans-serif;\n    font-size: 16px;\n    justify-content: center;\n    padding: 0.75rem 1.25rem;\n    position: relative;\n    transition: 0.1s all;\n\n    ", "\n\n    ", "\n"])), function (_a) {
    var theme = _a.theme;
    return theme.palette.texts.grey;
}, function (_a) {
    var isActive = _a.isActive, theme = _a.theme;
    return isActive &&
        "\n        color: ".concat(theme.palette.primary, ";\n    ");
}, function (_a) {
    var isActive = _a.isActive, theme = _a.theme;
    return !isActive &&
        "\n        :hover {\n            color: ".concat(theme.palette.primary, ";\n        }\n    ");
});
var ActiveLine = styled(motion.div)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    ", "\n    border-color: ", ";\n    position: absolute;\n"], ["\n    ", "\n    border-color: ", ";\n    position: absolute;\n"])), function (_a) {
    var isVertical = _a.isVertical;
    return isVertical
        ? "\n            border-right: 3px solid;\n            height: 100%;\n            top: 0;\n            right: 0;\n        "
        : "\n            border-bottom: 3px solid;\n            width: 100%;\n            bottom: 0;\n        ";
}, function (_a) {
    var theme = _a.theme;
    return theme.palette.primary;
});
var TabButton = function (_a) {
    var name = _a.name, text = _a.text, onClick = _a.onClick, rest = __rest(_a, ["name", "text", "onClick"]);
    var tabContext = useTabContext();
    var tabButtonRef = useRef(null);
    var ButtonComponent = (tabContext === null || tabContext === void 0 ? void 0 : tabContext.isVertical) ? VerticalButton : HorizontalButton;
    var handleOnClick = useCallback(function () {
        var _a, _b;
        if (onClick)
            onClick();
        tabContext === null || tabContext === void 0 ? void 0 : tabContext.setActiveTab(name);
        if ((_a = tabButtonRef === null || tabButtonRef === void 0 ? void 0 : tabButtonRef.current) === null || _a === void 0 ? void 0 : _a.scrollIntoView)
            (_b = tabButtonRef === null || tabButtonRef === void 0 ? void 0 : tabButtonRef.current) === null || _b === void 0 ? void 0 : _b.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
                inline: 'center'
            });
    }, [onClick, tabContext, name]);
    return (React.createElement(ButtonComponent, __assign({ type: "button", onClick: handleOnClick, ref: tabButtonRef, isActive: (tabContext === null || tabContext === void 0 ? void 0 : tabContext.activeTab) === name }, rest),
        text,
        (tabContext === null || tabContext === void 0 ? void 0 : tabContext.activeTab) === name && (React.createElement(ActiveLine, { layoutId: "outline-".concat(tabContext === null || tabContext === void 0 ? void 0 : tabContext.id), initial: false, transition: { duration: 0.15, ease: 'easeOut' }, isVertical: !!(tabContext === null || tabContext === void 0 ? void 0 : tabContext.isVertical) }))));
};
var TabContent = function (_a) {
    var children = _a.children;
    return React.createElement(React.Fragment, null, children);
};
var TabPanel = function (_a) {
    var name = _a.name, children = _a.children, _b = _a.isLoading, isLoading = _b === void 0 ? false : _b;
    var tabContext = useTabContext();
    if ((tabContext === null || tabContext === void 0 ? void 0 : tabContext.activeTab) !== name)
        return null;
    return (React.createElement(React.Fragment, null,
        isLoading && React.createElement(LoadingIndicator, null),
        !isLoading && children));
};
Tabs.Header = TabHeader;
Tabs.Button = TabButton;
Tabs.Content = TabContent;
Tabs.Panel = TabPanel;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
