import { SerialNumberTelematicsUsageTimesFragment } from '@daisy/data-access';
import { get, map, startCase } from 'lodash';

import { getChartColor, notEmpty } from '@/utils/helpers';

export const getOptions = (
    data: SerialNumberTelematicsUsageTimesFragment['data'] | null,
    handlePointClick: (x: number, y: number) => void,
    extFileIds: string[],
    deviceIds: string[]
): Highcharts.Options => {
    const zAxisCategories = createZAxisCategories(data);

    return {
        chart: {
            animation: false,
            options3d: {
                enabled: true,
                alpha: 10,
                beta: 4,
                depth: (30 + 30) * (data?.length || 1),
                viewDistance: 5,
                fitToPlot: true,
                frame: {
                    bottom: {
                        size: 1,
                        color: 'rgba(0, 0, 0, 0.05)'
                    }
                }
            }
        },
        legend: {
            margin: 20,
            symbolPadding: 0,
            symbolWidth: 0,
            symbolHeight: 0,
            squareSymbol: false
        },
        title: {
            margin: -20
        },
        yAxis: {
            title: {
                text: 'Hours'
            }
        },
        xAxis: {
            gridLineWidth: 1,
            categories: createXAxisCategories(data)
        },
        zAxis: {
            min: 0,
            max: data ? data?.length - 1 : undefined,
            gridLineWidth: 1,
            categories: createZAxisCategories(data),
            labels: {
                y: 0,
                rotation: 18
            }
        },
        plotOptions: {
            column: {
                groupZPadding: 30,
                depth: 30,
                groupPadding: 0,
                grouping: false,
                pointWidth: 30,
                events: {
                    click(e) {
                        // TODO: Use relevant info here instead of x and y.
                        // Probably need to add a "keys" field to createSeries.
                        handlePointClick(e.point.x, e.point.y ?? 0);
                    }
                }
            }
        },
        series: createSeries(
            data,
            zAxisCategories,
            extFileIds,
            deviceIds
        ) as Highcharts.SeriesOptionsType[],
        tooltip: {
            useHTML: true,
            headerFormat: `<table><tr><td colspan="2">${startCase(
                data?.[0]?.chartValues?.[0]?.type || ''
            )} {point.key}</td></tr>`,
            pointFormat:
                '<tr><td>{series.name} </td></tr>' +
                '<tr><td>Usage time: <b>{point.y}</b></td></tr>',
            footerFormat: '</table>'
        }
    };
};

// We should populate every field from backend (even if they are null)
// so we don't have to iterate through the whole data for categories.
const createXAxisCategories = (data: SerialNumberTelematicsUsageTimesFragment['data']): string[] =>
    map(get(data, '[0].chartValues', []), 'key');

const createZAxisCategories = (data: SerialNumberTelematicsUsageTimesFragment['data']): string[] =>
    map(data, 'key');

const createSeries = (
    data: SerialNumberTelematicsUsageTimesFragment['data'] | null,
    zAxisCategories: string[],
    extFileIds: string[],
    deviceIds: string[]
) => {
    const mappedSeries = data?.flatMap((dataPointValue, dataPointIndex) => ({
        stack: dataPointIndex,
        type: 'column',
        name: `${startCase(data?.[0]?.type || '')} ${zAxisCategories[dataPointIndex]}`,
        data: dataPointValue?.chartValues
            ?.filter(notEmpty)
            .map(({ value, ttpexternalid, header }) => {
                const subIndex = header ? deviceIds?.indexOf(header) : undefined;
                return {
                    y: value,
                    color: getChartColor(extFileIds.indexOf(ttpexternalid || ''), subIndex)
                };
            })
    }));

    return mappedSeries;
};
