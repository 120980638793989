import {
    AffectedSerialNumberFragment,
    useGetTruckErrorHistoryEventAffectedSerialNumbersQuery
} from '@daisy/data-access';
import {
    Button,
    Icon,
    Link,
    LoadingIndicator,
    Table,
    TableMain,
    TablePager
} from '@daisy/react-components';
import { faEye } from '@fortawesome/pro-solid-svg-icons';

import { TTPDownloadButton } from '@/components/Buttons/TTPDownloadButton';
import { TtpTags } from '@/components/TtpTag';
import { TableContainer } from '@/components/styled/shared';
import { useTimeframe } from '@/hooks/useTimeframe';
import { useTruckModel } from '@/hooks/useTruckModel';
import { getImagePath, notEmpty, tableSortArray } from '@/utils/helpers';

export const ErrorHistoryEventsTable: React.FC<any> = ({ selectedEvent }) => {
    const { timeframeValue } = useTimeframe(30);
    const { truckModel } = useTruckModel();

    const { data: affectedSerialNumbers, loading: isAffectedSerialNumbersLoading } =
        useGetTruckErrorHistoryEventAffectedSerialNumbersQuery({
            variables: {
                uuid: truckModel?.uuid as string,
                eventCode: selectedEvent?.eventCode as string,
                days: timeframeValue ? parseInt(timeframeValue, 10) : 30
            },
            skip: !truckModel?.uuid || !selectedEvent?.eventCode
        });

    const tableData =
        affectedSerialNumbers?.truckErrorHistoryEventAffectedSerialNumbers?.filter(notEmpty);

    return (
        <TableContainer>
            <Table columns={tableColumns as any} data={tableData || []}>
                <TableMain isSortable />
                <TablePager />
            </Table>
            {isAffectedSerialNumbersLoading && (
                <div
                    style={{
                        alignItems: 'center',
                        display: 'flex',
                        height: '20em',
                        justifyContent: 'center'
                    }}
                >
                    <LoadingIndicator />
                </div>
            )}
        </TableContainer>
    );
};

type CellProps = {
    row: {
        original: AffectedSerialNumberFragment;
    };
};

const HierarchyImagePathCell = ({ row: { original } }: CellProps) => (
    <img
        height={35}
        style={{ margin: '-10px 0' }}
        src={getImagePath(original.hierarchyImagePath)}
        alt={original.hierarchyName || ''}
    />
);

const SerialNumberCell = ({ row: { original } }: CellProps) => (
    <Link href={`/serial-number/${original.externalId}/error-history-events`}>
        {original.externalId}
    </Link>
);

const TagsCell = ({ row: { original } }: CellProps) =>
    original.tags ? <TtpTags tags={original.tags.filter(notEmpty)} /> : null;

const TTPFileCell = ({ row: { original } }: CellProps) =>
    original.ttpFileExternalId && (
        <div style={{ display: 'flex', gap: '0.5rem' }}>
            <TTPDownloadButton
                serialNumber={original.externalId || ''}
                ttpFile={{
                    time: original.time,
                    externalId: original.ttpFileExternalId || ''
                }}
                buttonText="Download"
                size="small"
            />
            <Button
                size="small"
                href={`/serial-number/${original.externalId}/truck-usage/events?extFileId=${original.ttpFileExternalId}`}
            >
                <Icon icon={faEye} />
                View
            </Button>
        </div>
    );

const tableColumns = [
    {
        accessor: 'hierarchyImagePath',
        disableSortBy: true,
        Cell: HierarchyImagePathCell
    },
    {
        Header: 'Serial number',
        accessor: 'externalId',
        Cell: SerialNumberCell
    },
    {
        Header: 'Brand model',
        accessor: 'hierarchyName'
    },
    {
        Header: 'Latest TTP time',
        accessor: 'time'
    },
    { Header: 'Version', accessor: 'truckToolVersion' },
    {
        Header: 'Tags',
        accessor: 'tags',
        sortType: tableSortArray,
        Cell: TagsCell
    },
    {
        Header: 'TTP File',
        disableSortBy: true,
        accessor: 'ttpFileExternalId',
        Cell: TTPFileCell
    }
];
