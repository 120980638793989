import { useEffect, useState } from 'react';

const isWindowAvailable = typeof window !== 'undefined';

const getPosition = () => (isWindowAvailable ? window.scrollY : undefined);

export const useScrollPosition = () => {
    const [scrollPosition, setScrollPosition] = useState(getPosition());

    useEffect(() => {
        if (!isWindowAvailable) {
            return () => undefined;
        }

        const handleScroll = () => {
            setScrollPosition(getPosition());
        };

        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return scrollPosition;
};
