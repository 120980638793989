"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setFilterMenuIsCollapsed = exports.filterMenuSlice = exports.initialState = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
exports.initialState = {
    isCollapsed: true
};
exports.filterMenuSlice = (0, toolkit_1.createSlice)({
    name: 'filterMenu',
    initialState: exports.initialState,
    reducers: {
        setFilterMenuIsCollapsed: (state, action) => {
            state.isCollapsed = action.payload;
        }
    }
});
exports.setFilterMenuIsCollapsed = exports.filterMenuSlice.actions.setFilterMenuIsCollapsed;
exports.default = exports.filterMenuSlice.reducer;
