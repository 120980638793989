import { SerialNumberTtpFilesResultFragment } from '@daisy/data-access';
import { colors, defaultTheme } from '@daisy/themes';
import { uniqueId } from 'lodash';
import styled from 'styled-components';

import { notEmpty } from '@/utils/helpers';

type TtpTagsProps = {
    tags: NonNullable<SerialNumberTtpFilesResultFragment['tags']>;
};

type TtpTagProps = {
    children: React.ReactNode;
    isFactory?: boolean;
    isWarranty?: boolean;
};

const StyledTag = styled('span')`
    border-radius: 4px;
    color: ${(props) => props.color};
    display: inline-block;
    font-weight: bold;
    padding: 1px;
`;

function translateTag(value: string) {
    switch (value) {
        case 'FACTORY_DEFAULT':
            return 'Factory default';
        case 'USER_EDIT':
            return 'User edit';
        case 'OBJECT_TABLE':
            return 'Object table';
        case 'GSE':
            return 'GSE';
        case 'WARRANTY_CLAIM':
            return 'Warranty claim';
    }

    return value;
}

export const TtpTags: React.FC<TtpTagsProps> = ({ tags }) => {
    if (!tags.length || tags.length === 0) {
        return null;
    }

    const renderedTags = tags.filter(notEmpty).map((tag) => {
        const isFactory = tag === 'FACTORY_DEFAULT';
        const isWarranty = tag === 'WARRANTY_CLAIM';
        const translatedTag = translateTag(tag);

        return (
            <TtpTag key={uniqueId()} isFactory={isFactory} isWarranty={isWarranty}>
                {translatedTag}
            </TtpTag>
        );
    });

    const concatenatedTags = renderedTags.reduce((result, item) => (
        <>
            {result}, {item}
        </>
    ));

    return concatenatedTags;
};

const TtpTag = ({ children, isFactory = false, isWarranty = false }: TtpTagProps) => {
    let color = defaultTheme.palette.texts.black;

    if (isFactory) {
        color = colors.orange.carrot;
    }

    if (isWarranty) {
        color = colors.blue.mariner;
    }

    return <StyledTag color={color}>{children}</StyledTag>;
};
