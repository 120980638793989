import {
    SerialNumberErrorHistorySummaryEventFragment,
    TruckErrorHistoryEventFragment
} from '@daisy/data-access';
import { get, map } from 'lodash';

import { CustomPointOptionsObject } from '@/types';

const SERIES_KEYS = ['y', 'eventCode', 'eventName', 'cumulativeEventCount', 'selectedIndex'];

export const getOptions = (
    series: Highcharts.SeriesOptionsType[],
    handlePointClick: (selectedIndex: number) => void
): Highcharts.Options => ({
    chart: {
        events: {
            load() {
                this.series[0].data[0]?.select();
            }
        }
    },
    title: {
        verticalAlign: 'middle',
        floating: true
    },
    tooltip: {
        useHTML: true,
        hideDelay: 100,
        headerFormat: '<table>',
        pointFormatter() {
            if (this) {
                const point = this.options as CustomPointOptionsObject;
                const headerRow = `<tr><td><small>${point.eventCode}</small></td></tr>`;
                const occurrenceRow = `<tr><td>Occurrences:<b style="font-size: 12px;">&nbsp;${this.y}</b></td></tr>`;
                const cumulativeCountRow = `<tr><td>Cumulative count:<b style="font-size: 12px;">&nbsp;${point.cumulativeEventCount}</b></td></tr>`;
                const eventRow = `<tr><td>${point.eventName}</td></tr>`;
                return headerRow + occurrenceRow + cumulativeCountRow + eventRow;
            }
            return '';
        },
        footerFormat: '</table>',
        style: {
            pointerEvents: 'none'
        }
    },
    accessibility: {
        point: {
            valueSuffix: '%'
        }
    },
    plotOptions: {
        pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            // borderColor: undefined,
            innerSize: '72.5%',
            dataLabels: {
                enabled: true,
                format: '<strong>{point.eventCode}</strong>: {point.y} ({point.percentage:.1f} %)',
                style: {
                    fontWeight: 'normal',
                    fontSize: '12px'
                }
            },
            point: {
                events: {
                    click(e) {
                        handlePointClick(get(e.point, 'selectedIndex', -1));
                    }
                }
            },
            states: {
                inactive: {
                    opacity: 1
                }
            }
        },
        series: {
            animation: false
        }
    },
    series
});

export const createSeries = (
    top10EventData: (
        | SerialNumberErrorHistorySummaryEventFragment
        | TruckErrorHistoryEventFragment
    )[]
): Highcharts.SeriesOptionsType[] => [
    {
        name: 'Top 10 events',
        type: 'pie',
        data: map(top10EventData, (value, index) => [...createSeriesData(value, index)]),
        keys: SERIES_KEYS
    }
];

const createSeriesData = (
    value: SerialNumberErrorHistorySummaryEventFragment | TruckErrorHistoryEventFragment,
    index: number
) => [value.occurrences, value.eventCode, value.eventName, value.cumulativeEventCount, index];
