import { useApolloClient } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { useAuth0 } from '@auth0/auth0-react';
import { ReactNode } from 'react';

import { LoadingContainer } from '@/components/LoadingContainer';
import { setToken } from '../store';
import { LoginView } from '../views/LoginView';
import { httpLink } from './ApolloProvider';
import { PermissionGuard } from './PermissionGuard';

const { REACT_APP_AUTH0_AUDIENCE } = process.env;

export const AuthGuard: React.FC<{ children?: ReactNode }> = ({ children }) => {
    const { isAuthenticated, getAccessTokenSilently, isLoading } = useAuth0();
    const client = useApolloClient();

    const authMiddleware = setContext(async (_operation, { headers }) => {
        const token = await getAccessTokenSilently({
            audience: REACT_APP_AUTH0_AUDIENCE,
            scope: 'openid profile email'
        });
        setToken(token);

        return {
            headers: {
                ...headers,
                authorization: token ? `Bearer ${token}` : ''
            }
        };
    });

    client.setLink(authMiddleware.concat(httpLink));

    if (isLoading) {
        return <LoadingContainer />;
    }

    if (!isAuthenticated) {
        return <LoginView />;
    }

    return (
        <PermissionGuard permission="application:tda" showUnauthorizedView>
            {children}
        </PermissionGuard>
    );
};
