"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setObjectTableShowStringFilter = exports.setObjectTableBehaviorTagFilter = exports.setObjectTableFreeSearchFilter = exports.setEventTableSearchFilter = exports.setDeviceChartData = exports.unmountDeviceChart = exports.setSelectedChartDevice = exports.addToCompareExtFileIds = exports.setCompareExtFileIds = exports.setBaseExtFileId = exports.setMode = exports.resetUsagePage = exports.usageGraphSlice = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const initialState = {
    mode: 'single',
    baseExtFileId: null,
    compareExtFileIds: [],
    deviceChart: {
        selectedChartDevice: null,
        data: []
    },
    eventTable: {
        eventTableSearchFilter: ''
    },
    objectTable: {
        freeSearchFilter: '',
        behaviorTagFilter: [],
        showStringFilter: true
    }
};
exports.usageGraphSlice = (0, toolkit_1.createSlice)({
    name: 'usageGraphData',
    initialState,
    reducers: {
        resetUsagePage: (state) => {
            state.mode = 'single';
            state.compareExtFileIds = [];
            state.objectTable.freeSearchFilter = '';
            state.objectTable.behaviorTagFilter = [];
            state.objectTable.showStringFilter = true;
            state.eventTable.eventTableSearchFilter = '';
        },
        setMode: (state, action) => {
            state.mode = action.payload;
        },
        setBaseExtFileId: (state, action) => {
            state.baseExtFileId = action.payload;
        },
        setCompareExtFileIds: (state, action) => {
            state.compareExtFileIds = action.payload;
        },
        addToCompareExtFileIds: (state, action) => {
            if (![...state.compareExtFileIds, state.baseExtFileId].includes(action.payload)) {
                state.compareExtFileIds = [...state.compareExtFileIds, action.payload];
            }
        },
        setSelectedChartDevice: (state, action) => {
            state.deviceChart.selectedChartDevice = action.payload;
        },
        unmountDeviceChart: (state) => {
            state.deviceChart.selectedChartDevice = null;
        },
        setDeviceChartData: (state, action) => {
            state.deviceChart.data = action.payload;
        },
        setEventTableSearchFilter: (state, action) => {
            state.eventTable.eventTableSearchFilter = action.payload;
        },
        setObjectTableFreeSearchFilter: (state, action) => {
            state.objectTable.freeSearchFilter = action.payload;
        },
        setObjectTableBehaviorTagFilter: (state, action) => {
            state.objectTable.behaviorTagFilter = action.payload;
        },
        setObjectTableShowStringFilter: (state, action) => {
            state.objectTable.showStringFilter = action.payload;
        }
    }
});
_a = exports.usageGraphSlice.actions, exports.resetUsagePage = _a.resetUsagePage, exports.setMode = _a.setMode, exports.setBaseExtFileId = _a.setBaseExtFileId, exports.setCompareExtFileIds = _a.setCompareExtFileIds, exports.addToCompareExtFileIds = _a.addToCompareExtFileIds, exports.setSelectedChartDevice = _a.setSelectedChartDevice, exports.unmountDeviceChart = _a.unmountDeviceChart, exports.setDeviceChartData = _a.setDeviceChartData, exports.setEventTableSearchFilter = _a.setEventTableSearchFilter, exports.setObjectTableFreeSearchFilter = _a.setObjectTableFreeSearchFilter, exports.setObjectTableBehaviorTagFilter = _a.setObjectTableBehaviorTagFilter, exports.setObjectTableShowStringFilter = _a.setObjectTableShowStringFilter;
exports.default = exports.usageGraphSlice.reducer;
