"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setFeatureFlags = exports.featureFlagsSlice = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const initialState = {
    flags: {}
};
exports.featureFlagsSlice = (0, toolkit_1.createSlice)({
    name: 'featureFlags',
    initialState,
    reducers: {
        setFeatureFlags: (state, action) => {
            state.flags = action.payload.flags;
        }
    }
});
exports.setFeatureFlags = exports.featureFlagsSlice.actions.setFeatureFlags;
exports.default = exports.featureFlagsSlice.reducer;
