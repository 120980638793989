import { XAxisDefaultSelections, setSelectedXAxis } from '@daisy/middleware-redux';
import { H3, RadioButton } from '@daisy/react-components';
import { map } from 'lodash';
import { useSelector } from 'react-redux';

import { RootState, useAppDispatch } from '@/store';
import { OneColumn } from '../styled/shared';

export const XAxisSelection = () => {
    const dispatch = useAppDispatch();

    const { referenceObjects, selectedXAxis } = useSelector(
        (state: RootState) => state.queryEditor
    );

    const handleXAxisChange = (event: React.FormEvent<HTMLInputElement>) => {
        dispatch(setSelectedXAxis(event.currentTarget.value));
    };

    return (
        <OneColumn gapSize="small">
            <H3>X-axis</H3>
            <div>
                <RadioButton
                    id="xAxis_time"
                    name="xAxis"
                    aria-label="X-axis time"
                    value={XAxisDefaultSelections.TIME}
                    checked={selectedXAxis === XAxisDefaultSelections.TIME}
                    label="Time"
                    onChange={(event) => handleXAxisChange(event)}
                />
                {/* <RadioButton
                    id="xAxis_deploymentYear"
                    name="xAxis"
                    value={XAxisDefaultSelections.DEPLOYMENT_YEAR}
                    checked={selectedXAxis === XAxisDefaultSelections.DEPLOYMENT_YEAR}
                    label="Deployment year"
                    onChange={() =>
                        dispatch(setSelectedXAxis(XAxisDefaultSelections.DEPLOYMENT_YEAR))
                    }
                /> */}
                {map(
                    referenceObjects,
                    ({ object }, index) =>
                        object.name &&
                        object.label && (
                            <RadioButton
                                key={`xAxis_ref-${index}`}
                                id={`xAxis_ref-${index}`}
                                name={`xAxis-${object.name}`}
                                aria-label={`X-axis ${object.name}`}
                                value={object.name}
                                checked={selectedXAxis === object.name}
                                label={object.label}
                                onChange={(event) => handleXAxisChange(event)}
                            />
                        )
                )}
            </div>
        </OneColumn>
    );
};
