import { Auth0Provider } from '@auth0/auth0-react';
import { ThemeProvider, ToastContainer } from '@daisy/react-components';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import Modal from 'react-modal';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import { createGlobalStyle } from 'styled-components';

import { App } from './App';
import { ApolloProvider } from './providers/ApolloProvider';
import { AuthGuard } from './providers/AuthGuard';
import { FeatureFlagProvider } from './providers/FeatureFlagProvider';
import { store } from './store';

const scope = 'openid profile';
const { REACT_APP_AUTH0_DOMAIN, REACT_APP_AUTH0_CLIENT_ID, REACT_APP_AUTH0_AUDIENCE } = process.env;


const GlobalStyle = createGlobalStyle`
    * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
    }
    
    #root,
    html {
        min-height: 100vh;
    }

    body {
        margin: 0;
        min-height: 100vh;
        font-family: ${({ theme }) => theme.fonts.families.content}, sans-serif;
    }

    a {
        text-decoration: none;
        color: inherit;
    }

    ul {
        padding-inline-start: 1em;
    }

    hr {
        border: none;
        border-bottom: 2px solid #eee;
    }

    @keyframes fadeIn {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    @keyframes fadeInY {
        0% {
            transform: translateY(-10px);
            opacity: 0;
        }
        100% {
            transform: translateY(0);
            opacity: 1;
        }
    }
`;

Modal.setAppElement('#root');

const container = document.getElementById('root');

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

root.render(
    <StrictMode>
        <ReduxProvider store={store}>
            <Router>
                <Auth0Provider
                    domain={REACT_APP_AUTH0_DOMAIN || 'UNKNOWN'}
                    clientId={REACT_APP_AUTH0_CLIENT_ID || 'UNKNOWN'}
                    redirectUri={window.location.origin}
                    audience={REACT_APP_AUTH0_AUDIENCE}
                    scope={scope}
                    useRefreshTokens
                    cacheLocation="localstorage"
                >
                    <ThemeProvider>
                        <GlobalStyle />
                        <ApolloProvider>
                            <AuthGuard>
                                <ToastProvider
                                    autoDismiss
                                    autoDismissTimeout={5000}
                                    components={{
                                        Toast: ToastContainer
                                    }}
                                    placement="bottom-right"
                                >
                                    <FeatureFlagProvider>
                                        <App />
                                    </FeatureFlagProvider>
                                </ToastProvider>
                            </AuthGuard>
                        </ApolloProvider>
                    </ThemeProvider>
                </Auth0Provider>
            </Router>
        </ReduxProvider>
    </StrictMode>
);
