import {
    useTtpTelematicsWithErrorHistoryEventsMultipleQuery,
    useTtpTelematicsWithErrorHistoryEventsQuery
} from '@daisy/data-access';
import { H3, LoadingIndicator } from '@daisy/react-components';
import { sortBy } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { StyledChart } from '@/components/styled/shared';
import { useSerialNumber } from '@/hooks/useSerialNumber';
import { useTtpFiles } from '@/hooks/useTtpFiles';
import { RootState } from '@/store';

export const TruckUsageOnlineEventTimeline: React.FC<{ title: string }> = ({ title }) => {
    const { serialNumber } = useSerialNumber();
    const { extFileIds, isLoading: isTtpLoading } = useTtpFiles(serialNumber);

    const telematicsForTtpQuery = useTtpTelematicsWithErrorHistoryEventsQuery({
        variables: { externalId: extFileIds[0] || '' },
        skip: !extFileIds[0]
    });

    const telematicsForTtpMultipleQuery = useTtpTelematicsWithErrorHistoryEventsMultipleQuery({
        variables: { externalIds: extFileIds },
        skip: !extFileIds || extFileIds.length <= 1
    });

    const { mode } = useSelector((state: RootState) => state.usageGraph);

    const onlineEvents = useMemo(
        () =>
            sortBy(
                telematicsForTtpQuery.data?.ttpTelematicsWithErrorHistoryEvents?.ttpEventsForTtp?.filter(
                    (e) => e.eventType === 'OnlineEvent' && e.event
                ),
                'time'
            ),
        [telematicsForTtpQuery.data?.ttpTelematicsWithErrorHistoryEvents?.ttpEventsForTtp]
    );

    const [chartOptions, setChartOptions] = useState<Highcharts.Options>({
        chart: {
            type: 'timeline'
        },
        xAxis: {
            visible: false,
            title: {
                text: undefined
            }
        },
        yAxis: {
            visible: false,
            title: {
                text: undefined
            }
        },
        title: undefined,
        subtitle: undefined,
        series: []
    });
    
    const formatter = new Intl.DateTimeFormat();

    useEffect(() => {
        if (onlineEvents.length > 0) {
            setChartOptions({
                series: [
                    {
                        type: 'timeline',
                        data: onlineEvents.map((event) => ({
                            name: `${event.event?.userId}: ${event.event?.name}`,
                            label: formatter.format(new Date(event.time)),
                            description: `${event.event?.userId}: ${event.event?.name}`
                        }))
                    }
                ]
            });
        }
    }, [onlineEvents, telematicsForTtpQuery.data]);

    const isLoading =
        telematicsForTtpMultipleQuery.loading || telematicsForTtpQuery.loading || isTtpLoading;

    const doRender =
        (mode === 'single' && telematicsForTtpQuery.data) ||
        (mode === 'compare' && !!telematicsForTtpMultipleQuery.data);

    return (
        <>
            {isLoading && (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <LoadingIndicator />
                </div>
            )}
            {doRender && !!onlineEvents.length && (
                <>
                    <H3>{title}</H3>
                    <StyledChart options={chartOptions} constructorType="chart" />
                </>
            )}
        </>
    );
};
