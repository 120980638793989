import { H2 } from '@daisy/react-components';
import styled from 'styled-components';

import { DummyActionBar } from '@/components/DummyActionBar';
import { Header } from '@/components/Header';
import { TopBar } from '@/components/TopBar';
import { ViewContainer } from '@/components/styled/shared';
import packageJson from '../../package.json';

const LoginViewContainer = styled.main`
    left: 0;
    bottom: 0;
    right: 0;
    flex: 1 1 auto;
    overflow: hidden auto;
    min-height: 100vh;
    padding: 0;
    padding: 7em 0 0;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    background-image: url(https://devdaisyapimanagement.developer.azure-api.net/content/warehouse_background_1440p_50opaque.png);
    background-repeat: no-repeat;
    background-size: cover;
`;

const LoginViewContent = styled.div`
    display: flex;
    flex-direction: row;
    padding: 2em;
`;

export const Panel = styled.div`
    box-shadow: ${({ theme }) => theme.palette.card.shadow};
    border-radius: 2px;
    padding: 1em;
    max-width: 40em;
    margin: 0 1em;
    background-color: rgba(255, 255, 255, 0.85);
`;

export const LoginView = () => (
    <ViewContainer>
        <Header>
            <TopBar versionText={packageJson.version} />
        </Header>
        <DummyActionBar />
        <LoginViewContainer>
            <LoginViewContent>
                <Panel>
                    <H2>TRUCK DATA ANALYZER</H2>
                    <p>
                        Truck Data Analyzer is a customized data visualization tool to help
                        developers, R&D teams, and owners/operators look at the performance of lift
                        trucks by analyzing parameters gathered from the machines.
                    </p>
                    <p>
                        The purpose of this app is to make it easier to identify problems, track
                        performance over time, and make appropriate maintenance decisions based on
                        real-time feedback.
                    </p>
                </Panel>
                <Panel>
                    <H2>GET ACCESS</H2>
                    <p>
                        To get access, please send a request to TruckTool Support by sending an
                        email to{' '}
                        <a href="mailto:trucktool.support@logisnext.eu">
                            trucktool.support@logisnext.eu
                        </a>
                    </p>
                </Panel>
            </LoginViewContent>
        </LoginViewContainer>
    </ViewContainer>
);
