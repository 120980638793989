import { QueryEditorState } from '@daisy/middleware-redux';
import { decode, encode } from 'js-base64';
import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

export const useQueryEditorSearchParam = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const queryParam = searchParams.get('query');

    const queryEditorSearchParam: QueryEditorState | undefined = useMemo(() => {
        if (queryParam) {
            try {
                return JSON.parse(decode(queryParam));
            } catch (e) {
                return undefined;
            }
        }
        return undefined;
    }, [queryParam]);

    const setQueryEditorSearchParam = useCallback(
        (queryEditorState: QueryEditorState) => {
            setSearchParams({
                query: encode(JSON.stringify(queryEditorState))
            });
        },
        [setSearchParams]
    );

    return { queryEditorSearchParam, setQueryEditorSearchParam };
};
