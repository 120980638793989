import { useCallback, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

export const useTimeframe = (defaultValue: number | string) => {
    const [daySearchParams, setDaySearchParams] = useSearchParams();
    const timeframeValue = useMemo(() => daySearchParams.get('days'), [daySearchParams]);

    const setTimeframeValue = useCallback(
        (value: string | number) =>
            setDaySearchParams(
                {
                    days: `${value}`
                },
                { replace: true }
            ),
        [setDaySearchParams]
    );

    useEffect(() => {
        if (defaultValue && !timeframeValue) setTimeframeValue(defaultValue);
    }, [timeframeValue, setTimeframeValue, defaultValue]);

    return { timeframeValue, setTimeframeValue };
};
