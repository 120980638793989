import { useMutation } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import Spellbook, { ApplicationEventType, CREATE_APPLICATION_EVENT } from '@daisy/spellbook';
import { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { isProduction } from '@/utils/helpers';

const applicationId = process.env.REACT_APP_AUTH0_CLIENT_ID as string;
const applicationName = 'Truck Data Analyzer';
const uuidGenerator = uuidv4;
const spellbook = new Spellbook(applicationId, applicationName, uuidGenerator);

const sendApplicationEvent = (eventChainName: string, eventData: ApplicationEventType) =>
    isProduction ? spellbook.createEventChain(eventChainName)(eventData) : Promise.resolve();

export const useSpellbook = () => {
    const { user, isAuthenticated } = useAuth0();
    const [createApplicationEvent] = useMutation(CREATE_APPLICATION_EVENT);

    useEffect(() => {
        if (isAuthenticated) {
            spellbook.setUserId(user?.sub as string);
        }
    }, [user, isAuthenticated]);

    useEffect(() => {
        spellbook.setMutator(createApplicationEvent);
    }, [createApplicationEvent]);

    return { sendApplicationEvent };
};
