var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import { useExpanded, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';
import styled from 'styled-components';
var TableContext = React.createContext({});
export function useTableContext() {
    var context = React.useContext(TableContext || null);
    if (!context) {
        console.error('Error deploying Table Context');
    }
    return context;
}
export function Table(_a) {
    var columns = _a.columns, data = _a.data, children = _a.children, defaultSorting = _a.defaultSorting, isLoading = _a.isLoading, controlledPageCount = _a.pageCount, controlledPageSize = _a.pageSize, fetchData = _a.fetchData, expandedRows = _a.expandedRows;
    var tableInstance = useTable(__assign(__assign({ columns: columns, data: data, initialState: __assign(__assign(__assign({}, (defaultSorting && { sortBy: defaultSorting })), { pageIndex: 0, pageSize: controlledPageSize !== null && controlledPageSize !== void 0 ? controlledPageSize : 100 }), (expandedRows && { expanded: expandedRows })), defaultColumn: {
            width: 'auto'
        }, autoResetExpanded: false, autoResetPage: !fetchData, 
        // Tell the usePagination
        // hook that we'll handle our own data fetching
        // This means we'll also have to provide our own
        // pageCount.
        manualPagination: !!fetchData }, (controlledPageCount && { pageCount: controlledPageCount })), { 
        // We need to update the state manually when expandedRows change
        useControlledState: function (state) {
            return React.useMemo(function () { return (__assign(__assign({}, state), (expandedRows && { expanded: __assign({}, expandedRows) }))); }, 
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [state, expandedRows]);
        } }), useGlobalFilter, useSortBy, useExpanded, usePagination);
    var _b = tableInstance.state, pageIndex = _b.pageIndex, pageSize = _b.pageSize;
    useEffect(function () {
        if (fetchData)
            fetchData({ pageIndex: pageIndex, pageSize: pageSize });
    }, [fetchData, pageIndex, pageSize]);
    return (React.createElement(TableContext.Provider, { value: {
            table: tableInstance,
            controlledPageSize: controlledPageSize,
            isLoading: isLoading
        } },
        React.createElement(Wrapper, null, children)));
}
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: flex;\n    flex-direction: column;\n    gap: 1rem;\n"], ["\n    display: flex;\n    flex-direction: column;\n    gap: 1rem;\n"])));
var templateObject_1;
