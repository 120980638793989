"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setActiveNotification = exports.notificationSlice = exports.initialState = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
exports.initialState = {
    activeNotification: null
};
exports.notificationSlice = (0, toolkit_1.createSlice)({
    name: 'devices',
    initialState: exports.initialState,
    reducers: {
        setActiveNotification(state, action) {
            state.activeNotification = action.payload;
        }
    }
});
exports.setActiveNotification = exports.notificationSlice.actions.setActiveNotification;
exports.default = exports.notificationSlice.reducer;
