var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import React from 'react';
import styled from 'styled-components';
import { Icon } from '../Icon/Icon';
var HiddenCheckbox = styled.input(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    border: 0;\n    clip: rect(0 0 0 0);\n    clip-path: inset(50%);\n    height: 1px;\n    margin: -1px;\n    overflow: hidden;\n    padding: 0;\n    position: absolute;\n    white-space: nowrap;\n    width: 1px;\n"], ["\n    border: 0;\n    clip: rect(0 0 0 0);\n    clip-path: inset(50%);\n    height: 1px;\n    margin: -1px;\n    overflow: hidden;\n    padding: 0;\n    position: absolute;\n    white-space: nowrap;\n    width: 1px;\n"])));
var StyledCheckbox = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    box-sizing: border-box;\n    display: inline-flex;\n    width: 25px;\n    height: 25px;\n    border-radius: 1px;\n    background-color: ", ";\n    border: 1px solid ", ";\n    justify-content: center;\n    align-content: center;\n    align-items: center;\n"], ["\n    box-sizing: border-box;\n    display: inline-flex;\n    width: 25px;\n    height: 25px;\n    border-radius: 1px;\n    background-color: ", ";\n    border: 1px solid ", ";\n    justify-content: center;\n    align-content: center;\n    align-items: center;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.palette.checkbox.background;
}, function (_a) {
    var theme = _a.theme;
    return theme.palette.checkbox.border;
});
var CheckboxLabel = styled.span(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    margin-left: 10px;\n    color: #000;\n    font-family: 'Roboto', sans-serif;\n    font-size: 14px;\n    font-weight: 400;\n    line-height: 25px;\n    letter-spacing: 1.5px;\n    text-align: left;\n"], ["\n    margin-left: 10px;\n    color: #000;\n    font-family: 'Roboto', sans-serif;\n    font-size: 14px;\n    font-weight: 400;\n    line-height: 25px;\n    letter-spacing: 1.5px;\n    text-align: left;\n"])));
var CheckboxWrapper = styled.label(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    display: inline-flex;\n    cursor: pointer;\n    margin: 0.25em 0;\n\n    &:hover {\n        ", " {\n            opacity: 0.8;\n        }\n    }\n"], ["\n    display: inline-flex;\n    cursor: pointer;\n    margin: 0.25em 0;\n\n    &:hover {\n        ", " {\n            opacity: 0.8;\n        }\n    }\n"])), CheckboxLabel);
export var CheckIcon = styled(Icon)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    color: ", ";\n    width: 15px !important;\n"], ["\n    color: ", ";\n    width: 15px !important;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.palette.checkbox.checked;
});
export var Checkbox = function (_a) {
    var style = _a.style, className = _a.className, _b = _a.label, label = _b === void 0 ? '' : _b, value = _a.value, name = _a.name, _c = _a.isChecked, isChecked = _c === void 0 ? false : _c, onChange = _a.onChange;
    var onChangeHandler = function (event) {
        onChange(event);
    };
    return (React.createElement(CheckboxWrapper, { style: style, className: className },
        React.createElement(HiddenCheckbox, { type: "checkbox", checked: isChecked, value: value, onChange: onChangeHandler, name: name }),
        React.createElement(StyledCheckbox, { checked: isChecked }, isChecked && React.createElement(CheckIcon, { icon: faCheck })),
        React.createElement(CheckboxLabel, null, label)));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
