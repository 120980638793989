"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setPermissionModalIsOpen = exports.setTDFUploadModalOpen = exports.setTDFDownloadModalOpen = exports.setCSVUploadModalOpen = exports.setSelectErrorCodeTruckModalIsOpen = exports.setSelectTruckModalOpen = exports.setSelectSerialNumberModalOpen = exports.setBluetoothModalOpen = exports.setFeedbackModalOpen = exports.modalSlice = exports.initialState = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
exports.initialState = {
    feedbackModalIsOpen: false,
    bluetoothModalIsOpen: false,
    selectSerialNumberModalIsOpen: false,
    selectTruckModalIsOpen: false,
    selectErrorCodeTruckModalIsOpen: false,
    CSVUploadModalIsOpen: false,
    TDFDownloadModalIsOpen: false,
    TDFUploadModalIsOpen: false,
    permissionModalIsOpen: false
};
exports.modalSlice = (0, toolkit_1.createSlice)({
    name: 'modal',
    initialState: exports.initialState,
    reducers: {
        setFeedbackModalOpen: (state, action) => {
            state.feedbackModalIsOpen = action.payload;
        },
        setBluetoothModalOpen: (state, action) => {
            state.bluetoothModalIsOpen = action.payload;
        },
        setSelectSerialNumberModalOpen: (state, action) => {
            state.selectSerialNumberModalIsOpen = action.payload;
        },
        setSelectTruckModalOpen: (state, action) => {
            state.selectTruckModalIsOpen = action.payload;
        },
        setSelectErrorCodeTruckModalIsOpen: (state, action) => {
            state.selectErrorCodeTruckModalIsOpen = action.payload;
        },
        setCSVUploadModalOpen: (state, action) => {
            state.CSVUploadModalIsOpen = action.payload;
        },
        setTDFDownloadModalOpen: (state, action) => {
            state.TDFDownloadModalIsOpen = action.payload;
        },
        setTDFUploadModalOpen: (state, action) => {
            state.TDFUploadModalIsOpen = action.payload;
        },
        setPermissionModalIsOpen: (state, action) => {
            state.permissionModalIsOpen = action.payload;
        }
    }
});
_a = exports.modalSlice.actions, exports.setFeedbackModalOpen = _a.setFeedbackModalOpen, exports.setBluetoothModalOpen = _a.setBluetoothModalOpen, exports.setSelectSerialNumberModalOpen = _a.setSelectSerialNumberModalOpen, exports.setSelectTruckModalOpen = _a.setSelectTruckModalOpen, exports.setSelectErrorCodeTruckModalIsOpen = _a.setSelectErrorCodeTruckModalIsOpen, exports.setCSVUploadModalOpen = _a.setCSVUploadModalOpen, exports.setTDFDownloadModalOpen = _a.setTDFDownloadModalOpen, exports.setTDFUploadModalOpen = _a.setTDFUploadModalOpen, exports.setPermissionModalIsOpen = _a.setPermissionModalIsOpen;
exports.default = exports.modalSlice.reducer;
