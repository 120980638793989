import { P1 } from '@daisy/react-components';
import { orderBy } from 'lodash';
import styled from 'styled-components';

import { useSerialNumber } from '@/hooks/useSerialNumber';
import { useTtpFiles } from '@/hooks/useTtpFiles';
import { getChartColor } from '@/utils/helpers';

const StyledChartLegendContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1rem;
`;

const LegendGroupContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
`;

const LegendItemContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.25rem;
`;

const StyledLegendColoredDiv = styled.div<{ background: string }>((props) => ({
    width: '1em',
    height: '1em',
    marginRight: '0.5em',
    background: props.background
}));

export const ChartLegend = ({ deviceIds }: { deviceIds?: string[] }) => {
    const { serialNumber } = useSerialNumber();
    const { extFileIds, selectedTtpFiles } = useTtpFiles(serialNumber);

    const orderedTtps = orderBy(selectedTtpFiles, 'ttpFileTime');

    const formatter = new Intl.DateTimeFormat();

    return (
        <StyledChartLegendContainer>
            {orderedTtps.map((ttp) => (
                <LegendGroupContainer key={ttp.ttpFileId}>
                    <p>{formatter.format(new Date(ttp.ttpFileTime))}</p>
                    {deviceIds?.map((deviceId, deviceIndex) => (
                        <LegendItemContainer key={deviceId}>
                            <StyledLegendColoredDiv
                                background={getChartColor(
                                    extFileIds.indexOf(ttp.ttpFileExternalId || ''),
                                    deviceIndex
                                )}
                            />
                            <P1>{deviceId}</P1>
                        </LegendItemContainer>
                    ))}
                </LegendGroupContainer>
            ))}
        </StyledChartLegendContainer>
    );
};
