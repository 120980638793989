"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setFeedbackInput = exports.feedbackSlice = exports.initialState = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
exports.initialState = {
    feedbackInput: null
};
exports.feedbackSlice = (0, toolkit_1.createSlice)({
    name: 'feedback',
    initialState: exports.initialState,
    reducers: {
        setFeedbackInput: (state, action) => {
            state.feedbackInput = action.payload;
        }
    }
});
exports.setFeedbackInput = exports.feedbackSlice.actions.setFeedbackInput;
exports.default = exports.feedbackSlice.reducer;
