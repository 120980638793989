import {
    Filter,
    FilterGroup,
    FilterValueObject,
    QueryOperator,
    QueryToggle
} from '@daisy/middleware-redux';
import {
    ACollapse,
    Card,
    Icon,
    LinkButton,
    SegmentedControl,
    UnstyledButton
} from '@daisy/react-components';
import { faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { AnimatePresence } from 'framer-motion';
import { filter, get, map, mapKeys } from 'lodash';
import styled from 'styled-components';

import { getFirstKey } from '../../utils/helpers';
import {
    getToggleKey,
    operatorLookup,
    operatorSelectOptions
} from '../../utils/objectValueInspectorHelpers';
import { OneColumn } from '../styled/shared';
import { QueryFilter } from './QueryFilter';

type QueryFilterGroupProps = {
    toggleKey: string;
    filterGroupIndex: number;
    filterGroup: FilterGroup;
    handleFilterGroupToggleUpdate: (updatedFilter: FilterGroup, filterGroupIndex: number) => void;
    handleFilterUpdate: (
        updatedFilter: FilterValueObject,
        groupIndex: number,
        filterIndex: number
    ) => void;
    addNewFilterToGroup: (filterGroupIndex: number) => void;
    removeFilterFromGroup: (filterGroupIndex: number, newFilters: Filter[]) => void;
    removeFilterGroup: (filterGroupIndex: number) => void;
};

const QueryFilterGroupWrapper = styled.div`
    display: flex;
    position: relative;
`;

const RemoveButton = styled(UnstyledButton)`
    display: flex;
    gap: 0.75em;
`;

export const Indent = styled.div`
    display: flex;
    align-items: center;
    position: relative;

    > * {
        position: relative;
        z-index: 1;
    }

    &:after {
        content: '';
        position: absolute;
        left: 50%;
        width: 1rem;
        top: 1rem;
        bottom: 1rem;
        border-radius: 5px 0 0 5px;
        border-left: 2px solid #ddd;
        border-bottom: 2px solid #ddd;
        border-top: 2px solid #ddd;
        z-index: 0;
    }
`;

export const QueryFilterGroup = ({
    toggleKey,
    filterGroupIndex = 0,
    filterGroup,
    handleFilterGroupToggleUpdate,
    handleFilterUpdate,
    addNewFilterToGroup,
    removeFilterFromGroup,
    removeFilterGroup
}: QueryFilterGroupProps) => {
    const toggle = getToggleKey(filterGroup) as QueryToggle;
    const filters = filterGroup[toggle];

    const handleToggleChange = (newToggle: QueryToggle) => {
        const newFilterGroup = mapKeys(filterGroup, (_, key) =>
            key === 'id' ? key : newToggle
        ) as FilterGroup;
        handleFilterGroupToggleUpdate(newFilterGroup, filterGroupIndex);
    };

    const handleOperatorChange = (newOperator: string, filterIndex: number) => {
        const newFilter: FilterValueObject = mapKeys(
            get(filters, `[${filterIndex}].valueNumber`),
            () => newOperator
        );
        handleFilterUpdate(newFilter, filterGroupIndex, filterIndex);
    };

    const handleValueChange = (value: string, filterIndex: number) => {
        const newFilter = { ...get(filters[filterIndex], 'valueNumber') };
        newFilter[getFirstKey(newFilter) as QueryOperator] = value;
        handleFilterUpdate(newFilter, filterGroupIndex, filterIndex);
    };

    const handleFilterRemove = (filterIndex: number) => {
        const newQueryFilters = filter(filters, (_, i) => filterIndex !== i);
        removeFilterFromGroup(filterGroupIndex, newQueryFilters);
    };

    return (
        <QueryFilterGroupWrapper>
            <Card
                headingLevel="h4"
                isSmall
                isSegmented
                isInner
                title="Condition group"
                titleSuffix={
                    <RemoveButton
                        onClick={() => removeFilterGroup(filterGroupIndex)}
                        data-test-id={`remove-condition-group-${toggleKey}`}
                        aria-label="remove-condition-group"
                    >
                        <Icon icon={faTrashCan} color="#444" />
                    </RemoveButton>
                }
            >
                <OneColumn gapSize="small">
                    <div style={{ display: 'flex', gap: '1rem' }}>
                        {filters.length > 1 && (
                            <Indent>
                                <SegmentedControl
                                    name={`toggle-${toggleKey}`}
                                    value={toggle}
                                    isSmall
                                    onChange={handleToggleChange}
                                    options={[
                                        { value: QueryToggle.AND, label: QueryToggle.AND },
                                        { value: QueryToggle.OR, label: QueryToggle.OR }
                                    ]}
                                />
                            </Indent>
                        )}
                        <div style={{ flex: '1' }}>
                            <OneColumn gapSize="small">
                                <AnimatePresence initial={false}>
                                    {map(filters, ({ id, valueNumber }, filterIndex) => {
                                        const operator = getFirstKey(valueNumber) as QueryOperator;
                                        return (
                                            <ACollapse key={id}>
                                                <QueryFilter
                                                    operatorOptions={operatorSelectOptions}
                                                    operatorValue={{
                                                        label: operatorLookup(operator),
                                                        value: operator
                                                    }}
                                                    operatorOnChange={(option) =>
                                                        handleOperatorChange(
                                                            option?.value as string,
                                                            filterIndex
                                                        )
                                                    }
                                                    inputValue={valueNumber[operator] as string}
                                                    inputOnChange={(newValue) =>
                                                        handleValueChange(newValue, filterIndex)
                                                    }
                                                    onRemoveClick={() =>
                                                        handleFilterRemove(filterIndex)
                                                    }
                                                />
                                            </ACollapse>
                                        );
                                    })}
                                </AnimatePresence>
                            </OneColumn>
                        </div>
                    </div>
                    <div>
                        <LinkButton onClick={() => addNewFilterToGroup(filterGroupIndex)}>
                            <Icon icon={faPlus} />
                            Add condition
                        </LinkButton>
                    </div>
                </OneColumn>
            </Card>
        </QueryFilterGroupWrapper>
    );
};
