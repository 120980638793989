"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ObjectAccessType = exports.StringFormatType = exports.NumberDisplayType = exports.ParameterStatus = void 0;
var ParameterStatus;
(function (ParameterStatus) {
    ParameterStatus["Pending"] = "pending";
    ParameterStatus["Received"] = "received";
})(ParameterStatus = exports.ParameterStatus || (exports.ParameterStatus = {}));
var NumberDisplayType;
(function (NumberDisplayType) {
    NumberDisplayType["Direct"] = "Direct";
    NumberDisplayType["Hex"] = "Hex";
    NumberDisplayType["BCD"] = "BCD";
    NumberDisplayType["DecimalBCD"] = "DecimalBCD";
})(NumberDisplayType = exports.NumberDisplayType || (exports.NumberDisplayType = {}));
var StringFormatType;
(function (StringFormatType) {
    StringFormatType["ASCII"] = "ASCII";
    StringFormatType["UTF8"] = "UTF-8";
    StringFormatType["UTF16"] = "UTF-16";
})(StringFormatType = exports.StringFormatType || (exports.StringFormatType = {}));
var ObjectAccessType;
(function (ObjectAccessType) {
    ObjectAccessType["Normal"] = "Normal";
    ObjectAccessType["Advanced"] = "Advanced";
})(ObjectAccessType = exports.ObjectAccessType || (exports.ObjectAccessType = {}));
