import { ErrorBoundary } from 'react-error-boundary';
import { useParams } from 'react-router-dom';

import { ErrorFallback } from '@/components/ErrorHandler';
import { ObjectValueInspectorChartView } from '@/views/ObjectValueInspector/ObjectValueInspectorChartView';
import { ObjectValueInspectorQueryView } from './ObjectValueInspectorQueryView';

type RouteParams = {
    activeSubTab: string;
};

export const ObjectValueInspectorView = () => {
    const { activeSubTab } = useParams<RouteParams>();

    return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            {!activeSubTab && <ObjectValueInspectorQueryView />}
            {activeSubTab === 'graph' && <ObjectValueInspectorChartView />}
        </ErrorBoundary>
    );
};
