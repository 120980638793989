import { ClusteringDefaultSelections, setSelectedClustering } from '@daisy/middleware-redux';
import { H3, P1, RadioButton } from '@daisy/react-components';
import { map } from 'lodash';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { RootState, useAppDispatch } from '@/store';
import { OneColumn } from '../styled/shared';

const Description = styled(P1)`
    color: ${({ theme }) => theme.palette.texts.lightgrey};
    margin-top: 0;
`;

export const ClusteringSelection = () => {
    const dispatch = useAppDispatch();

    const { mainObject, referenceObjects, selectedClustering } = useSelector(
        (state: RootState) => state.queryEditor
    );

    const handleClusteringChange = (event: React.FormEvent<HTMLInputElement>) => {
        dispatch(setSelectedClustering(event.currentTarget.value));
    };

    return (
        <OneColumn gapSize="small">
            <H3>Clustering</H3>
            <Description>Color items by unique values in the selected column.</Description>
            <div>
                <RadioButton
                    key="clustering_noSelection"
                    id="clustering_noSelection"
                    name="noSelection"
                    value={ClusteringDefaultSelections.NO_SELECTION}
                    checked={selectedClustering === ClusteringDefaultSelections.NO_SELECTION}
                    label="No clustering"
                    onChange={(event) => {
                        handleClusteringChange(event);
                    }}
                />
                {mainObject.object.name && mainObject.object.label && (
                    <RadioButton
                        key="clustering_main"
                        id="clustering_main"
                        aria-label={`Clustering ${mainObject.object.label}`}
                        name={mainObject.object.name}
                        value={mainObject.object.name}
                        checked={selectedClustering === mainObject.object.name}
                        label={mainObject.object.label}
                        onChange={(event) => {
                            handleClusteringChange(event);
                        }}
                    />
                )}
                {map(
                    referenceObjects,
                    ({ object }, index) =>
                        object.name &&
                        object.label && (
                            <RadioButton
                                key={`clustering_ref-${index}`}
                                id={`clustering_ref-${index}`}
                                name={`clustering-${object.name}`}
                                aria-label={`Clustering ${object.label}`}
                                value={object.name}
                                checked={selectedClustering === object.name}
                                label={object.label}
                                onChange={(event) => {
                                    handleClusteringChange(event);
                                }}
                            />
                        )
                )}
            </div>
        </OneColumn>
    );
};
