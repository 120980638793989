import { HierarchyTruckFragment } from '@daisy/data-access';
import { clearQueryState, setMainObjectName, setSelectedTruckModel } from '@daisy/middleware-redux';
import { LoadingIndicator, Modal, SearchInput } from '@daisy/react-components';
import {
    Dictionary,
    filter,
    find,
    flatMap,
    get,
    groupBy,
    includes,
    isEmpty,
    join,
    map,
    toLower,
    toString
} from 'lodash';
import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { OneColumn } from '@/components/styled/shared';
import { useHierarchyTrucks } from '@/hooks/useHierarchyTrucks';
import { useSpellbook } from '@/hooks/useSpellbook';
import { RootState } from '@/store';
import { getImagePath } from '@/utils/helpers';

const InputContainer = styled.div``;

const TrucksSelect = styled.ul`
    width: 100%;
    border: 1px solid ${({ theme }) => theme.palette.table.border};
    min-height: 200px;
    max-height: 400px;
    overflow-y: scroll;
    list-style-type: none;
    padding: 0;
`;

const TruckModelInfo = styled.div``;

const SoftwareModelName = styled.div`
    font-size: 1.5em;
    margin-right: 0.25em;
    line-height: 1.25em;
`;

const TdfFileName = styled.span`
    font-size: 0.583em;
    margin-left: 0.5em;
    color: ${({ theme }) => theme.palette.texts.lightgrey};
    vertical-align: bottom;
`;

const BrandModelList = styled.div`
    font-size: 0.875em;
    color: ${({ theme }) => theme.palette.texts.lightgrey};
    line-height: 1.286em;
`;

const NoResultsText = styled.p`
    font-size: 0.875em;
    color: ${({ theme }) => theme.palette.texts.lightgrey};
    padding: 0.5em 1em;
`;

const TruckOptionRow = styled.li<{ isActive: boolean }>`
    padding: 0.5em;
    cursor: pointer;

    :not(:last-child) {
        border-bottom: 2px solid ${({ theme }) => theme.palette.table.row.border};
    }

    ${({ isActive }) =>
        isActive
            ? css`
                  color: ${({ theme }) => theme.palette.texts.black};
                  background: ${({ theme }) => theme.palette.table.row.active};

                  > span,
                  > div > div {
                      color: ${({ theme }) => theme.palette.texts.black};
                  }
              `
            : css`
                  :nth-child(even) {
                      background: ${({ theme }) => theme.palette.table.row.even};
                  }

                  :hover {
                      background: ${({ theme }) => theme.palette.table.row.hover};
                  }
              `}
`;

const TruckImage = styled.div<{ imagePath: string | undefined }>`
    float: left;
    height: 3em;
    width: 3em;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    ${({ imagePath }) => imagePath && `background-image: url(${imagePath});`}
    margin-right: 0.5em;
`;

type SelectTruckModalProps = { handleModalClose: () => void };

export const SelectTruckModal: React.FC<SelectTruckModalProps> = ({ handleModalClose }) => {
    const dispatch = useDispatch();
    const { sendApplicationEvent } = useSpellbook();
    const { isLoading, formattedTruckNames } = useHierarchyTrucks();
    const navigate = useNavigate();
    const { selectedTruckModel } = useSelector((state: RootState) => state.truckModel);

    const [searchValue, setSearchValue] = useState<string>('');

    const handleActionButtonClick = () => {
        if (!selectedTruckModel) return;
        handleModalClose();
        dispatch(clearQueryState());
        dispatch(setMainObjectName({ label: null, name: null }));
        sendApplicationEvent('Selection', {
            eventName: 'Truck selection',
            eventMeta: {
                originalTdfFilename: selectedTruckModel?.originalTdfFilename,
                softwareModelName: selectedTruckModel?.softwareModelName,
                softwareModelVersion: selectedTruckModel?.softwareModelVersion,
                uuid: selectedTruckModel?.uuid
            }
        }).catch((e) => console.error(e));
        navigate(`/truck-model/${selectedTruckModel?.uuid}/summary`);
    };

    const renderTruckOptions = useMemo(() => {
        const groupedTruckNames = groupBy(formattedTruckNames, 'truck.originalTdfFilename');
        const groupedTrucks = prepareTruckGroups(groupedTruckNames, searchValue);

        if (isEmpty(groupedTrucks)) {
            return <NoResultsText>No results found.</NoResultsText>;
        }

        return map(groupedTrucks, (hierarchyTrucks) => (
            <TruckOptionRow
                data-testid="truck-option"
                key={`${hierarchyTrucks[0].hierarchy?.uuid}:${hierarchyTrucks[0].hierarchy?.name}:${hierarchyTrucks[0].truck.originalTdfFilename}`}
                onClick={() => dispatch(setSelectedTruckModel(hierarchyTrucks[0]?.truck))}
                isActive={
                    !!selectedTruckModel &&
                    hierarchyTrucks[0].truck.uuid === selectedTruckModel.uuid
                }
            >
                <TruckImage
                    imagePath={getImagePath(
                        getBrandHierarchy(hierarchyTrucks, 'Mitsubishi').hierarchy?.imagePath
                    )}
                />
                <TruckModelInfo>
                    <SoftwareModelName>
                        {hierarchyTrucks[0].truck?.softwareModelName}
                        <TdfFileName>({hierarchyTrucks[0].truck?.originalTdfFilename})</TdfFileName>
                    </SoftwareModelName>
                </TruckModelInfo>
                <BrandModelList>
                    {join(
                        map(
                            hierarchyTrucks,
                            (x) => `${x.hierarchy?.parent?.name} ${x.hierarchy?.name}`
                        ),
                        ', '
                    )}
                </BrandModelList>
            </TruckOptionRow>
        ));
    }, [searchValue, formattedTruckNames, selectedTruckModel, dispatch]);

    return (
        <Modal
            isOpen
            title="Select truck model"
            actionButtonText="Select"
            onClose={handleModalClose}
            onCancelButtonClick={handleModalClose}
            onActionButtonClick={handleActionButtonClick}
            dataTestId="select-truck-modal"
            buttonsAreDisabled={!selectedTruckModel}
        >
            <div data-testid="select-truck-modal-content">
                <OneColumn>
                    {isLoading ? (
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <LoadingIndicator />
                        </div>
                    ) : (
                        <>
                            <InputContainer>
                                <SearchInput
                                    value={searchValue}
                                    id="truck-search"
                                    name="truck-search"
                                    placeholder="Filter truck model, brand or brand name"
                                    onChange={(value: string) => setSearchValue(value)}
                                    onReset={() => setSearchValue('')}
                                    showIcon
                                    autoFocus
                                />
                            </InputContainer>
                            <TrucksSelect data-testid="truck-select">
                                {renderTruckOptions}
                            </TrucksSelect>
                        </>
                    )}
                </OneColumn>
            </div>
        </Modal>
    );
};

function getBrandHierarchy(hierarchyTrucks: HierarchyTruckFragment[], brand: string) {
    const mitsubishi = find(hierarchyTrucks, (h) => h.hierarchy?.parent?.name === brand);
    return mitsubishi || hierarchyTrucks[0];
}

function prepareTruckGroups(
    truckGroups: Dictionary<HierarchyTruckFragment[]>,
    searchValue: string
) {
    const filteredTruckGroups = flatMap(Object.keys(truckGroups), (key) =>
        filter(truckGroups[key], (hierarchyTruck) =>
            includes(
                toString([
                    toLower(hierarchyTruck.truck.softwareModelName),
                    toLower(hierarchyTruck.truck.originalTdfFilename),
                    toLower(hierarchyTruck.hierarchy?.name),
                    toLower(hierarchyTruck.hierarchy?.parent?.name),
                    `${toLower(hierarchyTruck.hierarchy?.parent?.name)} ${toLower(
                        hierarchyTruck.hierarchy?.name
                    )}`
                ]),
                toLower(searchValue)
            )
        )
    );
    return groupBy(filteredTruckGroups, (x) => get(x, 'truck.originalTdfFilename'));
}
