import { H3, Knob } from '@daisy/react-components';
import { useSelector } from 'react-redux';
import { setQueryState } from '@daisy/middleware-redux';

import { OneColumn } from '../styled/shared';
import { RootState, useAppDispatch } from '@/store';

export const OtherSelection = () => {
    const dispatch = useAppDispatch();

    const queryEditorState = useSelector((state: RootState) => state.queryEditor);
    const { showOnlyLatestTTP, showOnlyFactoryDefaultTTP, serialNumberFilterActive } = queryEditorState;

    const onLatestTTPChangeHandler = () =>
        dispatch(setQueryState({ ...queryEditorState,
            showOnlyLatestTTP: !showOnlyLatestTTP,
            serialNumberFilterActive: showOnlyLatestTTP === false ? false : serialNumberFilterActive}));

    const onDefaultTTPChangeHandler = () =>
        dispatch(setQueryState({ ...queryEditorState,
            showOnlyFactoryDefaultTTP: !showOnlyFactoryDefaultTTP }));

    return (
        <OneColumn gapSize="small">
            <H3>Other</H3>
            <Knob
                value={0}
                name="filterTTP"
                label="Show only latest TTP files"
                onChange={onLatestTTPChangeHandler}
                isChecked={showOnlyLatestTTP}
            />
            <Knob
                value={0}
                name="filterTTPsByDefaults"
                label="Show only factory default TTP files"
                onChange={onDefaultTTPChangeHandler}
                isChecked={showOnlyFactoryDefaultTTP}
            />
        </OneColumn>
    );
};
