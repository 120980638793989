import { LoadingIndicator } from '@daisy/react-components';
import styled from 'styled-components';

const LoadingIndicatorContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
`;

export const LoadingContainer = () => (
    <LoadingIndicatorContainer>
        <LoadingIndicator />
    </LoadingIndicatorContainer>
);
