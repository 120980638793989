import { TruckToolOperationFragment } from '@daisy/data-access';
import { createContext, Dispatch, ReactNode, useContext, useReducer } from 'react';

export type TruckToolOperationsState = {
    expandedRows?: number[];
    eventsData?: TruckToolOperationFragment[];
};

const initialState: TruckToolOperationsState = {
    expandedRows: [],
    eventsData: []
};

export const TruckToolOperationsStateContext =
    createContext<TruckToolOperationsState>(initialState);

export const TruckToolOperationsDispatchContext = createContext<
    Dispatch<TruckToolOperationsAction>
>(() => null);

export enum TruckToolOperationsActions {
    SET_EXPANDED = 'SET_EXPANDED',
    SET_EVENTS_DATA = 'SET_EVENTS_DATA'
}

interface TruckToolOperationsAction {
    type: TruckToolOperationsActions;
    payload: TruckToolOperationsState;
}

function TruckToolOperationsReducer(
    state: TruckToolOperationsState,
    action: TruckToolOperationsAction
) {
    const { type, payload } = action;

    switch (type) {
        case TruckToolOperationsActions.SET_EXPANDED:
            return { ...state, expandedRows: payload.expandedRows };
        case TruckToolOperationsActions.SET_EVENTS_DATA:
            return { ...state, eventsData: payload.eventsData };
        default:
            return state;
    }
}

export const TruckToolOperationsProvider: React.FC<{ children?: ReactNode }> = ({ children }) => {
    const [state, dispatch] = useReducer(TruckToolOperationsReducer, initialState);

    return (
        <TruckToolOperationsStateContext.Provider value={state}>
            <TruckToolOperationsDispatchContext.Provider value={dispatch}>
                {children}
            </TruckToolOperationsDispatchContext.Provider>
        </TruckToolOperationsStateContext.Provider>
    );
};

export const useTruckToolOperationsStateContext = (): TruckToolOperationsState =>
    useContext(TruckToolOperationsStateContext);

export const useTruckToolOperationsDispatchContext = () =>
    useContext(TruckToolOperationsDispatchContext);
