import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import styled, { useTheme } from 'styled-components';

const TitleArea = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
`;

const LegendArea = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
`;

export const ChartSkeleton = () => {
    const theme = useTheme();

    return (
        <SkeletonTheme
            baseColor={theme.palette.skeletons.chart.color}
            highlightColor={theme.palette.skeletons.chart.highlightColor}
        >
            <div style={{ display: 'flex', padding: '1em' }}>
                <div style={{ flex: 4, marginRight: '1em' }}>
                    <TitleArea>
                        <Skeleton width={400} height={20} style={{ marginBottom: 5 }} />
                        <Skeleton width={300} height={15} />
                    </TitleArea>

                    <Skeleton height={600} />
                </div>
                <div style={{ flex: 1, marginTop: '4em' }}>
                    <Skeleton height={200} />
                </div>
            </div>
        </SkeletonTheme>
    );
};
