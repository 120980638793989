"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClusteringDefaultSelections = exports.XAxisDefaultSelections = exports.QueryOperator = exports.QueryToggle = void 0;
var QueryToggle;
(function (QueryToggle) {
    QueryToggle["AND"] = "AND";
    QueryToggle["OR"] = "OR";
})(QueryToggle = exports.QueryToggle || (exports.QueryToggle = {}));
var QueryOperator;
(function (QueryOperator) {
    QueryOperator["EQ"] = "equals";
    QueryOperator["NE"] = "not";
    QueryOperator["GT"] = "gt";
    QueryOperator["LT"] = "lt";
    QueryOperator["GTE"] = "gte";
    QueryOperator["LTE"] = "lte";
    QueryOperator["IN"] = "in";
    QueryOperator["NI"] = "notin";
})(QueryOperator = exports.QueryOperator || (exports.QueryOperator = {}));
var XAxisDefaultSelections;
(function (XAxisDefaultSelections) {
    XAxisDefaultSelections["TIME"] = "time";
    XAxisDefaultSelections["DEPLOYMENT_YEAR"] = "deploymentYear";
})(XAxisDefaultSelections = exports.XAxisDefaultSelections || (exports.XAxisDefaultSelections = {}));
var ClusteringDefaultSelections;
(function (ClusteringDefaultSelections) {
    ClusteringDefaultSelections["NO_SELECTION"] = "noSelection";
})(ClusteringDefaultSelections = exports.ClusteringDefaultSelections || (exports.ClusteringDefaultSelections = {}));
