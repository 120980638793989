import { Card } from '@daisy/react-components';
import { ReactNode } from 'react';
import styled from 'styled-components';

import { PageTitle } from '@/components/PageTitle';
import { OneColumn, PageContent, ViewContainer, ViewContent } from '@/components/styled/shared';

const StyledLi = styled.li`
    line-height: 1.5em;
`;

const ReleaseNoteCard: React.FC<{ title: string; date: string; children?: ReactNode }> = ({
    title,
    date,
    children
}) => (
    <Card title={title} subtitle={date}>
        {children}
    </Card>
);

type ReleaseNote = {
    title: string;
    date: string;
    content: string[];
};

type ReleaseNotesViewProps = {
    releaseNotes: ReleaseNote[];
};

export const ReleaseNotesView: React.FC<ReleaseNotesViewProps> = ({ releaseNotes }) => (
    <ViewContainer>
        <PageContent>
            <ViewContent>
                <PageTitle title="Release Notes" />
                <OneColumn>
                    {releaseNotes.map(({ title, date, content }) => (
                        <ReleaseNoteCard key={title} title={title} date={date}>
                            <ul>
                                {content.map((item) => (
                                    <StyledLi key={item}>{item}</StyledLi>
                                ))}
                            </ul>
                        </ReleaseNoteCard>
                    ))}
                </OneColumn>
            </ViewContent>
        </PageContent>
    </ViewContainer>
);
