"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setPairedBluetoothDevices = exports.setScannedBluetoothDevices = exports.setSelectedDevice = exports.deviceSlice = exports.initialState = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
exports.initialState = {
    selectedDevice: null,
    bluetooth: {
        scannedDevices: [],
        pairedDevices: []
    },
    error: undefined
};
exports.deviceSlice = (0, toolkit_1.createSlice)({
    name: 'device',
    initialState: exports.initialState,
    reducers: {
        setSelectedDevice: (state, action) => {
            state.selectedDevice = action.payload;
        },
        setScannedBluetoothDevices: (state, action) => {
            state.bluetooth.scannedDevices = action.payload;
        },
        setPairedBluetoothDevices: (state, action) => {
            state.bluetooth.pairedDevices = action.payload;
        }
    }
});
_a = exports.deviceSlice.actions, exports.setSelectedDevice = _a.setSelectedDevice, exports.setScannedBluetoothDevices = _a.setScannedBluetoothDevices, exports.setPairedBluetoothDevices = _a.setPairedBluetoothDevices;
exports.default = exports.deviceSlice.reducer;
