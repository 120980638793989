import { useGetTrucksWithDevicesQuery, useTelematicsUsageTimesQuery } from '@daisy/data-access';
import { Multiselect, OnMultiselectChange, SelectOption } from '@daisy/react-components';
import { flatMap, map } from 'lodash';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { DummyProgressBar } from '@/components/DummyProgressBar';
import { Empty3DChartInfo } from '@/components/Empty3DChartInfo';
import { DatasetInfo } from '@/components/Infos/DatasetInfo';
import {
    OneColumn,
    ProgressBarContainer,
    Styled3DChart,
    StyledUsageTimesChart
} from '@/components/styled/shared';
import { useSerialNumber } from '@/hooks/useSerialNumber';
import { useTtpFiles } from '@/hooks/useTtpFiles';
import { RootState } from '@/store';
import { notEmpty } from '@/utils/helpers';
import { ChartLegend } from '@/views/Charts/ChartLegend';
import { getOptions as get2dOptions } from '@/views/Charts/truckUsage2DChartConfig';
import { getOptions as get3dOptions } from '@/views/Charts/truckUsage3DChartConfig';
import { TruckUsageDevicesTable } from '@/views/SerialNumber/Tabs/TruckUsageDevicesTable';

export const TruckUsageDevicesTabView = () => {
    const { serialNumber, relatedSerialNumbers } = useSerialNumber();
    const { extFileIds } = useTtpFiles(serialNumber);

    const { uiLoading } = useSelector((state: RootState) => state.ui);
    const [selectedPoint, setSelectedPoint] = useState<{ x: number; y: number }>();
    const [selectedChartDevices, setSelectedChartDevices] = useState<SelectOption<string>[]>();

    const serialNumberObject = relatedSerialNumbers?.[0];

    const { data: trucksWithDevices } = useGetTrucksWithDevicesQuery({
        variables: { uuids: [serialNumberObject?.truck?.uuid as string] },
        skip: !serialNumberObject?.truck?.uuid
    });

    const deviceOptions = useMemo(
        () =>
            flatMap(trucksWithDevices?.trucks, (truck) =>
                map(truck.devices, (device) => ({
                    label: `${device?.name} (${device?.externalId}, ${truck.softwareModelName})`,
                    value: device?.externalId || ''
                }))
            ),
        [trucksWithDevices?.trucks]
    );

    const selectedDeviceOptions = useMemo(
        () => deviceOptions.filter((option) => selectedChartDevices?.includes(option)) || null,
        [deviceOptions, selectedChartDevices]
    );

    const { data: telematicsUsageTimesData, loading: telematicsQueryLoading } =
        useTelematicsUsageTimesQuery({
            variables: {
                externalIds: selectedChartDevices?.map((option) => option.value) || [],
                uuid: serialNumberObject?.uuid as string,
                ttpExternalIds: extFileIds
            },
            fetchPolicy: 'no-cache',
            skip: !selectedChartDevices?.length || !serialNumberObject?.uuid
        });

    const { data, deviceIds, type } = useMemo(
        () => telematicsUsageTimesData?.telematicsUsageTimesQuery || {},
        [telematicsUsageTimesData?.telematicsUsageTimesQuery]
    );

    // TODO: Show relevant info instead of x and y when real data is available
    const handlePointClick = (x: number, y: number) => setSelectedPoint({ x, y });

    const handleDevicesChange: OnMultiselectChange<string> = (options) => {
        if (options) {
            setSelectedChartDevices([...options]);
        }
        setSelectedPoint(undefined);
    };

    const isLoading = uiLoading || telematicsQueryLoading;

    return (
        <>
            {isLoading && (
                <ProgressBarContainer>
                    <DummyProgressBar initialProgress={0} />
                </ProgressBarContainer>
            )}
            <OneColumn data-testid="truck-usage-devices-content">
                <div style={{ maxWidth: '41rem', width: '100%' }}>
                    <Multiselect<string>
                        name="devices"
                        placeholder="Select a device to inspect"
                        value={selectedDeviceOptions}
                        onChange={handleDevicesChange}
                        options={deviceOptions}
                    />
                </div>
                <div>
                    {selectedChartDevices && data ? (
                        <OneColumn>
                            {type === 'invalid' && <p>Cannot compare different types of data</p>}
                            {type === '3d' && (
                                <Styled3DChart
                                    title={selectedDeviceOptions.map(({ label }) => label) || ''}
                                    subtitle="Click and drag the chart area to rotate"
                                    options={get3dOptions(
                                        data,
                                        handlePointClick,
                                        extFileIds,
                                        deviceIds?.filter(notEmpty) || []
                                    )}
                                    shouldRotate
                                />
                            )}
                            {type === '2d' && (
                                <StyledUsageTimesChart
                                    title={selectedDeviceOptions.map(({ label }) => label) || ''}
                                    subtitle="Usage times"
                                    options={get2dOptions(
                                        data,
                                        handlePointClick,
                                        extFileIds,
                                        deviceIds?.filter(notEmpty) || []
                                    )}
                                    shouldRotate
                                />
                            )}
                            {type !== 'invalid' && (
                                <ChartLegend deviceIds={deviceIds?.filter(notEmpty)} />
                            )}
                        </OneColumn>
                    ) : (
                        <Empty3DChartInfo />
                    )}
                </div>

                {selectedPoint && (
                    <DatasetInfo
                        // title="Point info"
                        items={[
                            { label: 'x', value: selectedPoint.x },
                            { label: 'y', value: selectedPoint.y }
                        ]}
                    />
                )}
                <TruckUsageDevicesTable data={data} type={type} />
            </OneColumn>
        </>
    );
};
