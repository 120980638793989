var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled from 'styled-components';
var RadioButtonContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: flex;\n    align-items: center;\n    padding-bottom: 1rem;\n    cursor: pointer;\n\n    &:last-child {\n        padding-bottom: 0;\n    }\n"], ["\n    display: flex;\n    align-items: center;\n    padding-bottom: 1rem;\n    cursor: pointer;\n\n    &:last-child {\n        padding-bottom: 0;\n    }\n"])));
var Input = styled.input(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    appearance: none;\n    margin: 0 0.5em 0 0;\n    min-width: 1.25em;\n    width: 1.25em;\n    height: 1.25em;\n    border: 1px solid\n        ", ";\n    border-radius: 50%;\n    background-color: ", ";\n    padding: 3px;\n    position: relative;\n    font-size: 1em;\n\n    :checked:after {\n        content: '';\n        width: 10px;\n        height: 10px;\n        border-radius: 50%;\n        position: absolute;\n        top: 4px;\n        right: 4px;\n        background-color: ", ";\n    }\n"], ["\n    appearance: none;\n    margin: 0 0.5em 0 0;\n    min-width: 1.25em;\n    width: 1.25em;\n    height: 1.25em;\n    border: 1px solid\n        ", ";\n    border-radius: 50%;\n    background-color: ", ";\n    padding: 3px;\n    position: relative;\n    font-size: 1em;\n\n    :checked:after {\n        content: '';\n        width: 10px;\n        height: 10px;\n        border-radius: 50%;\n        position: absolute;\n        top: 4px;\n        right: 4px;\n        background-color: ", ";\n    }\n"])), function (_a) {
    var theme = _a.theme, disabled = _a.disabled;
    return (disabled ? 'transparent' : theme.palette.radioButton.border);
}, function (_a) {
    var theme = _a.theme, disabled = _a.disabled;
    return disabled
        ? theme.palette.radioButton.disabledBackground
        : theme.palette.radioButton.background;
}, function (_a) {
    var theme = _a.theme, disabled = _a.disabled;
    return disabled
        ? theme.palette.radioButton.disabledChecked
        : theme.palette.radioButton.checked;
});
export var Label = styled.label(function (_a) {
    var theme = _a.theme;
    return (__assign(__assign({}, theme.fonts.fonts.p1), { wordBreak: 'break-all', cursor: 'inherit' }));
});
export var RadioButton = function (_a) {
    var id = _a.id, name = _a.name, value = _a.value, checked = _a.checked, label = _a.label, onChange = _a.onChange, _b = _a.disabled, disabled = _b === void 0 ? false : _b, rest = __rest(_a, ["id", "name", "value", "checked", "label", "onChange", "disabled"]);
    return (React.createElement(RadioButtonContainer, null,
        React.createElement(Input, __assign({ type: "radio", id: id, name: name, value: value, checked: checked, disabled: disabled, onChange: onChange }, rest)),
        React.createElement(Label, { htmlFor: id }, label)));
};
var templateObject_1, templateObject_2;
