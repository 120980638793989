import { P1, ToastContent } from '@daisy/react-components';
import React, { useEffect } from 'react';
import { FallbackProps } from 'react-error-boundary';
import { useToasts } from 'react-toast-notifications';
import styled from 'styled-components';

type ErrorHandlerProps = {
    hasErrored: boolean;
    errorMessage?: string;
    toastText?: string;
};

const ErrorText = styled(P1)`
    color: ${({ theme }) => theme.palette.error};
`;

export const ErrorFallback: React.FC<FallbackProps> = ({ error }) => (
    <ErrorText>Error: {error.message}</ErrorText>
);

export const ErrorHandler = ({
    hasErrored,
    errorMessage = 'Something went wrong',
    toastText = 'Something went wrong'
}: ErrorHandlerProps) => {
    const { addToast } = useToasts();

    useEffect(() => {
        if (hasErrored) {
            addToast(<ToastContent type="error" description={toastText} />);
        }
    }, [addToast, hasErrored, toastText]);

    return <>{hasErrored && <ErrorText>Error: {errorMessage}</ErrorText>}</>;
};
