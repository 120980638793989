import { useGetTruckModelQuery } from '@daisy/data-access';
import { useParams } from 'react-router-dom';

import { getImagePath } from '@/utils/helpers';

export const useTruckModel = () => {
    const { truckModelId } = useParams<{ truckModelId: string }>();
    const { data, loading, error } = useGetTruckModelQuery({
        variables: { uuid: truckModelId || '' },
        skip: !truckModelId
    });

    return {
        truckModel: data?.truck,
        image: getImagePath(data?.truck?.hierarchyTrucks?.[0]?.hierarchy?.imagePath),
        isLoading: loading,
        isError: error
    };
};
