var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import ReactSlider from 'react-slider';
import styled from 'styled-components';
var StyledSlider = styled(ReactSlider)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    width: 100%;\n    height: 2px;\n\n    .custom-mark {\n        width: 2px;\n        height: 2px;\n        background: ", ";\n        border-radius: 50%;\n    }\n\n    &.disabled {\n        .track {\n            background-color: ", ";\n        }\n\n        .thumb {\n            background-color: ", ";\n        }\n    }\n"], ["\n    width: 100%;\n    height: 2px;\n\n    .custom-mark {\n        width: 2px;\n        height: 2px;\n        background: ", ";\n        border-radius: 50%;\n    }\n\n    &.disabled {\n        .track {\n            background-color: ", ";\n        }\n\n        .thumb {\n            background-color: ", ";\n        }\n    }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.palette.white;
}, function (_a) {
    var theme = _a.theme;
    return theme.palette.slider.disabledLight;
}, function (_a) {
    var theme = _a.theme;
    return theme.palette.slider.disabledDark;
});
var StyledThumb = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    height: 16px;\n    line-height: 16px;\n    width: 16px;\n    top: -7px;\n    text-align: center;\n    background-color: ", ";\n    border-radius: 50%;\n    outline: none;\n    cursor: grab;\n\n    &:active {\n        cursor: grabbing;\n        outline: ", ";\n    }\n\n    &:disabled {\n        background-color: ", ";\n    }\n"], ["\n    height: 16px;\n    line-height: 16px;\n    width: 16px;\n    top: -7px;\n    text-align: center;\n    background-color: ", ";\n    border-radius: 50%;\n    outline: none;\n    cursor: grab;\n\n    &:active {\n        cursor: grabbing;\n        outline: ", ";\n    }\n\n    &:disabled {\n        background-color: ", ";\n    }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.palette.slider.light;
}, function (_a) {
    var theme = _a.theme;
    return "5px solid ".concat(theme.palette.slider.outline);
}, function (_a) {
    var theme = _a.theme;
    return theme.palette.slider.disabledLight;
});
var Thumb = function (props) { return React.createElement(StyledThumb, __assign({}, props)); };
var StyledTrack = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    top: 0;\n    bottom: 0;\n    background: ", ";\n    border-radius: 3px;\n\n    &.disabled {\n        background: ", ";\n    }\n"], ["\n    top: 0;\n    bottom: 0;\n    background: ", ";\n    border-radius: 3px;\n\n    &.disabled {\n        background: ", ";\n    }\n"])), function (_a) {
    var theme = _a.theme, index = _a.index;
    return index === 1 ? theme.palette.slider.light : theme.palette.slider.dark;
}, function (_a) {
    var theme = _a.theme, index = _a.index;
    return index === 1 ? theme.palette.slider.disabledLight : theme.palette.slider.disabledDark;
});
var Track = function (props, _a) {
    var index = _a.index;
    return (React.createElement(StyledTrack, __assign({}, props, { index: index })));
};
var StyledLabel = styled.label(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    display: block;\n    font-size: 12px;\n    color: #666;\n    margin-bottom: 1em;\n    text-transform: uppercase;\n    font-weight: 300;\n"], ["\n    display: block;\n    font-size: 12px;\n    color: #666;\n    margin-bottom: 1em;\n    text-transform: uppercase;\n    font-weight: 300;\n"])));
export var Slider = function (_a) {
    var min = _a.min, max = _a.max, label = _a.label, value = _a.value, handleChange = _a.handleChange, handleAfterChange = _a.handleAfterChange, _b = _a.disabled, disabled = _b === void 0 ? false : _b, marks = _a.marks;
    return (React.createElement("div", null,
        React.createElement(StyledLabel, null, label),
        React.createElement(StyledSlider, { min: min, max: max, renderTrack: Track, renderThumb: Thumb, value: value, onChange: handleChange, onAfterChange: handleAfterChange, disabled: disabled, marks: marks, markClassName: "custom-mark" })));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
