var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { StyledLabel } from '../Label/Label';
var InputContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: flex;\n    flex-direction: column;\n\n    letter-spacing: 1px;\n    text-align: left;\n    transition: 0.1s all;\n"], ["\n    display: flex;\n    flex-direction: column;\n\n    letter-spacing: 1px;\n    text-align: left;\n    transition: 0.1s all;\n"])));
export var InputField = styled.input(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    -webkit-appearance: none;\n    appearance: none;\n    background-color: ", ";\n    color: ", ";\n    border: 1px solid ", ";\n    border-radius: 8px;\n    box-shadow: ", ";\n    box-sizing: border-box;\n    font-family: 'Lato', sans-serif;\n    font-size: 16px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 20px;\n    height: 42px;\n    min-width: 17em;\n    padding: 0.5rem 0.75rem;\n    outline: 2px solid transparent;\n    transition: outline 0.1s ease-in;\n\n    ::placeholder {\n        color: #808080;\n    }\n\n    :focus {\n        outline: 2px solid ", ";\n    }\n\n    &.invalid {\n        color: #e07b7b;\n        border: 1px solid #bf5757;\n        border-bottom: 2px solid #bf5757;\n        padding-bottom: calc(0.25rem - 2px);\n    }\n"], ["\n    -webkit-appearance: none;\n    appearance: none;\n    background-color: ", ";\n    color: ", ";\n    border: 1px solid ", ";\n    border-radius: 8px;\n    box-shadow: ", ";\n    box-sizing: border-box;\n    font-family: 'Lato', sans-serif;\n    font-size: 16px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 20px;\n    height: 42px;\n    min-width: 17em;\n    padding: 0.5rem 0.75rem;\n    outline: 2px solid transparent;\n    transition: outline 0.1s ease-in;\n\n    ::placeholder {\n        color: #808080;\n    }\n\n    :focus {\n        outline: 2px solid ", ";\n    }\n\n    &.invalid {\n        color: #e07b7b;\n        border: 1px solid #bf5757;\n        border-bottom: 2px solid #bf5757;\n        padding-bottom: calc(0.25rem - 2px);\n    }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.palette.input.background;
}, function (_a) {
    var theme = _a.theme;
    return theme.palette.input.text;
}, function (_a) {
    var theme = _a.theme;
    return theme.palette.input.border;
}, function (_a) {
    var theme = _a.theme;
    return theme.palette.input.shadow;
}, function (_a) {
    var theme = _a.theme;
    return theme.palette.primary;
});
export var Input = function (_a) {
    var style = _a.style, className = _a.className, id = _a.id, label = _a.label, name = _a.name, value = _a.value, placeholder = _a.placeholder, _b = _a.isInvalid, isInvalid = _b === void 0 ? false : _b, inputRef = _a.inputRef, onChange = _a.onChange;
    return (React.createElement(InputContainer, { style: style, className: className },
        label && (React.createElement(StyledLabel, { as: "label", htmlFor: id }, label)),
        React.createElement(InputField, { id: id, name: name, value: value, placeholder: placeholder, type: "text", isInvalid: isInvalid, onChange: function (event) { return onChange(event.target.value); }, className: isInvalid ? 'invalid' : '', ref: inputRef })));
};
var templateObject_1, templateObject_2;
