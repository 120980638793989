import { ErrorEventFragment } from '@daisy/data-access';
import { H3, P1 } from '@daisy/react-components';
import { useEffect, useState } from 'react';

import { DatasetInfo } from '@/components/Infos/DatasetInfo';
import { StyledMediumChart, TwoColumn } from '@/components/styled/shared';

export const TruckToolOperationsEventChart: React.FC<{
    errorEvents: ErrorEventFragment[];
}> = ({ errorEvents }) => {
    const [selectedErrorCode, setSelectedErrorCode] = useState<ErrorEventFragment | null>(null);

    const [eventChartOptions, setEventChartOptions] = useState<Highcharts.Options>({
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                // borderColor: undefined,
                innerSize: '72.5%',
                dataLabels: {
                    enabled: true,
                    format: '<strong>{point.errorCode}</strong>: {point.y} ({point.percentage:.1f} %)',
                    style: {
                        fontWeight: 'normal',
                        fontSize: '12px'
                    }
                },
                point: {
                    events: {
                        click(e) {
                            handlePointClick(e.point);
                        }
                    }
                },
                states: {
                    inactive: {
                        opacity: 1
                    }
                }
            },
            series: {
                animation: false
            }
        },
        yAxis: {
            title: undefined,
            tickInterval: 1
        },
        xAxis: {
            type: 'datetime',
            tickInterval: 3600 * 1000,
            labels: {
                format: '{value:%H:%M}'
            },
            endOnTick: false
        },
        legend: {
            enabled: false
        },
        rangeSelector: {
            selected: 1,
            enabled: false
        },
        navigator: {
            enabled: false
        },
        scrollbar: {
            enabled: false
        },

        series: []
    });

    const handlePointClick = ({ device, errorClass, errorCode, errorName, description }: any) => {
        setSelectedErrorCode({ device, errorClass, errorCode, errorName, description });
    };

    useEffect(() => {
        setEventChartOptions({
            series: [
                {
                    type: 'pie',
                    name: 'Event occurences',
                    data: errorEvents?.map((event) => [
                        event.occurrences,
                        event.errorCode,
                        event.device,
                        event.errorClass,
                        event.errorName,
                        event.description
                    ]),
                    keys: ['y', 'errorCode', 'device', 'errorClass', 'errorName', 'description']
                }
            ]
        });
    }, [errorEvents]);

    return (
        <>
            <H3>Error events by occurrence</H3>
            <TwoColumn leftWidth={7} gapSize="large" withBorder>
                <StyledMediumChart options={eventChartOptions} />
                <div>
                    {!selectedErrorCode && <P1>Click on a slice to see info.</P1>}
                    {selectedErrorCode && (
                        <DatasetInfo
                            items={[
                                {
                                    label: 'Error name',
                                    value: selectedErrorCode.errorName
                                },
                                {
                                    label: 'Error code',
                                    value: selectedErrorCode.errorCode
                                },
                                {
                                    label: 'Error class',
                                    value: selectedErrorCode.errorClass
                                },
                                {
                                    label: 'Device',
                                    value: selectedErrorCode.device
                                },
                                {
                                    label: 'Description',
                                    value: selectedErrorCode.description
                                }
                            ]}
                        />
                    )}
                </div>
            </TwoColumn>
        </>
    );
};
