import { FileInput, FileInputError, Modal, ToastContent } from '@daisy/react-components';

import { useToasts } from 'react-toast-notifications';

type UploadTTPModalProps = { handleModalClose: () => void };

export const UploadTTPModal: React.FC<UploadTTPModalProps> = ({ handleModalClose }) => {
    const { addToast } = useToasts();

    return (
        <Modal isOpen title="Upload TTP" onClose={handleModalClose}>
            <FileInput
                label="Browse"
                onFileSelectError={({ error }: { error: FileInputError }) =>
                    addToast(<ToastContent type="error" description={error} />)
                }
                uploadOnClick={() => addToast(<ToastContent type="success" description="jee" />)}
            />
        </Modal>
    );
};
