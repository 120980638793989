var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable react/jsx-props-no-spreading */
import { faSort, faSortDown, faSortUp } from '@fortawesome/pro-solid-svg-icons';
import { AnimatePresence } from 'framer-motion';
import { includes } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { ACollapse } from '../Animations';
import { Icon } from '../Icon/Icon';
import { useTableContext } from './Table';
export function TableMain(_a) {
    var rowSubComponent = _a.rowSubComponent, isLoading = _a.isLoading, isSortable = _a.isSortable, highlightedCells = _a.highlightedCells, highlightedCellName = _a.highlightedCellName, highlightedCell = _a.highlightedCell;
    var table = useTableContext().table;
    if (!table)
        return React.createElement(React.Fragment, null);
    var getTableProps = table.getTableProps, getTableBodyProps = table.getTableBodyProps, headerGroups = table.headerGroups, prepareRow = table.prepareRow, page = table.page, visibleColumns = table.visibleColumns;
    var renderRowSubComponent = function (row) {
        return !!rowSubComponent && rowSubComponent(row);
    };
    return (React.createElement(TableWrapper, null,
        React.createElement(StyledTable, __assign({}, getTableProps(), { isLoading: isLoading, "data-testid": "table" }),
            React.createElement(THead, null, headerGroups.map(function (headerGroup) { return (React.createElement(StyledRow, __assign({}, headerGroup.getHeaderGroupProps()), headerGroup.headers.map(function (column) {
                return isSortable ? (React.createElement(TH, __assign({}, column.getHeaderProps(column.getSortByToggleProps()), { style: { width: column.width, cursor: 'pointer' } }),
                    column.render('Header'),
                    column.canSort && (React.createElement(SortIcon, { icon: resolveSortIcon(column.isSorted, column.isSortedDesc), iconcolor: "#696969" })))) : (React.createElement(TH, __assign({}, column.getHeaderProps(), { style: { width: column.width } }), column.render('Header')));
            }))); })),
            React.createElement(TBody, __assign({}, getTableBodyProps()), page === null || page === void 0 ? void 0 : page.map(function (row, index) {
                prepareRow(row);
                if (highlightedCells &&
                    highlightedCellName &&
                    includes(highlightedCells, row.values[highlightedCellName])) {
                    return (React.createElement(HighlightedRow, __assign({}, row.getRowProps()), row.cells.map(function (cell) { return (React.createElement("td", __assign({}, cell.getCellProps()), cell.render('Cell'))); })));
                }
                return (React.createElement(React.Fragment, { key: row.getRowProps().key },
                    React.createElement(StyledRow, __assign({}, row.getRowProps(), { id: row.getRowProps().key.toString(), className: index % 2 === 0 ? 'even' : 'odd', isExpanded: row.isExpanded }), row.cells.map(function (cell) {
                        if (cell.column.id === highlightedCell) {
                            return (React.createElement(HighlightedCell, __assign({}, cell.getCellProps(), { "data-testid": "table-cell" }), cell.render('Cell')));
                        }
                        return (React.createElement("td", __assign({}, cell.getCellProps(), { "data-testid": "table-cell" }), cell.render('Cell')));
                    })),
                    React.createElement(AnimatePresence, null, row.isExpanded && (React.createElement(SubRow, __assign({}, row.getRowProps(), { isExpanded: true, className: index % 2 === 0 ? 'even' : 'odd' }),
                        React.createElement("td", { colSpan: visibleColumns.length },
                            React.createElement(ACollapse, null,
                                React.createElement("div", null, rowSubComponent &&
                                    renderRowSubComponent(row)))))))));
            })))));
}
function resolveSortIcon(isSorted, isSortedDesc) {
    var icon = faSort;
    if (isSorted) {
        icon = faSortUp;
        if (isSortedDesc) {
            icon = faSortDown;
        }
    }
    return icon;
}
var TableWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    border: 1px solid ", ";\n    border-radius: 6px;\n    overflow: hidden;\n"], ["\n    border: 1px solid ", ";\n    border-radius: 6px;\n    overflow: hidden;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.palette.table.row.border;
});
var StyledTable = styled.table(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    border-spacing: 0;\n    border-collapse: collapse;\n    width: 100%;\n    transition: opacity 0.15s ease;\n\n    th,\n    td {\n        margin: 0;\n        padding: 1rem 1.5rem;\n        overflow-wrap: break-word;\n        white-space: pre-line;\n\n        :last-child {\n            border-right: 0;\n        }\n    }\n    ", "\n"], ["\n    border-spacing: 0;\n    border-collapse: collapse;\n    width: 100%;\n    transition: opacity 0.15s ease;\n\n    th,\n    td {\n        margin: 0;\n        padding: 1rem 1.5rem;\n        overflow-wrap: break-word;\n        white-space: pre-line;\n\n        :last-child {\n            border-right: 0;\n        }\n    }\n    ", "\n"])), function (_a) {
    var isLoading = _a.isLoading;
    return isLoading && 'opacity: 0.5;';
});
var THead = styled.thead(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    background: white;\n    position: relative;\n    border-bottom: 2px solid ", ";\n    text-align: left;\n    font-size: 12px;\n    line-height: 17px;\n    color: #696969;\n"], ["\n    background: white;\n    position: relative;\n    border-bottom: 2px solid ", ";\n    text-align: left;\n    font-size: 12px;\n    line-height: 17px;\n    color: #696969;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.palette.table.row.border;
});
var TH = styled.th(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    font-weight: 600;\n    text-transform: uppercase;\n    position: sticky;\n    top: -32px;\n    ::selection {\n        color: none;\n        background: none;\n    }\n"], ["\n    font-weight: 600;\n    text-transform: uppercase;\n    position: sticky;\n    top: -32px;\n    ::selection {\n        color: none;\n        background: none;\n    }\n"])));
var TBody = styled.tbody(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    font-family: ", ";\n    font-size: 14px;\n    line-height: 20px;\n    color: ", ";\n"], ["\n    font-family: ", ";\n    font-size: 14px;\n    line-height: 20px;\n    color: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.fonts.families.content;
}, function (_a) {
    var theme = _a.theme;
    return theme.palette.texts.black;
});
var StyledRow = styled.tr(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    border-top: 1px solid ", ";\n    vertical-align: middle;\n    background: ", ";\n    transition: background-color, box-shadow 0.1s ease;\n\n    &:first-child {\n        border: none;\n    }\n\n    &:first-child:last-child {\n        background: none;\n    }\n\n    &.odd {\n        background-color: #f9fbfd;\n\n        // background: ", ";\n    }\n\n    ", "\n"], ["\n    border-top: 1px solid ", ";\n    vertical-align: middle;\n    background: ", ";\n    transition: background-color, box-shadow 0.1s ease;\n\n    &:first-child {\n        border: none;\n    }\n\n    &:first-child:last-child {\n        background: none;\n    }\n\n    &.odd {\n        background-color: #f9fbfd;\n\n        // background: ", ";\n    }\n\n    ", "\n"])), function (_a) {
    var theme = _a.theme;
    return theme.palette.table.row.border;
}, function (_a) {
    var theme = _a.theme;
    return theme.palette.table.row.default;
}, function (_a) {
    var theme = _a.theme;
    return theme.palette.table.row.even;
}, function (_a) {
    var isExpanded = _a.isExpanded;
    return isExpanded &&
        "\n        box-shadow: 0px 6px 14px -8px inset rgba(0,0,0,0.1);\n    ";
});
var SubRow = styled(StyledRow)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    box-shadow: 0px -6px 14px -8px inset rgba(0, 0, 0, 0.1);\n\n    td {\n        padding: 1.5rem;\n    }\n"], ["\n    box-shadow: 0px -6px 14px -8px inset rgba(0, 0, 0, 0.1);\n\n    td {\n        padding: 1.5rem;\n    }\n"])));
var HighlightedRow = styled.tr(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n    border-top: 1px solid ", ";\n    vertical-align: middle;\n    background: ", ";\n"], ["\n    border-top: 1px solid ", ";\n    vertical-align: middle;\n    background: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.palette.table.row.border;
}, function (_a) {
    var theme = _a.theme;
    return theme.palette.table.row.active;
});
var HighlightedCell = styled.td(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n    background: ", ";\n"], ["\n    background: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.palette.table.row.active;
});
var SortIcon = styled(Icon)(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n    margin-left: 8px;\n    color: ", ";\n"], ["\n    margin-left: 8px;\n    color: ", ";\n"])), function (_a) {
    var iconcolor = _a.iconcolor, theme = _a.theme;
    return iconcolor !== null && iconcolor !== void 0 ? iconcolor : theme.palette.primary;
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;
