import { setEventTableSearchFilter } from '@daisy/middleware-redux';
import { SearchInput } from '@daisy/react-components';
import { debounce } from 'lodash';
import { useCallback, useRef } from 'react';

import { OneColumn } from '@/components/styled/shared';
import { useAppDispatch } from '@/store';
import { TruckUsageEvents } from '@/views/SerialNumber/Tabs/TruckUsageEvents';
import { TruckUsageOnlineEventTimeline } from './TruckUsageOnlineEventTimeline';

export const TruckUsageEventsTabView = () => {
    const eventFilterRef = useRef<HTMLInputElement>(null);
    const dispatch = useAppDispatch();

    const eventFilterOnChangeHandler = useCallback(
        (value: string) => {
            dispatch(setEventTableSearchFilter(value));
        },
        [dispatch]
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const eventFilterDebouncedChangeHandler = useCallback(
        debounce(eventFilterOnChangeHandler, 300),
        [eventFilterOnChangeHandler]
    );

    const eventFilterResetHandler = () => {
        if (eventFilterRef.current) {
            eventFilterRef.current.value = '';
        }
        dispatch(setEventTableSearchFilter(''));
    };

    return (
        <OneColumn data-testid="truck-usage-events-content">
            <SearchInput
                style={{ maxWidth: '20rem' }}
                name="eventFilter"
                placeholder="Filter rows"
                inputRef={eventFilterRef}
                onChange={eventFilterDebouncedChangeHandler}
                onReset={eventFilterResetHandler}
            />
            <TruckUsageEvents title="Error History Events" />
            <TruckUsageEvents eventType="ActiveEvent" title="Active Events" />
            <TruckUsageOnlineEventTimeline title="Online Event Timeline" />
        </OneColumn>
    );
};
