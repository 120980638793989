import { FeatureFlagFragment } from '@daisy/data-access';
import React, { createContext } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../store';

const initialContext: FeatureFlagFragment = {
    flags: {}
};

export const FeatureFlagContext = createContext<FeatureFlagFragment>(initialContext);

export const FeatureFlagProvider = ({ children }: { children: React.ReactNode }) => {
    const { flags } = useSelector((state: RootState) => state.featureFlags);
    return <FeatureFlagContext.Provider value={flags}>{children}</FeatureFlagContext.Provider>;
};
