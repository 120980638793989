import { SerialNumberFragment, SerialNumberShortFragment } from '@daisy/data-access';
import { GroupedSelectOption, SelectOption } from '@daisy/react-components';
import { filter, includes, reduce, sortBy, toLower } from 'lodash';

export const generateSelectOption = (value: string) => ({
    label: value,
    value
});

export const generateSerialNumberSelectOptions = (
    serialNumbers?: SerialNumberShortFragment[]
): SelectOption[] =>
    serialNumbers?.map(({ externalId, truck }) => ({
        label: `${externalId} (${truck?.softwareModelName})`,
        value: externalId
    })) || [];

export const generateTruckModelUUIDs = (
    truckUUID?: string,
    serialNumbers?: SerialNumberFragment[] | null
) => {
    let truckModelUUIDs: string[] = [];

    if (serialNumbers?.length) {
        truckModelUUIDs = serialNumbers.map((sn) => sn.truck?.uuid || '');
    } else if (truckUUID) {
        truckModelUUIDs = [truckUUID];
    }

    return truckModelUUIDs;
};

// Used in async selects to filter values based on input
export const handleLoadOptions = (values: any[]) => (inputValue: string, callback: any) => {
    callback(
        sortBy(
            filter(values, ({ label }) => includes(toLower(label), toLower(inputValue))),
            'label'
        )
    );
};

interface Option {
    value: string;
    label: string;
}

interface GroupedOptions {
    label: string;
    value: string;
    options: Option[];
}

// Used in async grouped selects to filter values based on input
export const handleGroupedLoadOptions =
    (values: GroupedOptions[]) => (inputValue: string, callback: any) => {
        const newGroups = reduce(
            values,
            (acc, { label, options }) => {
                const newOps: SelectOption[] = sortBy(
                    filter(options, (option) =>
                        includes(toLower(option.label), toLower(inputValue))
                    ),
                    'label'
                );
                if (newOps.length > 0) {
                    acc.push({ label, options: newOps });
                }
                return acc;
            },
            [] as GroupedSelectOption[]
        );

        callback(newGroups);
    };
