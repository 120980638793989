import { LoadingIndicator, Tabs } from '@daisy/react-components';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

import { useTruckModel } from '@/hooks/useTruckModel';
import { ObjectValueInspectorView } from '@/views/ObjectValueInspector/ObjectValueInspectorView';
import { TruckModelErrorHistoryEventsTabView } from '@/views/TruckModel/TruckModelErrorHistoryEventsTabView';
import { TruckModelSerialNumbersTabView } from '@/views/TruckModel/TruckModelSerialNumbersTabView';
import { TruckModelSummaryTabView } from './TruckModelSummaryTabView';

type RouteParams = {
    truckModelId: string;
    activeTab: TruckModelTabs;
};

type TruckModelTabs =
    | 'summary'
    | 'error-history-events'
    | 'object-value-inspector'
    | 'serial-numbers';

export const TruckModelViewTabs = () => {
    const { truckModel, isLoading } = useTruckModel();
    const { activeTab } = useParams<RouteParams>();
    const navigate = useNavigate();

    if (isLoading) return <LoadingIndicator />;

    if (!truckModel) return null;

    const tabList: Array<{ name: TruckModelTabs; label: string; onClick: () => void }> = [
        {
            name: 'summary',
            label: 'Summary',
            onClick: () =>
                navigate({
                    pathname: generatePath('/truck-model/:truckModelId/:activeTab', {
                        truckModelId: truckModel.uuid,
                        activeTab: 'summary'
                    })
                })
        },
        {
            name: 'error-history-events',
            label: 'Error History Events',
            onClick: () =>
                navigate({
                    pathname: generatePath('/truck-model/:truckModelId/:activeTab', {
                        truckModelId: truckModel.uuid,
                        activeTab: 'error-history-events'
                    })
                })
        },
        {
            name: 'object-value-inspector',
            label: 'Object Value Inspector',
            onClick: () =>
                navigate({
                    pathname: generatePath('/truck-model/:truckModelId/:activeTab', {
                        truckModelId: truckModel.uuid,
                        activeTab: 'object-value-inspector'
                    })
                })
        },
        {
            name: 'serial-numbers',
            label: 'Serial Numbers',
            onClick: () =>
                navigate({
                    pathname: generatePath('/truck-model/:truckModelId/:activeTab', {
                        truckModelId: truckModel.uuid,
                        activeTab: 'serial-numbers'
                    })
                })
        }
    ];

    return (
        <Tabs initialValue={activeTab}>
            <Tabs.Header>
                {tabList.map((tab) => (
                    <Tabs.Button
                        key={tab.name}
                        name={tab.name}
                        text={tab.label}
                        onClick={tab.onClick}
                    />
                ))}
            </Tabs.Header>
            <Tabs.Content>
                {tabList.map((tab) => (
                    <Tabs.Panel key={tab.name} name={tab.name}>
                        {tab.name === 'summary' && <TruckModelSummaryTabView />}
                        {tab.name === 'error-history-events' && (
                            <TruckModelErrorHistoryEventsTabView />
                        )}
                        {tab.name === 'object-value-inspector' && <ObjectValueInspectorView />}
                        {tab.name === 'serial-numbers' && <TruckModelSerialNumbersTabView />}
                    </Tabs.Panel>
                ))}
            </Tabs.Content>
        </Tabs>
    );
};
