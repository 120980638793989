export const createTTPFilename = (date: string, serialNumber: string) => {
    const dateObject = new Date(date);

    const year = String(dateObject.getFullYear());
    let month = String(dateObject.getMonth() + 1);
    let day = String(dateObject.getDate());
    let hours = String(dateObject.getHours());
    let minutes = String(dateObject.getMinutes());
    let seconds = String(dateObject.getSeconds());

    if (month.length < 2) month = `0${month}`;
    if (day.length < 2) day = `0${day}`;
    if (hours.length < 2) hours = `0${hours}`;
    if (minutes.length < 2) minutes = `0${minutes}`;
    if (seconds.length < 2) seconds = `0${seconds}`;

    return `${[year, month, day].join('')}_${[hours, minutes, seconds].join(
        ''
    )}_${serialNumber}.ttp`;
};

export const downloadFile = (file: any, filename: string) =>
    new Promise((resolve, reject) => {
        const { body } = document;
        if (body) {
            const a = document.createElement('a');
            body.appendChild(a);
            a.style.display = 'none';
            a.href = URL.createObjectURL(file);
            a.download = filename;
            a.click();
            a.remove();
            resolve('Download successful');
        } else {
            reject(new Error('Download failed'));
        }
    });
