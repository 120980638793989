import {
    ApolloClient,
    ApolloProvider as ApolloClientProvider,
    InMemoryCache,
    createHttpLink,
    from
} from '@apollo/client';
import { ReactNode } from 'react';

const { REACT_APP_GRAPHQL_API_URL } = process.env;

export const httpLink = createHttpLink({
    uri: REACT_APP_GRAPHQL_API_URL
});

export const ApolloProvider: React.FC<{ children?: ReactNode }> = ({ children }) => {
    const client = new ApolloClient({
        link: from([httpLink]),
        cache: new InMemoryCache({
            typePolicies: {
                Truck: {
                    keyFields: ['uuid']
                },
                SerialNumber: {
                    keyFields: ['uuid']
                }
            }
        })
    });

    return <ApolloClientProvider client={client}>{children}</ApolloClientProvider>;
};
