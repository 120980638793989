import { useFeatureFlagsQuery } from '@daisy/data-access';
import { setUILoading } from '@daisy/middleware-redux';
import { useEffect } from 'react';

import { useAppDispatch } from '@/store';

export const useFeatureFlags = () => {
    const dispatch = useAppDispatch();
    const { data, loading } = useFeatureFlagsQuery();

    useEffect(() => {
        if (loading) {
            dispatch(setUILoading(true));
        } else {
            dispatch(setUILoading(false));
        }
    }, [loading, dispatch]);

    return data?.featureFlags?.flags;
};
