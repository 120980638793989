import { useGetTruckSummaryQuery } from '@daisy/data-access';
import { Card, H3, Icon, Label, LoadingIndicator } from '@daisy/react-components';
import { faFiles, faIndustryWindows, faSealExclamation } from '@fortawesome/pro-light-svg-icons';
import { groupBy, sortBy } from 'lodash';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Row } from '@/components/Row';
import { OneColumn, TwoColumn } from '@/components/styled/shared';
import { useTruckModel } from '@/hooks/useTruckModel';
import { getImagePath } from '@/utils/helpers';

const Grid = styled.div<{ columns?: number }>`
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 1rem;
    row-gap: 1rem;

    @media (min-width: 768px) {
        grid-template-columns: repeat(${({ columns = 4 }) => columns}, 1fr);
    }
`;

const IconWrapper = styled.div`
    align-items: center;
    display: flex;
    gap: 0.5em;
`;

const Header = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
`;

export const TruckModelSummaryTabView: React.FC = () => {
    const { truckModel, image } = useTruckModel();
    const navigate = useNavigate();

    const { data, loading } = useGetTruckSummaryQuery({
        variables: { uuid: truckModel?.uuid as string },
        skip: !truckModel?.uuid
    });

    const truckSummary = data?.truckSummary ?? {};

    const distinctModels = sortBy(
        Object.entries(
            groupBy(
                truckModel?.hierarchyTrucks,
                (hierarchyTrucks) => hierarchyTrucks?.hierarchy?.name
            )
        ),
        (model) => model[0]
    );

    if (loading) {
        return <LoadingIndicator />;
    }

    if (!truckModel) {
        navigate('/not-found');
        return null;
    }

    return (
        <>
            <Card data-testid="truck-model-summary-tab-content">
                <TwoColumn leftWidth={7} gapSize="large" withBorder>
                    <TwoColumn leftWidth={1} rightWidth={5} gapSize="large">
                        {image && (
                            <img alt={truckModel.softwareModelName} src={image} height={210} />
                        )}
                        <OneColumn>
                            <header>
                                <Label>{truckModel.category?.name}</Label>
                                <h2>{truckModel.softwareModelName}</h2>
                            </header>
                            <Grid columns={2}>
                                <Row
                                    isVertical
                                    label="Software model version"
                                    value={truckModel.softwareModelVersion.toPrecision(2)}
                                />
                                <Row
                                    isVertical
                                    label="Original TDF filename"
                                    value={truckModel.originalTdfFilename}
                                />
                                <Row
                                    isVertical
                                    label="Serial numbers"
                                    value={truckSummary.serialNumberCount ?? 0}
                                />
                                <Row
                                    isVertical
                                    label="Average operating hours"
                                    value={`${
                                        truckSummary.averageOperatingHours?.toFixed(2) ?? 0
                                    } h`}
                                />
                            </Grid>
                        </OneColumn>
                    </TwoColumn>
                    <OneColumn gapSize="small">
                        <Header>
                            <H3>Statistics</H3>
                            <Label>Last 30 days</Label>
                        </Header>
                        <Row
                            isVertical
                            label="TTP files"
                            value={
                                <IconWrapper>
                                    <Icon icon={faFiles} className="fa-sm" />
                                    {truckSummary.ttpFileCount ?? 0}
                                </IconWrapper>
                            }
                        />
                        <Row
                            isVertical
                            label="Factory defaults"
                            value={
                                <IconWrapper>
                                    <Icon icon={faIndustryWindows} className="fa-sm" />
                                    {truckSummary.factoryDefaultCount ?? 0}
                                </IconWrapper>
                            }
                        />
                        <Row
                            isVertical
                            label="Warranty claims"
                            value={
                                <IconWrapper>
                                    <Icon icon={faSealExclamation} className="fa-sm" />
                                    {truckSummary.warrantyClaimsCount ?? 0}
                                </IconWrapper>
                            }
                        />
                    </OneColumn>
                </TwoColumn>
            </Card>

            <h2>Brand Models</h2>
            <Grid columns={4}>
                {distinctModels.map(([name, hierarchyTrucks]) =>
                    hierarchyTrucks ? (
                        <Card
                            key={name}
                            padding="small"
                            imageSource={
                                getImagePath(hierarchyTrucks[0]?.hierarchy?.imagePath) ?? 'empty'
                            }
                        >
                            <OneColumn gapSize="small">
                                <Row
                                    isVertical
                                    label={hierarchyTrucks[0]?.hierarchy?.parent?.name ?? ''}
                                    value={hierarchyTrucks[0]?.hierarchy?.name}
                                />
                                <Label>
                                    {hierarchyTrucks
                                        .map(
                                            (hierarchyTruck) =>
                                                hierarchyTruck?.hierarchy?.parent?.parent?.name
                                        )
                                        .join(' · ')}
                                </Label>
                            </OneColumn>
                        </Card>
                    ) : null
                )}
            </Grid>
        </>
    );
};
