import styled from 'styled-components';

const EmptyChartContainer = styled.div`
    margin: 0 auto;
`;

const Empty3DChart = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 35em;
    border: 1px solid ${({ theme }) => theme.palette.defaultBorder};
    border-radius: 8px;
`;

const NoDataText = styled.span`
    color: ${({ theme }) => theme.palette.texts.lightgrey};
`;

export const Empty3DChartInfo = () => (
    <EmptyChartContainer>
        <Empty3DChart>
            <NoDataText>No data to show</NoDataText>
        </Empty3DChart>
    </EmptyChartContainer>
);
