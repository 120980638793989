var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import React, { useRef, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import { Icon } from '../Icon/Icon';
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    position: relative;\n    display: flex;\n    flex-direction: column;\n    width: 265px;\n\n    font-family: ", ";\n"], ["\n    position: relative;\n    display: flex;\n    flex-direction: column;\n    width: 265px;\n\n    font-family: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.fonts.families.content;
});
var ProfileInfo = styled.button(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    display: flex;\n    align-items: center;\n    height: 100%;\n    cursor: pointer;\n    background-color: transparent;\n    text-align: left;\n    border: none;\n"], ["\n    display: flex;\n    align-items: center;\n    height: 100%;\n    cursor: pointer;\n    background-color: transparent;\n    text-align: left;\n    border: none;\n"])));
var Avatar = styled.img(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    width: 36px;\n    height: 36px;\n    border-radius: 50%;\n    object-fit: cover;\n"], ["\n    width: 36px;\n    height: 36px;\n    border-radius: 50%;\n    object-fit: cover;\n"])));
var TextContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    margin: 0 0 0 1em;\n    display: flex;\n    flex-direction: column;\n    flex-grow: 1;\n"], ["\n    margin: 0 0 0 1em;\n    display: flex;\n    flex-direction: column;\n    flex-grow: 1;\n"])));
var NameText = styled.span(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    font-size: 14px;\n    color: ", ";\n"], ["\n    font-size: 14px;\n    color: ", ";\n"])), function (_a) {
    var theme = _a.theme, hasDarkBackground = _a.hasDarkBackground;
    return hasDarkBackground ? theme.palette.white : theme.palette.texts.black;
});
var EmailText = styled.span(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    font-size: 12px;\n    margin-left: 1px;\n    color: ", ";\n"], ["\n    font-size: 12px;\n    margin-left: 1px;\n    color: ", ";\n"])), function (_a) {
    var theme = _a.theme, hasDarkBackground = _a.hasDarkBackground;
    return hasDarkBackground ? theme.palette.texts.disabled : theme.palette.texts.grey;
});
export var DropdownContainer = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    display: flex;\n    padding: 10px;\n    position: absolute;\n    top: 45px;\n    left: 0;\n    right: 0;\n    background-color: ", ";\n    border: ", ";\n    border-radius: 3px;\n    box-shadow: ", ";\n    z-index: 2;\n    color: ", ";\n"], ["\n    display: flex;\n    padding: 10px;\n    position: absolute;\n    top: 45px;\n    left: 0;\n    right: 0;\n    background-color: ", ";\n    border: ", ";\n    border-radius: 3px;\n    box-shadow: ", ";\n    z-index: 2;\n    color: ", ";\n"])), function (_a) {
    var theme = _a.theme, hasDarkBackground = _a.hasDarkBackground;
    return hasDarkBackground ? theme.palette.header.dropdownBackground : theme.palette.white;
}, function (_a) {
    var theme = _a.theme, hasDarkBackground = _a.hasDarkBackground;
    return hasDarkBackground ? "".concat(theme.palette.header.dropdownBorderColor) : 'none';
}, function (_a) {
    var hasDarkBackground = _a.hasDarkBackground;
    return hasDarkBackground ? '2px 2px 7px -3px #3c3c3c' : '0px 2px 5px rgba(34, 34, 34, 0.25)';
}, function (_a) {
    var theme = _a.theme, hasDarkBackground = _a.hasDarkBackground;
    return hasDarkBackground ? theme.palette.white : theme.palette.texts.black;
});
export var ProfileItem = function (_a) {
    var children = _a.children, imageSource = _a.imageSource, name = _a.name, email = _a.email, _b = _a.hasDarkBackground, hasDarkBackground = _b === void 0 ? false : _b;
    var _c = useState(false), isExpanded = _c[0], setIsExpanded = _c[1];
    var dropdownRef = useRef(null);
    var theme = useTheme();
    var handleContainerClick = function () { return setIsExpanded(!isExpanded); };
    useOutsideClick(dropdownRef, function () { return setIsExpanded(false); });
    return (React.createElement(Container, { onClick: handleContainerClick, ref: dropdownRef },
        React.createElement(ProfileInfo, { "data-testid": "profile-container" },
            React.createElement(Avatar, { src: imageSource }),
            React.createElement(TextContainer, null,
                React.createElement(NameText, { "data-testid": "name-text", hasDarkBackground: hasDarkBackground }, name),
                React.createElement(EmailText, { hasDarkBackground: hasDarkBackground }, email)),
            children && (React.createElement(Icon, { icon: faCaretDown, color: hasDarkBackground ? theme.palette.white : theme.palette.texts.black, style: {
                    transform: "rotate(".concat(isExpanded ? '-180deg' : '0deg', ")"),
                    transition: 'transform 0.2s',
                    marginLeft: '1em'
                } }))),
        isExpanded && children && (React.createElement(DropdownContainer, { hasDarkBackground: hasDarkBackground, "data-testid": "dropdown-container" }, children))));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
