import {
    TruckErrorHistoryEventFragment,
    useGetTruckErrorHistoryEventSummaryQuery
} from '@daisy/data-access';
import { H2, P1, SegmentedControl, Suspense } from '@daisy/react-components';
import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { DummyProgressBar } from '@/components/DummyProgressBar';
import { ErrorHandler } from '@/components/ErrorHandler';
import { DatasetInfo } from '@/components/Infos/DatasetInfo';
import { ErrorEventInfo } from '@/components/Infos/ErrorEventInfo';
import { PieChartSkeleton } from '@/components/Skeletons/PieChartSkeleton';
import { OneColumn, ProgressBarContainer } from '@/components/styled/shared';
import { useTimeframe } from '@/hooks/useTimeframe';
import { useTruckModel } from '@/hooks/useTruckModel';
import { TwoColStackedLayout } from '@/layouts/TwoColStackedLayout';
import { RootState } from '@/store';
import { notEmpty } from '@/utils/helpers';
import { ErrorHistoryEventsChart } from './Tabs/ErrorHistoryEventsChart';
import { ErrorHistoryEventsTable } from './Tabs/ErrorHistoryEventsTable';

export const TruckModelErrorHistoryEventsTabView = () => {
    const { truckModel } = useTruckModel();
    const { uiLoading } = useSelector((state: RootState) => state.ui);
    const { timeframeValue, setTimeframeValue } = useTimeframe(30);

    const [selectedEvent, setSelectedEvent] = useState<TruckErrorHistoryEventFragment>();

    const {
        data: errorHistoryEventsQueryData,
        loading: isErrorHistoryEventsLoading,
        error: errorHistoryEventsError
    } = useGetTruckErrorHistoryEventSummaryQuery({
        skip: !truckModel?.uuid,
        variables: {
            uuid: truckModel?.uuid || '',
            days: timeframeValue ? parseInt(timeframeValue, 10) : 30
        },
        onCompleted: ({ truckErrorHistoryEventSummary }) => {
            if (truckErrorHistoryEventSummary?.errorHistoryEvents?.[0]) {
                setSelectedEvent(truckErrorHistoryEventSummary.errorHistoryEvents[0]);
            }
        }
    });

    const { totalCount, infoCount, warningCount, alarmCount } =
        errorHistoryEventsQueryData?.truckErrorHistoryEventSummary || {};

    const errorHistoryEvents = useMemo(
        () =>
            errorHistoryEventsQueryData?.truckErrorHistoryEventSummary?.errorHistoryEvents?.filter(
                notEmpty
            ),
        [errorHistoryEventsQueryData?.truckErrorHistoryEventSummary?.errorHistoryEvents]
    );

    const handlePointClick = useCallback(
        (selectedIndex: number) => {
            const newSelectedEvent = errorHistoryEvents?.[selectedIndex];

            if (newSelectedEvent) {
                setSelectedEvent(newSelectedEvent);
            }
        },
        [errorHistoryEvents]
    );

    const datasetItems = [
        {
            label: 'Total count of error events',
            value: totalCount
        },
        {
            label: 'Alarm events',
            value: alarmCount
        },
        {
            label: 'Warning events',
            value: warningCount
        },
        {
            label: 'Info events',
            value: infoCount
        }
    ];

    const isLoading = uiLoading || isErrorHistoryEventsLoading;

    return (
        <>
            <ErrorHandler hasErrored={!!errorHistoryEventsError} />
            {isErrorHistoryEventsLoading && (
                <ProgressBarContainer>
                    <DummyProgressBar initialProgress={0} />
                </ProgressBarContainer>
            )}
            <TwoColStackedLayout
                isHeaderOutside
                title="Events by occurrence"
                dataTestId="error-history-events-tab-content"
                titleSuffix={
                    <SegmentedControl
                        isHorizontal
                        isSmall
                        name="timeframe"
                        label="Time frame"
                        value={timeframeValue ? parseInt(timeframeValue, 10) : null}
                        options={timeframeSelectOptions}
                        onChange={setTimeframeValue}
                    />
                }
            >
                <>
                    <P1>Click on a slice to see the event info.</P1>
                    <Suspense
                        condition={!isErrorHistoryEventsLoading}
                        fallback={
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: '100%'
                                }}
                            >
                                <PieChartSkeleton />
                            </div>
                        }
                    >
                        <ErrorHistoryEventsChart
                            errorHistoryEvents={errorHistoryEvents}
                            onPointClick={handlePointClick}
                        />
                    </Suspense>
                </>
                <DatasetInfo items={datasetItems} />
                {selectedEvent && (
                    <>
                        <div>
                            <ErrorEventInfo
                                linkTo={`/truck-model/${truckModel?.uuid}/error-code/${selectedEvent.eventCode}`}
                                linkDisabled={!selectedEvent.eventCode}
                                eventName={selectedEvent.eventName || ''}
                                eventClass={selectedEvent.eventClass || ''}
                                eventCode={selectedEvent.eventCode || ''}
                                eventDevice={selectedEvent.eventDevice || ''}
                            />
                        </div>
                        <hr />
                        <OneColumn>
                            <H2>Affected Serial Numbers</H2>
                            {!errorHistoryEvents?.length && !isLoading && (
                                <span>No data found for selected time frame</span>
                            )}
                            {!!errorHistoryEvents?.length && (
                                <ErrorHistoryEventsTable selectedEvent={selectedEvent} />
                            )}
                        </OneColumn>
                    </>
                )}
            </TwoColStackedLayout>
        </>
    );
};

const timeframeSelectOptions = [
    { label: '30', value: 30 },
    { label: '60', value: 60 },
    { label: '90', value: 90 },
    { label: '120', value: 120 },
    { label: '150', value: 150 },
    { label: '180', value: 180 }
];
