"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setSearchParams = exports.setUILoading = exports.setAppVersion = exports.uiSlice = exports.initialState = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
exports.initialState = {
    appVersion: '',
    uiLoading: false,
    searchParams: null
};
exports.uiSlice = (0, toolkit_1.createSlice)({
    name: 'ui',
    initialState: exports.initialState,
    reducers: {
        setAppVersion: (state, action) => {
            state.appVersion = action.payload;
        },
        setUILoading: (state, action) => {
            state.uiLoading = action.payload;
        },
        setSearchParams: (state, action) => {
            state.searchParams = action.payload;
        }
    }
});
_a = exports.uiSlice.actions, exports.setAppVersion = _a.setAppVersion, exports.setUILoading = _a.setUILoading, exports.setSearchParams = _a.setSearchParams;
exports.default = exports.uiSlice.reducer;
