"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setSidebarIsCollapsed = exports.sidebarSlice = exports.initialState = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
exports.initialState = {
    isCollapsed: false
};
exports.sidebarSlice = (0, toolkit_1.createSlice)({
    name: 'sidebar',
    initialState: exports.initialState,
    reducers: {
        setSidebarIsCollapsed: (state, action) => {
            state.isCollapsed = action.payload;
        }
    }
});
exports.setSidebarIsCollapsed = exports.sidebarSlice.actions.setSidebarIsCollapsed;
exports.default = exports.sidebarSlice.reducer;
