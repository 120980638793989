type MemoizedFunction<F extends (...args: any[]) => any> = (
    ...args: Parameters<F>
) => ReturnType<F>;

const memoize = <F extends (...args: any[]) => any>(func: F): MemoizedFunction<F> => {
    const cache: Record<string, ReturnType<F>> = {}; // Cache for memoized function results

    return (...args: Parameters<F>): ReturnType<F> => {
        const keyFromArgs = JSON.stringify(args); // Cache key generated from function arguments
        if (!cache[keyFromArgs]) {
            cache[keyFromArgs] = func(...args);
        }
        return cache[keyFromArgs];
    };
};

export default memoize;
