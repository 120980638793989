import { HierarchyTruckFragment, useGetHierarchyTrucksQuery } from '@daisy/data-access';
import { flow, sortBy, uniqWith } from 'lodash';
import { useMemo } from 'react';

export const useHierarchyTrucks = () => {
    const hierarchyTrucksQuery = useGetHierarchyTrucksQuery();

    const formattedTruckNames = useMemo(
        () =>
            hierarchyTrucksQuery?.data?.hierarchyTrucks
                ? hierarchyTruckNameFormatter(hierarchyTrucksQuery.data.hierarchyTrucks)
                : [],
        [hierarchyTrucksQuery?.data?.hierarchyTrucks]
    );

    return {
        hierarchyTrucks: hierarchyTrucksQuery.data?.hierarchyTrucks,
        formattedTruckNames,
        isLoading: hierarchyTrucksQuery.loading
    };
};

function hierarchyTruckNameFormatter(
    hierarchyTrucks: HierarchyTruckFragment[]
): HierarchyTruckFragment[] {
    return flow([
        (x) => sortBy(x, 'hierarchy.parent.name'),
        (x) => sortBy(x, 'truck.softwareModelName'),
        (x) =>
            uniqWith<HierarchyTruckFragment>(
                x,
                (xa, xb) =>
                    xa.hierarchy?.parent?.name === xb.hierarchy?.parent?.name &&
                    xa.hierarchy?.name === xb.hierarchy?.name &&
                    xa.truck?.softwareModelVersion === xb.truck?.softwareModelVersion &&
                    xa.truck?.originalTdfFilename === xb.truck?.originalTdfFilename
            )
    ])(hierarchyTrucks);
}
