import { CustomQueryFragment } from '@daisy/data-access';
import { setQueryState, setSelectedData } from '@daisy/middleware-redux';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';

import { StyledPointChart } from '@/components/styled/shared';
import { RootState, useAppDispatch } from '@/store';
import { TTP } from '@/types';
import {
    createSeries,
    createXAxisCategories,
    getOVIChartOptions
} from '@/views/Charts/objectValueChartConfig';
import { LargeDatasetToastContent } from './styles';

export type SelectedData = {
    serialNumber: string;
    ttp: TTP;
};

type ObjectValueInspectorChartProps = {
    filteredTelematicsData: CustomQueryFragment[];
    serialNumberFilterActive: boolean;
};

export const ObjectValueInspectorChart: React.FC<ObjectValueInspectorChartProps> = ({
    filteredTelematicsData,
    serialNumberFilterActive
}) => {
    const dispatch = useAppDispatch();

    const selectedEventRef = useRef<HTMLDivElement>(null);

    const queryEditorState = useSelector((state: RootState) => state.queryEditor);
    const { mainObject, referenceObjects, selectedXAxis, selectedClustering, selectedData } =
        queryEditorState;

    const handlePointClick = useCallback(
        (selectedSerialNumber: string, selectedTtpFilename: string, time: string) => {
            dispatch(
                setSelectedData({
                    serialNumber: selectedSerialNumber,
                    ttp: { filename: selectedTtpFilename, time }
                })
            );
            selectedEventRef?.current?.scrollIntoView({
                behavior: 'smooth',
                block: 'end',
                inline: 'nearest'
            });
        },
        [dispatch]
    );
    
    const chartOptions = useMemo(
        () =>
            getOVIChartOptions({
                series: createSeries(
                    filteredTelematicsData,
                    selectedData?.serialNumber,
                    serialNumberFilterActive,
                    selectedClustering,
                    mainObject.object.name || '',
                    referenceObjects
                ),
                xAxis: {
                    categories: createXAxisCategories(
                        filteredTelematicsData,
                        referenceObjects,
                        selectedXAxis
                    ),
                    tickInterval: Math.floor(filteredTelematicsData.length / 10)
                },
                handlePointClick,
                serialNumberFilterActive
            }),
        [
            filteredTelematicsData,
            selectedData?.serialNumber,
            serialNumberFilterActive,
            selectedClustering,
            mainObject.object.name,
            referenceObjects,
            selectedXAxis,
            handlePointClick
        ]
    );

    return (
        <>
            <StyledPointChart
                subtitle="Click and drag to zoom in. Hold down shift key to pan."
                options={chartOptions}
            />
            {filteredTelematicsData.length > 5000 && (
                <LargeDatasetToastContent
                    type="info"
                    description="This dataset has more than 5000 points. Some features
                                            may not work as intended. Filter the dataset to enable
                                            them."
                />
            )}
        </>
    );
};
