import { map, uniqBy } from 'lodash';
import regression from 'regression';

interface DataOption {
    y: number;
    id: string;
    date: string;
    serialNumber: string;
    color: string;
}

const SERIES_KEYS = ['x', 'y', 'time', 'id', 'className', 'serialNumber'];

export const getOptions = (
    data: DataOption[],
    clickPointHandler: (event: any) => void,
    renderHandler: () => void,
    redrawHandler: () => void
): Highcharts.Options => ({
    chart: {
        events: {
            render() {
                renderHandler();
            },
            redraw() {
                redrawHandler();
            }
        }
    },
    xAxis: {
        title: {
            text: undefined
        },
        categories: createXAxisCategories(data)
    },
    yAxis: {
        title: {
            text: undefined
        }
    },
    legend: {
        maxHeight: 100
    },
    series: createSeries(data),
    //[
    // Uncomment to enable regression line
    // {
    //     type: 'line',
    //     data: calculateRegression(data),
    //     enableMouseTracking: false
    // }
    //],
    plotOptions: {
        scatter: {
            marker: {
                radius: 5
            }
        },
        series: {
            cursor: 'pointer',
            point: {
                events: {
                    select() {
                        clickPointHandler(this);
                    }
                }
            },
            allowPointSelect: true,
            marker: {
                states: {
                    select: {
                        lineWidth: 0,
                        fillColor: undefined
                    }
                }
            }
        }
    },
    tooltip: {
        useHTML: true,
        hideDelay: 100,
        headerFormat: '<small>{point.key}</small><table>',
        pointFormatter() {
            if (this) {
                const firstRow = `<tr><td><b style="font-size: 16px;">${this.y}</b></td></tr>`;
                const secondRow = `<tr><td>${(this.options as any).serialNumber}</td></tr>`;
                return firstRow + secondRow;
            }
            return '';
        },
        footerFormat: '</table>',
        style: {
            pointerEvents: 'none'
        }
    }
});

export const createXAxisCategories = (data: DataOption[]) => {
    const formatter = new Intl.DateTimeFormat();
    return data.map((item) => formatter.format(new Date(item.date)));
}

const createSeriesData = (condition: boolean, value: DataOption, index: number) => ({
    x: index,
    y: condition ? value.y : null,
    key: value.date,
    id: condition ? value.id : '',
    color: value.color,
    serialNumber: condition ? value.serialNumber : ''
});

const createSeries = (data: DataOption[]): Highcharts.SeriesOptionsType[] => {
    const serialNumbers = map(uniqBy(data, 'serialNumber'), 'serialNumber');

    return map(serialNumbers, (sn) => ({
        name: sn,
        type: 'scatter',
        data: map(data, (value, index) =>
            createSeriesData(value.serialNumber === sn, value, index)
        ),
        keys: SERIES_KEYS
    }));
};

const calculateRegression = (data: DataOption[]) => {
    const dataForRegression = data.reduce(
        (acc: [number, number][], curr: DataOption, index: number) => {
            acc.push([index, curr.y]);
            return acc;
        },
        []
    );

    const result = regression.linear(dataForRegression);
    const gradient = result.equation[0];
    const yIntercept = result.equation[1];

    // Create an array from x axis values
    const xValues: number[] = [];
    dataForRegression.forEach((d: [number, number]) => xValues.push(d[0]));

    // Get the first data points for the line
    const firstX = Math.min.apply(null, xValues);
    const firstY = gradient * firstX + yIntercept;

    // Get the last data points for the line
    const lastX = Math.max.apply(null, xValues);
    const lastY = gradient * lastX + yIntercept;

    return [
        [firstX, firstY < 0 ? 0 : firstY],
        [lastX, lastY]
    ];
};
