import { ReactComponent as TruckDataAnalyzerText } from '@daisy/assets/images/TruckDataAnalyzerText.svg';
import { ReactComponent as TruckToolLogo } from '@daisy/assets/images/TruckToolLogo.svg';
import { UserInfoWeb } from '@daisy/middleware-redux';
import { ProfileItem } from '@daisy/react-components';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { LoginButton } from '@/components/Buttons/LoginButton';
import { LogoutButton } from '@/components/Buttons/LogoutButton';
import { HeaderContent } from '@/components/styled/shared';

type TopBarProps = {
    user?: UserInfoWeb;
    versionText: string;
};

const TopBarContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 56px;
    width: 100%;
    padding: 8px 0;
    background-color: ${({ theme }) => theme.palette.header.background};
`;

const TopBarTitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const StyledLink = styled(Link)`
    display: flex;
    align-items: center;
`;

const VersionText = styled.span`
    color: ${({ theme }) => theme.palette.texts.lightgrey};
    margin-left: 0.5em;

    &:hover {
        color: ${({ theme }) => theme.palette.texts.grey};
    }
`;

const VersionLink = styled(Link)`
    text-decoration: underline;
`;

const ProfileItemWrapper = styled.div`
    display: flex;
    flex: 1;
    justify-content: flex-end;
`;

export const TopBar = ({ user, versionText }: TopBarProps) => (
    <TopBarContainer>
        <HeaderContent>
            <div style={{ display: 'flex', gap: '0.75em' }}>
                <StyledLink to="/" id="home_link" data-testid="home-link">
                    <TruckToolLogo style={{ height: '36px', width: '36px' }} />
                </StyledLink>
                <TopBarTitleContainer>
                    <TruckDataAnalyzerText style={{ height: '24px', marginTop: '4px' }} />
                    <VersionText>
                        ( <VersionLink to="/release-notes/">{versionText}</VersionLink> )
                    </VersionText>
                </TopBarTitleContainer>
            </div>
            <ProfileItemWrapper>
                {(user && (
                    <ProfileItem
                        imageSource={user.picture}
                        name={user.name}
                        email={user.email}
                        hasDarkBackground
                    >
                        <LogoutButton />
                    </ProfileItem>
                )) || <LoginButton />}
            </ProfileItemWrapper>
        </HeaderContent>
    </TopBarContainer>
);
