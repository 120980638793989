import { H2 } from '@daisy/react-components';
import styled from 'styled-components';

import { Row } from '@/components/Row';
import { OneColumn, StyledLink } from '@/components/styled/shared';

type ErrorEventInfoProps = {
    title?: React.ReactNode;
    linkTo?: string;
    eventName: string;
    eventCode: string;
    eventClass: string;
    time?: string;
    eventDevice?: string;
    linkDisabled?: boolean;
};

const Wrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 0.5rem;
    row-gap: 1rem;
`;

export const ErrorEventInfo = ({
    title = 'Event info',
    eventName,
    eventCode,
    eventClass,
    time,
    eventDevice,
    linkTo,
    linkDisabled
}: ErrorEventInfoProps) => (
    <OneColumn>
        <H2>{title}</H2>
        <Wrapper>
            <Row
                isVertical
                label="Event code"
                value={
                    linkDisabled
                        ? eventCode
                        : linkTo && <StyledLink to={linkTo}>{eventCode}</StyledLink>
                }
            />
            <Row isVertical label="Event name" value={eventName} />
            <Row isVertical label="Event class" value={eventClass} />
            {eventDevice && <Row isVertical label="Event device" value={eventDevice} />}
            {time && <Row isVertical label="TTP date" value={time} />}
        </Wrapper>
    </OneColumn>
);
