import { uniqBy } from 'lodash';
import { useEffect } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

import { PageTitle } from '@/components/PageTitle';
import { PageContent, ViewContainer, ViewContent } from '@/components/styled/shared';
import { useSerialNumber } from '@/hooks/useSerialNumber';
import { SerialNumberViewTabOptions, TruckUsageTabSubTabOptions } from '@/types';
import { getImagePath } from '@/utils/helpers';
import { SerialNumberTabsView } from './SerialNumberTabsView';
import { LargeDatasetToastContent } from '../ObjectValueInspector/styles';

export const SerialNumberView = () => {
    const { serialNumber, relatedSerialNumbers, softwareModelName, brandModelName } =
        useSerialNumber();
    const navigate = useNavigate();
    const { activeTab, activeSubTab } = useParams();

    useEffect(() => {
        if (activeTab === SerialNumberViewTabOptions.TRUCK_USAGE && !activeSubTab) {
            navigate(
                {
                    pathname: generatePath(':activeSubTab', {
                        activeSubTab: TruckUsageTabSubTabOptions.EVENTS
                    })
                },
                { replace: true }
            );
        }
    }, [activeSubTab, activeTab, navigate]);

    const multipleTruckModels =
        uniqBy(relatedSerialNumbers, 'truck.softwareModelUIName').length > 1;

    const modelsString = multipleTruckModels ? 
        `This serial number is related to following truck models: \n${relatedSerialNumbers
            ?.map(s => s.truck?.softwareModelName).filter(m => m !== undefined).join(', ')}` :
        undefined;

    const subtitle =
        (softwareModelName || brandModelName) && !multipleTruckModels
            ? [softwareModelName, brandModelName].join(', ')
            : undefined;

    return (
        <ViewContainer>
            {serialNumber && (
                <PageContent>
                    <ViewContent>
                        <PageTitle
                            title={`Serial Number: ${serialNumber}`}
                            subtitle={subtitle}
                            image={getImagePath(
                                relatedSerialNumbers?.[0]?.truck?.hierarchyTrucks?.[0]?.hierarchy
                                    ?.imagePath
                            )}
                        />
                        {/* <ErrorHandler hasErrored={!!ttpQuery.error} /> */}
                        {multipleTruckModels && 
                            <LargeDatasetToastContent
                                type="warning"
                                description="Our records indicate that this serial number has been attached to multiple truck models.
                                We cannot ensure that data in this page is shown correctly."
                                additionalInfo={modelsString}
                                />}
                        <SerialNumberTabsView />
                    </ViewContent>
                </PageContent>
            )}
        </ViewContainer>
    );
};
