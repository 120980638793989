import styled from 'styled-components';

import { ViewContainer, ViewContent } from '@/components/styled/shared';
import { LogoutButton } from '../components/Buttons/LogoutButton';

const CenteredText = styled.span`
    text-align: center;
`;

const CenteredLogoutButton = styled(LogoutButton)`
    align-self: center;
`;

export const UnauthorizedView = () => (
    <ViewContainer>
        <ViewContent>
            <CenteredText>
                You do not have the required permissions to view this application.
            </CenteredText>
        </ViewContent>
        <CenteredLogoutButton />
    </ViewContainer>
);
