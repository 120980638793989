import { Button, Card } from '@daisy/react-components';

import { Collapsible } from '@/components/Collapsible';
import { ErrorCodesModal } from '@/modals/ErrorCodesModal';

export const ErrorCodeSearchCardView = () => (
    <Card title="Error codes">
        <Collapsible>
            {({ isOpen, toggleOpen }) => (
                <>
                    <Button id="card_error_codes_search_button" onClick={toggleOpen}>
                        Search for error codes
                    </Button>
                    {isOpen && <ErrorCodesModal handleModalClose={toggleOpen} />}
                </>
            )}
        </Collapsible>
    </Card>
);
