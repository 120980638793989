import { SerialNumberTtpFileFragment } from '@daisy/data-access/src/generated/graphql';
import { defaultTheme } from '@daisy/themes';
import Color from 'colorjs.io';
import { uniqBy } from 'lodash';

export const convertFromCamelCase = (text: string) => {
    const result = text.replace(/([A-Z])/g, ' $1');
    return result.charAt(0).toUpperCase() + result.slice(1);
};

export const getFirstKey = (object: Record<string, any>): string =>
    object ? Object.keys(object)[0] : '';

export const isProduction = process.env.NODE_ENV === 'production';

export const calculatePercentage = (num1: number, num2: number): number => {
    if (num1 === 0 && num2 === 0) return 0;
    return (num1 / num2) * 100;
};

export const getUniqueTtpFiles = (files?: SerialNumberTtpFileFragment[]) => {
    const uniques = uniqBy(files, ({ ttpFileTime }) => ttpFileTime);

    return uniques;
};

export function getSign(number: number | undefined | null) {
    return number ? getPlus(number) + +number.toFixed(2) : number;
}

function getPlus(number: number) {
    return number < 0 ? '' : '+';
}

export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
    return value !== null && value !== undefined;
}

export function getImagePath(path: string | undefined | null) {
    if (path) {
        const imagePath = path.replace(/\\/g, '/')
        .replace(/\s/g, "%20")
        .replace(/\(/g, "%28")
        .replace(/\)/g, "%29");
        return `${process.env.REACT_APP_ASSETS_BASE_URL}${imagePath}`;
    }
    return undefined;
}

export const tableSortArray = (rowA: any, rowB: any, id: string) => {
    if (JSON.stringify(rowA.values[id]) > JSON.stringify(rowB.values[id])) return 1;
    if (JSON.stringify(rowB.values[id]) > JSON.stringify(rowA.values[id])) return -1;
    return 0;
};

export const tableSortWithNulls = (rowA: any, rowB: any, id: string, desc: any) => {
    let a = Number.parseFloat(rowA.values[id]);
    let b = Number.parseFloat(rowB.values[id]);
    if (Number.isNaN(a)) {
        // Blanks and non-numeric strings to bottom
        a = desc ? Number.NEGATIVE_INFINITY : Number.POSITIVE_INFINITY;
    }
    if (Number.isNaN(b)) {
        b = desc ? Number.NEGATIVE_INFINITY : Number.POSITIVE_INFINITY;
    }
    if (a > b) {
        return 1;
    }
    if (b > a) {
        return -1;
    }
    return 0;
};

function getSubColor(color: string, subIndex: number) {
    const colorObj = new Color(color)
        .to('hsl')
        .set('s', (s) => s - subIndex * 80)
        .to('srgb');

    return colorObj.toString({ format: 'hex' });
}

export const getChartColor = (index: number, subIndex?: number) => {
    const colors = [
        defaultTheme.palette.charts.baseColor,
        defaultTheme.palette.charts.compareColors.first,
        defaultTheme.palette.charts.compareColors.second,
        defaultTheme.palette.charts.compareColors.third,
        defaultTheme.palette.charts.compareColors.fourth,
        defaultTheme.palette.charts.compareColors.fifth,
        defaultTheme.palette.charts.compareColors.sixth
    ];

    const color = index >= 0 ? colors[index] : defaultTheme.palette.charts.nonSelected;

    return subIndex ? getSubColor(color, subIndex) : color;
};
