import { TtpFilesDailyCount, TtpFilesDailyCountFragment } from '@daisy/data-access';
import { SegmentedControl, SelectOption } from '@daisy/react-components';
import { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';

import { StyledStockChart } from '@/components/styled/shared';

const periodSizeOptions: SelectOption[] = [
    {
        value: '7',
        label: '7'
    },
    {
        value: '30',
        label: '30'
    },
    {
        value: '90',
        label: '90'
    },
    {
        value: '180',
        label: '180'
    },
    {
        value: '365',
        label: '365'
    }
];

export const BusinessIntelligenceChart: React.FC<{
    selectedDealers: SelectOption[] | null;
    selectedTruckModel?: string;
    ttpFilesDailyCount?: TtpFilesDailyCountFragment[];
}> = ({ selectedDealers, selectedTruckModel, ttpFilesDailyCount }) => {
    const theme = useTheme();
    const [periodSize, setPeriodSize] = useState<number>(30);

    const [chartOptions, setChartOptions] = useState<Highcharts.Options>({
        chart: {
            zooming: { type: 'xy' },
            type: 'spline',
            scrollablePlotArea: {
                minWidth: 600,
                scrollPositionX: 1
            }
        },
        yAxis: {
            title: undefined
        },
        rangeSelector: {
            selected: 2,
            inputPosition: {
                align: 'center',
                x: 0,
                y: 0
            },
            buttonPosition: {
                align: 'left',
                x: 0,
                y: 0
            }
        },
        legend: {
            enabled: true
        },
        plotOptions: {
            sma: {
                visible: true,
                lineWidth: 3,
                zIndex: 1,
                marker: {
                    enabled: false
                },
                shadow: {
                    offsetX: 8,
                    offsetY: 8,
                    opacity: 0.0075,
                    width: 8
                }
            },
            series: {
                label: {
                    connectorAllowed: false
                },
                showInLegend: true
            },
            area: {
                opacity: 0.15,
                fillColor: {
                    linearGradient: {
                        x1: 0,
                        y1: 0,
                        x2: 0,
                        y2: 1
                    },
                    stops: [
                        [0, theme.palette.charts.gradient],
                        [1, '#fff']
                    ]
                },
                lineWidth: 3,
                marker: {
                    radius: 2
                },
                threshold: null
            },
            scatter: {
                visible: false,
                opacity: 0.3
            }
        },

        series: [],

        responsive: {
            rules: [
                {
                    condition: {
                        maxWidth: 500
                    },
                    chartOptions: {
                        legend: {
                            layout: 'horizontal',
                            align: 'center',
                            verticalAlign: 'bottom'
                        }
                    }
                }
            ]
        }
    });

    useEffect(() => {
        if (ttpFilesDailyCount && selectedDealers) {
            setChartOptions({
                series: selectedDealers?.flatMap((dealer) => [
                    {
                        type: 'scatter',
                        id: dealer.value.toString(),
                        name: `${dealer.label} TTP count`,
                        // color: 'rgb(1,184,249)',
                        data: parseDataForChart(
                            ttpFilesDailyCount.filter(
                                (item) =>
                                    item?.dealers?.includes(dealer.value.toString()) &&
                                    (selectedTruckModel
                                        ? item.brandModel === selectedTruckModel
                                        : true)
                            )
                        )
                    },
                    {
                        type: 'sma',
                        linkedTo: dealer.value.toString(),
                        // dashStyle: 'LongDash',
                        // color: theme.palette.charts.baseColor,
                        params: {
                            period: periodSize
                        },
                        showInNavigator: true
                    }
                ])
            });
        }
    }, [
        selectedDealers,
        selectedTruckModel,
        theme.palette.charts.baseColor,
        periodSize,
        ttpFilesDailyCount
    ]);

    return (
        <>
            <div style={{ position: 'absolute', right: 0, top: '3px', zIndex: 1 }}>
                <SegmentedControl
                    label="Period size"
                    name="periodSize"
                    value={periodSize.toString()}
                    options={periodSizeOptions}
                    disabled={!selectedDealers?.length}
                    isSmall
                    isHorizontal
                    onChange={setPeriodSize}
                />
            </div>
            <StyledStockChart options={chartOptions} constructorType="stockChart" />
        </>
    );
};

function parseDataForChart(data: Partial<TtpFilesDailyCount>[]) {
    return data.map(({ bucket, ttpCount }) => [new Date(bucket).getTime(), ttpCount || 0]);
}
