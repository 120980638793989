import { downloadTruckToolParameters } from '@daisy/middleware-redux';
import { Button, Icon, LoadingIndicator, ToastContent } from '@daisy/react-components';
import { Sizes } from '@daisy/themes';
import { faArrowDownToBracket } from '@fortawesome/pro-solid-svg-icons';
import { useToasts } from 'react-toast-notifications';

import { useState } from 'react';

import { useSpellbook } from '@/hooks/useSpellbook';
import { useAppDispatch } from '@/store';
import { StrictUnion } from '@/types/helpers';
import { createTTPFilename, downloadFile } from '@/utils/fileHelpers';
import { DummyProgressBar } from '../DummyProgressBar';

type TTPDownloadButtonProps = {
    ttpFile?: StrictUnion<{ time: any; externalId?: string } | { time: any; ttpFilename?: string }>;
    serialNumber: string;
    buttonText?: string;
    size?: Sizes;
};

export const TTPDownloadButton = ({
    ttpFile,
    serialNumber,
    size,
    buttonText = 'Download TTP'
}: TTPDownloadButtonProps) => {
    const dispatch = useAppDispatch();
    const { addToast } = useToasts();
    const { sendApplicationEvent } = useSpellbook();

    const [isDownloadingFiles, setIsDownloadingFiles] = useState<boolean>(false);

    const downloadTTP = (uuid: string, filename: string) => {
        setIsDownloadingFiles(true);

        dispatch(downloadTruckToolParameters(uuid))
            .then((result: Record<string, any>) => {
                const file = new Blob([result.payload.body], { type: 'application/xml' });
                downloadFile(file, filename)
                    .then((downloadResult) =>
                        addToast(<ToastContent type="success" description={downloadResult} />)
                    )
                    .catch((error) => addToast(<ToastContent type="error" description={error} />));
            })
            .catch((e) => {
                addToast(<ToastContent type="error" description="Download failed." />);
            })
            .finally(() => setIsDownloadingFiles(false));
    };

    const handleTTPDownload = () => {
        sendApplicationEvent('Download', {
            eventName: 'Download TTP',
            eventMeta: {
                where: 'TTP Info',
                serialNumber
            }
        });

        if (ttpFile) {
            const fileName = createTTPFilename(ttpFile.time, serialNumber || 'undefined');
            if ('externalId' in ttpFile && ttpFile.externalId) {
                downloadTTP(ttpFile.externalId, fileName);
            } else {
                downloadTTP(ttpFile.ttpFilename || '', fileName);
            }
        } else {
            addToast(<ToastContent type="error" description="No TTP found." />);
        }
    };

    return (
        <>
            {isDownloadingFiles && (
                <DummyProgressBar text="Preparing download..." initialProgress={0} />
            )}
            <Button isPrimary onClick={handleTTPDownload} disabled={isDownloadingFiles} size={size}>
                {isDownloadingFiles && <LoadingIndicator className="fa-1x" color="white" />}
                {!isDownloadingFiles && <Icon icon={faArrowDownToBracket} />}
                {buttonText}
            </Button>
        </>
    );
};
