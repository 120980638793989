var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import styled from 'styled-components';
import { Async } from 'react-select-virtualized';
export var StyledAsync = styled(Async)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    .fast-option {\n        color: #333;\n        padding: 0 1rem;\n        font-size: 14px;\n        font-family: 'Roboto';\n    }\n\n    .fast-option-focused {\n        background-color: #fafafa;\n    }\n\n    .fast-option-selected {\n        color: white;\n        background-color: rgba(97, 169, 208, 1);\n    }\n"], ["\n    .fast-option {\n        color: #333;\n        padding: 0 1rem;\n        font-size: 14px;\n        font-family: 'Roboto';\n    }\n\n    .fast-option-focused {\n        background-color: #fafafa;\n    }\n\n    .fast-option-selected {\n        color: white;\n        background-color: rgba(97, 169, 208, 1);\n    }\n"])));
export var selectStyles = function (_a) {
    var theme = _a.theme, showIcon = _a.showIcon, size = _a.size;
    return ({
        container: function (provided) { return (__assign(__assign({}, provided), { fontFamily: 'Lato', fontSize: size === 'm' ? '16px' : '18px', lineHeight: '16px', width: '100%' })); },
        control: function (provided, state) { return (__assign(__assign({}, provided), { background: theme.palette.input.background, borderRadius: state.menuIsOpen ? '8px 8px 0 0' : 8, border: "1px solid ".concat(theme.palette.input.border), boxShadow: theme.palette.input.shadow, height: size === 'm' ? 42 : 48, outline: '2px solid #61a9d0', outlineColor: !state.menuIsOpen && state.isFocused ? '#61a9d0' : 'transparent' })); },
        menu: function () { return ({
            background: '#fff',
            color: '#333',
            position: 'absolute',
            width: '100%',
            zIndex: '100',
            borderTop: "1px solid ".concat(theme.palette.border),
            borderRadius: '0 0 8px 8px',
            boxShadow: theme.palette.card.shadow,
            overflow: 'hidden'
        }); },
        menuList: function (provided) { return (__assign(__assign({}, provided), { lineHeight: 'normal', overflow: 'auto' })); },
        option: function (provided, state) { return (__assign(__assign({}, provided), { height: 40, padding: '15px 10px', color: state.isFocused ? '#fff' : '#333', backgroundColor: state.isFocused ? '#5e5e5e;' : '#fff', cursor: 'pointer' })); },
        valueContainer: function (provided) { return (__assign(__assign({}, provided), { flexWrap: 'nowrap', padding: "0.5rem 0.75rem 0.5rem ".concat(showIcon ? '2.5rem' : '0.75rem') })); },
        multiValue: function (provided) { return (__assign(__assign({}, provided), { backgroundColor: '#ddd', boxShadow: theme.palette.segmentedControl.item.shadow })); }
    });
};
var templateObject_1;
