import { useGetTrucksByEventCodesQuery } from '@daisy/data-access';
import { setSelectedTruckModel } from '@daisy/middleware-redux';
import { Button, H3, LoadingIndicator } from '@daisy/react-components';
import { join, map } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { ErrorHandler } from '@/components/ErrorHandler';
import { OneColumn } from '@/components/styled/shared';
import { RootState } from '@/store';

const TrucksSelect = styled.ul`
    width: 100%;
    border: 1px solid ${({ theme }) => theme.palette.table.border};
    min-height: 72px;
    max-height: 250px;
    overflow-y: scroll;
    border-radius: 8px;
    list-style-type: none;
    padding: 0;
`;

const TDFFileName = styled.span`
    font-size: 14px;
    color: ${({ theme }) => theme.palette.texts.lightgrey};
`;

const TruckOption = styled.li<{ isActive: boolean }>`
    padding: 8px;
    cursor: pointer;

    :not(:last-child) {
        border-bottom: 2px solid ${({ theme }) => theme.palette.table.row.border};
    }

    ${({ isActive }) =>
        isActive
            ? css`
                  color: ${({ theme }) => theme.palette.texts.black};
                  background: ${({ theme }) => theme.palette.table.row.active};

                  > span {
                      color: ${({ theme }) => theme.palette.texts.black};
                  }
              `
            : css`
                  :nth-child(even) {
                      background: ${({ theme }) => theme.palette.table.row.even};
                  }

                  :hover {
                      background: ${({ theme }) => theme.palette.table.row.hover};
                  }
              `}
`;

export const ErrorCodeTruckSerialNumberSelect: React.FC<{ callback: () => void }> = ({
    callback
}) => {
    const dispatch = useDispatch();
    const { selectedTruckModel, selectedEventCodes } = useSelector(
        (state: RootState) => state.truckModel
    );
    const navigate = useNavigate();

    const trucksByEventCodesQuery = useGetTrucksByEventCodesQuery({
        variables: {
            eventCodes: map(selectedEventCodes, 'userId')
        }
    });

    const handleTruckModelSelect = () => {
        if (!selectedTruckModel) return;
        // Search error counts based on error code and truck model
        callback();
        navigate(
            `/truck-model/${selectedTruckModel.uuid}/error-code/${join(
                map(selectedEventCodes, (code) => `${code.userId}`),
                ','
            )}`
        );
    };

    const isLoading = trucksByEventCodesQuery.loading;
    const trucksData = trucksByEventCodesQuery.data?.trucksByEventCodes || [];

    return (
        <>
            {isLoading ? (
                <div style={{ margin: '0 auto' }}>
                    <LoadingIndicator />
                </div>
            ) : (
                <>
                    <div style={{ minHeight: '18em' }}>
                        <OneColumn gapSize="small">
                            <H3>Select truck model</H3>
                            <div>
                                <TrucksSelect data-testid="truck-select">
                                    {map(trucksData, (truck) => (
                                        <TruckOption
                                            key={truck.uuid}
                                            data-testid="truck-option"
                                            onClick={() => dispatch(setSelectedTruckModel(truck))}
                                            isActive={
                                                !!selectedTruckModel &&
                                                truck.uuid === selectedTruckModel.uuid
                                            }
                                        >
                                            {`${truck.softwareModelName} `}
                                            <TDFFileName>{truck.originalTdfFilename}</TDFFileName>
                                        </TruckOption>
                                    ))}
                                </TrucksSelect>
                            </div>
                            <div>
                                <Button
                                    aria-label="Select truck model"
                                    role="button"
                                    data-testid="errorCode-selectTruckModel"
                                    onClick={handleTruckModelSelect}
                                    disabled={!selectedTruckModel}
                                >
                                    Select
                                </Button>
                            </div>
                        </OneColumn>
                    </div>
                </>
            )}
            <ErrorHandler hasErrored={!!trucksByEventCodesQuery.error} />
        </>
    );
};
