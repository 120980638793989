var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import 'focus-visible';
import { faSlidersH } from '@fortawesome/pro-solid-svg-icons';
import React from 'react';
import styled from 'styled-components';
import { resolveSpacingValues } from '../../helpers/helpers';
import { Button } from '../Button/Button';
import { Icon } from '../Icon/Icon';
import { H2 } from '../Typography/H2';
import { H3 } from '../Typography/H3';
var FilterMenuPositioner = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    left: 0;\n    position: fixed;\n    z-index: 99;\n"], ["\n    left: 0;\n    position: fixed;\n    z-index: 99;\n"])));
var FilterMenuContainer = styled.aside(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    position: relative;\n    width: ", ";\n    height: calc(100vh - 2em);\n    background-color: ", ";\n    transition: all 0.2s;\n    box-shadow: 2px 7px 10px rgb(0 0 0 / 20%);\n    top: -139px;\n"], ["\n    position: relative;\n    width: ", ";\n    height: calc(100vh - 2em);\n    background-color: ", ";\n    transition: all 0.2s;\n    box-shadow: 2px 7px 10px rgb(0 0 0 / 20%);\n    top: -139px;\n"])), function (_a) {
    var isCollapsed = _a.isCollapsed;
    return (isCollapsed ? 0 : '900px');
}, function (_a) {
    var theme = _a.theme;
    return theme.palette.white;
});
var FilterMenuContent = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    display: flex;\n    flex-direction: column;\n    height: 100%;\n    overflow: auto;\n    padding: ", ";\n    padding-left: ", ";\n    padding-right: ", ";\n    visibility: ", ";\n    white-space: nowrap;\n"], ["\n    display: flex;\n    flex-direction: column;\n    height: 100%;\n    overflow: auto;\n    padding: ", ";\n    padding-left: ", ";\n    padding-right: ", ";\n    visibility: ", ";\n    white-space: nowrap;\n"])), function (_a) {
    var theme = _a.theme;
    return resolveSpacingValues(theme.spacings.sm);
}, function (_a) {
    var isCollapsed = _a.isCollapsed;
    return isCollapsed && 0;
}, function (_a) {
    var isCollapsed = _a.isCollapsed;
    return isCollapsed && 0;
}, function (_a) {
    var isCollapsed = _a.isCollapsed;
    return (isCollapsed ? 'hidden' : 'visible');
});
var FilterMenuSegmentContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    display: flex;\n    flex-direction: column;\n    margin: 15px 0;\n"], ["\n    display: flex;\n    flex-direction: column;\n    margin: 15px 0;\n"])));
var ResetButton = styled(Button)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    position: absolute;\n    align-self: flex-end;\n    margin: 4px 0;\n"], ["\n    position: absolute;\n    align-self: flex-end;\n    margin: 4px 0;\n"])));
var MenuButton = styled.button(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    box-shadow: 1px 1px 10px rgb(0 0 0 / 20%);\n\n    position: absolute;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    top: ", "px;\n    right: -30px;\n    width: 30px;\n    height: 30px;\n    color: ", ";\n    background-color: ", ";\n    border: none;\n    border-bottom-right-radius: 3px;\n    border-top-right-radius: 3px;\n    cursor: pointer;\n"], ["\n    box-shadow: 1px 1px 10px rgb(0 0 0 / 20%);\n\n    position: absolute;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    top: ", "px;\n    right: -30px;\n    width: 30px;\n    height: 30px;\n    color: ", ";\n    background-color: ", ";\n    border: none;\n    border-bottom-right-radius: 3px;\n    border-top-right-radius: 3px;\n    cursor: pointer;\n"])), function (_a) {
    var menuButtonTopPosition = _a.menuButtonTopPosition;
    return menuButtonTopPosition;
}, function (_a) {
    var theme = _a.theme;
    return theme.palette.white;
}, function (_a) {
    var theme = _a.theme;
    return theme.palette.filterMenu.background;
});
var Badge = styled.span(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    width: 10px;\n    height: 10px;\n    border-radius: 50%;\n    background-color: ", ";\n    position: absolute;\n    top: -3px;\n    right: -3px;\n"], ["\n    width: 10px;\n    height: 10px;\n    border-radius: 50%;\n    background-color: ", ";\n    position: absolute;\n    top: -3px;\n    right: -3px;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.palette.filterMenu.badge;
});
export var FilterMenu = function (_a) {
    var style = _a.style, className = _a.className, title = _a.title, segments = _a.segments, onCollapse = _a.onCollapse, isCollapsed = _a.isCollapsed, _b = _a.onResetButtonClick, onResetButtonClick = _b === void 0 ? function () { } : _b, showResetButton = _a.showResetButton, showBadge = _a.showBadge, _c = _a.menuButtonTopPosition, menuButtonTopPosition = _c === void 0 ? 62 : _c;
    return (React.createElement(FilterMenuPositioner, null,
        React.createElement(FilterMenuContainer, { style: style, className: className, isCollapsed: isCollapsed },
            React.createElement(MenuButton, { onClick: onCollapse, menuButtonTopPosition: menuButtonTopPosition, "aria-label": title },
                showBadge && React.createElement(Badge, null),
                React.createElement(Icon, { icon: faSlidersH })),
            React.createElement(FilterMenuContent, { isCollapsed: isCollapsed, "aria-hidden": isCollapsed },
                React.createElement(H2, null, title),
                showResetButton && React.createElement(ResetButton, { onClick: onResetButtonClick }, "Reset"),
                segments.map(function (segment, index) { return (React.createElement(FilterMenuSegmentContainer, { key: segment.title || index },
                    segment.title && React.createElement(H3, null, segment.title),
                    segment.content)); })))));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
