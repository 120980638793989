import styled from 'styled-components';

import { Row } from '../Row';

type DatasetInfoProps = {
    items: { label: string; value?: string | number | null }[];
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 0.9rem;
`;

export const DatasetInfo = ({ items }: DatasetInfoProps) => (
    <>
        <Wrapper>
            {items.map((item) => (
                <Row key={item.label} isVertical label={item.label} value={item.value} />
            ))}
        </Wrapper>
    </>
);
