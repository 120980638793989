var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import styled from 'styled-components';
export var H2 = styled.h2(function (_a) {
    var theme = _a.theme;
    return (__assign(__assign({}, theme.fonts.fonts.h2), { 
        // padding: '0 0 0.8em',
        margin: 0, lineHeight: '1.125em' }));
});
