var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { HighchartsReact as HighchartsReactComponent } from 'highcharts-react-official';
import highcharts3D from 'highcharts/highcharts-3d';
import * as Highcharts from 'highcharts/highstock';
import indicators from 'highcharts/indicators/indicators';
import trendline from 'highcharts/indicators/trendline';
import highchartsAccessibility from 'highcharts/modules/accessibility';
import boost from 'highcharts/modules/boost';
import drilldown from 'highcharts/modules/drilldown';
import noDataToDisplay from 'highcharts/modules/no-data-to-display';
import timeline from 'highcharts/modules/timeline';
import React from 'react';
import { useTheme } from 'styled-components';
import { addChartRotation } from './helpers';
boost(Highcharts);
highcharts3D(Highcharts);
drilldown(Highcharts);
timeline(Highcharts);
indicators(Highcharts);
trendline(Highcharts);
noDataToDisplay(Highcharts);
highchartsAccessibility(Highcharts);
/**
 * This component is a building block for different types of charts.
 * Different chart types are available using the 'options' prop.
 */
export var Chart = function (_a) {
    var 
    // style,
    className = _a.className, options = _a.options, title = _a.title, _b = _a.subtitle, subtitle = _b === void 0 ? '' : _b, callback = _a.callback, _c = _a.shouldRotate, shouldRotate = _c === void 0 ? false : _c, constructorType = _a.constructorType, chartRef = _a.chartRef;
    var theme = useTheme();
    var chartOptions = __assign(__assign({ credits: { enabled: false }, tooltip: { enabled: true }, colors: theme.palette.charts.default, yAxis: {
            gridLineColor: theme.palette.charts.gridLineColor,
            gridLineWidth: 2,
            labels: {
                style: {
                    fontSize: '13px',
                    color: theme.palette.charts.yAxis.labelColor
                }
            }
        }, xAxis: {
            lineColor: theme.palette.charts.gridLineColor,
            labels: { style: { fontSize: '13px', color: theme.palette.charts.xAxis.labelColor } },
            tickWidth: 0
        }, chart: {
            style: { fontFamily: 'Lato' },
            animation: { duration: 150 }
        } }, options), { title: __assign(__assign({}, options.title), { text: title }), subtitle: __assign(__assign({}, options.subtitle), { text: subtitle }) });
    return (React.createElement(HighchartsReactComponent, { containerProps: { className: className }, highcharts: Highcharts, options: chartOptions, callback: function (chart) {
            if (callback)
                callback(chart);
            if (shouldRotate)
                addChartRotation(Highcharts, chart);
        }, ref: chartRef, constructorType: constructorType }));
};
