var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { motion } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';
import { Label } from '../Label/Label';
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: inline-flex;\n    align-items: center;\n    flex-direction: ", ";\n"], ["\n    display: inline-flex;\n    align-items: center;\n    flex-direction: ", ";\n"])), function (_a) {
    var isHorizontal = _a.isHorizontal;
    return (isHorizontal ? 'row' : 'column');
});
var Options = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    align-items: center;\n    background-color: ", ";\n    border-radius: 3px;\n    box-shadow: ", ";\n    display: inline-flex;\n    flex-direction: row;\n    padding: 4px;\n    opacity: ", ";\n"], ["\n    align-items: center;\n    background-color: ", ";\n    border-radius: 3px;\n    box-shadow: ", ";\n    display: inline-flex;\n    flex-direction: row;\n    padding: 4px;\n    opacity: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.palette.segmentedControl.background;
}, function (_a) {
    var theme = _a.theme;
    return theme.palette.input.shadow;
}, function (_a) {
    var isDisabled = _a.isDisabled;
    return (isDisabled ? '0.5' : '1');
});
var Input = styled.input(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    height: 0;\n    width: 0;\n    position: absolute;\n    overflow: hidden;\n    white-space: nowrap;\n    opacity: 0;\n"], ["\n    height: 0;\n    width: 0;\n    position: absolute;\n    overflow: hidden;\n    white-space: nowrap;\n    opacity: 0;\n"])));
var OptionLabel = styled.label(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    color: ", ";\n    cursor: pointer;\n    display: flex;\n    font-size: ", ";\n    padding: ", ";\n    ", "\n    z-index: 1;\n    position: relative;\n    word-break: break-all;\n\n    &.active,\n    &:hover {\n        color: ", ";\n    }\n"], ["\n    color: ", ";\n    cursor: pointer;\n    display: flex;\n    font-size: ", ";\n    padding: ", ";\n    ", "\n    z-index: 1;\n    position: relative;\n    word-break: break-all;\n\n    &.active,\n    &:hover {\n        color: ", ";\n    }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.palette.texts.grey;
}, function (_a) {
    var isSmall = _a.isSmall;
    return isSmall && '12px';
}, function (_a) {
    var isSmall = _a.isSmall;
    return (isSmall ? '0.2em 0.5em' : '0.3em 0.8em');
}, function (_a) {
    var isSmall = _a.isSmall;
    return isSmall && 'font-weight: 600;';
}, function (_a) {
    var theme = _a.theme;
    return theme.palette.input.text;
});
var Background = styled(motion.div)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    border-radius: 3px;\n    background-color: white;\n    box-shadow: ", ";\n    position: absolute;\n    width: 100%;\n    height: 100%;\n    top: 0;\n    left: 0;\n}"], ["\n    border-radius: 3px;\n    background-color: white;\n    box-shadow: ", ";\n    position: absolute;\n    width: 100%;\n    height: 100%;\n    top: 0;\n    left: 0;\n}"])), function (_a) {
    var theme = _a.theme;
    return theme.palette.segmentedControl.item.shadow;
});
export var SegmentedControl = function (_a) {
    var name = _a.name, value = _a.value, label = _a.label, onChange = _a.onChange, isSmall = _a.isSmall, isHorizontal = _a.isHorizontal, options = _a.options, _b = _a.disabled, disabled = _b === void 0 ? false : _b;
    return (React.createElement(Container, { isHorizontal: isHorizontal },
        label && React.createElement(Label, { text: label, isHorizontal: isHorizontal, isSmall: isSmall }),
        React.createElement(Options, { isDisabled: disabled }, options.map(function (item) { return (React.createElement("div", { key: item.value, style: { position: 'relative', flexShrink: 0 } },
            value === item.value && (React.createElement(motion.div, { className: "switch-container", layout: true, transition: { duration: 0 } },
                React.createElement(Background, { layoutId: "background-".concat(name), transition: { duration: 0.15 } }))),
            React.createElement(Input, { type: "radio", id: "".concat(name, "-").concat(item.value), name: name, checked: value === item.value, value: item.value, disabled: disabled, onChange: function (event) {
                    return onChange(event.target.value);
                } }),
            React.createElement(OptionLabel, { isSmall: isSmall, htmlFor: "".concat(name, "-").concat(item.value), className: value === item.value ? 'active' : '' }, item.label))); }))));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
