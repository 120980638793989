import { useTtpFilesDailyCountQuery } from '@daisy/data-access';
import { Card, SelectOption } from '@daisy/react-components';
import { useMemo, useState } from 'react';

import { DummyProgressBar } from '@/components/DummyProgressBar';
import { PageTitle } from '@/components/PageTitle';
import {
    PageContent,
    ProgressBarContainer,
    TwoColumn,
    ViewContainer,
    ViewContent
} from '@/components/styled/shared';
import { BusinessIntelligenceChart } from './BusinessIntelligenceChart';
import { BusinessIntelligenceFilters } from './BusinessIntelligenceFilters';

export const BusinessIntelligenceView = () => {
    const [selectedDealers, setSelectedDealers] = useState<SelectOption[] | null>(null);
    const [selectedTruckModel, setSelectedTruckModel] = useState<string>();

    const { data: ttpCountData, loading } = useTtpFilesDailyCountQuery({
        variables: { dealerUuids: selectedDealers?.map((dealer) => dealer.value.toString()) },
        skip: !selectedDealers
    });

    const truckModelSelectOptions: SelectOption[] = useMemo(
        () =>
            [...new Set(ttpCountData?.ttpFilesDailyCount.map((item) => item?.brandModel))]
                .filter((item) => item !== null)
                .map((item) => ({
                    value: item || '',
                    label: item?.toString() || ''
                })),
        [ttpCountData?.ttpFilesDailyCount]
    );

    return (
        <ViewContainer>
            <PageContent>
                <ViewContent>
                    <PageTitle title="Business Intelligence" />
                    <Card title="Analytics">
                        {loading && (
                            <div>
                                <ProgressBarContainer>
                                    <DummyProgressBar initialProgress={0} />
                                </ProgressBarContainer>
                            </div>
                        )}
                        <TwoColumn leftWidth={7} withBorder gapSize="large">
                            <BusinessIntelligenceChart
                                selectedDealers={selectedDealers}
                                selectedTruckModel={selectedTruckModel}
                                ttpFilesDailyCount={ttpCountData?.ttpFilesDailyCount}
                            />

                            <BusinessIntelligenceFilters
                                selectedDealers={selectedDealers}
                                setSelectedDealers={setSelectedDealers}
                                setSelectedTruckModel={setSelectedTruckModel}
                                truckModelSelectOptions={truckModelSelectOptions}
                            />
                        </TwoColumn>
                    </Card>
                </ViewContent>
            </PageContent>
        </ViewContainer>
    );
};
