import { useEffect, useState } from 'react';

export type CollapsibleRenderProps = {
    isOpen: boolean;
    toggleOpen: () => void;
};

export type CollapsibleProps = {
    children: ({ isOpen, toggleOpen }: CollapsibleRenderProps) => React.ReactNode;
    currentIsOpen?: boolean;
    onOpen?: () => void;
};

export const Collapsible: React.FC<CollapsibleProps> = ({ children, currentIsOpen, onOpen }) => {
    const [isOpen, setIsOpen] = useState(currentIsOpen ?? false);

    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        if (isOpen && onOpen) onOpen();
    }, [isOpen, onOpen]);

    return (
        <>
            {children({
                isOpen,
                toggleOpen
            })}
        </>
    );
};
