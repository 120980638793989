var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
var Form = styled.form(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: flex;\n    align-items: center;\n    font-family: 'Lato';\n    text-transform: uppercase;\n"], ["\n    display: flex;\n    align-items: center;\n    font-family: 'Lato';\n    text-transform: uppercase;\n"])));
var Input = styled.input(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    position: absolute;\n    clip: rect(0, 0, 0, 0);\n    height: 1px;\n    width: 1px;\n    border: 0;\n    overflow: hidden;\n\n    :checked + label {\n        color: ", ";\n        background-color: ", ";\n        margin-top: 2px;\n        box-shadow: 0 -2px 0 ", ";\n    }\n"], ["\n    position: absolute;\n    clip: rect(0, 0, 0, 0);\n    height: 1px;\n    width: 1px;\n    border: 0;\n    overflow: hidden;\n\n    :checked + label {\n        color: ", ";\n        background-color: ", ";\n        margin-top: 2px;\n        box-shadow: 0 -2px 0 ", ";\n    }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.palette.texts.white;
}, function (_a) {
    var theme = _a.theme;
    return theme.palette.primary;
}, function (_a) {
    var theme = _a.theme;
    return theme.palette.backgrounds.buttons.dark;
});
var Label = styled.label(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    padding: 0.5rem 1rem;\n    color: #61a9d0;\n    background: #fff;\n    font-size: 12px;\n    font-family: 'Roboto', sans-serif;\n    font-weight: 500;\n    text-transform: uppercase;\n    letter-spacing: 1px;\n    border-color: transparent;\n    margin-top: -2px;\n    box-shadow: 0 2px 0 ", ";\n    :first-of-type {\n        border-top-left-radius: 1000px;\n        border-bottom-left-radius: 1000px;\n    }\n    :last-of-type {\n        border-top-right-radius: 1000px;\n        border-bottom-right-radius: 1000px;\n    }\n    :focus:not(.focus-visible) {\n        outline: none;\n    }\n    display: inline-block;\n    min-width: 50px;\n    background-color: #e4e4e4;\n    color: ", ";\n    text-align: center;\n    text-shadow: none;\n    cursor: pointer;\n"], ["\n    padding: 0.5rem 1rem;\n    color: #61a9d0;\n    background: #fff;\n    font-size: 12px;\n    font-family: 'Roboto', sans-serif;\n    font-weight: 500;\n    text-transform: uppercase;\n    letter-spacing: 1px;\n    border-color: transparent;\n    margin-top: -2px;\n    box-shadow: 0 2px 0 ", ";\n    :first-of-type {\n        border-top-left-radius: 1000px;\n        border-bottom-left-radius: 1000px;\n    }\n    :last-of-type {\n        border-top-right-radius: 1000px;\n        border-bottom-right-radius: 1000px;\n    }\n    :focus:not(.focus-visible) {\n        outline: none;\n    }\n    display: inline-block;\n    min-width: 50px;\n    background-color: #e4e4e4;\n    color: ", ";\n    text-align: center;\n    text-shadow: none;\n    cursor: pointer;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.palette.backgrounds.buttons.dark;
}, function (_a) {
    var theme = _a.theme;
    return theme.palette.texts.black;
});
export var Toggle = function (_a) {
    var className = _a.className, style = _a.style, toggleKey = _a.toggleKey, name = _a.name, value = _a.value, onChange = _a.onChange, leftLabel = _a.leftLabel, rightLabel = _a.rightLabel;
    return (React.createElement(Form, { className: className, style: style },
        React.createElement(Input, { type: "radio", id: "leftId-".concat(toggleKey), name: name, value: leftLabel, checked: value === leftLabel, onChange: function (event) { return onChange(event.target.value); } }),
        React.createElement(Label, { htmlFor: "leftId-".concat(toggleKey) }, leftLabel),
        React.createElement(Input, { type: "radio", id: "rightId-".concat(toggleKey), name: name, value: rightLabel, checked: value === rightLabel, onChange: function (event) { return onChange(event.target.value); } }),
        React.createElement(Label, { htmlFor: "rightId-".concat(toggleKey) }, rightLabel)));
};
var templateObject_1, templateObject_2, templateObject_3;
