import { Modal, ProgressBar } from '@daisy/react-components';
import { useEffect, useState } from 'react';
import { OneColumn } from './styled/shared';

export type DummyProgressBarProps = {
    initialProgress: number;
    text?: string;
};

export const DummyProgressBar = ({
    initialProgress,
    text = 'Loading...'
}: DummyProgressBarProps) => {
    const [progress, setProgress] = useState<number>(initialProgress);

    useEffect(() => {
        const timer = setTimeout(() => {
            setProgress(progress + (100 - progress) * 0.1);
        }, 500);
        return () => clearTimeout(timer);
    }, [progress]);

    return (
        <Modal isOpen>
            <OneColumn data-testid="progress-bar">
                <h3>{text}</h3>
                <ProgressBar progress={progress} />
            </OneColumn>
        </Modal>
    );
};
