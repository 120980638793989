import { DeviceByTruckFragment } from '@daisy/data-access';
import {
    Filter,
    FilterGroup,
    ObjectQuery,
    QueryOperator,
    QueryToggle
} from '@daisy/middleware-redux';
import { flow, map, mapValues, omit, toNumber } from 'lodash';
import { nanoid } from 'nanoid';

export const operatorLookup = (operator: QueryOperator) => {
    const operators: { [key: string]: string } = {
        [QueryOperator.EQ]: '=',
        [QueryOperator.NE]: '!=',
        [QueryOperator.GT]: '>',
        [QueryOperator.LT]: '<',
        [QueryOperator.GTE]: '>=',
        [QueryOperator.LTE]: '<=',
        [QueryOperator.IN]: 'IN',
        [QueryOperator.NI]: 'NOT IN'
    };
    return operators[operator];
};

export const operatorSelectOptions = map(
    [
        QueryOperator.EQ,
        QueryOperator.NE,
        QueryOperator.GT,
        QueryOperator.LT,
        QueryOperator.GTE,
        QueryOperator.LTE,
        QueryOperator.IN,
        QueryOperator.NI
    ],
    (operator) => ({
        label: operatorLookup(operator),
        value: operator
    })
);

export const createFilterObject = (operator: QueryOperator, value: string): Filter => ({
    id: nanoid(),
    valueNumber: { [operator]: value }
});

export const getToggleKey = (object: Record<string, any>): string =>
    object
        ? Object.keys(object).filter((key) =>
              Object.values(QueryToggle).includes(key as QueryToggle)
          )[0]
        : '';

// Format data to correct object structure.
export const prepareQueryFilters = (
    mainObject: ObjectQuery,
    referenceObjects: ObjectQuery[],
    selectedXAxis: string
) => ({
    object: formatObjectForQuery(mainObject),
    referenceObjects: map(referenceObjects, (referenceObject) =>
        formatObjectForQuery(referenceObject)
    ),
    orderBy: {
        direction: 'ASC',
        value: selectedXAxis
    }
});

export const formatObjectSelectOptions = (
    devices: DeviceByTruckFragment[],
    softwareModelName: string
) =>
    devices.map((device) => ({
        label: `${device.name} (${softwareModelName})`,
        value: device.externalId,
        options: device.deviceObjects.map(({ uiName, externalId }) => ({
            value: externalId,
            label: uiName || ''
        }))
    }));

export const formatObjectForQuery = (object: ObjectQuery) => {
    const newQueryFilters = { ...object.where };
    const filterGroups = newQueryFilters[getToggleKey(newQueryFilters) as QueryToggle];

    newQueryFilters[getToggleKey(newQueryFilters) as QueryToggle] = flow([
        (x) => convertFilterValuesToNumber(x),
        (x) => pruneIds(x)
    ])(filterGroups);

    return {
        name: object.object.name,
        where: newQueryFilters
    };
};

// Convert values from string to number before sending them to backend.
export const convertFilterValuesToNumber = (filterGroups: FilterGroup[]) =>
    map(filterGroups, (filterGroup) => {
        const filters = filterGroup[getToggleKey(filterGroup) as QueryToggle];
        const newFilters = map(filters, (filterValue) => ({
            ...filterValue,
            valueNumber: mapValues(filterValue.valueNumber, (value) => toNumber(value))
        }));

        return {
            ...filterGroup,
            [getToggleKey(filterGroup)]: newFilters
        };
    });

// Remove ids from filter objects before sending them to the backend.
const pruneIds = (filterGroups: FilterGroup[]) =>
    map(filterGroups, (filterGroup) => {
        const filters = filterGroup[getToggleKey(filterGroup) as QueryToggle];
        const newFilters = map(filters, (filterValue) => omit(filterValue, 'id'));

        return omit({ ...filterGroup, [getToggleKey(filterGroup)]: newFilters }, 'id');
    });
