import { useDealersQuery } from '@daisy/data-access';
import { Multiselect, OnMultiselectChange, Select, SelectOption } from '@daisy/react-components';
import { Dispatch, SetStateAction, useMemo } from 'react';

import { OneColumn } from '@/components/styled/shared';

type BusinessIntelligenceFiltersProps = {
    selectedDealers: SelectOption[] | null;
    setSelectedDealers: Dispatch<SetStateAction<SelectOption[] | null>>;
    setSelectedTruckModel: Dispatch<SetStateAction<string | undefined>>;
    truckModelSelectOptions: SelectOption[];
};

export const BusinessIntelligenceFilters: React.FC<BusinessIntelligenceFiltersProps> = ({
    selectedDealers,
    setSelectedDealers,
    setSelectedTruckModel,
    truckModelSelectOptions
}) => {
    const { data: dealerData } = useDealersQuery();

    const onDealerChange: OnMultiselectChange = (options) => {
        if (options) {
            setSelectedDealers([...options]);
        }
    };

    const dealerSelectOptions: SelectOption[] = useMemo(
        () =>
            dealerData?.dealers?.map(({ name, uuid }: { name: string; uuid: string }) => ({
                value: uuid,
                label: name
            })) || [],
        [dealerData?.dealers]
    );

    return (
        <OneColumn invertedMobile>
            <Multiselect
                label="Dealer"
                placeholder="Select dealer(s)"
                name="dealer"
                inputId="select-dealer"
                value={selectedDealers}
                isAsync
                showIcon={false}
                onChange={onDealerChange}
                options={dealerSelectOptions}
            />
            {!!truckModelSelectOptions?.length && (
                <div>
                    <Select
                        label="Truck model"
                        placeholder="Select model"
                        isClearable
                        name="model"
                        showIcon={false}
                        handleChange={(option) =>
                            option
                                ? setSelectedTruckModel(option.value.toString())
                                : setSelectedTruckModel(undefined)
                        }
                        options={truckModelSelectOptions}
                    />
                </div>
            )}
        </OneColumn>
    );
};
