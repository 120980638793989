var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { faSearch, faTimes } from '@fortawesome/pro-solid-svg-icons';
import React from 'react';
import styled, { useTheme } from 'styled-components';
import { UnstyledButton } from '../Button/Button';
import { Icon } from '../Icon/Icon';
import { InputField } from '../Input/Input';
import { LoadingIndicator } from '../LoadingIndicator/LoadingIndicator';
export var InputContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: flex;\n    text-align: left;\n    transition: 0.1s all;\n    position: relative;\n    width: 100%;\n"], ["\n    display: flex;\n    text-align: left;\n    transition: 0.1s all;\n    position: relative;\n    width: 100%;\n"])));
var SearchInputField = styled(InputField)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    padding: 0.5rem 2.5rem 0.5rem ", ";\n    outline: 2px solid transparent;\n    transition: outline 0.1s ease-in;\n    width: 100%;\n\n    ", "\n\n    :focus {\n        outline: 2px solid ", ";\n    }\n"], ["\n    padding: 0.5rem 2.5rem 0.5rem ", ";\n    outline: 2px solid transparent;\n    transition: outline 0.1s ease-in;\n    width: 100%;\n\n    ", "\n\n    :focus {\n        outline: 2px solid ", ";\n    }\n"])), function (_a) {
    var showIcon = _a.showIcon;
    return (showIcon ? '2.5rem' : '0.75rem');
}, function (_a) {
    var isLoading = _a.isLoading;
    return isLoading && 'opacity: 0.5;';
}, function (_a) {
    var theme = _a.theme;
    return theme.palette.primary;
});
var Actions = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    display: flex;\n    gap: 1rem;\n    position: absolute;\n    right: 1rem;\n    top: 50%;\n    transform: translateY(-50%);\n"], ["\n    display: flex;\n    gap: 1rem;\n    position: absolute;\n    right: 1rem;\n    top: 50%;\n    transform: translateY(-50%);\n"])));
export var SearchInput = function (_a) {
    var _b;
    var style = _a.style, className = _a.className, name = _a.name, value = _a.value, _c = _a.placeholder, placeholder = _c === void 0 ? 'Search' : _c, inputRef = _a.inputRef, _d = _a.isInvalid, isInvalid = _d === void 0 ? false : _d, onChange = _a.onChange, onReset = _a.onReset, isLoading = _a.isLoading, showSubmit = _a.showSubmit, _e = _a.showIcon, showIcon = _e === void 0 ? true : _e, _f = _a.autoComplete, autoComplete = _f === void 0 ? 'off' : _f, _g = _a.autoFocus, autoFocus = _g === void 0 ? false : _g, rest = __rest(_a, ["style", "className", "name", "value", "placeholder", "inputRef", "isInvalid", "onChange", "onReset", "isLoading", "showSubmit", "showIcon", "autoComplete", "autoFocus"]);
    var theme = useTheme();
    return (React.createElement(InputContainer, { style: style, className: className },
        showIcon && (React.createElement(Icon, { icon: faSearch, color: theme.palette.texts.grey, style: {
                left: '0.75rem',
                position: 'absolute',
                top: '50%',
                transform: 'translateY(-50%)',
                zIndex: 1
            } })),
        React.createElement(SearchInputField, __assign({ showIcon: showIcon, isLoading: isLoading, name: name, placeholder: placeholder, value: value, type: "text", disabled: isLoading, isInvalid: isInvalid, onChange: function (event) { return onChange(event.target.value); }, autoComplete: autoComplete, autoFocus: autoFocus, ref: inputRef }, rest)),
        React.createElement(Actions, null,
            ((value && value !== '') || ((_b = inputRef === null || inputRef === void 0 ? void 0 : inputRef.current) === null || _b === void 0 ? void 0 : _b.value)) && onReset && (React.createElement(UnstyledButton, { type: "button", onClick: onReset, "data-testid": "reset-button" },
                React.createElement(Icon, { icon: faTimes, color: theme.palette.texts.grey, className: "fa-lg" }))),
            isLoading && React.createElement(LoadingIndicator, { className: "fa-1x" }),
            !isLoading && showSubmit && (React.createElement(UnstyledButton, { type: "submit" },
                React.createElement(Icon, { icon: faSearch, color: theme.palette.primary, className: "fa-lg", "data-testid": "search-icon" }))))));
};
var templateObject_1, templateObject_2, templateObject_3;
