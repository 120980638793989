import { useCallback, useEffect } from 'react';
export var useOutsideClick = function (ref, callback) {
    var handleClickOutside = useCallback(function (event) {
        if (ref.current && !ref.current.contains(event.target)) {
            callback();
        }
    }, [ref, callback]);
    useEffect(function () {
        document.addEventListener('click', handleClickOutside);
        return function () { return document.removeEventListener('click', handleClickOutside); };
    }, [ref, handleClickOutside]);
};
