var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import 'focus-visible';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import React from 'react';
import styled from 'styled-components';
import { Icon } from '../Icon/Icon';
var toastStates = {
    entering: { transform: 'translate3d(120%, 0, 0) scale(0.9)' },
    entered: { transform: 'translate3d(0, 0, 0) scale(1)' },
    exiting: { transform: 'translate3d(120%, 0, 0) scale(0.9)' },
    exited: { transform: 'translate3d(120%, 0, 0) scale(0.9)' }
};
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: flex;\n    align-items: center;\n    border-radius: 3px;\n\n    transition-property: transform;\n    transition-duration: 500ms;\n    transition-timing-function: cubic-bezier(0.2, 0, 0, 1);\n    ", "\n"], ["\n    display: flex;\n    align-items: center;\n    border-radius: 3px;\n\n    transition-property: transform;\n    transition-duration: 500ms;\n    transition-timing-function: cubic-bezier(0.2, 0, 0, 1);\n    ", "\n"])), function (_a) {
    var transitionState = _a.transitionState;
    return toastStates[transitionState];
});
var CloseButton = styled.button(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    display: flex;\n    position: absolute;\n    right: 24px;\n    background: transparent;\n    border: none;\n    color: rgba(255, 255, 255, 0.5);\n    cursor: pointer;\n\n    :focus:not(.focus-visible) {\n        outline: none;\n    }\n"], ["\n    display: flex;\n    position: absolute;\n    right: 24px;\n    background: transparent;\n    border: none;\n    color: rgba(255, 255, 255, 0.5);\n    cursor: pointer;\n\n    :focus:not(.focus-visible) {\n        outline: none;\n    }\n"])));
export var ToastContainer = function (_a) {
    var children = _a.children, transitionState = _a.transitionState, onDismiss = _a.onDismiss;
    return (React.createElement(Container, { transitionState: transitionState, "data-testid": "toast-container" },
        children,
        onDismiss && (React.createElement(CloseButton, { type: "button", onClick: onDismiss },
            React.createElement(Icon, { style: {
                    height: 16,
                    width: 16
                }, icon: faTimes })))));
};
var templateObject_1, templateObject_2;
