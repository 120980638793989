import {
    SerialNumberErrorHistorySummaryEventFragment,
    SerialNumberTtpFilesResultFragment,
    useGetSerialNumberErrorHistoryEventsSummaryQuery
} from '@daisy/data-access';
import { setSelectedEventCodes } from '@daisy/middleware-redux';
import { Button, Icon, P1, Suspense, Table, TableColumn, TableMain } from '@daisy/react-components';
import { faEye } from '@fortawesome/pro-solid-svg-icons';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import { TTPDownloadButton } from '@/components/Buttons/TTPDownloadButton';
import { DummyProgressBar } from '@/components/DummyProgressBar';
import { EmptyPieChartInfo } from '@/components/EmptyPieChartInfo';
import { ErrorHandler } from '@/components/ErrorHandler';
import { DatasetInfo } from '@/components/Infos/DatasetInfo';
import { ErrorEventInfo } from '@/components/Infos/ErrorEventInfo';
import { SerialNumberViewSkeleton } from '@/components/Skeletons/SerialNumberViewSkeleton';
import { TtpTags } from '@/components/TtpTag';
import { ProgressBarContainer, StyledChart } from '@/components/styled/shared';
import { useSerialNumber } from '@/hooks/useSerialNumber';
import { TwoColStackedLayout } from '@/layouts/TwoColStackedLayout';
import { RootState, useAppDispatch } from '@/store';
import { notEmpty, tableSortArray } from '@/utils/helpers';
import { createSeries, getOptions } from '../Charts/errorEventsChartConfig';

const getColumns = (serialNumber: string): TableColumn<SerialNumberTtpFilesResultFragment>[] => [
    { Header: 'Time', accessor: 'ttpFileTime' },
    { Header: 'Operating hours', accessor: 'operatingHours' },
    { Header: 'Version', accessor: 'truckToolVersion' },
    {
        Header: 'Tags',
        accessor: 'tags',
        sortType: tableSortArray,
        Cell: ({ row: { original } }: { row: { original: SerialNumberTtpFilesResultFragment } }) =>
            original?.tags ? <TtpTags tags={original.tags.filter(notEmpty)} /> : null
    },
    {
        Header: 'TTP',
        accessor: 'ttpFileExternalId',
        Cell: ({
            row: { original }
        }: {
            row: { original: SerialNumberTtpFilesResultFragment };
        }) => (
            <div style={{ display: 'flex', gap: '1rem' }}>
                <TTPDownloadButton
                    serialNumber={serialNumber}
                    ttpFile={{
                        time: original.ttpFileTime,
                        externalId: original.ttpFileExternalId || ''
                    }}
                    buttonText="Download"
                    size="small"
                />
                <Button
                    size="small"
                    href={`/serial-number/${serialNumber}/truck-usage/events?extFileId=${original.ttpFileExternalId}`}
                >
                    <Icon icon={faEye} />
                    View
                </Button>
            </div>
        )
    }
];

export const SerialNumberErrorHistoryEventsTabView = () => {
    const dispatch = useAppDispatch();
    const { serialNumber } = useSerialNumber();

    const [selectedEvent, setSelectedEvent] =
        useState<SerialNumberErrorHistorySummaryEventFragment | null>(null);

    const { uiLoading } = useSelector((state: RootState) => state.ui);

    const errorEventsQuery = useGetSerialNumberErrorHistoryEventsSummaryQuery({
        variables: { externalId: serialNumber as string },
        skip: !serialNumber,
        onCompleted: ({ serialNumberErrorHistoryEventsSummary: { errorHistoryEvents } }) => {
            if (errorHistoryEvents?.[0]) {
                setSelectedEvent(errorHistoryEvents[0]);

                dispatch(
                    setSelectedEventCodes([
                        {
                            uuid: errorHistoryEvents?.[0].eventUuid ?? '',
                            userId: errorHistoryEvents?.[0].eventCode ?? ''
                        }
                    ])
                );
            }
        }
    });

    const totalCount =
        errorEventsQuery?.data?.serialNumberErrorHistoryEventsSummary.totalCount || 0;
    const infoCount = errorEventsQuery?.data?.serialNumberErrorHistoryEventsSummary.infoCount || 0;
    const warningCount =
        errorEventsQuery?.data?.serialNumberErrorHistoryEventsSummary.warningCount || 0;
    const alarmCount =
        errorEventsQuery?.data?.serialNumberErrorHistoryEventsSummary.alarmCount || 0;
    const events =
        errorEventsQuery?.data?.serialNumberErrorHistoryEventsSummary?.errorHistoryEvents?.filter(
            notEmpty
        );
    const ttpFiles =
        errorEventsQuery?.data?.serialNumberErrorHistoryEventsSummary.ttpFiles?.filter(notEmpty);

    const isDataAvailable = !!events?.length;

    const handlePointClick = (selectedIndex: number) => {
        const newSelectedEvent = events?.[selectedIndex] || null;
        setSelectedEvent(newSelectedEvent);
        dispatch(
            setSelectedEventCodes([
                {
                    uuid: newSelectedEvent?.eventUuid || '',
                    userId: newSelectedEvent?.eventCode || ''
                }
            ])
        );
    };

    const isLoading = uiLoading || errorEventsQuery.loading;

    return (
        <>
            <ErrorHandler hasErrored={!!errorEventsQuery.error} />

            <Suspense condition={!isLoading} fallback={<ProgressBar />}>
                <TwoColStackedLayout
                    title="Events by occurrence"
                    isHeaderOutside
                    dataTestId="error-history-events-tab-content"
                >
                    <>
                        {events ? (
                            <StyledChart
                                options={getOptions(createSeries(events), handlePointClick)}
                                title={`<span>Top ${events?.length}<br/>events</span>`}
                            />
                        ) : (
                            <EmptyPieChartInfo />
                        )}
                    </>
                    <DatasetInfo
                        items={[
                            {
                                label: 'Total number of event codes',
                                value: totalCount
                            },
                            {
                                label: 'TTP files in table',
                                value: ttpFiles?.length || 0
                            },
                            {
                                label: 'Alarm events',
                                value: alarmCount
                            },
                            {
                                label: 'Warning events',
                                value: warningCount
                            },
                            {
                                label: 'Info events',
                                value: infoCount
                            }
                        ]}
                    />
                    <>
                        <div>
                            {selectedEvent && (
                                <ErrorEventInfo
                                    linkTo={`/serial-number/${serialNumber}/error-code/${selectedEvent?.eventCode}`}
                                    linkDisabled={!selectedEvent?.eventCode}
                                    eventName={selectedEvent?.eventName || ''}
                                    eventClass={selectedEvent?.eventClass || ''}
                                    time={selectedEvent.time || ''}
                                    eventCode={selectedEvent.eventCode || ''}
                                    eventDevice={selectedEvent.eventDevice || ''}
                                />
                            )}
                            {!selectedEvent && isDataAvailable && (
                                <P1>Click on a slice to see the event info.</P1>
                            )}
                        </div>
                        <hr />
                        <div>
                            {serialNumber && !!ttpFiles?.length && (
                                <Table<SerialNumberTtpFilesResultFragment>
                                    columns={getColumns(serialNumber) as any}
                                    data={ttpFiles}
                                    defaultSorting={[{ id: 'ttpFileTime', desc: true }]}
                                >
                                    <TableMain
                                        isSortable
                                        highlightedCells={selectedEvent?.ttpExternalIds as any}
                                        highlightedCellName="ttpFileTime"
                                        // NOTE: use "time" instead of "externalId" until the TTP duplication issue has been solved
                                        //highlightedCellName="externalId"
                                    />
                                </Table>
                            )}
                        </div>
                    </>
                </TwoColStackedLayout>
            </Suspense>
        </>
    );
};

const ProgressBar = () => (
    <div>
        <ProgressBarContainer>
            <DummyProgressBar initialProgress={0} />
        </ProgressBarContainer>
        <SerialNumberViewSkeleton />
    </div>
);
