var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import 'focus-visible';
import * as React from 'react';
import styled, { css } from 'styled-components';
var StyledButton = styled.button(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    align-items: center;\n    background: ", ";\n    border-color: transparent;\n    border-radius: 8px;\n    box-shadow: ", ";\n    color: #fff;\n    cursor: pointer;\n    display: flex;\n    font-family: 'Roboto', sans-serif;\n    font-size: ", ";\n    font-weight: 500;\n    gap: ", ";\n    justify-content: center;\n    letter-spacing: 0.3px;\n    line-height: 1;\n    padding: ", ";\n    transition: 0.1s all;\n\n    :focus:not(.focus-visible) {\n        outline: none;\n    }\n\n    :hover {\n        opacity: 0.8;\n    }\n\n    ", "\n\n    ", "\n\n    ", "\n"], ["\n    align-items: center;\n    background: ", ";\n    border-color: transparent;\n    border-radius: 8px;\n    box-shadow: ", ";\n    color: #fff;\n    cursor: pointer;\n    display: flex;\n    font-family: 'Roboto', sans-serif;\n    font-size: ", ";\n    font-weight: 500;\n    gap: ", ";\n    justify-content: center;\n    letter-spacing: 0.3px;\n    line-height: 1;\n    padding: ", ";\n    transition: 0.1s all;\n\n    :focus:not(.focus-visible) {\n        outline: none;\n    }\n\n    :hover {\n        opacity: 0.8;\n    }\n\n    ", "\n\n    ", "\n\n    ", "\n"])), function (_a) {
    var theme = _a.theme;
    return theme.palette.primary;
}, function (_a) {
    var theme = _a.theme;
    return theme.palette.backgrounds.buttons.shadow;
}, function (_a) {
    var size = _a.size;
    return (size === 'small' ? '13px' : '15px');
}, function (_a) {
    var size = _a.size;
    return (size === 'small' ? '0.4rem' : '0.6rem');
}, function (_a) {
    var size = _a.size;
    return (size === 'small' ? '0.5rem 0.7rem' : '0.6rem 0.8rem');
}, function (props) {
    return !props.isPrimary && css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n            color: ", ";\n            background: #fff;\n            font-weight: 400;\n            border: 1px solid #ddd;\n\n            &:hover {\n                background: #eee;\n                opacity: 1;\n            }\n        "], ["\n            color: ", ";\n            background: #fff;\n            font-weight: 400;\n            border: 1px solid #ddd;\n\n            &:hover {\n                background: #eee;\n                opacity: 1;\n            }\n        "])), function (_a) {
        var theme = _a.theme;
        return theme.palette.texts.black;
    });
}, function (props) {
    return props.isDark && css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n            color: ", ";\n            background: ", ";\n        "], ["\n            color: ", ";\n            background: ", ";\n        "])), function (_a) {
        var theme = _a.theme;
        return theme.palette.texts.white;
    }, function (_a) {
        var theme = _a.theme;
        return theme.palette.backgrounds.buttons.dark;
    });
}, function (props) {
    return props.disabled && css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n            opacity: 0.4;\n            pointer-events: none;\n        "], ["\n            opacity: 0.4;\n            pointer-events: none;\n        "])));
});
export var Button = function (_a) {
    var style = _a.style, className = _a.className, children = _a.children, _b = _a.isPrimary, isPrimary = _b === void 0 ? true : _b, isDark = _a.isDark, disabled = _a.disabled, _c = _a.type, type = _c === void 0 ? 'button' : _c, id = _a.id, icon = _a.icon, onClick = _a.onClick, _d = _a.size, size = _d === void 0 ? 'default' : _d, href = _a.href, rest = __rest(_a, ["style", "className", "children", "isPrimary", "isDark", "disabled", "type", "id", "icon", "onClick", "size", "href"]);
    return (React.createElement(StyledButton, __assign({ as: href ? 'a' : undefined, style: style, className: className, isPrimary: isPrimary, isDark: isDark, onClick: onClick, href: href, disabled: disabled, type: type, id: id, size: size }, rest),
        icon,
        children));
};
export var UnstyledButton = styled.button(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    align-items: flex-end;\n    background-color: transparent;\n    border: none;\n    box-shadow: none;\n    color: unset;\n    cursor: pointer;\n    display: flex;\n    font-family: inherit;\n    font-size: unset;\n    gap: 0.6rem;\n    margin: 0;\n    outline: none;\n    padding: 0;\n    text-align: left;\n"], ["\n    align-items: flex-end;\n    background-color: transparent;\n    border: none;\n    box-shadow: none;\n    color: unset;\n    cursor: pointer;\n    display: flex;\n    font-family: inherit;\n    font-size: unset;\n    gap: 0.6rem;\n    margin: 0;\n    outline: none;\n    padding: 0;\n    text-align: left;\n"])));
export var LinkButton = styled(UnstyledButton)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    color: ", ";\n\n    &:hover {\n        color: ", ";\n    }\n"], ["\n    color: ", ";\n\n    &:hover {\n        color: ", ";\n    }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.palette.primary;
}, function (_a) {
    var theme = _a.theme;
    return theme.palette.primaryDarker;
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
