"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setRouteSerialNumber = exports.setComparisons = exports.setSelectedEventCodes = exports.setSelectedSerialNumbers = exports.initialState = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const redux_persist_1 = require("redux-persist");
exports.initialState = {
    routeSerialNumber: undefined,
    selectedEventCodes: [],
    selectedSerialNumbers: [],
    comparisons: null,
    loading: false,
    error: undefined
};
const serialNumberSlice = (0, toolkit_1.createSlice)({
    name: 'serialNumber',
    initialState: exports.initialState,
    reducers: {
        setSelectedSerialNumbers: (state, action) => {
            state.selectedSerialNumbers = action.payload;
        },
        setSelectedEventCodes: (state, action) => {
            state.selectedEventCodes = action.payload;
        },
        setComparisons: (state, action) => {
            state.comparisons = action.payload;
        },
        setRouteSerialNumber: (state, action) => {
            state.routeSerialNumber = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(redux_persist_1.PURGE, (state) => {
            state.selectedSerialNumbers = exports.initialState.selectedSerialNumbers;
            state.selectedEventCodes = exports.initialState.selectedEventCodes;
        });
    }
});
_a = serialNumberSlice.actions, exports.setSelectedSerialNumbers = _a.setSelectedSerialNumbers, exports.setSelectedEventCodes = _a.setSelectedEventCodes, exports.setComparisons = _a.setComparisons, exports.setRouteSerialNumber = _a.setRouteSerialNumber;
exports.default = serialNumberSlice.reducer;
