import { useAuth0 } from '@auth0/auth0-react';
import { FeedbackType, useSendFeedbackMutation } from '@daisy/data-access';
import {
    LoadingIndicator,
    Modal,
    ModalProps,
    Select,
    SelectOption,
    Suspense,
    TextArea,
    ToastContent
} from '@daisy/react-components';
import { isEmpty } from 'lodash';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import styled from 'styled-components';

import { ErrorHandler } from '../components/ErrorHandler';

type FeedbackModalProps = ModalProps & {
    title: string;
};

const feedbackTypes = [
    { label: 'Feedback', value: FeedbackType.Feedback },
    { label: 'Feature suggestion', value: FeedbackType.Feature },
    { label: 'Issue', value: FeedbackType.Issue }
];

const SelectContainer = styled.div`
    margin-top: 1.25em;

    > p {
        font-family: 'Lato';
        font-size: 14px;
    }
`;

const TextAreaContainer = styled.div`
    margin: 1.25em 0;
`;

const LoadingIndicatorWrapper = styled.div`
    height: 260px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const FeedbackModal = ({ isOpen, title, onClose }: FeedbackModalProps) => {
    const { user } = useAuth0();
    const { pathname } = useLocation();
    const { addToast } = useToasts();

    const [selectedFeedbackType, setSelectedFeedbackType] = useState<SelectOption<FeedbackType>>(
        feedbackTypes[0]
    );
    const [messageValue, setMessageValue] = useState<string>('');
    const [validationError, setValidationError] = useState<boolean>(false);
    const [feedbackLoading, setFeedbackLoading] = useState<boolean>(false);

    const [createFeedback] = useSendFeedbackMutation();

    const handleSendFeedback = () => {
        if (isEmpty(messageValue)) {
            setValidationError(true);
            return;
        }
        setFeedbackLoading(true);
        createFeedback({
            variables: {
                input: {
                    createdBy: user?.name as string,
                    context: `Application: Truck Data Analyzer, location: ${pathname}`,
                    type: selectedFeedbackType.value,
                    content: messageValue.trim()
                }
            }
        })
            .then(() => {
                setFeedbackLoading(false);
                addToast(<ToastContent type="success" description="Feedback sent" />);
                handleClose();
            })
            .catch((error) => {
                setFeedbackLoading(false);
                addToast(
                    <ToastContent
                        type="error"
                        description="Something went wrong, please try again."
                    />
                );
            });
    };

    const resetFields = () => {
        setSelectedFeedbackType(feedbackTypes[0]);
        setMessageValue('');
        setValidationError(false);
    };

    const handleClose = () => {
        resetFields();
        onClose();
    };

    return (
        <Modal
            isOpen={isOpen}
            title={title}
            actionButtonText="Send feedback"
            onClose={handleClose}
            shouldCloseOnOverlayClick={false}
            onCancelButtonClick={handleClose}
            onActionButtonClick={handleSendFeedback}
        >
            <div>
                <Suspense
                    condition={!feedbackLoading}
                    fallback={
                        <LoadingIndicatorWrapper>
                            <LoadingIndicator />
                        </LoadingIndicatorWrapper>
                    }
                >
                    <ErrorHandler
                        hasErrored={!!validationError}
                        errorMessage="Message can not be empty"
                    />
                    <SelectContainer>
                        <p>Select feedback type</p>
                        <Select<FeedbackType>
                            name="feedbackType"
                            value={selectedFeedbackType}
                            handleChange={(option) => {
                                if (option) {
                                    setSelectedFeedbackType(option);
                                }
                            }}
                            defaultOptions={feedbackTypes}
                            options={feedbackTypes}
                        />
                    </SelectContainer>
                    <TextAreaContainer>
                        <TextArea
                            id="feedback-message"
                            value={messageValue}
                            label="Message"
                            onChange={(value: string) => setMessageValue(value)}
                            placeholder="Give feedback"
                        />
                    </TextAreaContainer>
                </Suspense>
            </div>
        </Modal>
    );
};
