import { NumericMeasurementFragment, useGetLiveDataQuery } from '@daisy/data-access';
import { Suspense, Table, TableColumn, TableMain, TablePager } from '@daisy/react-components';
import { find, isEmpty } from 'lodash';
import { Map, Marker } from 'pigeon-maps';
import { useSelector } from 'react-redux';

import { DummyProgressBar } from '@/components/DummyProgressBar';
import { ErrorHandler } from '@/components/ErrorHandler';
import { DatasetInfo } from '@/components/Infos/DatasetInfo';
import { SerialNumberViewSkeleton } from '@/components/Skeletons/SerialNumberViewSkeleton';
import { ProgressBarContainer } from '@/components/styled/shared';
import { TwoColStackedLayout } from '@/layouts/TwoColStackedLayout';
import { RootState } from '@/store';
import { notEmpty } from '@/utils/helpers';

const columns: TableColumn<NumericMeasurementFragment>[] = [
    { Header: 'Label', accessor: 'label', id: 'label' },
    { Header: 'Time', accessor: 'time', id: 'time' },
    { Header: 'Value', accessor: 'value', id: 'value' }
];

export const SerialNumberLiveDataTabView = () => {
    const { uiLoading } = useSelector((state: RootState) => state.ui);
    const liveDataQuery = useGetLiveDataQuery({
        variables: {
            machineId: '221534'
        },
        fetchPolicy: 'no-cache'
    });

    const renderProgressBar = () => (
        <div>
            <ProgressBarContainer>
                <DummyProgressBar initialProgress={0} />
            </ProgressBarContainer>
            <SerialNumberViewSkeleton />
        </div>
    );

    const isLoading = uiLoading || liveDataQuery.loading;
    const numericData = liveDataQuery.data?.liveData?.numeric?.filter(notEmpty);

    let latitude = 0;
    let longitude = 0;
    if (!isEmpty(numericData)) {
        latitude = find(numericData, { label: 'value.common.machine.geo.latitude' })?.value || 0;
        longitude = find(numericData, { label: 'value.common.machine.geo.longitude' })?.value || 0;
    }

    return (
        <>
            <ErrorHandler hasErrored={!!liveDataQuery.error} />

            <Suspense condition={!isLoading} fallback={renderProgressBar()}>
                <TwoColStackedLayout
                    title="Live Data"
                    isHeaderOutside
                    dataTestId="live-data-tab-content"
                >
                    <div>
                        <Map height={300} defaultCenter={[latitude, longitude]} defaultZoom={11}>
                            <Marker width={50} anchor={[latitude, longitude]} />
                        </Map>
                    </div>
                    <DatasetInfo
                        items={[
                            {
                                label: 'Latitude',
                                value: latitude
                            },
                            {
                                label: 'Longitude',
                                value: longitude
                            }
                        ]}
                    />
                    {numericData && (
                        <Table
                            columns={columns}
                            data={numericData}
                            defaultSorting={[{ id: 'time', desc: true }]}
                        >
                            <TableMain isSortable />
                            <TablePager />
                        </Table>
                    )}
                </TwoColStackedLayout>
            </Suspense>
        </>
    );
};
