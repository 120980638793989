import { SerialNumberTelematicsUsageTimesFragment } from '@daisy/data-access';
import { map } from 'lodash';

import { getChartColor, notEmpty } from '@/utils/helpers';
import memoize from '@/utils/memoize';

export const getOptions = (
    data: SerialNumberTelematicsUsageTimesFragment['data'] | null,
    handlePointClick: (x: number, y: number) => void,
    extFileIds: string[],
    deviceIds: string[]
): Highcharts.Options => {
    const xAxisCategories = createXAxisCategories(data);

    return {
        chart: {
            animation: false
        },
        legend: {
            enabled: false
        },
        title: {
            margin: 40
        },
        yAxis: {
            min: 0,
            // max: maxBy(data?.[0], 'value'),
            title: {
                text: 'Hours'
            }
        },
        xAxis: {
            title: {
                text: ''
            },
            min: 0,
            gridLineWidth: 0,
            categories: xAxisCategories
        },
        plotOptions: {
            line: {
                dataLabels: {
                    enabled: true
                }
            },
            column: {
                pointWidth: 20,
                allowPointSelect: true,
                states: { select: { color: '#F39649', borderColor: 'none' } },
                events: {
                    click(e) {
                        // TODO: Use relevant info here instead of x and y.
                        // Probably need to add a "keys" field to createSeries.
                        handlePointClick(e.point.x, e.point.y ?? 0);
                    }
                }
            }
        },
        series: createSeries(data, extFileIds, deviceIds)
    };
};

// We should populate every field from backend (even if they are null)
// so we don't have to iterate through the whole data for categories.
const createXAxisCategories = memoize(
    (data: SerialNumberTelematicsUsageTimesFragment['data']): string[] => {
        if (data?.[0]?.chartValues?.length) return map(data[0]['chartValues'], 'key');
        return [];
    }
);

const createSeries = (
    data: SerialNumberTelematicsUsageTimesFragment['data'] | null,
    extFileIds: string[],
    deviceIds: string[]
): Highcharts.SeriesOptionsType[] => {
    const mappedSeries = data?.map((dataPointValue, dataPointIndex) => ({
        stack: dataPointIndex,
        type: 'column',
        name: 'Usage time',
        data: map(
            dataPointValue?.chartValues?.filter(notEmpty),
            ({ value, header, ttpexternalid }) => {
                const subIndex = header ? deviceIds?.indexOf(header) : undefined;
                return {
                    y: value,
                    color: getChartColor(extFileIds.indexOf(ttpexternalid || ''), subIndex)
                };
            }
        )
    }));

    return mappedSeries as any;
};
