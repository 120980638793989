import React from 'react';
import { useFeatureFlags } from '@/hooks/useFeatureFlags';

type FeatureGuardPropsType = {
    feature: string;
    children: React.ReactNode;
};

export const FeatureFlagGuard = ({ feature, children }: FeatureGuardPropsType) => {
    const flags = useFeatureFlags();

    if (flags?.[feature]) {
        return <>{children}</>;
    }
    return null;
};
