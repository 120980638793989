var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import { motion } from 'framer-motion';
import { useMemo } from 'react';
import { animTransition } from './utils';
export var ACollapse = function (_a) {
    var children = _a.children, _b = _a.initial, initial = _b === void 0 ? 'collapsed' : _b, openDelay = _a.openDelay, transition = _a.transition, rest = __rest(_a, ["children", "initial", "openDelay", "transition"]);
    var variants = useMemo(function () { return ({
        collapsed: {
            opacity: 0,
            height: 0,
            overflow: 'hidden',
            transitionEnd: { overflow: 'visible' }
        },
        open: __assign(__assign({ opacity: 1, height: 'auto' }, (openDelay && { transition: { delay: openDelay } })), { overflow: 'hidden', transitionEnd: { overflow: 'visible' } })
    }); }, [openDelay]);
    return (React.createElement(motion.div, __assign({ initial: initial, animate: "open", exit: "collapsed", variants: variants, transition: __assign(__assign({}, animTransition), transition) }, rest), children));
};
