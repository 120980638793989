import {
    GetSerialNumbersByTruckModelQuery,
    SerialNumberByTruckModelFragment,
    useGetSerialNumbersByTruckModelLazyQuery
} from '@daisy/data-access';
import { Button, Card, Icon, Link, Table, TableMain, TablePager } from '@daisy/react-components';
import { useCallback, useState } from 'react';
import { faEye } from '@fortawesome/pro-solid-svg-icons';

import { TTPDownloadButton } from '@/components/Buttons/TTPDownloadButton';
import { ErrorHandler } from '@/components/ErrorHandler';
import { TtpTags } from '@/components/TtpTag';
import { useTruckModel } from '@/hooks/useTruckModel';
import { getImagePath, notEmpty } from '@/utils/helpers';

export const TruckModelSerialNumbersTabView = () => {
    const { truckModel } = useTruckModel();

    const [loadedData, setLoadedData] = useState<GetSerialNumbersByTruckModelQuery>();

    const [getSerialNumbers, { loading: isLoading, error }] =
        useGetSerialNumbersByTruckModelLazyQuery();

    const fetchFunction = useCallback(
        async ({ pageIndex, pageSize }: { pageIndex: number; pageSize: number }) => {
            await getSerialNumbers({
                variables: {
                    uuid: truckModel?.uuid || '',
                    offset: pageIndex * pageSize,
                    limit: pageSize
                },
                onCompleted: (result) => {
                    setLoadedData(result);
                }
            });
        },
        [getSerialNumbers, truckModel?.uuid]
    );

    return (
        <>
            <ErrorHandler hasErrored={!!error} />
            <Card title="Serial Numbers" isTransparent dataTestId="serial-numbers-tab-content">
                <Table
                    data={loadedData?.serialNumbersByTruckModel?.data || []}
                    columns={columns as any}
                    pageSize={initPageSize}
                    pageCount={
                        loadedData?.serialNumbersByTruckModel?.count
                            ? Math.ceil(loadedData?.serialNumbersByTruckModel?.count / initPageSize)
                            : undefined
                    }
                    fetchData={fetchFunction}
                    isLoading={isLoading}
                >
                    <TableMain isLoading={isLoading} />
                    <TablePager />
                </Table>
            </Card>
        </>
    );
};

const initPageSize = 100;

type CellProps = {
    row: { original: SerialNumberByTruckModelFragment };
};

const ImageCell = ({ row: { original } }: CellProps) => (
    <img
        style={{ margin: '-10px 0' }}
        height={35}
        src={getImagePath(original.hierarchy?.imagePath)}
        alt={original.hierarchy?.name}
    />
);

const SerialNumberCell = ({ row: { original } }: CellProps) => (
    <Link href={`/serial-number/${original.externalId}/error-history-events`}>
        {original.externalId}
    </Link>
);

const TagsCell = ({ row: { original } }: CellProps) =>
    original.latestTtpFile?.tags ? (
        <TtpTags tags={original.latestTtpFile.tags.filter(notEmpty)} />
    ) : null;

const TTPFileCell = ({ row: { original } }: CellProps) =>
    original.latestTtpFile && (
        <div style={{ display: 'flex', gap: '1rem' }}>
            <TTPDownloadButton
                serialNumber={original.externalId}
                ttpFile={original.latestTtpFile}
                buttonText="Download"
                size="small"
            />
            <Button
                size="small"
                href={`/serial-number/${original.externalId}/truck-usage/events?extFileId=${original.latestTtpFile.externalId}`}
            >
                <Icon icon={faEye} />
                View
            </Button>
        </div>
    );

const columns = [
    {
        accessor: 'hierarchy.imagePath',
        canSort: false,
        Cell: ImageCell
    },
    {
        Header: 'Serial number',
        accessor: 'externalId',
        Cell: SerialNumberCell
    },
    {
        Header: 'Brand model',
        accessor: 'hierarchy.name'
    },
    {
        Header: 'Latest TTP time',
        accessor: 'latestTtpFile.time'
    },
    { Header: 'Version', accessor: 'latestTtpFile.truckToolVersion' },
    {
        Header: 'Tags',
        accessor: 'latestTtpFile.tags',
        Cell: TagsCell
    },
    {
        Header: 'TTP File',
        accessor: 'latestTtpFile',
        id: 'latestTtpFile',
        Cell: TTPFileCell
    }
];
