var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { AnimatePresence } from 'framer-motion';
import { Icon } from '../Icon/Icon';
import { IconButton } from '../IconButton/IconButton';
import { ACollapse } from '../Animations';
var AccordionContext = createContext(null);
function useAccordionContext() {
    return useContext(AccordionContext);
}
var StyledAccordionWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: flex;\n    flex-direction: column;\n    ", ";\n"], ["\n    display: flex;\n    flex-direction: column;\n    ", ";\n"])), function (_a) {
    var gapSize = _a.gapSize, theme = _a.theme;
    switch (gapSize) {
        case 'small':
            return 'gap: 1rem';
        case 'default':
            return 'gap: 1.5rem';
        case 'large':
            return 'gap: 2rem';
        default:
            return "\n                    border: 1px solid ".concat(theme.palette.border, ";\n                    border-radius: 8px; \n                    overflow: hidden;\n                    ");
    }
});
export var AccordionWrapper = function (_a) {
    var children = _a.children, gapSize = _a.gapSize, accordionMode = _a.accordionMode;
    var _b = useState(''), activeItem = _b[0], setActiveItem = _b[1];
    var accordionContextValue = useMemo(function () { return ({
        accordionMode: accordionMode,
        activeItem: activeItem,
        setActiveItem: setActiveItem,
        gapSize: gapSize
    }); }, [accordionMode, activeItem, setActiveItem, gapSize]);
    return (React.createElement(AccordionContext.Provider, { value: accordionContextValue },
        React.createElement(StyledAccordionWrapper, { gapSize: gapSize }, children)));
};
var AccordionContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    font-family: ", ";\n    color: ", ";\n    display: flex;\n    flex-direction: column;\n    background: #fff;\n\n    ", "\n"], ["\n    font-family: ", ";\n    color: ", ";\n    display: flex;\n    flex-direction: column;\n    background: #fff;\n\n    ", "\n"])), function (_a) {
    var theme = _a.theme;
    return theme.fonts.families.content;
}, function (_a) {
    var theme = _a.theme;
    return theme.palette.texts.black;
}, function (_a) {
    var withBorder = _a.withBorder, theme = _a.theme;
    if (!withBorder) {
        return "\n                box-shadow: ".concat(theme.palette.accordion.shadow, ";\n                border-radius: 8px;\n                ");
    }
    return "\n            border-bottom: 1px solid #ddd;\n            &:last-child {\n                border-bottom: none;\n            }";
});
var AccordionTitleArea = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    align-items: center;\n    display: flex;\n    justify-content: space-between;\n    font-size: ", "\n    padding: 0 1.5rem;\n    ", "\n"], ["\n    align-items: center;\n    display: flex;\n    justify-content: space-between;\n    font-size: ", "\n    padding: 0 1.5rem;\n    ", "\n"])), function (_a) {
    var size = _a.size;
    switch (size) {
        case 'small':
            return '13px;';
        case 'large':
            return '15px;';
        default:
            return '14px;';
    }
}, function (_a) {
    var isActive = _a.isActive, theme = _a.theme;
    return isActive && "border-bottom: 1px solid ".concat(theme.palette.border);
});
var AccordionClickableArea = styled.button(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    align-items: center;\n    display: flex;\n    flex: 1;\n    gap: 1em;\n    cursor: pointer;\n    user-select: none;\n    overflow: hidden;\n    text-overflow: ellipsis;\n\n    padding: ", ";\n"], ["\n    align-items: center;\n    display: flex;\n    flex: 1;\n    gap: 1em;\n    cursor: pointer;\n    user-select: none;\n    overflow: hidden;\n    text-overflow: ellipsis;\n\n    padding: ", ";\n"])), function (_a) {
    var size = _a.size;
    switch (size) {
        case 'small':
            return '0.75rem 0;';
        case 'large':
            return '1.5rem 0;';
        default:
            return '1rem 0;';
    }
});
var AccordionContent = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    padding: 1.5rem;\n"], ["\n    padding: 1.5rem;\n"])));
export var Accordion = function (_a) {
    var name = _a.name, className = _a.className, style = _a.style, title = _a.title, children = _a.children, _b = _a.isDark, isDark = _b === void 0 ? false : _b, _c = _a.initActive, initActive = _c === void 0 ? false : _c, _d = _a.isDeletable, isDeletable = _d === void 0 ? false : _d, _e = _a.size, size = _e === void 0 ? 'default' : _e, onClick = _a.onClick, onDeleteClick = _a.onDeleteClick;
    var accordionContext = useAccordionContext();
    var _f = useState(false), isActive = _f[0], setIsActive = _f[1];
    // initActive handling
    useEffect(function () {
        if (initActive) {
            if (name && (accordionContext === null || accordionContext === void 0 ? void 0 : accordionContext.accordionMode)) {
                accordionContext.setActiveItem(name);
            }
            else {
                setIsActive(true);
            }
        }
    }, [
        accordionContext,
        initActive,
        name,
        accordionContext === null || accordionContext === void 0 ? void 0 : accordionContext.accordionMode,
        accordionContext === null || accordionContext === void 0 ? void 0 : accordionContext.setActiveItem
    ]);
    // Local state handling when parent context with accordionMode
    useEffect(function () {
        if ((accordionContext === null || accordionContext === void 0 ? void 0 : accordionContext.accordionMode) && name)
            setIsActive(accordionContext.activeItem === name);
    }, [accordionContext === null || accordionContext === void 0 ? void 0 : accordionContext.accordionMode, accordionContext === null || accordionContext === void 0 ? void 0 : accordionContext.activeItem, name]);
    var handleClick = useCallback(function () {
        if (name && (accordionContext === null || accordionContext === void 0 ? void 0 : accordionContext.accordionMode)) {
            // accordionMode: use parent/context state
            accordionContext.setActiveItem(name === accordionContext.activeItem ? '' : name);
        }
        else {
            // No accordionMode: use local state
            setIsActive(!isActive);
        }
        if (onClick)
            onClick();
    }, [accordionContext, name, isActive, onClick]);
    return (React.createElement(AccordionContainer, { className: className, style: style, isDark: isDark, withBorder: !(accordionContext === null || accordionContext === void 0 ? void 0 : accordionContext.gapSize) },
        React.createElement(AccordionTitleArea, { isActive: isActive, size: size },
            React.createElement(AccordionClickableArea, { onClick: handleClick, size: size },
                React.createElement("span", null,
                    React.createElement(Icon, { className: "fa-sm", icon: faChevronDown, style: {
                            transform: "rotate(".concat(!isActive ? '-90deg' : '0deg', ")"),
                            transition: 'transform 0.2s'
                        } })),
                React.createElement("span", null, title)),
            isDeletable && onDeleteClick && (React.createElement("div", { style: {
                    width: '1.125em',
                    height: '1.125em',
                    display: 'inline-flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '0 0.5em'
                } },
                React.createElement(IconButton, { icon: "remove", onClick: onDeleteClick, isTransparent: true, isWhiteIcon: isDark })))),
        React.createElement(AnimatePresence, { initial: false }, isActive && (React.createElement(ACollapse, null,
            React.createElement(AccordionContent, null, children))))));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
