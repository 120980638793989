import { H1, ToastContent } from '@daisy/react-components';
import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const ContentContainer = styled.article`
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    padding: 1em;
`;

export const Title = styled(H1)`
    margin-bottom: 1em;
`;

export const FiltersAndContent = styled.div`
    display: flex;
    width: 100%;
`;

export const TitleArea = styled.div`
    display: flex;
    align-items: center;
    padding: 0 2.5em;
`;

export const LargeDatasetToastContent = styled(ToastContent)`
    margin: 0 0 0.5em;
`;

export const TTPInfoContainer = styled.div`
    flex: 1;
    margin: 1em 1em 0 0;
    padding: 0.5em 0 0 0;
    min-width: 15em;
`;

export const InfoContainer = styled.div`
    margin-top: 0.5em;
`;
