import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import styled, { useTheme } from 'styled-components';

import { PieChartSkeleton } from './PieChartSkeleton';

const TableContent = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1em;
    flex: 1;
`;

const ActionContainer = styled.div`
    display: flex;
    justify-content: space-between;
    padding-right: 1em;
    margin-bottom: 1em;
`;

const TableContainer = styled.div`
    padding: 1em;
`;

export const SerialNumberViewSkeleton = () => {
    const theme = useTheme();

    return (
        <div>
            <SkeletonTheme
                baseColor={theme.palette.skeletons.chart.color}
                highlightColor={theme.palette.skeletons.chart.highlightColor}
            >
                <div
                    style={{
                        display: 'flex',
                        marginTop: '2em',
                        justifyContent: 'center',
                        padding: '0 1em'
                    }}
                >
                    <PieChartSkeleton />

                    <Skeleton height={300} width={500} style={{ marginLeft: '6em' }} />
                </div>
                <TableContent>
                    <Skeleton width={100} style={{ marginBottom: '1em' }} />

                    <ActionContainer>
                        <Skeleton width={150} height={30} />
                        <Skeleton width={100} height={30} />
                    </ActionContainer>

                    <TableContainer>
                        <Skeleton height={300} />
                    </TableContainer>
                </TableContent>
            </SkeletonTheme>
        </div>
    );
};
