var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
var TextAreaContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: flex;\n    flex-direction: column;\n    position: relative;\n    width: 100%;\n    font-family: 'Lato';\n    font-size: 14px;\n"], ["\n    display: flex;\n    flex-direction: column;\n    position: relative;\n    width: 100%;\n    font-family: 'Lato';\n    font-size: 14px;\n"])));
var Label = styled.label(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    margin-bottom: 10px;\n"], ["\n    margin-bottom: 10px;\n"])));
var StyledTextArea = styled.textarea(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    position: relative;\n    padding: 10px;\n    border-radius: 2px;\n    border: 1px solid ", ";\n    resize: ", ";\n    font-family: 'Lato';\n    font-size: 14px;\n\n    &:focus {\n        outline: none;\n\n        + span:after {\n            content: '';\n            position: absolute;\n            bottom: 0;\n            background-color: ", ";\n            height: ", ";\n            width: 100%;\n        }\n    }\n"], ["\n    position: relative;\n    padding: 10px;\n    border-radius: 2px;\n    border: 1px solid ", ";\n    resize: ", ";\n    font-family: 'Lato';\n    font-size: 14px;\n\n    &:focus {\n        outline: none;\n\n        + span:after {\n            content: '';\n            position: absolute;\n            bottom: 0;\n            background-color: ", ";\n            height: ", ";\n            width: 100%;\n        }\n    }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.palette.defaultBorder;
}, function (_a) {
    var isResizable = _a.isResizable;
    return (isResizable ? 'both' : 'none');
}, function (_a) {
    var theme = _a.theme;
    return theme.palette.primary;
}, function (_a) {
    var isResizable = _a.isResizable;
    return (isResizable ? '0' : '2px;');
});
var HackSpan = styled.span(templateObject_4 || (templateObject_4 = __makeTemplateObject([""], [""])));
export var TextArea = function (_a) {
    var style = _a.style, className = _a.className, id = _a.id, value = _a.value, label = _a.label, name = _a.name, _b = _a.rows, rows = _b === void 0 ? 5 : _b, columns = _a.columns, placeholder = _a.placeholder, onChange = _a.onChange, _c = _a.isResizable, isResizable = _c === void 0 ? false : _c;
    return (React.createElement(TextAreaContainer, { style: style, className: className },
        label && React.createElement(Label, { htmlFor: id }, label),
        React.createElement(StyledTextArea, { id: id, name: name, rows: rows, cols: columns, value: value, placeholder: placeholder, onChange: function (event) {
                return onChange(event.target.value);
            }, isResizable: isResizable, "data-testid": "textarea" }),
        React.createElement(HackSpan, null)));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
