var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
/* eslint-disable @typescript-eslint/ban-types */
import React from 'react';
import styled from 'styled-components';
import { GlobalSearchFilter } from './Filters/GlobalSearchFilter';
export function TableHeader(_a) {
    var searchLabel = _a.searchLabel, title = _a.title, enableSearch = _a.enableSearch;
    return (React.createElement(Wrapper, null,
        title,
        enableSearch && (React.createElement("div", null,
            React.createElement(Item, null,
                React.createElement(GlobalSearchFilter, { searchLabel: searchLabel }))))));
}
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    align-items: center;\n    display: flex;\n    justify-content: space-between;\n"], ["\n    align-items: center;\n    display: flex;\n    justify-content: space-between;\n"])));
var Item = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    display: inline-flex;\n"], ["\n    display: inline-flex;\n"])));
var templateObject_1, templateObject_2;
