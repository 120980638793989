import {
    useGetErrorHistoryEventsByEventCodesForSerialNumberQuery,
    useGetErrorHistoryEventsByEventCodesForTruckModelQuery,
    useGetTruckQuery
} from '@daisy/data-access';
import { join, uniqBy } from 'lodash';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { useEventCodeParams } from '@/hooks/useEventCodeParams';
import { useSerialNumber } from '@/hooks/useSerialNumber';
import { useSpellbook } from '@/hooks/useSpellbook';
import { useTruckModel } from '@/hooks/useTruckModel';
import { RootState } from '@/store';
import { EventTTP } from '@/types';
import { getImagePath } from '@/utils/helpers';
import { ChartToggle } from '@/views/Charts/errorCodeChartConfig';
import { useTimeframe } from './useTimeframe';

export const useErrorCodeView = () => {
    const { timeframeValue, setTimeframeValue } = useTimeframe(30);
    const { uiLoading } = useSelector((state: RootState) => state.ui);

    const { serialNumber, relatedSerialNumbers, softwareModelName, brandModelName } =
        useSerialNumber();
    const { truckModel } = useTruckModel();

    const [chartToggleValue, setChartToggleValue] = useState<ChartToggle>(ChartToggle.SCATTER);
    const [selectedEvent, setSelectedEvent] = useState<EventTTP | null>(null);

    const truckQuery = useGetTruckQuery({
        variables: { uuid: truckModel?.uuid || '' },
        skip: !truckModel?.uuid
    });

    const {
        data: eventData,
        isLoading: isEventsLoading,
        hasErrored
    } = useEventData({ timeframeValue });

    const uniqueSerialNumbers = useUniqueSerialNumbers(eventData);

    const headerImage = relatedSerialNumbers
        ? getImagePath(relatedSerialNumbers?.[0].truck?.hierarchyTrucks?.[0]?.hierarchy?.imagePath)
        : getImagePath(truckModel?.hierarchyTrucks?.[0]?.hierarchy.imagePath);

    const subtitle =
        softwareModelName || brandModelName
            ? [softwareModelName, brandModelName].join(', ')
            : undefined;

    const allSerialNumbersInTruckModel = truckQuery.data?.truck?._count?.serialNumbers || 0;

    const isLoading = uiLoading || isEventsLoading;

    return {
        allSerialNumbersInTruckModel,
        chartToggleValue,
        eventData,
        hasErrored,
        headerImage,
        isLoading,
        selectedEvent,
        serialNumber,
        setChartToggleValue,
        setSelectedEvent,
        setTimeframeValue,
        subtitle,
        timeframeValue,
        truckModel,
        uniqueSerialNumbers
    };
};

function useUniqueSerialNumbers(eventData: ReturnType<typeof useEventData>['data']) {
    return useMemo(() => uniqBy(eventData, 'serialNumber.externalId'), [eventData]);
}

function useEventData({ timeframeValue }: { timeframeValue: string | null }) {
    const { eventCodeParams } = useEventCodeParams();
    const { sendApplicationEvent } = useSpellbook();
    const { serialNumber } = useSerialNumber();
    const { truckModel } = useTruckModel();

    const days = timeframeValue ? parseInt(timeframeValue, 10) : 30;

    const onCompleted = () => {
        sendApplicationEvent('Selection', {
            eventName: 'Event code selection',
            eventMeta: {
                eventCodeParams: join(eventCodeParams, ', '),
                serialNumber
            }
        }).catch((e) => console.error(e));
    };

    const {
        data: dataForSerialNumber,
        loading: isDataForSerialNumberLoading,
        error: hasDataForSerialNumberErrored
    } = useGetErrorHistoryEventsByEventCodesForSerialNumberQuery({
        variables: {
            externalId: serialNumber || '',
            eventCodes: eventCodeParams || [],
            days
        },
        skip: !serialNumber || !eventCodeParams?.length,
        onCompleted
    });

    const {
        data: dataForTruckModel,
        loading: isDataForTruckModelLoading,
        error: hasDataForTruckModelErrored
    } = useGetErrorHistoryEventsByEventCodesForTruckModelQuery({
        variables: {
            softwareModelName: truckModel?.softwareModelName || '',
            eventCodes: eventCodeParams || [],
            days
        },
        skip: !!serialNumber || !truckModel?.softwareModelName || !eventCodeParams?.length,
        onCompleted
    });

    return {
        data: serialNumber
            ? dataForSerialNumber?.errorHistoryEventsByEventCodesForSerialNumber ?? []
            : dataForTruckModel?.errorHistoryEventsByEventCodesForTruck ?? [],
        isLoading: isDataForSerialNumberLoading || isDataForTruckModelLoading,
        hasErrored: !!hasDataForSerialNumberErrored || !!hasDataForTruckModelErrored
    };
}
