import { Card } from '@daisy/react-components';

import { OneColumn } from '@/components/styled/shared';

type OneColStackedLayoutProps = {
    children: Array<React.ReactNode | undefined>;
    title: string;
    titleSuffix?: React.ReactNode;
    isHeaderOutside?: boolean;
    dataTestId?: string;
};

export const OneColStackedLayout: React.FC<OneColStackedLayoutProps> = ({
    title,
    titleSuffix,
    isHeaderOutside,
    dataTestId,
    children
}) => {
    const [main, inner] = children;

    return (
        <Card
            title={title}
            titleSuffix={titleSuffix}
            isHeaderOutside={isHeaderOutside}
            dataTestId={dataTestId}
        >
            <OneColumn>
                {main}
                {inner && (
                    <Card isInner>
                        <OneColumn>{inner}</OneColumn>
                    </Card>
                )}
            </OneColumn>
        </Card>
    );
};
