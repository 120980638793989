import { useGetSerialNumbersLazyQuery } from '@daisy/data-access';
import { Modal, OnSelectChange, Select, SelectOption, ToastContent } from '@daisy/react-components';
import { debounce } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { useToasts } from 'react-toast-notifications';

const MIN_VALID_SEARCH_LENGTH = 4;

type SerialNumberModalProps = { handleModalClose: () => void };

export const SerialNumberModal: React.FC<SerialNumberModalProps> = ({ handleModalClose }) => {
    const navigate = useNavigate();
    const { addToast } = useToasts();

    const [getSerialNumbersQuery] = useGetSerialNumbersLazyQuery();

    const handleSerialNumberSelectChange: OnSelectChange = useCallback(
        (option) => {
            if (option?.value) {
                navigate(`/serial-number/${option?.value}/error-history-events`);
                handleModalClose();
            }
        },
        [handleModalClose, navigate]
    );

    const handleLoadOptions = useMemo(
        () =>
            debounce((inputValue: string | null, callback: (options: SelectOption[]) => void) => {
                if (inputValue?.length && inputValue?.length >= MIN_VALID_SEARCH_LENGTH) {
                    getSerialNumbersQuery({
                        variables: { externalId: inputValue },
                        fetchPolicy: 'network-only',
                        onError: () => {
                            addToast(
                                <ToastContent
                                    type="error"
                                    description="Error on validating serial number"
                                />
                            );
                        },
                        onCompleted: ({ serialNumbers }) => {
                            if (!serialNumbers?.length) {
                                callback([]);
                                return;
                            }

                            if (serialNumbers?.length > 0) {
                                const newOptions = serialNumbers.map(({ externalId }) => ({
                                    label: externalId,
                                    value: externalId
                                }));
                                callback(newOptions);
                            }
                        }
                    });
                }
            }, 500),
        [addToast, getSerialNumbersQuery]
    );

    return (
        <Modal
            isOpen
            title="Find serial number"
            actionButtonText="Select"
            onClose={handleModalClose}
            isOverflow
        >
            <Select
                name="serialNumberModal"
                placeholder="Search for serial number"
                handleChange={handleSerialNumberSelectChange}
                handleLoadOptions={handleLoadOptions}
                isClearable
                inputId="serial-number-search-modal-select"
                isAsync
                noOptionsMessage={({ inputValue }) =>
                    inputValue.length < MIN_VALID_SEARCH_LENGTH
                        ? `Type at least ${MIN_VALID_SEARCH_LENGTH} characters.`
                        : 'No options'
                }
            />
        </Modal>
    );
};
