var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import React from 'react';
import styled from 'styled-components';
import { Icon } from '../Icon/Icon';
var TagContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: space-between;\n    border: 1px solid ", ";\n    padding: 0.5em 1em;\n    border-radius: 50px;\n    width: 100%;\n    max-width: 80px;\n    font-size: 0.88em;\n    color: ", ";\n    margin-right: 1em;\n\n    &:hover {\n        background: ", ";\n        color: ", ";\n    }\n"], ["\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: space-between;\n    border: 1px solid ", ";\n    padding: 0.5em 1em;\n    border-radius: 50px;\n    width: 100%;\n    max-width: 80px;\n    font-size: 0.88em;\n    color: ", ";\n    margin-right: 1em;\n\n    &:hover {\n        background: ", ";\n        color: ", ";\n    }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.palette.primary;
}, function (_a) {
    var theme = _a.theme;
    return theme.palette.texts.black;
}, function (_a) {
    var theme = _a.theme;
    return theme.palette.primary;
}, function (_a) {
    var theme = _a.theme;
    return theme.palette.texts.white;
});
var IconContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    cursor: pointer;\n    display: flex;\n    flex-basis: 33%;\n    justify-content: flex-end;\n"], ["\n    cursor: pointer;\n    display: flex;\n    flex-basis: 33%;\n    justify-content: flex-end;\n"])));
export var Tag = function (_a) {
    var text = _a.text, onClick = _a.onClick;
    return (React.createElement(TagContainer, { "data-testid": "tag" },
        React.createElement("span", null, text),
        onClick && (React.createElement(IconContainer, { onClick: onClick, "data-testid": "tag-icon" },
            React.createElement(Icon, { icon: faTimes })))));
};
var templateObject_1, templateObject_2;
