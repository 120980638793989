import { useGetSerialNumbersQuery } from '@daisy/data-access';
import { useParams } from 'react-router-dom';

export const useSerialNumber = () => {
    const { serialNumberId } = useParams<{ serialNumberId: string }>();
    const { data, loading } = useGetSerialNumbersQuery({
        variables: { externalId: serialNumberId || '' },
        skip: !serialNumberId
    });

    return {
        serialNumber: serialNumberId,
        isLoading: loading,
        relatedSerialNumbers: data?.serialNumbers,
        softwareModelName: data?.serialNumbers?.[0]?.truck?.softwareModelName,
        brandModelName: data?.serialNumbers?.[0]?.truck?.hierarchyTrucks?.[0]?.hierarchy.name
    };
};
