import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export var DeviceObjectAccessType;
(function (DeviceObjectAccessType) {
    DeviceObjectAccessType["Advanced"] = "Advanced";
    DeviceObjectAccessType["Normal"] = "Normal";
})(DeviceObjectAccessType || (DeviceObjectAccessType = {}));
export var DeviceObjectBehaviourTag;
(function (DeviceObjectBehaviourTag) {
    DeviceObjectBehaviourTag["ActiveTest"] = "ActiveTest";
    DeviceObjectBehaviourTag["Analog"] = "Analog";
    DeviceObjectBehaviourTag["Battery"] = "Battery";
    DeviceObjectBehaviourTag["Calibratable"] = "Calibratable";
    DeviceObjectBehaviourTag["DeviceConfiguration"] = "DeviceConfiguration";
    DeviceObjectBehaviourTag["Digital"] = "Digital";
    DeviceObjectBehaviourTag["DirectPartNumber"] = "DirectPartNumber";
    DeviceObjectBehaviourTag["DoNotOverwriteByTdfGenerator"] = "DoNotOverwriteByTdfGenerator";
    DeviceObjectBehaviourTag["DoNotUseForChangedObjectsCheck"] = "DoNotUseForChangedObjectsCheck";
    DeviceObjectBehaviourTag["DoNotUseForCommunication"] = "DoNotUseForCommunication";
    DeviceObjectBehaviourTag["DoNotUseForOfflinityCheck"] = "DoNotUseForOfflinityCheck";
    DeviceObjectBehaviourTag["DynamicallyCreated"] = "DynamicallyCreated";
    DeviceObjectBehaviourTag["Empty"] = "Empty";
    DeviceObjectBehaviourTag["ExcludeFromExport"] = "ExcludeFromExport";
    DeviceObjectBehaviourTag["ExcludeFromImport"] = "ExcludeFromImport";
    DeviceObjectBehaviourTag["ExportLimit"] = "ExportLimit";
    DeviceObjectBehaviourTag["HeartBeat"] = "HeartBeat";
    DeviceObjectBehaviourTag["IgnoreExportFail"] = "IgnoreExportFail";
    DeviceObjectBehaviourTag["IgnoreImportFail"] = "IgnoreImportFail";
    DeviceObjectBehaviourTag["IgnoreStatusError"] = "IgnoreStatusError";
    DeviceObjectBehaviourTag["IncorrectConfiguration"] = "IncorrectConfiguration";
    DeviceObjectBehaviourTag["Info"] = "Info";
    DeviceObjectBehaviourTag["Input"] = "Input";
    DeviceObjectBehaviourTag["LinkedOnlyObject"] = "LinkedOnlyObject";
    DeviceObjectBehaviourTag["MastSerialNumber"] = "MastSerialNumber";
    DeviceObjectBehaviourTag["ModifyOnlyUiValue"] = "ModifyOnlyUIValue";
    DeviceObjectBehaviourTag["Numeric"] = "Numeric";
    DeviceObjectBehaviourTag["ObjectDictionaryChange"] = "ObjectDictionaryChange";
    DeviceObjectBehaviourTag["OperatingHours"] = "OperatingHours";
    DeviceObjectBehaviourTag["Option"] = "Option";
    DeviceObjectBehaviourTag["Output"] = "Output";
    DeviceObjectBehaviourTag["Parameter"] = "Parameter";
    DeviceObjectBehaviourTag["SellableOption"] = "SellableOption";
    DeviceObjectBehaviourTag["SellableOptionSalt"] = "SellableOptionSalt";
    DeviceObjectBehaviourTag["SerialNumber"] = "SerialNumber";
    DeviceObjectBehaviourTag["ServiceHours"] = "ServiceHours";
    DeviceObjectBehaviourTag["ServiceHoursReset"] = "ServiceHoursReset";
    DeviceObjectBehaviourTag["ShowOnlyInFlashMode"] = "ShowOnlyInFlashMode";
    DeviceObjectBehaviourTag["SlowCommunication"] = "SlowCommunication";
    DeviceObjectBehaviourTag["TruckScope"] = "Truck_Scope";
    DeviceObjectBehaviourTag["UseForOfflineCheck"] = "UseForOfflineCheck";
    DeviceObjectBehaviourTag["VmcSerialNumber"] = "VmcSerialNumber";
})(DeviceObjectBehaviourTag || (DeviceObjectBehaviourTag = {}));
export var DeviceObjectCommunicationParametersType;
(function (DeviceObjectCommunicationParametersType) {
    DeviceObjectCommunicationParametersType["ActiveFlagEventChannelType"] = "ActiveFlagEventChannelType";
    DeviceObjectCommunicationParametersType["BitMaskEventChannelType"] = "BitMaskEventChannelType";
    DeviceObjectCommunicationParametersType["EventChannelType"] = "EventChannelType";
    DeviceObjectCommunicationParametersType["FreeFormEventChannelType"] = "FreeFormEventChannelType";
    DeviceObjectCommunicationParametersType["GroupedEventChannelType"] = "GroupedEventChannelType";
    DeviceObjectCommunicationParametersType["NumberType"] = "NumberType";
    DeviceObjectCommunicationParametersType["StringType"] = "StringType";
    DeviceObjectCommunicationParametersType["TimeType"] = "TimeType";
})(DeviceObjectCommunicationParametersType || (DeviceObjectCommunicationParametersType = {}));
export var EventClass;
(function (EventClass) {
    EventClass["Alarm"] = "Alarm";
    EventClass["Info"] = "Info";
    EventClass["Warning"] = "Warning";
})(EventClass || (EventClass = {}));
export var FeedbackStatus;
(function (FeedbackStatus) {
    FeedbackStatus["Acknowledged"] = "acknowledged";
    FeedbackStatus["Created"] = "created";
    FeedbackStatus["Deleted"] = "deleted";
    FeedbackStatus["Processed"] = "processed";
    FeedbackStatus["Replied"] = "replied";
})(FeedbackStatus || (FeedbackStatus = {}));
export var FeedbackType;
(function (FeedbackType) {
    FeedbackType["Feature"] = "feature";
    FeedbackType["Feedback"] = "feedback";
    FeedbackType["Issue"] = "issue";
})(FeedbackType || (FeedbackType = {}));
export var FileType;
(function (FileType) {
    FileType["ElectricalDiagram"] = "ElectricalDiagram";
    FileType["Firmware"] = "Firmware";
    FileType["HydraulicsDiagram"] = "HydraulicsDiagram";
    FileType["NotSpecified"] = "NotSpecified";
    FileType["ServiceBulletin"] = "ServiceBulletin";
    FileType["ServiceManual"] = "ServiceManual";
    FileType["SparePartCatalog"] = "SparePartCatalog";
    FileType["UserManual"] = "UserManual";
    FileType["Video"] = "Video";
})(FileType || (FileType = {}));
export var HierarchyType;
(function (HierarchyType) {
    HierarchyType["Brand"] = "brand";
    HierarchyType["BrandModel"] = "brand_model";
    HierarchyType["Dealer"] = "dealer";
    HierarchyType["Region"] = "region";
    HierarchyType["SubDealer"] = "sub_dealer";
})(HierarchyType || (HierarchyType = {}));
export var QueryLogic;
(function (QueryLogic) {
    QueryLogic["And"] = "AND";
    QueryLogic["Or"] = "OR";
})(QueryLogic || (QueryLogic = {}));
export var QueryOperator;
(function (QueryOperator) {
    QueryOperator["Eq"] = "EQ";
    QueryOperator["Gt"] = "GT";
    QueryOperator["Gte"] = "GTE";
    QueryOperator["Lt"] = "LT";
    QueryOperator["Lte"] = "LTE";
    QueryOperator["Ne"] = "NE";
})(QueryOperator || (QueryOperator = {}));
export var Sort;
(function (Sort) {
    Sort["Asc"] = "asc";
    Sort["Desc"] = "desc";
})(Sort || (Sort = {}));
export var Status;
(function (Status) {
    Status["Active"] = "active";
    Status["Created"] = "created";
    Status["Deleted"] = "deleted";
    Status["Inactive"] = "inactive";
})(Status || (Status = {}));
export const DeviceByTruckFragmentDoc = gql `
    fragment DeviceByTruck on Device {
  id
  externalId
  name
  deviceObjects(tags: []) {
    externalId
    objectName
    uiName
  }
}
    `;
export const TtpFileShortFragmentDoc = gql `
    fragment TtpFileShort on TtpFile {
  externalId
  time
}
    `;
export const CommonErrorHistoryEventFragmentDoc = gql `
    fragment CommonErrorHistoryEvent on ErrorHistoryEvent {
  externalId
  serialNumber {
    uuid
    externalId
  }
  eventCode
  event {
    uuid
    name
  }
  eventTemperature
  eventDevice
  eventHours
  eventType
  eventSeverity
  eventTimestamp
  eventLogValue1
  eventLogValue2
  eventLogValue3
  eventLogValue4
  eventLogValue5
  eventCount
  eventNo
  eventSubInfo
  time
  eventBdi
  eventHour
  eventHourMeter
  eventClass
  eventDescription
  eventFirstTime
  eventLastTime
  ttpFile {
    ...TtpFileShort
  }
}
    ${TtpFileShortFragmentDoc}`;
export const SerialNumberErrorHistoryEventFragmentDoc = gql `
    fragment SerialNumberErrorHistoryEvent on ErrorHistoryEvent {
  eventCode
  eventCount
  time
  eventDevice
  event {
    uuid
    name
    class
  }
  ttpFile {
    externalId
  }
}
    `;
export const TruckErrorHistoryEventFragmentDoc = gql `
    fragment TruckErrorHistoryEvent on TruckErrorHistoryEventResult {
  softwareModel
  serialNumber
  serialNumberUuid
  eventCode
  eventName
  eventTime
  eventCount
  eventClass
  eventDevice
  eventUuid
  errorHistoryEventExternalId
  occurrences
  cumulativeEventCount
}
    `;
export const AffectedSerialNumberFragmentDoc = gql `
    fragment AffectedSerialNumber on AffectedSerialNumber {
  uuid
  externalId
  ttpFileExternalId
  time
  tags
  truckToolVersion
  hierarchyName
  hierarchyImagePath
}
    `;
export const SerialNumberErrorHistorySummaryEventFragmentDoc = gql `
    fragment SerialNumberErrorHistorySummaryEvent on SerialNumberErrorHistoryEventResult {
  eventCode
  eventCount
  time
  eventName
  eventDevice
  eventClass
  eventUuid
  occurrences
  cumulativeEventCount
  ttpExternalIds
}
    `;
export const SerialNumberTtpFilesResultFragmentDoc = gql `
    fragment SerialNumberTtpFilesResult on SerialNumberTtpFilesResult {
  tags
  ttpFileTime
  operatingHours
  truckToolVersion
  ttpFileExternalId
}
    `;
export const FeatureFlagFragmentDoc = gql `
    fragment FeatureFlag on FeatureFlags {
  flags
}
    `;
export const FeedbackFragmentDoc = gql `
    fragment Feedback on Feedback {
  id
  uuid
  created
  createdBy
  modified
  modifiedBy
  type
  status
  context
  content
}
    `;
export const NumericMeasurementFragmentDoc = gql `
    fragment NumericMeasurement on NumericMeasurement {
  label
  time
  value
}
    `;
export const StringMeasurementFragmentDoc = gql `
    fragment StringMeasurement on StringMeasurement {
  label
  time
  value
}
    `;
export const SerialNumberFragmentDoc = gql `
    fragment SerialNumber on SerialNumber {
  uuid
  externalId
  truck {
    uuid
    softwareModelName
    softwareModelUIName
    hierarchyTrucks {
      hierarchy {
        name
        imagePath
      }
    }
  }
}
    `;
export const SerialNumberShortFragmentDoc = gql `
    fragment SerialNumberShort on SerialNumber {
  uuid
  externalId
  truck {
    uuid
    softwareModelName
    softwareModelVersion
    originalTdfFilename
  }
}
    `;
export const SerialNumberTtpFileFragmentDoc = gql `
    fragment SerialNumberTtpFile on SerialNumberTtpFilesResult {
  serialNumber
  serialNumberUuid
  softwareModelName
  softwareModelVersion
  firstTtpFileId
  truckUuid
  originalTdfFilename
  operatingHours
  ttpFileId
  ttpFileExternalId
  ttpFileTime
  truckToolVersion
  tags
}
    `;
export const SerialNumberByTruckModelFragmentDoc = gql `
    fragment SerialNumberByTruckModel on SerialNumber {
  externalId
  uuid
  latestTtpFile {
    time
    tags
    externalId
    truckToolVersion
  }
  hierarchy {
    name
    imagePath
  }
}
    `;
export const CustomQueryFragmentDoc = gql `
    fragment CustomQuery on QueryResult {
  softwareModel
  serialNumber
  time
  ttpFilename
  objectValue {
    name
    value
  }
  referenceObjectValues
}
    `;
export const SerialNumberTelematicsChartDataFragmentDoc = gql `
    fragment SerialNumberTelematicsChartData on SerialNumberTelematicsChartData {
  key
  type
  chartValues {
    header
    key
    type
    value
    delta
    ttpexternalid
  }
}
    `;
export const SerialNumberTelematicsUsageTimesFragmentDoc = gql `
    fragment SerialNumberTelematicsUsageTimes on SerialNumberTelematicsUsageTimesFront {
  type
  deviceIds
  data {
    ...SerialNumberTelematicsChartData
  }
}
    ${SerialNumberTelematicsChartDataFragmentDoc}`;
export const TtpTelematicsFragmentDoc = gql `
    fragment TtpTelematics on TtpTelematics {
  object_name
  group_hierarchy
  object_behaviour_tags
  ttp_external_id
  ttp_file_time
  snt_external_id
  value_number
  value_text
  value
  delta
}
    `;
export const ErrorHistoryEventForTtpFragmentDoc = gql `
    fragment ErrorHistoryEventForTtp on ErrorHistoryEvent {
  externalId
  ttpFile {
    externalId
  }
  eventCode
  eventCount
  eventCountDelta
  eventType
  created
  serialNumberId
  time
  event {
    name
    userId
    class
  }
}
    `;
export const TtpEventForTtpFragmentDoc = gql `
    fragment TtpEventForTtp on TtpEvent {
  created
  eventId
  event {
    name
    userId
    class
  }
  ttpFile {
    externalId
  }
  time
  eventType
}
    `;
export const TtpTelematicsWithHistoryEventsFragmentDoc = gql `
    fragment TtpTelematicsWithHistoryEvents on TtpTelematicsWithErrorHistoryEvents {
  ttpTelematics {
    ...TtpTelematics
  }
  errorHistoryEventsForTtp {
    ...ErrorHistoryEventForTtp
  }
  ttpEventsForTtp {
    ...TtpEventForTtp
  }
}
    ${TtpTelematicsFragmentDoc}
${ErrorHistoryEventForTtpFragmentDoc}
${TtpEventForTtpFragmentDoc}`;
export const TtpFilesDailyCountFragmentDoc = gql `
    fragment TtpFilesDailyCount on TtpFilesDailyCount {
  bucket
  ttpCount
  dealers
  brandModel
}
    `;
export const TruckModelFragmentDoc = gql `
    fragment TruckModel on Truck {
  uuid
  softwareModelName
  softwareModelVersion
  originalTdfFilename
  category {
    name
  }
  hierarchyTrucks {
    hierarchy {
      externalId
      name
      imagePath
      parent {
        name
        parent {
          name
        }
      }
    }
  }
}
    `;
export const TruckWithDeviceObjectFragmentDoc = gql `
    fragment TruckWithDeviceObject on Truck {
  uuid
  softwareModelName
  devices {
    id
    externalId
    name
    deviceObjects(tags: []) {
      externalId
      objectName
      groupHierarchy
      device {
        externalId
        name
      }
    }
  }
}
    `;
export const HierarchyTruckFragmentDoc = gql `
    fragment HierarchyTruck on HierarchyTrucks {
  truck {
    uuid
    softwareModelName
    softwareModelVersion
    originalTdfFilename
  }
  hierarchy {
    uuid
    parent {
      name
    }
    name
    imagePath
  }
}
    `;
export const TruckWithDeviceFragmentDoc = gql `
    fragment TruckWithDevice on Truck {
  uuid
  softwareModelName
  devices {
    uuid
    externalId
    name
  }
}
    `;
export const TruckSummaryFragmentDoc = gql `
    fragment TruckSummary on TruckSummary {
  averageOperatingHours
  ttpFileCount
  serialNumberCount
  warrantyClaimsCount
  factoryDefaultCount
}
    `;
export const ErrorEventFragmentDoc = gql `
    fragment ErrorEvent on ErrorEvent {
  errorCode
  errorName
  errorClass
  device
  description
  occurrences
}
    `;
export const TruckToolOperationFragmentDoc = gql `
    fragment TruckToolOperation on TruckToolOperation {
  time
  deviceOsVersion
  deviceRoleInstance
  deviceType
  location
  locationCity
  locationClientip
  locationCountry
  locationProvince
  eventCount
  eventName
  errorEvent {
    ...ErrorEvent
  }
  internal
  custom
  operation
}
    ${ErrorEventFragmentDoc}`;
export const TruckToolOperationsResultFragmentDoc = gql `
    fragment TruckToolOperationsResult on TruckToolOperationsResult {
  operations {
    ...TruckToolOperation
  }
  errorEvents {
    ...ErrorEvent
  }
}
    ${TruckToolOperationFragmentDoc}
${ErrorEventFragmentDoc}`;
export const DealersDocument = gql `
    query Dealers {
  dealers {
    name
    uuid
  }
}
    `;
/**
 * __useDealersQuery__
 *
 * To run a query within a React component, call `useDealersQuery` and pass it any options that fit your needs.
 * When your component renders, `useDealersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDealersQuery({
 *   variables: {
 *   },
 * });
 */
export function useDealersQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(DealersDocument, options);
}
export function useDealersLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(DealersDocument, options);
}
export const GetDevicesByTrucksDocument = gql `
    query GetDevicesByTrucks($uuids: [String!]) {
  devicesByTrucks(uuids: $uuids) {
    ...DeviceByTruck
  }
}
    ${DeviceByTruckFragmentDoc}`;
/**
 * __useGetDevicesByTrucksQuery__
 *
 * To run a query within a React component, call `useGetDevicesByTrucksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDevicesByTrucksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDevicesByTrucksQuery({
 *   variables: {
 *      uuids: // value for 'uuids'
 *   },
 * });
 */
export function useGetDevicesByTrucksQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(GetDevicesByTrucksDocument, options);
}
export function useGetDevicesByTrucksLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(GetDevicesByTrucksDocument, options);
}
export const GetErrorCodesByInputDocument = gql `
    query GetErrorCodesByInput($input: String!) {
  eventsByInput(input: $input) {
    userId
    class
    uuid
  }
}
    `;
/**
 * __useGetErrorCodesByInputQuery__
 *
 * To run a query within a React component, call `useGetErrorCodesByInputQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetErrorCodesByInputQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetErrorCodesByInputQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetErrorCodesByInputQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(GetErrorCodesByInputDocument, options);
}
export function useGetErrorCodesByInputLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(GetErrorCodesByInputDocument, options);
}
export const GetErrorCodesByTruckModelDocument = gql `
    query GetErrorCodesByTruckModel($uuid: String!) {
  truck(uuid: $uuid) {
    uuid
    events {
      name
      userId
      uuid
    }
  }
}
    `;
/**
 * __useGetErrorCodesByTruckModelQuery__
 *
 * To run a query within a React component, call `useGetErrorCodesByTruckModelQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetErrorCodesByTruckModelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetErrorCodesByTruckModelQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useGetErrorCodesByTruckModelQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(GetErrorCodesByTruckModelDocument, options);
}
export function useGetErrorCodesByTruckModelLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(GetErrorCodesByTruckModelDocument, options);
}
export const GetErrorHistoryEventsDocument = gql `
    query GetErrorHistoryEvents($eventCode: String!) {
  errorHistoryEvents(eventCode: $eventCode) {
    ...CommonErrorHistoryEvent
  }
}
    ${CommonErrorHistoryEventFragmentDoc}`;
/**
 * __useGetErrorHistoryEventsQuery__
 *
 * To run a query within a React component, call `useGetErrorHistoryEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetErrorHistoryEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetErrorHistoryEventsQuery({
 *   variables: {
 *      eventCode: // value for 'eventCode'
 *   },
 * });
 */
export function useGetErrorHistoryEventsQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(GetErrorHistoryEventsDocument, options);
}
export function useGetErrorHistoryEventsLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(GetErrorHistoryEventsDocument, options);
}
export const GetErrorHistoryEventsByEventCodesForSerialNumberDocument = gql `
    query GetErrorHistoryEventsByEventCodesForSerialNumber($externalId: String!, $eventCodes: [String]!, $days: Int) {
  errorHistoryEventsByEventCodesForSerialNumber(
    externalId: $externalId
    eventCodes: $eventCodes
    days: $days
  ) {
    ...CommonErrorHistoryEvent
  }
}
    ${CommonErrorHistoryEventFragmentDoc}`;
/**
 * __useGetErrorHistoryEventsByEventCodesForSerialNumberQuery__
 *
 * To run a query within a React component, call `useGetErrorHistoryEventsByEventCodesForSerialNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetErrorHistoryEventsByEventCodesForSerialNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetErrorHistoryEventsByEventCodesForSerialNumberQuery({
 *   variables: {
 *      externalId: // value for 'externalId'
 *      eventCodes: // value for 'eventCodes'
 *      days: // value for 'days'
 *   },
 * });
 */
export function useGetErrorHistoryEventsByEventCodesForSerialNumberQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(GetErrorHistoryEventsByEventCodesForSerialNumberDocument, options);
}
export function useGetErrorHistoryEventsByEventCodesForSerialNumberLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(GetErrorHistoryEventsByEventCodesForSerialNumberDocument, options);
}
export const GetErrorHistoryEventsByEventCodesForTruckModelDocument = gql `
    query GetErrorHistoryEventsByEventCodesForTruckModel($softwareModelName: String!, $eventCodes: [String]!, $days: Int) {
  errorHistoryEventsByEventCodesForTruck(
    softwareModelName: $softwareModelName
    eventCodes: $eventCodes
    days: $days
  ) {
    ...CommonErrorHistoryEvent
  }
}
    ${CommonErrorHistoryEventFragmentDoc}`;
/**
 * __useGetErrorHistoryEventsByEventCodesForTruckModelQuery__
 *
 * To run a query within a React component, call `useGetErrorHistoryEventsByEventCodesForTruckModelQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetErrorHistoryEventsByEventCodesForTruckModelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetErrorHistoryEventsByEventCodesForTruckModelQuery({
 *   variables: {
 *      softwareModelName: // value for 'softwareModelName'
 *      eventCodes: // value for 'eventCodes'
 *      days: // value for 'days'
 *   },
 * });
 */
export function useGetErrorHistoryEventsByEventCodesForTruckModelQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(GetErrorHistoryEventsByEventCodesForTruckModelDocument, options);
}
export function useGetErrorHistoryEventsByEventCodesForTruckModelLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(GetErrorHistoryEventsByEventCodesForTruckModelDocument, options);
}
export const GetErrorHistoryEventsBySerialNumberDocument = gql `
    query GetErrorHistoryEventsBySerialNumber($externalId: String!) {
  errorHistoryEventsBySerialNumber(externalId: $externalId) {
    ...SerialNumberErrorHistoryEvent
  }
}
    ${SerialNumberErrorHistoryEventFragmentDoc}`;
/**
 * __useGetErrorHistoryEventsBySerialNumberQuery__
 *
 * To run a query within a React component, call `useGetErrorHistoryEventsBySerialNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetErrorHistoryEventsBySerialNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetErrorHistoryEventsBySerialNumberQuery({
 *   variables: {
 *      externalId: // value for 'externalId'
 *   },
 * });
 */
export function useGetErrorHistoryEventsBySerialNumberQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(GetErrorHistoryEventsBySerialNumberDocument, options);
}
export function useGetErrorHistoryEventsBySerialNumberLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(GetErrorHistoryEventsBySerialNumberDocument, options);
}
export const GetErrorHistoryEventsForTtpDocument = gql `
    query GetErrorHistoryEventsForTtp($externalIds: [String!]) {
  errorHistoryEventsForTtp(externalIds: $externalIds) {
    ...ErrorHistoryEventForTtp
  }
}
    ${ErrorHistoryEventForTtpFragmentDoc}`;
/**
 * __useGetErrorHistoryEventsForTtpQuery__
 *
 * To run a query within a React component, call `useGetErrorHistoryEventsForTtpQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetErrorHistoryEventsForTtpQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetErrorHistoryEventsForTtpQuery({
 *   variables: {
 *      externalIds: // value for 'externalIds'
 *   },
 * });
 */
export function useGetErrorHistoryEventsForTtpQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(GetErrorHistoryEventsForTtpDocument, options);
}
export function useGetErrorHistoryEventsForTtpLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(GetErrorHistoryEventsForTtpDocument, options);
}
export const GetTruckWithEventsDocument = gql `
    query GetTruckWithEvents($uuid: String!) {
  truck(uuid: $uuid) {
    uuid
    id
    events {
      id
      created
      modified
      status
      uuid
      externalId
      name
      userId
      alias
      class
      description
      action
      manufacturerDescription
    }
  }
}
    `;
/**
 * __useGetTruckWithEventsQuery__
 *
 * To run a query within a React component, call `useGetTruckWithEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTruckWithEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTruckWithEventsQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useGetTruckWithEventsQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(GetTruckWithEventsDocument, options);
}
export function useGetTruckWithEventsLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(GetTruckWithEventsDocument, options);
}
export const GetTruckErrorHistoryEventSummaryDocument = gql `
    query GetTruckErrorHistoryEventSummary($uuid: String!, $days: Int) {
  truckErrorHistoryEventSummary(uuid: $uuid, days: $days) {
    errorHistoryEvents {
      ...TruckErrorHistoryEvent
    }
    alarmCount
    infoCount
    warningCount
    totalCount
  }
}
    ${TruckErrorHistoryEventFragmentDoc}`;
/**
 * __useGetTruckErrorHistoryEventSummaryQuery__
 *
 * To run a query within a React component, call `useGetTruckErrorHistoryEventSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTruckErrorHistoryEventSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTruckErrorHistoryEventSummaryQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      days: // value for 'days'
 *   },
 * });
 */
export function useGetTruckErrorHistoryEventSummaryQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(GetTruckErrorHistoryEventSummaryDocument, options);
}
export function useGetTruckErrorHistoryEventSummaryLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(GetTruckErrorHistoryEventSummaryDocument, options);
}
export const GetTruckErrorHistoryEventAffectedSerialNumbersDocument = gql `
    query GetTruckErrorHistoryEventAffectedSerialNumbers($uuid: String!, $eventCode: String!, $days: Int) {
  truckErrorHistoryEventAffectedSerialNumbers(
    uuid: $uuid
    eventCode: $eventCode
    days: $days
  ) {
    ...AffectedSerialNumber
  }
}
    ${AffectedSerialNumberFragmentDoc}`;
/**
 * __useGetTruckErrorHistoryEventAffectedSerialNumbersQuery__
 *
 * To run a query within a React component, call `useGetTruckErrorHistoryEventAffectedSerialNumbersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTruckErrorHistoryEventAffectedSerialNumbersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTruckErrorHistoryEventAffectedSerialNumbersQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      eventCode: // value for 'eventCode'
 *      days: // value for 'days'
 *   },
 * });
 */
export function useGetTruckErrorHistoryEventAffectedSerialNumbersQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(GetTruckErrorHistoryEventAffectedSerialNumbersDocument, options);
}
export function useGetTruckErrorHistoryEventAffectedSerialNumbersLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(GetTruckErrorHistoryEventAffectedSerialNumbersDocument, options);
}
export const GetSerialNumberErrorHistoryEventsSummaryDocument = gql `
    query GetSerialNumberErrorHistoryEventsSummary($externalId: String!) {
  serialNumberErrorHistoryEventsSummary(externalId: $externalId) {
    errorHistoryEvents {
      ...SerialNumberErrorHistorySummaryEvent
    }
    alarmCount
    infoCount
    warningCount
    totalCount
    ttpFiles {
      ...SerialNumberTtpFilesResult
    }
  }
}
    ${SerialNumberErrorHistorySummaryEventFragmentDoc}
${SerialNumberTtpFilesResultFragmentDoc}`;
/**
 * __useGetSerialNumberErrorHistoryEventsSummaryQuery__
 *
 * To run a query within a React component, call `useGetSerialNumberErrorHistoryEventsSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSerialNumberErrorHistoryEventsSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSerialNumberErrorHistoryEventsSummaryQuery({
 *   variables: {
 *      externalId: // value for 'externalId'
 *   },
 * });
 */
export function useGetSerialNumberErrorHistoryEventsSummaryQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(GetSerialNumberErrorHistoryEventsSummaryDocument, options);
}
export function useGetSerialNumberErrorHistoryEventsSummaryLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(GetSerialNumberErrorHistoryEventsSummaryDocument, options);
}
export const FeatureFlagsDocument = gql `
    query FeatureFlags {
  featureFlags {
    ...FeatureFlag
  }
}
    ${FeatureFlagFragmentDoc}`;
/**
 * __useFeatureFlagsQuery__
 *
 * To run a query within a React component, call `useFeatureFlagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeatureFlagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeatureFlagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFeatureFlagsQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(FeatureFlagsDocument, options);
}
export function useFeatureFlagsLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(FeatureFlagsDocument, options);
}
export const SendFeedbackDocument = gql `
    mutation sendFeedback($input: CreateFeedbackInput) {
  createFeedback(input: $input) {
    ...Feedback
  }
}
    ${FeedbackFragmentDoc}`;
/**
 * __useSendFeedbackMutation__
 *
 * To run a mutation, you first call `useSendFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendFeedbackMutation, { data, loading, error }] = useSendFeedbackMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendFeedbackMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(SendFeedbackDocument, options);
}
export const GetLiveDataDocument = gql `
    query GetLiveData($machineId: String!) {
  liveData(machineId: $machineId) {
    numeric {
      ...NumericMeasurement
    }
    string {
      ...StringMeasurement
    }
  }
}
    ${NumericMeasurementFragmentDoc}
${StringMeasurementFragmentDoc}`;
/**
 * __useGetLiveDataQuery__
 *
 * To run a query within a React component, call `useGetLiveDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLiveDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLiveDataQuery({
 *   variables: {
 *      machineId: // value for 'machineId'
 *   },
 * });
 */
export function useGetLiveDataQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(GetLiveDataDocument, options);
}
export function useGetLiveDataLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(GetLiveDataDocument, options);
}
export const GetSerialNumbersDocument = gql `
    query GetSerialNumbers($externalId: String!) {
  serialNumbers(externalId: $externalId) {
    ...SerialNumber
  }
}
    ${SerialNumberFragmentDoc}`;
/**
 * __useGetSerialNumbersQuery__
 *
 * To run a query within a React component, call `useGetSerialNumbersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSerialNumbersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSerialNumbersQuery({
 *   variables: {
 *      externalId: // value for 'externalId'
 *   },
 * });
 */
export function useGetSerialNumbersQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(GetSerialNumbersDocument, options);
}
export function useGetSerialNumbersLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(GetSerialNumbersDocument, options);
}
export const GetSerialNumberByUuidDocument = gql `
    query GetSerialNumberByUUID($uuid: String!) {
  serialNumberByUUID(uuid: $uuid) {
    ...SerialNumberShort
  }
}
    ${SerialNumberShortFragmentDoc}`;
/**
 * __useGetSerialNumberByUuidQuery__
 *
 * To run a query within a React component, call `useGetSerialNumberByUuidQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSerialNumberByUuidQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSerialNumberByUuidQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useGetSerialNumberByUuidQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(GetSerialNumberByUuidDocument, options);
}
export function useGetSerialNumberByUuidLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(GetSerialNumberByUuidDocument, options);
}
export const GetSerialNumbersByEventCodesDocument = gql `
    query GetSerialNumbersByEventCodes($eventCodes: [String!]!) {
  serialNumbersByEventCodes(eventCodes: $eventCodes) {
    ...SerialNumberShort
  }
}
    ${SerialNumberShortFragmentDoc}`;
/**
 * __useGetSerialNumbersByEventCodesQuery__
 *
 * To run a query within a React component, call `useGetSerialNumbersByEventCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSerialNumbersByEventCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSerialNumbersByEventCodesQuery({
 *   variables: {
 *      eventCodes: // value for 'eventCodes'
 *   },
 * });
 */
export function useGetSerialNumbersByEventCodesQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(GetSerialNumbersByEventCodesDocument, options);
}
export function useGetSerialNumbersByEventCodesLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(GetSerialNumbersByEventCodesDocument, options);
}
export const GetSerialNumberTtpFilesDocument = gql `
    query GetSerialNumberTtpFiles($serialNumber: String!) {
  serialNumberTtpFiles(serialNumber: $serialNumber) {
    ...SerialNumberTtpFile
  }
}
    ${SerialNumberTtpFileFragmentDoc}`;
/**
 * __useGetSerialNumberTtpFilesQuery__
 *
 * To run a query within a React component, call `useGetSerialNumberTtpFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSerialNumberTtpFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSerialNumberTtpFilesQuery({
 *   variables: {
 *      serialNumber: // value for 'serialNumber'
 *   },
 * });
 */
export function useGetSerialNumberTtpFilesQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(GetSerialNumberTtpFilesDocument, options);
}
export function useGetSerialNumberTtpFilesLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(GetSerialNumberTtpFilesDocument, options);
}
export const GetSerialNumbersByTruckModelDocument = gql `
    query GetSerialNumbersByTruckModel($uuid: String!, $offset: Int, $limit: Int) {
  serialNumbersByTruckModel(uuid: $uuid, offset: $offset, limit: $limit) {
    data {
      ...SerialNumberByTruckModel
    }
    count
  }
}
    ${SerialNumberByTruckModelFragmentDoc}`;
/**
 * __useGetSerialNumbersByTruckModelQuery__
 *
 * To run a query within a React component, call `useGetSerialNumbersByTruckModelQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSerialNumbersByTruckModelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSerialNumbersByTruckModelQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetSerialNumbersByTruckModelQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(GetSerialNumbersByTruckModelDocument, options);
}
export function useGetSerialNumbersByTruckModelLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(GetSerialNumbersByTruckModelDocument, options);
}
export const TelematicsDocument = gql `
    query Telematics($softwareModel: String, $serialNumber: String, $query: String!, $onlyLatestTTP: Boolean, $onlyFactoryDefaultTTP: Boolean) {
  customQuery(
    softwareModel: $softwareModel
    serialNumber: $serialNumber
    query: $query
    onlyLatestTTP: $onlyLatestTTP
    onlyFactoryDefaultTTP: $onlyFactoryDefaultTTP
  ) {
    ...CustomQuery
  }
}
    ${CustomQueryFragmentDoc}`;
/**
 * __useTelematicsQuery__
 *
 * To run a query within a React component, call `useTelematicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTelematicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTelematicsQuery({
 *   variables: {
 *      softwareModel: // value for 'softwareModel'
 *      serialNumber: // value for 'serialNumber'
 *      query: // value for 'query'
 *      onlyLatestTTP: // value for 'onlyLatestTTP'
 *      onlyFactoryDefaultTTP: // value for 'onlyFactoryDefaultTTP'
 *   },
 * });
 */
export function useTelematicsQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(TelematicsDocument, options);
}
export function useTelematicsLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(TelematicsDocument, options);
}
export const TelematicsUsageTimesDocument = gql `
    query TelematicsUsageTimes($externalIds: [String!]!, $uuid: String!, $ttpExternalIds: [String!]!) {
  telematicsUsageTimesQuery(
    externalIds: $externalIds
    uuid: $uuid
    ttpExternalIds: $ttpExternalIds
  ) {
    ...SerialNumberTelematicsUsageTimes
  }
}
    ${SerialNumberTelematicsUsageTimesFragmentDoc}`;
/**
 * __useTelematicsUsageTimesQuery__
 *
 * To run a query within a React component, call `useTelematicsUsageTimesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTelematicsUsageTimesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTelematicsUsageTimesQuery({
 *   variables: {
 *      externalIds: // value for 'externalIds'
 *      uuid: // value for 'uuid'
 *      ttpExternalIds: // value for 'ttpExternalIds'
 *   },
 * });
 */
export function useTelematicsUsageTimesQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(TelematicsUsageTimesDocument, options);
}
export function useTelematicsUsageTimesLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(TelematicsUsageTimesDocument, options);
}
export const TtpTelematicsDocument = gql `
    query TtpTelematics($externalIds: [String!]) {
  ttpTelematics(externalIds: $externalIds) {
    ...TtpTelematics
  }
}
    ${TtpTelematicsFragmentDoc}`;
/**
 * __useTtpTelematicsQuery__
 *
 * To run a query within a React component, call `useTtpTelematicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTtpTelematicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTtpTelematicsQuery({
 *   variables: {
 *      externalIds: // value for 'externalIds'
 *   },
 * });
 */
export function useTtpTelematicsQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(TtpTelematicsDocument, options);
}
export function useTtpTelematicsLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(TtpTelematicsDocument, options);
}
export const TtpTelematicsWithErrorHistoryEventsDocument = gql `
    query TtpTelematicsWithErrorHistoryEvents($externalId: String!) {
  ttpTelematicsWithErrorHistoryEvents(externalId: $externalId) {
    ...TtpTelematicsWithHistoryEvents
  }
}
    ${TtpTelematicsWithHistoryEventsFragmentDoc}`;
/**
 * __useTtpTelematicsWithErrorHistoryEventsQuery__
 *
 * To run a query within a React component, call `useTtpTelematicsWithErrorHistoryEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTtpTelematicsWithErrorHistoryEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTtpTelematicsWithErrorHistoryEventsQuery({
 *   variables: {
 *      externalId: // value for 'externalId'
 *   },
 * });
 */
export function useTtpTelematicsWithErrorHistoryEventsQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(TtpTelematicsWithErrorHistoryEventsDocument, options);
}
export function useTtpTelematicsWithErrorHistoryEventsLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(TtpTelematicsWithErrorHistoryEventsDocument, options);
}
export const TtpTelematicsWithErrorHistoryEventsMultipleDocument = gql `
    query TtpTelematicsWithErrorHistoryEventsMultiple($externalIds: [String!]) {
  ttpTelematicsWithErrorHistoryEventsMultiple(externalIds: $externalIds) {
    ...TtpTelematicsWithHistoryEvents
  }
}
    ${TtpTelematicsWithHistoryEventsFragmentDoc}`;
/**
 * __useTtpTelematicsWithErrorHistoryEventsMultipleQuery__
 *
 * To run a query within a React component, call `useTtpTelematicsWithErrorHistoryEventsMultipleQuery` and pass it any options that fit your needs.
 * When your component renders, `useTtpTelematicsWithErrorHistoryEventsMultipleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTtpTelematicsWithErrorHistoryEventsMultipleQuery({
 *   variables: {
 *      externalIds: // value for 'externalIds'
 *   },
 * });
 */
export function useTtpTelematicsWithErrorHistoryEventsMultipleQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(TtpTelematicsWithErrorHistoryEventsMultipleDocument, options);
}
export function useTtpTelematicsWithErrorHistoryEventsMultipleLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(TtpTelematicsWithErrorHistoryEventsMultipleDocument, options);
}
export const TtpFilesDailyCountDocument = gql `
    query TtpFilesDailyCount($dealerUuids: [String!]) {
  ttpFilesDailyCount(dealerUuids: $dealerUuids) {
    ...TtpFilesDailyCount
  }
}
    ${TtpFilesDailyCountFragmentDoc}`;
/**
 * __useTtpFilesDailyCountQuery__
 *
 * To run a query within a React component, call `useTtpFilesDailyCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useTtpFilesDailyCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTtpFilesDailyCountQuery({
 *   variables: {
 *      dealerUuids: // value for 'dealerUuids'
 *   },
 * });
 */
export function useTtpFilesDailyCountQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(TtpFilesDailyCountDocument, options);
}
export function useTtpFilesDailyCountLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(TtpFilesDailyCountDocument, options);
}
export const GetTruckDocument = gql `
    query GetTruck($uuid: String!) {
  truck(uuid: $uuid) {
    uuid
    _count {
      serialNumbers
    }
  }
}
    `;
/**
 * __useGetTruckQuery__
 *
 * To run a query within a React component, call `useGetTruckQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTruckQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTruckQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useGetTruckQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(GetTruckDocument, options);
}
export function useGetTruckLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(GetTruckDocument, options);
}
export const GetTruckModelDocument = gql `
    query GetTruckModel($uuid: String!) {
  truck(uuid: $uuid) {
    ...TruckModel
  }
}
    ${TruckModelFragmentDoc}`;
/**
 * __useGetTruckModelQuery__
 *
 * To run a query within a React component, call `useGetTruckModelQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTruckModelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTruckModelQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useGetTruckModelQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(GetTruckModelDocument, options);
}
export function useGetTruckModelLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(GetTruckModelDocument, options);
}
export const GetTruckDeviceObjectsDocument = gql `
    query GetTruckDeviceObjects($uuids: [String!]) {
  trucks(uuids: $uuids) {
    ...TruckWithDeviceObject
  }
}
    ${TruckWithDeviceObjectFragmentDoc}`;
/**
 * __useGetTruckDeviceObjectsQuery__
 *
 * To run a query within a React component, call `useGetTruckDeviceObjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTruckDeviceObjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTruckDeviceObjectsQuery({
 *   variables: {
 *      uuids: // value for 'uuids'
 *   },
 * });
 */
export function useGetTruckDeviceObjectsQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(GetTruckDeviceObjectsDocument, options);
}
export function useGetTruckDeviceObjectsLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(GetTruckDeviceObjectsDocument, options);
}
export const GetHierarchyTrucksDocument = gql `
    query GetHierarchyTrucks {
  hierarchyTrucks {
    ...HierarchyTruck
  }
}
    ${HierarchyTruckFragmentDoc}`;
/**
 * __useGetHierarchyTrucksQuery__
 *
 * To run a query within a React component, call `useGetHierarchyTrucksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHierarchyTrucksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHierarchyTrucksQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetHierarchyTrucksQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(GetHierarchyTrucksDocument, options);
}
export function useGetHierarchyTrucksLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(GetHierarchyTrucksDocument, options);
}
export const GetTrucksByEventCodesDocument = gql `
    query GetTrucksByEventCodes($eventCodes: [String!]!) {
  trucksByEventCodes(eventCodes: $eventCodes) {
    uuid
    softwareModelName
    softwareModelVersion
    originalTdfFilename
  }
}
    `;
/**
 * __useGetTrucksByEventCodesQuery__
 *
 * To run a query within a React component, call `useGetTrucksByEventCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTrucksByEventCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTrucksByEventCodesQuery({
 *   variables: {
 *      eventCodes: // value for 'eventCodes'
 *   },
 * });
 */
export function useGetTrucksByEventCodesQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(GetTrucksByEventCodesDocument, options);
}
export function useGetTrucksByEventCodesLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(GetTrucksByEventCodesDocument, options);
}
export const GetTrucksWithDevicesDocument = gql `
    query GetTrucksWithDevices($uuids: [String!]) {
  trucks(uuids: $uuids) {
    ...TruckWithDevice
  }
}
    ${TruckWithDeviceFragmentDoc}`;
/**
 * __useGetTrucksWithDevicesQuery__
 *
 * To run a query within a React component, call `useGetTrucksWithDevicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTrucksWithDevicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTrucksWithDevicesQuery({
 *   variables: {
 *      uuids: // value for 'uuids'
 *   },
 * });
 */
export function useGetTrucksWithDevicesQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(GetTrucksWithDevicesDocument, options);
}
export function useGetTrucksWithDevicesLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(GetTrucksWithDevicesDocument, options);
}
export const GetTruckSummaryDocument = gql `
    query GetTruckSummary($uuid: String!) {
  truckSummary(uuid: $uuid) {
    ...TruckSummary
  }
}
    ${TruckSummaryFragmentDoc}`;
/**
 * __useGetTruckSummaryQuery__
 *
 * To run a query within a React component, call `useGetTruckSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTruckSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTruckSummaryQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useGetTruckSummaryQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(GetTruckSummaryDocument, options);
}
export function useGetTruckSummaryLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(GetTruckSummaryDocument, options);
}
export const GetTruckToolOperationTimesDocument = gql `
    query GetTruckToolOperationTimes($externalId: String!) {
  truckToolOperationTimes(externalId: $externalId)
}
    `;
/**
 * __useGetTruckToolOperationTimesQuery__
 *
 * To run a query within a React component, call `useGetTruckToolOperationTimesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTruckToolOperationTimesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTruckToolOperationTimesQuery({
 *   variables: {
 *      externalId: // value for 'externalId'
 *   },
 * });
 */
export function useGetTruckToolOperationTimesQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(GetTruckToolOperationTimesDocument, options);
}
export function useGetTruckToolOperationTimesLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(GetTruckToolOperationTimesDocument, options);
}
export const GetTruckToolOperationsDocument = gql `
    query GetTruckToolOperations($externalId: String!, $dateString: String!) {
  truckToolOperations(externalId: $externalId, dateString: $dateString) {
    ...TruckToolOperationsResult
  }
}
    ${TruckToolOperationsResultFragmentDoc}`;
/**
 * __useGetTruckToolOperationsQuery__
 *
 * To run a query within a React component, call `useGetTruckToolOperationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTruckToolOperationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTruckToolOperationsQuery({
 *   variables: {
 *      externalId: // value for 'externalId'
 *      dateString: // value for 'dateString'
 *   },
 * });
 */
export function useGetTruckToolOperationsQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(GetTruckToolOperationsDocument, options);
}
export function useGetTruckToolOperationsLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(GetTruckToolOperationsDocument, options);
}
export const UserProfileDocument = gql `
    query UserProfile {
  userProfile {
    profile
  }
}
    `;
/**
 * __useUserProfileQuery__
 *
 * To run a query within a React component, call `useUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserProfileQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(UserProfileDocument, options);
}
export function useUserProfileLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(UserProfileDocument, options);
}
