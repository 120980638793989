import { useApolloClient } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, Icon } from '@daisy/react-components';
import { faSignOut } from '@fortawesome/pro-solid-svg-icons';

type LogoutButtonProps = {
    className?: string;
};

export const LogoutButton = ({ className }: LogoutButtonProps) => {
    const { logout } = useAuth0();
    const client = useApolloClient();

    const handleLogoutClick = () => {
        client.resetStore();
        logout({ returnTo: window.location.origin });
    };

    return (
        <Button className={className} onClick={handleLogoutClick} isPrimary>
            <Icon icon={faSignOut} />
            Log out
        </Button>
    );
};
