import { Card } from '@daisy/react-components';

import { OneColumn, TwoColumn } from '@/components/styled/shared';

type TwoColStackedLayoutProps = {
    children: Array<React.ReactNode | undefined>;
    title?: string;
    titleSuffix?: React.ReactNode;
    leftWidth?: number;
    isSegmented?: boolean;
    isHeaderOutside?: boolean;
    contentBackground?: string;
    dataTestId?: string;
};

export const TwoColStackedLayout: React.FC<TwoColStackedLayoutProps> = ({
    title,
    titleSuffix,
    isSegmented,
    isHeaderOutside,
    leftWidth = 7,
    contentBackground,
    dataTestId,
    children
}) => {
    const [left, right, inner] = children;

    return (
        <Card
            title={title}
            titleSuffix={titleSuffix}
            isSegmented={isSegmented}
            contentPadding={isSegmented ? 'none' : undefined}
            isHeaderOutside={isHeaderOutside}
            dataTestId={dataTestId}
        >
            <OneColumn>
                <Card isTransparent>
                    <TwoColumn
                        leftWidth={leftWidth}
                        gapSize={isSegmented ? 'none' : 'large'}
                        withBorder
                    >
                        <div
                            style={
                                isSegmented
                                    ? {
                                          padding: '2rem',
                                          backgroundColor: contentBackground,
                                          borderRadius: isHeaderOutside ? '8px' : '0 0 0 8px'
                                      }
                                    : undefined
                            }
                        >
                            {left}
                        </div>
                        <div style={isSegmented ? { padding: '2rem' } : undefined}>{right}</div>
                    </TwoColumn>
                </Card>
                {inner && (
                    <Card isInner>
                        <OneColumn>{inner}</OneColumn>
                    </Card>
                )}
            </OneColumn>
        </Card>
    );
};
