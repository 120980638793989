import { useUserProfileQuery } from '@daisy/data-access';
import { setPermissions } from '@daisy/middleware-redux';
import { includes } from 'lodash';
import { ReactNode } from 'react';
import { useSelector } from 'react-redux';

import { RootState, useAppDispatch } from '../store';
import { UnauthorizedView } from '../views/UnauthorizedView';
import { LoadingContainer } from '@/components/LoadingContainer';

type PermissionGuardProps = {
    permission: string;
    showUnauthorizedView?: boolean;
    children?: ReactNode;
};

export const PermissionGuard: React.FC<PermissionGuardProps> = ({
    permission,
    showUnauthorizedView = false,
    children
}) => {
    const dispatch = useAppDispatch();
    const { permissions } = useSelector((store: RootState) => store.user);

    const { loading } = useUserProfileQuery({
        onCompleted: (data) => {
            if (!permissions && data) {
                const userPermissions = (data?.userProfile.profile.permissions || []) as string[];
                dispatch(setPermissions(userPermissions));
            }
        }
    });

    const missingPermissions =
        !permissions ||
        (!!permissions && (!permissions.length || !includes(permissions, permission)));

    if (loading) {
        return <LoadingContainer />;
    }

    if (missingPermissions) {
        if (showUnauthorizedView) return <UnauthorizedView />;
        return null;
    }

    return <>{children}</>;
};
