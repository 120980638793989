import { H2, Knob } from '@daisy/react-components';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { RootState } from '@/store';
import { StrictUnion } from '@/types/helpers';
import { TTPDownloadButton } from '../Buttons/TTPDownloadButton';
import { Row } from '../Row';
import { OneColumn } from '../styled/shared';

type TTPInfoProps = {
    serialNumber: string;
    time: string;
    onChange?: (active: boolean) => void;
    ttpFile?: StrictUnion<{ time: any; externalId?: string } | { time: any; ttpFilename?: string }>;
    isChecked?: boolean;
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 0.9rem;

    @media (min-width: 768px) {
        flex-direction: row;
    }
`;

export const TTPInfo = ({ serialNumber, time, onChange, ttpFile, isChecked }: TTPInfoProps) => {
    const { showOnlyLatestTTP } = useSelector((state: RootState) => state.queryEditor);
    return (
        <OneColumn gapSize="small">
            <H2>Parameter Info</H2>
            <Wrapper>
                <Row isVertical label="Serial number" value={serialNumber} />
                <Row isVertical label="TTP date" value={time} />
                {onChange && (
                    <Knob
                        value={0}
                        name="filterSN"
                        label="Show only this Serial Number"
                        onChange={onChange}
                        isChecked={isChecked}
                        disabled={showOnlyLatestTTP}
                    />
                )}
                <TTPDownloadButton serialNumber={serialNumber} ttpFile={ttpFile} />
            </Wrapper>
        </OneColumn>
    );
};
