"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Auth0SessionProvider = void 0;
class Auth0SessionProvider {
    constructor() {
        this.token = '';
        this.tenant = '';
        this.idToken = '';
    }
    getToken() {
        return this.token;
    }
    setToken(token) {
        this.token = token;
    }
    getTenant() {
        return this.tenant;
    }
    setTenant(tenant) {
        this.tenant = tenant;
    }
    getIdToken() {
        return this.idToken;
    }
    setIdToken(idToken) {
        this.idToken = idToken;
    }
}
exports.Auth0SessionProvider = Auth0SessionProvider;
