import * as React from 'react';
import styled from 'styled-components';

type JumboHeaderProps = {
    title?: string;
    subtitle?: string;
    image?: string;
    children?: React.ReactNode;
};

const Wrapper = styled.header`
    align-items: center;
    display: flex;
    column-gap: 1em;
    width: 100%;
`;

const TitleImage = styled.div<{ imagePath: string | undefined }>`
    height: 2.5em;
    width: 2.5em;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    ${({ imagePath }) => imagePath && `background-image: url(${imagePath});`}
`;

const TitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const Title = styled.h1`
    margin: 0;
    font-size: 1.5em;
    color: ${({ theme }) => theme.palette.jumboHeader.title};
`;

const Subtitle = styled.h2`
    margin: 0;
    font-size: 1em;
    line-height: 1.25rem;
    text-transform: uppercase;
    color: ${({ theme }) => theme.palette.jumboHeader.subtitle};
`;

export const PageTitle = ({ title = '', image = '', subtitle, children }: JumboHeaderProps) => (
    <Wrapper>
        {image && <TitleImage imagePath={image} />}
        <TitleWrapper>
            <Title id="jumbo_header_title">{title}</Title>
            {subtitle && <Subtitle>{subtitle}</Subtitle>}
        </TitleWrapper>

        {children}
    </Wrapper>
);
