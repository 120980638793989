"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapSpacings = exports.mapFontStyles = exports.getColorValue = void 0;
const getColorValue = (color) => color.value;
exports.getColorValue = getColorValue;
const mapFontStyles = (font) => ({
    fontFamily: font.fontFamily.value,
    fontSize: font.fontSize.value,
    letterSpacing: font.letterSpacing.value,
    lineHeight: `${font.lineHeight.value}px`,
    fontWeight: font.fontWeight.value,
    textTransform: font.textCase.value
});
exports.mapFontStyles = mapFontStyles;
const mapSpacings = (spacing) => ({
    top: spacing.top.value,
    right: spacing.right.value,
    bottom: spacing.bottom.value,
    left: spacing.left.value
});
exports.mapSpacings = mapSpacings;
